import React from "react"
import { RouteComponentProps } from "react-router"

import {
    GridListTile,
    Container,
    Grid,
    Drawer,
    GridList,
    Snackbar
} from "@material-ui/core"

import { Alert } from "@material-ui/lab"

import {
    ArrowBackRounded
} from "@material-ui/icons"

import logo from "../images/logo-smelt.png"

import CheckoutSteps from './components/checkout_steps'
import "../styles/checkout_pages.scss"

import { config } from "./../config"

import { BackendProjectFull, BackendCheckoutFull, BackendCheckout, BackendUserFull, BackendClientUserProject, BackendFreelancerProfile, BackendProposal, BackendPayment } from "./interfaces/backend"

const paymentsComponentsFreelancerMap: Record<string, Record<string, any>> = { // todo - change the texts here to suit freelancer
    "direct": { "title": "Direct with service provider", "client_fee": 0, "freelancer_fee": 0, "description": <div className="textPaymentDescription"><p>This payment method allows you to hire the project directly with the service provider. If you choose this option, you should contact the service provider to understand what would be the best payment method to do the transaction directly.</p><p>This payment method does not include quality protection by Smelt Technologies Inc.</p></div>, "extra_label": <p className="insuranceLabel">No insurance or<br /> quality guarantee</p> },
    "credit_card": { "title": "Credit Card", "client_fee": 0, "freelancer_fee": 0.02, "description": <div className="textPaymentDescription"><p>This payment method allows you to pay using a credit card with the money then being deposited to the freelancer provided bank account.</p><p>This payment method does not include quality protection by Smelt Technologies Inc.</p></div>, "extra_label": <p className="insuranceLabel">No insurance or<br /> quality guarantee</p> },
    "basic_escrow": { "title": "Basic Escrow", "client_fee": 0.03, "freelancer_fee": 0.02, "description": <div className="textPaymentDescription"><p>Basic escrow is great if you have the capability to evaluate the delivered project yourself.</p><p>Smelt Technologies Inc may resolve any conflicts between the parties, but will evaluate based on facts and arguments, and not based on the actual project delivered.</p></div>, "extra_label": <p className="insuranceLabel">Dispute-based Escrow</p> },
    "assurance_escrow": { "title": "Technical-assurance Escrow", "client_fee": 0.05, "freelancer_fee": 0.02, "description": <div className="textPaymentDescription"><p>Technical-assurance escrow guarantees technical quality to you.</p> <p>Before sending the project to you and releasing payments to the service provider, our technical team will evaluate the project after it has been delivered to check if it matches your proposal. Based on that you can then open a dispute or accept the deliverables.</p></div>, "extra_label": <p className="insuranceLabel">Technical-quality guaranteed</p> },
    "flexible": { "title": "Flexible (buy-now pay-later)", "client_fee": -1, "freelancer_fee": -1, "description": <div className="textPaymentDescription"><p>Flexible options are evaluated on a case-by-case basis and it allows you to not only guarantee quality of the project, but also pay only after you have received the project done.</p></div>, "extra_label": <p className="insuranceLabel">Technical-quality guaranteed<br />Pay after project delivered</p> }
}

const regexExpressions: Record<string, string> = {
    "cardNameCheck": "^((.|\n){0,512})$",
    "cardEmailheck": "^((.|\n){0,512})$",
    "cardAddressCheck": "^((.|\n){0,512})$",
    "cardCityCheck": "^((.|\n){0,512})$",
    "cardStateCheck": "^((.|\n){0,512})$",
    "cardCountryCheck": "^((.|\n){0,512})$",
    "cardZipCheck": "^((.|\n){0,512})$"
}

interface Params {
    id?: number
    noRedirect?: boolean
}

interface Props extends RouteComponentProps<{}> { }

interface State {
    freelancer_user_project_id?: number
    client_user_project_id?: number

    freelancer_email: string
    freelancer_name: string

    client_name: string
    client_email: string

    paymentMethodIndex: string

    project_id: number
    project_name: string
    project_current_state: string
    checkout_id: number
    payment_id?: number

    clients_users_projects: BackendClientUserProject[]

    checkout?: BackendCheckout
    freelancerProfile?: BackendFreelancerProfile
    payment?: BackendPayment

    is_freelancer: boolean
    is_client: boolean

    errorForm: boolean
    errorMessage: string
    errorDuration: number
}

class CheckPayment extends React.Component<Props, State> {
    setStateLikeSync(state: any) {
        return new Promise<void>((resolve) => {
            this.setState(state, resolve)
        });
    }

    showSnackMessage(message?: string, duration?: number) {
        if (message == undefined) {
            return
        }

        let actualDuration = duration ? duration : 3000

        this.setState({
            "errorForm": true,
            "errorMessage": message,
            "errorDuration": actualDuration
        })

        setTimeout(() => {
            this.setState({
                "errorForm": false,
                "errorMessage": "",
                "errorDuration": 0
            })
        }, actualDuration)
    }

    constructor(props: Props) {
        super(props)

        this.state = {
            freelancer_user_project_id: undefined,
            client_user_project_id: undefined,

            freelancer_email: "",
            freelancer_name: "",

            client_name: "",
            client_email: "",

            paymentMethodIndex: "",

            project_id: -1,
            project_name: "",
            project_current_state: "",
            checkout_id: -1,
            payment_id: undefined,

            clients_users_projects: [],

            checkout: undefined,
            freelancerProfile: undefined,
            payment: undefined,

            is_freelancer: false,
            is_client: false,

            errorForm: false,
            errorMessage: "",
            errorDuration: 0
        }
    }

    async componentDidMount() {
        let persistenceToken = localStorage.getItem("persistence_token")
        if (persistenceToken === null) {
            this.props.history.replace("/login")
            return
        }

        let params = this.parseUrlParams(this.props.location.search)

        if (params.id) {
            try {
                const response = await fetch(`${config.API_URL}/read_project?project_id=${encodeURIComponent(params.id!)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
                    method: 'get',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
                })
                if (!response.ok) {
                    // this.showSnackMessage(`An error has occured: ${response.status}`)
                }
                const responseJSON = await response.json();
                this.responseReadProjectResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.project_full, responseJSON.error)
            } catch (error) {
                this.showSnackMessage(`read-project-fetch-error: ${error}`)
            }

            try {
                let persistenceToken = localStorage.getItem("persistence_token")

                const response = await fetch(`${config.API_URL}/read_user?persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
                    method: 'get',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
                })
                if (!response.ok) {
                    // this.showSnackMessage(`An error has occured: ${response.status}`)
                }
                const responseJSON = await response.json();
                this.responseReadUserResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.user_full, responseJSON.error)
            } catch (error) {
                this.showSnackMessage(`read-user-fetch-error: ${error}`)
            }

            try {
                let persistenceToken = localStorage.getItem("persistence_token")
                let checkout_id = this.state.checkout_id

                const response = await fetch(`${config.API_URL}/read_checkout?checkout_id=${encodeURIComponent(checkout_id)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
                    method: 'get',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
                })
                if (!response.ok) {
                    // this.showSnackMessage(`An error has occured: ${response.status}`)
                }
                const responseJSON = await response.json();
                let isDone = this.responseReadCheckoutResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.checkout_full, responseJSON.error)
                if (isDone) {
                    return
                }
            } catch (error) {
                this.showSnackMessage(`read-checkout-fetch-error: ${error}`)
            }

            let isDone = this.redirect(5, params.noRedirect)
            if (isDone) {
                return
            }

            let payment_id = this.state.checkout?.payment_id
            if (payment_id) {
                try {
                    let persistenceToken = localStorage.getItem("persistence_token")

                    const response = await fetch(`${config.API_URL}/read_payment?payment_id=${encodeURIComponent(payment_id)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
                        method: 'get',
                        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
                    })
                    if (!response.ok) {
                        // this.showSnackMessage(`An error has occured: ${response.status}`)
                    }
                    const responseJSON = await response.json();
                    this.responseReadPaymentResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.payment, responseJSON.error)
                } catch (error) {
                    this.showSnackMessage(`read-payment-fetch-error: ${error}`)
                }
            }
        } else {
            this.showSnackMessage("Missing project_id")
            this.props.history.replace("/dashboard")
        }
    }

    parseUrlParams = (query: string): Params => {
        const urlSearchParams = new URLSearchParams(query)

        let params: Params = {
            id: urlSearchParams.get("id") === null ? undefined : Number(urlSearchParams.get("id")!),
            noRedirect: urlSearchParams.get("no_redirect") === null ? undefined : (urlSearchParams.get("no_redirect")! == "true")
        }
        return params
    }

    redirect = (currentStep: number, no_redirect?: boolean) => {
        let activeStep = this.getActiveStep()

        if (no_redirect) {
            return false
        }

        if (currentStep == activeStep) {
            return false
        }

        var pushString = undefined

        if (activeStep == 3) {
            pushString = `/view_project_briefing?id=${this.state.project_id}`
        } else if (activeStep == 4) {
            pushString = `/make_proposal?id=${this.state.project_id}`
        } else if (activeStep == 5) {
            pushString = `/check_payment?id=${this.state.project_id}`
        } else if (activeStep == 6) {
            pushString = `/make_delivery?id=${this.state.project_id}`
        } else if (activeStep == 7) {
            pushString = `/check_delivery?id=${this.state.project_id}`
        }

        if (pushString) {
            this.props.history.push(pushString)
        }

        return true
    }

    getActiveStep() {
        if (this.state.checkout?.briefing_step_status != undefined && this.state.checkout?.proposal_step_status == undefined) {
            return 3
        } else if (this.state.checkout?.proposal_step_status != undefined && this.state.checkout?.payment_step_status == undefined) {
            return 4
        } else if (this.state.checkout?.payment_step_status != undefined && this.state.checkout?.delivery_step_status == undefined) {
            return 5
        } else if (this.state.checkout?.delivery_step_status != undefined && this.state.checkout?.release_step_status == undefined) {
            return 6
        } else if (this.state.checkout?.release_step_status != undefined) {
            return 7
        }

        return 0
    }

    responseReadProjectResolve = (response: boolean, persistence_token?: string, project_full?: BackendProjectFull, error?: string): void => {
        if (response === true) {
            if ((project_full!.project.current_state !== "published") || project_full!.preferences_groups.length != 1) {
                this.showSnackMessage("Project is not published, or has 0 or more than 1 (not possible) preference group")
                this.props.history.replace("/dashboard")
                return
            }

            this.setState({
                "freelancer_email": project_full!.freelancers_users_projects[0].email,
                "freelancer_name": project_full!.freelancers_users_projects[0].first_name,
                "client_name": (project_full!.clients_users_projects.length == 1) ? project_full!.clients_users_projects[0].first_name : project_full!.freelancers_users_projects[0].first_name,
                "client_email": (project_full!.clients_users_projects.length == 1) ? project_full!.clients_users_projects[0].email : project_full!.freelancers_users_projects[0].email,
                "freelancer_user_project_id": project_full!.freelancer_user_project_id,
                "client_user_project_id": project_full!.client_user_project_id,
                "project_id": project_full!.project.id,
                "project_name": project_full!.project.name,
                "project_current_state": project_full!.project.current_state,
                "checkout_id": project_full!.checkouts[0].id,
                "payment_id": project_full!.checkouts[0].payment_id,
                "clients_users_projects": project_full!.clients_users_projects
            })
            localStorage.setItem("persistence_token", persistence_token as string)
            document.title = this.state.project_name + " - Smelt"
        } else {
            this.showSnackMessage(error)
            this.props.history.replace("/dashboard")
        }
    }

    responseReadUserResolve = (response: boolean, persistence_token?: string, user_full?: BackendUserFull, error?: string): void => {
        if (response === true) {
            this.setState({
                "is_freelancer": user_full!.is_freelancer,
                "is_client": user_full!.is_client
            })
            localStorage.setItem("persistence_token", persistence_token as string)
        } else {
            this.showSnackMessage(error) // user with token not loaded
            localStorage.removeItem("persistence_token")
            this.props.history.replace("/login")
        }
    }

    responseReadCheckoutResolve = (response: boolean, persistence_token?: string, checkout_full?: BackendCheckoutFull, error?: string): boolean => {
        if (response === true) {
            this.setState({
                "checkout": checkout_full!.checkout,
                "freelancerProfile": checkout_full!.freelancer_profile
            })
            localStorage.setItem("persistence_token", persistence_token as string)
        } else {
            this.showSnackMessage(error)
            this.props.history.replace("/dashboard")
            return true
        }
        return false
    }

    responseReadPaymentResolve = (response: boolean, persistence_token?: string, payment?: BackendPayment, error?: string) => {
        if (response === true) {
            this.setState((previousState) => ({
                "payment": payment ? payment : undefined,
                "paymentMethodIndex": payment ? JSON.parse(payment!.payment_option.replace(/'/g, '"')).name : previousState.paymentMethodIndex
            }))
            localStorage.setItem("persistence_token", persistence_token as string)
        } else {
            this.showSnackMessage(error)
        }
    }

    push = (page: string) => {
        this.props.history.push(page)
    }

    render() {
        let payment_option_name = this.state.payment?.payment_option ? JSON.parse(this.state.payment?.payment_option.replace(/'/g, '"')).name : "" // only if already paid

        return (
            <div className="mainStyle checkoutStyle checkoutStyleLeft">
                <Snackbar open={this.state.errorForm} autoHideDuration={this.state.errorDuration}>
                    <Alert severity="error">
                        {this.state.errorMessage}
                    </Alert>
                </Snackbar>

                <div className="drawerRoot">
                    <Drawer
                        className="drawerDrawer"
                        variant="permanent"
                        classes={{
                            paper: "drawerDrawerPaper"
                        }}
                        anchor="left">
                        <div className="topButtonMenu">
                            <a href="/dashboard">
                                <ArrowBackRounded /> <span>back</span>
                            </a>
                        </div>

                        <div className="logo">
                            <a href="/dashboard">
                                <img src={logo} width="115" height="32" />
                            </a>
                        </div>

                        <CheckoutSteps
                            id={this.state.checkout?.id}
                            project_id={this.state.checkout?.project_id}
                            briefing_step_status={this.state.checkout?.briefing_step_status}
                            proposal_step_status={this.state.checkout?.proposal_step_status}
                            payment_step_status={this.state.checkout?.payment_step_status}
                            delivery_step_status={this.state.checkout?.delivery_step_status}
                            release_step_status={this.state.checkout?.release_step_status}
                            preference_group_id={this.state.checkout?.preference_group_id}
                            proposal_id={this.state.checkout?.proposal_id}
                            payment_id={this.state.checkout?.payment_id}
                            delivery_id={this.state.checkout?.delivery_id}
                            release_id={this.state.checkout?.release_id}

                            is_freelancer={true}
                            is_client={false}
                            number_of_clients={this.state.clients_users_projects.length}

                            profile_id={this.state.freelancerProfile?.profile_id}
                            profile_image_link={this.state.freelancerProfile?.profile_image_link}
                            freelancer_name={this.state.freelancer_name}
                            job_title={this.state.freelancerProfile?.job_title}
                            city={this.state.freelancerProfile?.city}
                            country={this.state.freelancerProfile?.country}
                            job_description={this.state.freelancerProfile?.job_description}
                            project_name={this.state.project_name}

                            client_name={this.state.client_name}
                            client_email={this.state.client_email}

                            push={this.push}
                        />
                    </Drawer>

                    <main className="drawerContent">
                        <Container
                            maxWidth="md"
                            className="topCenterContent topCenterContentVerticalScroll">

                            <Grid container spacing={3}>
                                <Grid item xs={12}>

                                    <div className="clientHeader">
                                        <h2>Hi, {this.state.freelancer_name}!</h2>
                                        {this.state.payment && <p>Among the different options you made available for your customer to hire this project, below is the one your customer chose and its final amount given fees.</p>}
                                        <p>Please, contact the customer at <a href={this.state.client_email}>{this.state.client_email}</a> if you have any questions or reach out to us.</p>

                                    </div>

                                    <div className="titleBar">
                                        <div className="clear"></div>
                                    </div>

                                    <form autoComplete="off" onSubmit={e => e.preventDefault()}>
                                        <Grid
                                            container
                                            justify="flex-start"
                                            xs={12}
                                            direction="column"
                                            className="block"
                                            alignItems="stretch"
                                            spacing={1}>
                                            <Grid item xs={12}>

                                                <GridList
                                                    cellHeight="auto"
                                                    className="gridList"
                                                    cols={1}
                                                    spacing={10}>
                                                    <GridListTile
                                                        cols={1} className="noBorder">
                                                        <div className="questionBox">
                                                            {!this.state.payment && <p>Your proposal has been accepted but the payment for it is pending - we will let you know when it's done! :)</p>}
                                                            {this.state.payment && <>
                                                                <div className="investmentDescription left">
                                                                    <div className="clear"></div>

                                                                    <h3>Chosen Payment Method{this.state.checkout?.payment_step_status == "pending_internal" ? " - Pending Internal Approval" : ""}</h3>

                                                                    <p><strong>{paymentsComponentsFreelancerMap[payment_option_name].title}</strong></p>
                                                                    {paymentsComponentsFreelancerMap[payment_option_name].description}
                                                                </div>
                                                                <div className="investmentBox right">
                                                                    <h3>Total Receivable</h3>
                                                                    <p><span className="price">U$ <strong>{((this.state.payment?.amount ? this.state.payment?.amount : 0) - (this.state.payment?.freelancer_fee ? this.state.payment?.freelancer_fee : 0)) / 100}</strong>.00</span></p>
                                                                </div>
                                                                <div className="clear"></div>
                                                            </>}
                                                        </div>
                                                    </GridListTile>
                                                </GridList>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                            </Grid>
                        </Container>
                    </main>
                </div>
            </div>
        )
    }
}

export default CheckPayment