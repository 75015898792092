import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import Dashboard from './pages/dashboard'
import Login from './pages/login'
import Signup from './pages/signup'
import ForgotPassword from './pages/forgot_password'
import UpdatePassword from './pages/update_password'
import EditFreelancerInfo from './pages/edit_freelancer_info'
import EditLibraryTemplates from './pages/edit_library_templates'

import CreateProjectBasics from './pages/create_project_basics'
import CreateProjectBriefing from './pages/create_project_briefing'
import EditProjectBriefing from './pages/edit_project_briefing'
import ViewProjectBriefing from './pages/view_project_briefing'
import AnswerProjectBriefing from './pages/answer_project_briefing'
import MobilePreLaunch from './pages/mobile_pre_launch'
import NotFound from './pages/not_found'
import './App.scss';
import { useMediaQuery } from "@material-ui/core";
import { SegmentAnalytics } from '@segment/analytics.js-core';

import ProfessionalProfile from "./pages/professional_profile";
import ViewProposal from "./pages/view_proposal";
import StripeInjectedMakePayment from "./pages/make_payment";
import CheckDelivery from "./pages/check_delivery";
import MakeProposal from "./pages/make_proposal";
import MakeDelivery from "./pages/make_delivery";
import CheckPayment from "./pages/check_payment"

import AhazouLogo from "./pages/partners_payments/ahazou_logo"

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
  }
}

function App() {
  let isPageWide = useMediaQuery('(max-width: 968px)')

  function loadAnalyticsPageHandler(){
    window.analytics.page();
  }

  // TO-DO: load analytics just after everything is rendered
  loadAnalyticsPageHandler();

  return (
    <div className="App">
      {(!isPageWide || (window.location.href.indexOf('/u/') != -1 || window.location.href.indexOf('/profile/') != -1 || window.location.href.indexOf('/ahazou_logo') != -1)) && <Router>
          <Switch>
            <Route path="/" exact={true} component={Dashboard} /> {/* todo: make sure this redirection works */}
            <Route path="/login" exact={true} component={Login} />
            <Route path='/signup' exact={true} component={Signup} />
            <Route path="/forgot_password" exact={true} component={ForgotPassword} />
            <Route path="/update_password" exact={true} component={UpdatePassword} />
            <Route path="/dashboard" component={Dashboard} />            
            <Route path="/edit_freelancer_profile" exact={true} component={EditFreelancerInfo} />
            <Route path="/edit_library_templates" exact={true} component={EditLibraryTemplates} />
            <Route path="/create_project_basics" exact={true} component={CreateProjectBasics} />
            <Route path="/create_project_briefing" exact={true} component={CreateProjectBriefing} />
            <Route path="/edit_project_briefing" exact={true} component={EditProjectBriefing} />

            <Route path="/view_project_briefing" exact={true} component={ViewProjectBriefing} /> {/* freelancer */}
            <Route path="/answer_project_briefing" exact={true} component={AnswerProjectBriefing} /> {/* client */}

            <Route path="/make_proposal" exact={true} component={MakeProposal} />
            <Route path="/view_proposal" exact={true} component={ViewProposal} /> 

            <Route path="/check_payment" exact={true} component={CheckPayment} /> freelancer (when proposal is accepted, client see this waiting/pretty static payment page, and once it is done, he can see which option was chosen, and then go to delivery)
            <Route path="/make_payment" exact={true} component={StripeInjectedMakePayment} />

            <Route path="/make_delivery" exact={true} component={MakeDelivery} />
            <Route path="/check_delivery" exact={true} component={CheckDelivery} /> 

            {/* <Route path="/see_receipt" exact={true} component={SeeReceipt} /> freelancer & client (static page showing release information, same for both parties, on top of the releases table) */}            

            <Route path="/ahazou_logo" component={AhazouLogo} />

            <Route path="/404" component={NotFound} />
            <Route path="/profile/:profile_id" component={ProfessionalProfile} />
            <Route path="/u/:profile_id" component={ProfessionalProfile} />
            <Redirect from="*" to='/404' /> 
          </Switch>
        </Router>   
      }
      {isPageWide && (window.location.href.indexOf('/u/') == -1 && window.location.href.indexOf('/profile/') == -1 && window.location.href.indexOf('/ahazou_logo') == -1) && <MobilePreLaunch/> }
    </div>
  );
}

export default App;
