import React from "react"

import {
	ButtonBase,
	Chip,
	Grid,
	GridListTile,
	CardMedia,
	Modal,
	Fade,
	Backdrop,
	Button,
	FormGroup,
	FormControlLabel,
	Checkbox,
	TextField,
	Radio,
	RadioGroup
} from "@material-ui/core"

import {
	FormatQuoteRounded,
	InsertLinkRounded,
	ThumbUpRounded,
	ThumbDownRounded,
	NavigateNextRounded,
	InsertDriveFile,
	TextFormat
} from "@material-ui/icons"

import {
	Autocomplete,
	AutocompleteChangeReason
} from "@material-ui/lab"

import { config } from "./../../config"

import { BackendInputExtra } from "./../interfaces/backend"
import { FrontendAnswerExtra } from "./../interfaces/frontend"

interface Props {
	input_id: number
	input_order: number
	total_questions_section: number
	type: string
	is_required: boolean
	title: string
	free_text: string
	thumbnail?: Record<string, any>
	input_extras?: BackendInputExtra
	answer_extras?: FrontendAnswerExtra

	is_saved: boolean
	is_completed: boolean
	is_current: boolean

	free_text_check: boolean

	updateAnswer: (input_id: number, free_text?: string, is_saved?: boolean, is_skipped?: boolean, is_completed?: boolean, is_current?: boolean, answer_extra_params?: Record<string, any>) => void
	updateExtraAnswer: (input_id: number, like_dislike?: string, quote?: string, file_s3_link?: string, url_link?: string, specific_part?: string, relevance_reason?: string, options?: Record<string, any>[]) => void

	showSnackMessage: (message?: string, duration?: number) => void
}

interface State {
	like_dislike?: string

	openPictureModalInput: boolean
	openPictureModal: boolean[]

	// only for examples inputs
	exampleType?: string

	// only for url link preview
	url_preview_title?: string
	url_preview_description?: string
	url_preview_image?: string
}

const monthNames: Array<string> = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
// free_text_check

class InputCardView extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)

		this.state = {
			like_dislike: undefined,

			openPictureModalInput: false,
			openPictureModal: [],

			exampleType: undefined,

			url_preview_title: undefined,
			url_preview_description: undefined,
			url_preview_image: undefined
		}
	}

	async updateComponentType() {
		if (this.state.exampleType == null) {
			if (this.props.input_extras?.file_s3_link) {
				this.setState({ "exampleType": "image" })
			}
			else if (this.props.input_extras?.url_link) {
				this.setState({ "exampleType": "link" })

				let url_link = this.props.input_extras.url_link
				let persistenceToken = localStorage.getItem("persistence_token")

				try {
					const response = await fetch(`${config.API_URL}/get_url_preview?url_link=${url_link}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
						method: 'get',
						headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
					})
					if (!response.ok) {
						// this.props.showSnackMessage(`An error has occured: ${response.status}`)
					}
					const responseJSON = await response.json();
					this.responseGetUrlPreviewResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.url_preview_title, responseJSON.url_preview_description, responseJSON.url_preview_image, responseJSON.error)
				} catch (error) {
					this.props.showSnackMessage(`get-url-preview-error: ${error}`)
				}
			}
			else {
				if (this.props.input_extras?.quote) {
					this.setState({ "exampleType": "text" })
				}
			}
		}
	}

	async componentDidUpdate() {
		this.updateComponentType();
	}

	async componentDidMount() {
		this.updateComponentType();
	}

	responseGetUrlPreviewResolve = (response: boolean, persistence_token?: string, url_preview_title?: string, url_preview_description?: string, url_preview_image?: string, error?: string): void => {
		if (response === true) {
			localStorage.setItem(
				"persistence_token",
				persistence_token as string
			)
			this.setState({
				url_preview_title: url_preview_title!,
				url_preview_description: url_preview_description!,
				url_preview_image: url_preview_image!
			})

		} else {
			this.props.showSnackMessage(error) // likely a library problem loading url
		}
	}

	handleSkip = (event: React.MouseEvent<HTMLButtonElement>) => {
		this.props.updateAnswer(this.props.input_id, undefined, undefined, true, undefined, undefined, undefined)
	}

	handleChangeFreeText = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.props.updateAnswer(this.props.input_id, undefined, undefined, undefined, undefined, true, undefined) // any iteraction makes card the current one

		this.props.updateAnswer(this.props.input_id, event.target.value, undefined, undefined, undefined, undefined, undefined) // change and try to make it completed
	}

	handleChangeAnswerExtras = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.props.updateAnswer(this.props.input_id, undefined, undefined, undefined, undefined, true, undefined) // any iteraction makes card the current one

		const key = event.target.name
		const value = event.target.value

		if (key == "quote") {
			this.props.updateExtraAnswer(this.props.input_id, undefined, value, undefined, undefined, undefined, undefined, undefined)
		}
		if (key == "file_s3_link") {
			this.props.updateExtraAnswer(this.props.input_id, undefined, undefined, value, undefined, undefined, undefined, undefined)
		}
		if (key == "url_link") {
			this.props.updateExtraAnswer(this.props.input_id, undefined, undefined, undefined, value, undefined, undefined, undefined)
		}
		if (key == "specific_part") {
			this.props.updateExtraAnswer(this.props.input_id, undefined, undefined, undefined, undefined, value, undefined, undefined)
		}
		if (key == "relevance_reason") {
			this.props.updateExtraAnswer(this.props.input_id, undefined, undefined, undefined, undefined, undefined, value, undefined)
		}
	}

	handleCheck = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		this.props.updateAnswer(this.props.input_id, undefined, undefined, undefined, undefined, true, undefined) // any iteraction makes card the current one

		let option_check_order = event.target.name
		let option_check_name = event.target.value
		let already_has_option = this.props.answer_extras?.options?.map((option) => option.order).includes(option_check_order)

		var newOptions = (this.props.answer_extras?.options ? this.props.answer_extras?.options : [])

		if (checked) {
			if (!already_has_option) {
				newOptions = newOptions.concat({ "order": option_check_order, "name": option_check_name })
			}
		} else {
			if (already_has_option) {
				newOptions = newOptions.filter((option) => option.order != option_check_order)
			}
		}

		this.props.updateExtraAnswer(this.props.input_id, undefined, undefined, undefined, undefined, undefined, undefined, newOptions)
	}

	handleRadio = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		this.props.updateAnswer(this.props.input_id, undefined, undefined, undefined, undefined, true, undefined) // any iteraction makes card the current one

		let option_check_order = event.target.name
		let option_check_name = event.target.value

		var newOptions = [{ "order": option_check_order, "name": option_check_name }]

		this.props.updateExtraAnswer(this.props.input_id, undefined, undefined, undefined, undefined, undefined, undefined, newOptions)
	}

	handleInputChange = (event: React.ChangeEvent<{}>, value: any[], reason: AutocompleteChangeReason) => {
		this.props.updateAnswer(this.props.input_id, undefined, undefined, undefined, undefined, true, undefined) // any iteraction makes card the current one
		var newOptions = value.map((name, index) => ({ "order": index + 1, "name": name }))
		this.props.updateExtraAnswer(this.props.input_id, undefined, undefined, undefined, undefined, undefined, undefined, newOptions)
	}

	handleSelectLikeDislike = (like_dislike: string) => {
		this.props.updateAnswer(this.props.input_id, undefined, undefined, undefined, undefined, true, undefined) // any iteraction makes card the current one

		let current_like_dislike = this.props.answer_extras?.like_dislike

		if (like_dislike != current_like_dislike) {
			this.setState({ "like_dislike": like_dislike })
			this.props.updateExtraAnswer(this.props.input_id, like_dislike, undefined, undefined, undefined, undefined, undefined, undefined)
		}
	}

	handleSelectExampleType = (type: string) => {
		this.props.updateAnswer(this.props.input_id, undefined, undefined, undefined, undefined, true, undefined) // any iteraction makes card the current one

		this.setState({
			exampleType: type
		});
	}

	handleOpenPictureModalInput = () => {
		this.setState({ openPictureModalInput: true });
	}

	handleClosePictureModalInput = () => {
		this.setState({ openPictureModalInput: false });
	}


	handleOpenPictureModal = (optionOrder: number) => {

		var currentState = this.state.openPictureModal;
		currentState[optionOrder] = true;
		this.setState({ openPictureModal: currentState });
	}

	handleClosePictureModal = (optionOrder: number) => {
		var currentState = this.state.openPictureModal;
		currentState[optionOrder] = false;
		this.setState({ openPictureModal: currentState });
	}

	render() {
		return (
			<GridListTile
				cols={1}
				className={this.props.is_completed ? "activeTile" : ""}>
				<div className="questionBox">
					<label className="upperLabel left">
						{`Question ${this.props.input_order}/${this.props.total_questions_section}`}
					</label>
					{!this.props.is_required && (
						<Button
							className="defaultSmallButton right"
							endIcon={<NavigateNextRounded />}
							size="small"
							onClick={this.handleSkip}
						>
							Skip question
						</Button>
					)}
					<div className="clear"></div>
					<h3>{this.props.title}</h3>
					{!this.props.is_required && (
						<label className="helperLabel">Optional</label>
					)}
					{this.props.thumbnail && <div className="inputGroupOptionImage">

						<div className="illustration">

							<CardMedia
								image={this.props.thumbnail.thumbnail_img}
								onClick={this.handleOpenPictureModalInput} />
							<Modal
								className="modalPicture"
								aria-labelledby="transition-modal-title"
								aria-describedby="transition-modal-description"
								open={this.state.openPictureModalInput}
								onClose={this.handleClosePictureModal}
								closeAfterTransition
								BackdropComponent={Backdrop}
								BackdropProps={{ timeout: 500 }}
							>
								<Fade in={this.state.openPictureModalInput}>
									<div className="paperModal">
										<img src={this.props.thumbnail.thumbnail_img}></img>
									</div>
								</Fade>
							</Modal>
						</div>

						<label>{this.props.thumbnail.name}</label>

						{this.props.thumbnail.thumbnail_url && <div className="anchor">
							<InsertLinkRounded className="left" />
							<a href={this.props.thumbnail.thumbnail_url} className="left" target="_blank">{this.props.thumbnail.thumbnail_url}</a>
						</div>}

						<div className="clear"></div>

					</div>}
					<div className="clear"></div>

					<>
						{
							{
								question: (
									<TextField
										className="noOutline blueText"
										name={this.props.free_text}
										label="Type your text here"
										multiline
										fullWidth
										onChange={this.handleChangeFreeText}
										type="text"
										error={!this.props.free_text_check}
										helperText={!this.props.free_text_check ? 'Invalid Free Text' : ''}

									/>
								),
								checkbox: (
									<FormGroup className="fullWidth">
										{this.props.input_extras?.options?.map(
											(option) => {
												return (<div>
													{option.thumbnail_img && <div>
														<div className="groupOptionImage">

															<div className="illustration">

																<CardMedia image={option.thumbnail_img}
																	onClick={() => { this.handleOpenPictureModal(option.order as number) }} />
																<Modal
																	className="modalPicture"
																	aria-labelledby="transition-modal-title"
																	aria-describedby="transition-modal-description"
																	open={this.state.openPictureModal[option.order]}
																	onClose={() => { this.handleClosePictureModal(option.order as number) }}
																	closeAfterTransition
																	BackdropComponent={Backdrop}
																	BackdropProps={{ timeout: 500 }}
																>
																	<Fade in={this.state.openPictureModal[option.order]}>
																		<div className="paperModal">
																			<img src={option.thumbnail_img}></img>
																		</div>
																	</Fade>
																</Modal>
															</div>

															<Checkbox id={'input' + this.props.input_id + '_' + option.order} name={'input_' + this.props.input_id} className="left" value={option.name} onChange={this.handleCheck} />

															<label htmlFor={'input' + this.props.input_id + '_' + option.order}>{option.name}</label>

															{option.thumbnail_url && <div className="anchor">
																<InsertLinkRounded className="left" />
																<a href={option.thumbnail_url} className="left" target="_blank">{option.thumbnail_url}</a>
															</div>}

															<div className="clear"></div>

														</div>
														<div className="clear"></div></div>}
													{!option.thumbnail_img && <FormControlLabel
														control={<Checkbox name={'input_' + this.props.input_id} value={option.name} onChange={this.handleCheck} />}
														label={option.name}
													/>}
												</div>)
											}
										)}
									</FormGroup>
								),
								radio: (
									<FormGroup className="fullWidth">
										<RadioGroup name={'input_' + this.props.input_id}>
											{this.props.input_extras?.options?.map(
												(option) => {
													return (<div>
														{option.thumbnail_img && <div>
															<div className="groupOptionImage">

																<div className="illustration">

																	<CardMedia image={option.thumbnail_img}
																		onClick={() => { this.handleOpenPictureModal(option.order as number) }} />
																	<Modal
																		className="modalPicture"
																		aria-labelledby="transition-modal-title"
																		aria-describedby="transition-modal-description"
																		open={this.state.openPictureModal[option.order]}
																		onClose={() => { this.handleClosePictureModal(option.order as number) }}
																		closeAfterTransition
																		BackdropComponent={Backdrop}
																		BackdropProps={{ timeout: 500 }}
																	>
																		<Fade in={this.state.openPictureModal[option.order]}>
																			<div className="paperModal">
																				<img src={option.thumbnail_img}></img>
																			</div>
																		</Fade>
																	</Modal>
																</div>

																<Radio id={'input' + this.props.input_id + '_' + option.order} name='input_' value={option.name} onChange={this.handleRadio} />

																<label htmlFor={'input' + this.props.input_id + '_' + option.order}>{option.name}</label>

																{option.thumbnail_url && <div className="anchor">
																	<InsertLinkRounded className="left" />
																	<a href={option.thumbnail_url} className="left" target="_blank">{option.thumbnail_url}</a>
																</div>}

																<div className="clear"></div>

															</div>
															<div className="clear"></div></div>}
														{!option.thumbnail_img && <FormControlLabel
															control={<Radio name={'input_' + this.props.input_id} value={option.name} onChange={this.handleRadio} />}
															label={option.name}
														/>}
													</div>)
												}
											)}
										</RadioGroup>
									</FormGroup>
								),
								ask_feedback: (
									<div className="questionPart1">
										<div className="exampleAnswer">
											<div className="exampleContainer">
												<div className="left quoteArea">
													<div className="opinion">
														<p>{this.props.input_extras?.specific_part}</p>


														<p>
															{this.props.input_extras?.relevance_reason}
														</p>
													</div>


													{this.props.input_extras?.quote && <div>
														<FormatQuoteRounded className="left" />
														<div className="quote">
															{this.props.input_extras?.quote}
														</div>
													</div>
													}

													{this.props.input_extras?.url_link && (
														<div className="quote">
															<div className="linkDescription">
																<InsertLinkRounded className="left" />
																<a href={this.props.input_extras?.url_link} className="left" target="_blank">
																	{this.props.input_extras?.url_link}
																</a>
																<a href={this.props.input_extras?.url_link} className="right regularLink" target="_blank">
																	visit link
																</a>
															</div>
														</div>
													)}
												</div>
												{this.props.input_extras?.url_link && (
													<div className="illustration right">
														<CardMedia
															image={this.state.url_preview_image}
															onClick={() => { this.handleOpenPictureModal(0) }} />
														<Modal
															className="modalPicture"
															aria-labelledby="transition-modal-title"
															aria-describedby="transition-modal-description"
															open={this.state.openPictureModal[0]}
															onClose={() => { this.handleClosePictureModal(0) }}
															closeAfterTransition
															BackdropComponent={Backdrop}
															BackdropProps={{ timeout: 500 }}>
															<Fade in={this.state.openPictureModal[0]}>
																<div className="paperModal">
																	<img src={this.state.url_preview_image}></img>
																</div>
															</Fade>
														</Modal>
													</div>
												)}
												<div className="clear"></div>
											</div>
										</div>

										<Button
											className={`primaryButton ${this.state.like_dislike == "like" ? "" : "defaultButton"} smallButton`}
											variant="contained"
											color="primary"
											size="large"
											startIcon={<ThumbUpRounded />}
											onClick={() => this.handleSelectLikeDislike("like")}
										></Button>

										<Button
											className={`primaryButton ${this.state.like_dislike == "dislike" ? "" : "defaultButton"} smallButton`}
											variant="contained"
											color="primary"
											size="large"
											startIcon={<ThumbDownRounded />}
											onClick={() => this.handleSelectLikeDislike("dislike")}
										></Button>
										<div className="clear"></div>

										<div className="clear"></div>
										{this.state.like_dislike && <div className="extraInputs">
											<h4>
												Would you like to explain the
												reasons why you liked/disliked it?
												(optional)
											</h4>
											<TextField
												className="noOutline blueText"
												name={this.props.free_text}
												label="Type your text here"
												fullWidth
												onChange={this.handleChangeFreeText}
												type="text"
												error={!this.props.free_text_check}
												helperText={!this.props.free_text_check ? 'Invalid Free Text' : ''}
											/>
											<div className="clear"></div>
										</div>}
									</div>
								),
								ask_example: (
									<FormGroup className="fullWidth">
										<div className="addExamplePart1">
											<ButtonBase className={this.state.exampleType == 'link' ? "selectExampleType selectedExampleType" : "selectExampleType"} onClick={() => this.handleSelectExampleType('link')}>
												<InsertDriveFile />
												<label>Link</label>
											</ButtonBase>
											<ButtonBase className={this.state.exampleType == 'text' ? "selectExampleType selectedExampleType" : "selectExampleType"} onClick={() => this.handleSelectExampleType('text')}>
												<TextFormat />
												<label>Text</label>
											</ButtonBase>
										</div>
										{this.state.exampleType && <div className="addExamplePart2">
											<h4>Example details</h4>
											<Grid
												container
												spacing={2}
												direction="row">
												{this.state.exampleType == 'link' && <Grid item xs={12}>
													<TextField
														required
														className="outlinedStyle"
														name="url_link"
														label="Copy your link here"
														variant="outlined"
														onChange={this.handleChangeAnswerExtras}
														error={!this.props.answer_extras?.url_link_check}
														helperText={!this.props.answer_extras?.url_link_check ? 'Invalid url link' : ''}
													/>
												</Grid>}
												{this.state.exampleType == 'text' && <Grid item xs={12}>
													<TextField
														required
														className="outlinedStyle"
														name="quote"
														label="Copy your text here"
														multiline
														rows={2}
														variant="outlined"
														onChange={this.handleChangeAnswerExtras}
														error={!this.props.answer_extras?.quote_check}
														helperText={!this.props.answer_extras?.quote_check ? 'Invalid quote' : ''}
													/>
												</Grid>}
												<Grid item xs={12}>
													<TextField
														className="outlinedStyle"
														name="specific_part"
														label="Is there anything on this example that we should give special attention to?"
														multiline
														rows={2}
														variant="outlined"
														onChange={this.handleChangeAnswerExtras}
														error={!this.props.answer_extras?.specific_part_check}
														helperText={!this.props.answer_extras?.specific_part_check ? 'Invalid specific part' : ''}
													/>
												</Grid>
												<Grid item xs={12}>
													<TextField
														className="outlinedStyle"
														name="relevance_reason"
														label="Explain the reason why you would like to add this example / or what it illustrates."
														multiline
														rows={2}
														variant="outlined"
														onChange={this.handleChangeAnswerExtras}
														error={!this.props.answer_extras?.relevance_reason_check}
														helperText={!this.props.answer_extras?.relevance_reason_check ? 'Invalid relevance reason' : ''}
													/>
												</Grid>
											</Grid>
										</div>}
									</FormGroup>
								),
								multiline: (
									<Autocomplete
										onChange={this.handleInputChange}
										multiple
										options={this.props.input_extras?.options ? this.props.input_extras?.options?.map((option) => option.name) : []}
										freeSolo
										renderTags={(value, getTagProps) =>
											value.map((option, index) => (
												<Chip
													variant="outlined"
													label={option as string}
													{...getTagProps({ index })}
												/>
											))
										}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="outlined"

												label="Please, type your answer"
											/>
										)}
									/>
								)
							}[this.props.type]
						}
					</>

					<div className="clear"></div>
				</div>
			</GridListTile >
		)
	}
}

export default InputCardView
