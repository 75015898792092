
import { config } from "../config"
import { BackendClientUserProject } from "../pages/interfaces/backend";

export default class CoreAnalytics {

    static trackUser(user_id: string, email: string, first_name: string, last_name: string, is_client: boolean, is_freelancer: boolean) {
        window.analytics.identify(user_id, {
            firstName: first_name,
            lastName: last_name,
            isClient: is_client,
            isFreelancer: is_freelancer,
            email: email
        });
    }

    static trackSignUp(user_id: string, email: string, first_name: string, last_name: string, main_job: [string?] | undefined, is_client: boolean, is_freelancer: boolean) {
        window.analytics.identify(user_id, {
            firstName: first_name,
            lastName: last_name,
            isClient: is_client,
            mainJob: main_job,
            isFreelancer: is_freelancer,
            email: email
        });

        window.analytics.track("Signed Up", {
            first_name: first_name,
            last_name: last_name,
            email: email,
            context: {
                groupId: user_id
            }
        });
    }

    static trackSignIn(user_id: string, email: string) {
        window.analytics.identify(user_id, {
            email: email
        });

        window.analytics.track("Signed In", {
            username: email,
            context: {
                groupId: user_id
            }
        });
    }

    static trackAskForgetPassword(email: string) {
        window.analytics.track("Ask Forget Password", {
            email_input: email
        });
    }

    static trackArchiveProject(project_id: number) {
        window.analytics.track("Archive Project", {
            projectId: project_id
        });
    }

    static trackCreateProject(project_name: string, project_type: string) {
        window.analytics.track("Create Project", {
            projectName: project_name,
            projectType: project_type
        });
    }

    static trackCreateProjectBriefing(project_id: number, project_name: string, sections: string) {
        window.analytics.track("Create Project Briefing", {
            projectId: project_id,
            projectName: project_name,
            sections: sections
        });
    }

    static trackPublishProject(project_id: number) {
        window.analytics.track("Publish Project", {
            projectId: project_id
        });
    }

    static trackSaveProject(project_id: number) {
        window.analytics.track("Save Project", {
            projectId: project_id
        });
    }

    static trackProjectCreateInput(project_id: number, input_type: string | undefined, input_question: string | undefined) {
        window.analytics.track("Create Input on Project", {
            projectId: project_id,
            inputType: input_type,
            inputTitle: input_question
        });
    }

    static trackProjectCreateSection(project_id: number, section_name: string | undefined) {
        window.analytics.track("Create Section on Project", {
            projectId: project_id,
            sectionName: section_name
        });
    }

    static trackProjectDeleteInput(project_id: number, input_question: string | undefined) {
        window.analytics.track("Delete Input from Project", {
            projectId: project_id,
            inputTitle: input_question
        });
    }

    static trackProjectDeleteSection(project_id: number, section_name: string | undefined) {
        window.analytics.track("Delete Section from Project", {
            projectId: project_id,
            sectionName: section_name
        });
    }

    static trackProjectInviteMember(project_id: number, invited_member_email: string) {
        window.analytics.track("Invite Member to Project", {
            projectId: project_id,
            invitedMemberEmail: invited_member_email
        });

    }

    static trackLogout(user_id: string, email: string) {
        window.analytics.track("Signed Out", {
            username: email,
            context: {
                groupId: user_id
            }
        });
        window.analytics.reset();

    }

    static trackProjectFinishAnswer(project_id: number, project_name: string, section_name: string, freelancer_email: string, freelancer_name: string) {
        window.analytics.track("Finished Answering Project", {
            projectId: project_id,
            projectName: project_name,
            sectionName: section_name,
            freelancerEmail: freelancer_email,
            freelancerName: freelancer_name
        });
    }

    static trackProjectSkipAnswer(project_id: number, project_name: string, freelancer_email: string, freelancer_name: string) {
        window.analytics.track("Skipped Answering Project", {
            projectId: project_id,
            projectName: project_name,
            freelancerEmail: freelancer_email,
            freelancerName: freelancer_name
        });
    }

    static trackProjectAnswerSection(project_id: number, project_name: string, section_name: string) {
        window.analytics.track("Finished Answering Section", {
            projectId: project_id,
            projectName: project_name,
            sectionName: section_name
        });
    }

    static trackProjectCreatedByCustomer(project_id: number | undefined, project_name:string, freelancer_email: string, freelancer_name: string) {
        window.analytics.track("Customer Create Project", {
            projectId: project_id,
            projectName: project_name,
            freelancerEmail: freelancer_email,
            freelancerName: freelancer_name
        });
    }

    static trackProjectCreateProposal(project_id: number, project_name: string, customers: BackendClientUserProject[]){

        window.analytics.track("Create Proposal", {
            projectId: project_id,
            projectName: project_name,
            customers: customers
        });

    }

    static trackProjectCreateDelivery(project_id: number, project_name: string, customers: BackendClientUserProject[]){

        window.analytics.track("Create Delivery", {
            projectId: project_id,
            projectName: project_name,
            customers: customers
        });

    }

    static trackProjectAcceptProposal(project_id: number | undefined, project_name:string, freelancer_email: string, freelancer_name: string) {
        window.analytics.track("Customer Accept Proposal", {
            projectId: project_id,
            projectName: project_name,
            freelancerEmail: freelancer_email,
            freelancerName: freelancer_name
        });
    }

    static trackProjectMakePayment(project_id: number | undefined, project_name:string, freelancer_email: string, freelancer_name: string) {
        window.analytics.track("Customer Make Payment", {
            projectId: project_id,
            projectName: project_name,
            freelancerEmail: freelancer_email,
            freelancerName: freelancer_name
        });
    }


}