import React from "react"

import {
    Checkbox,
    TextField,
    Radio,
    IconButton,
    CardMedia,
    Modal,
    Backdrop,
    Fade
} from "@material-ui/core"

import {
    DeleteRounded,
    CheckRounded,
    ClearRounded,
    InsertLinkRounded
} from "@material-ui/icons"

interface Props {
    name: string
    order: number
    type: string
    thumbnail_img?: string
    thumbnail_url?: string

    is_view?: boolean
    is_saved?: boolean

    name_check?: boolean

    updateOption?: (order: number, name?: string, is_saved?: boolean, is_deleted?: boolean) => void
}

interface State {

    openPictureModal: boolean

}

class OptionCardView extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            openPictureModal: false
        }

    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (this.props.updateOption != undefined)
            this.props.updateOption(this.props.order, event.target.value, undefined, undefined)
    }

    handleSave = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (this.props.updateOption != undefined)
            this.props.updateOption(this.props.order, undefined, true, undefined)
    }

    handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (this.props.updateOption != undefined)
            this.props.updateOption(this.props.order, undefined, undefined, true)
    }

    handleDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (this.props.updateOption != undefined)
            this.props.updateOption(this.props.order, undefined, undefined, true)
    }

    handleOpenPictureModal = () => {
        this.setState({ openPictureModal: true });
    };

    handleClosePictureModal = () => {
        this.setState({ openPictureModal: false });
    };

    render() {

        return (
            <div className={!this.props.is_view ? "addOption" : "viewOption"}>
                {this.props.is_saved && <div>
                    {!this.props.thumbnail_img && <span>
                        {this.props.type == "checkbox" && <Checkbox
                            disabled
                            className="left"
                        />}
                        {this.props.type == "radio" && <Radio
                            disabled
                            className="left"
                        />}
                    </span>}
                    {!this.props.is_view && <div className="left">
                        <TextField
                            disabled
                            className="noOutline"
                            label={this.props.name}
                            name={this.props.name}
                            onChange={this.handleChange}
                        />
                        <IconButton onClick={this.handleDelete} className="right">
                            <DeleteRounded />
                        </IconButton>
                    </div>
                    }

                    {this.props.is_view && this.props.thumbnail_img && <div className="groupOptionImage">

                        <div className="illustration">

                            <CardMedia
                                image={this.props.thumbnail_img}
                                onClick={this.handleOpenPictureModal} />
                            <Modal
                                className="modalPicture"
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                open={this.state.openPictureModal}
                                onClose={this.handleClosePictureModal}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{ timeout: 500 }}
                            >
                                <Fade in={this.state.openPictureModal}>
                                    <div className="paperModal">
                                        <img src={this.props.thumbnail_img}></img>
                                    </div>
                                </Fade>
                            </Modal>
                        </div>

                        {this.props.type == "checkbox" && <Checkbox
                            disabled
                            className="left"
                        />}
                        {this.props.type == "radio" && <Radio
                            disabled
                            className="left"
                        />}
                        <label>{this.props.name}</label>

                        {this.props.thumbnail_url && <div className="anchor">
                            <InsertLinkRounded className="left" />
                            <a href={this.props.thumbnail_url} className="left" target="_blank">{this.props.thumbnail_url}</a>
                        </div>}

                        <div className="clear"></div>

                    </div>}

                    {this.props.is_view && !this.props.thumbnail_img && <label>{this.props.name}</label>}
                </div>}
                {!this.props.is_saved && <div>
                    {this.props.type == "checkbox" && <Checkbox
                        disabled
                        defaultChecked
                        className="left"
                    />}
                    {this.props.type == "radio" && <Radio
                        disabled
                        defaultChecked
                        className="left"
                    />}
                    {!this.props.is_view && <div className="left"><TextField
                        className="noOutline"
                        label="New option"
                        name={this.props.name}
                        onChange={this.handleChange}
                    />
                        <IconButton onClick={this.handleCancel} className="right">
                            <ClearRounded />
                        </IconButton>
                        <IconButton onClick={this.handleSave} className="right">
                            <CheckRounded />
                        </IconButton></div>
                    }
                    {this.props.is_view && <label>{this.props.name}</label>}
                </div>}
                <div className="clear"></div>
            </div>
        )
    }
}

export default OptionCardView