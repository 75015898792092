import React from "react"
import { RouteComponentProps } from "react-router"

import {
	Grid,
	TextField,
	Checkbox,
	FormGroup,
	Button,
	FormControlLabel,
	CircularProgress,
	Snackbar,
	Box
} from "@material-ui/core"

import { Alert } from "@material-ui/lab"

import {
	CheckRounded,
	ArrowForward
} from "@material-ui/icons"

import "../styles/general.scss"
import "../styles/unauthenticated.scss"
import logo from "../images/logo-smelt.png"

import CoreAnalytics from "../utils/core_analytics"
import { config } from "./../config"

import { BackendUserFull } from "./interfaces/backend"

interface Params { }

interface Props extends RouteComponentProps<{}> { }

interface State {
	email: string
	password: string

	isLoadingForm: boolean
	successForm: boolean

	emailCheck: boolean
	passwordCheck: boolean

	errorForm: boolean
	errorMessage: string
	errorDuration: number
}

const regexExpressions: Record<string, string | RegExp> = {
	emailCheck: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	passwordCheck: /^.{6,64}$/
}

class Login extends React.Component<Props, State> {
	setStateLikeSync(state: any) {
		return new Promise<void>((resolve) => {
			this.setState(state, resolve)
		});
	}

	showSnackMessage(message?: string, duration?: number) {
		if (message == undefined) {
			return
		}

		let actualDuration = duration ? duration : 3000

		this.setState({
			"errorForm": true,
			"errorMessage": message,
			"errorDuration": actualDuration
		})

		setTimeout(() => {
			this.setState({
				"errorForm": false,
				"errorMessage": "",
				"errorDuration": 0
			})
		}, actualDuration)
	}

	constructor(props: any) {
		super(props)

		this.state = {
			email: "",
			password: "",
			isLoadingForm: false,
			successForm: false,

			emailCheck: true,
			passwordCheck: true,

			errorForm: false,
			errorMessage: "",
			errorDuration: 0
		}
	}

	async componentDidMount() {
		document.title = "Login - Smelt"

		let persistenceToken = localStorage.getItem("persistence_token")
		if (persistenceToken) {
			try {
				const response = await fetch(`${config.API_URL}/read_user?persistence_token=${encodeURIComponent(localStorage.getItem("persistence_token") as string)}`, { // todo: create parser function, and interface for params?
					method: 'get',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				this.responseReadUserResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.user_full, responseJSON.error)
			} catch (error) {
				this.showSnackMessage(`read-user-fetch-error: ${error}`)
			}
		}
	}

	responseReadUserResolve = (response: boolean, persistence_token?: string, user_full?: BackendUserFull, error?: string): void => {
		if (response === true) {
			localStorage.setItem("persistence_token", persistence_token as string)
			this.props.history.push("/dashboard")
		} else {
			this.showSnackMessage(error) // user with token not loaded
			localStorage.removeItem("persistence_token")
		}
	}

	responseLoginResolve = (response: boolean, persistence_token?: string, user_full?: BackendUserFull, error?: string): void => {
		if (response === true) {
			CoreAnalytics.trackSignIn(this.state.email, this.state.email)

			this.setState({ successForm: true });
			localStorage.setItem("persistence_token", persistence_token as string)
			var el = this;
			setTimeout(() => {
				el.props.history.push('/dashboard');
			}, 500);
		} else {
			this.setState({ successForm: false });

			if (error == 'invalid-password') {
				this.setState({
					passwordCheck: false
				});
			}
			else if (error == 'invalid-email') {
				this.setState({
					emailCheck: false
				});
			}
			else if (error == 'no-token-generation') {
				this.setState({
					passwordCheck: false,
					emailCheck: false
				});
			}
		}
	}

	handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const key = event.target.name
		const keyCheck = event.target.name + "Check"
		const value = event.target.value
		const valueCheck = RegExp(regexExpressions[keyCheck]).test(value)

		this.setState({ [key]: value, [keyCheck]: valueCheck } as any)
	}

	handleSubmit = async (event: React.MouseEvent<any>) => { // todo: change any type to submit
		event.preventDefault()

		await this.setStateLikeSync({
			emailCheck: (RegExp(regexExpressions["emailCheck"])).test(this.state.email),
			passwordCheck: (RegExp(regexExpressions["passwordCheck"])).test(this.state.password)
		})

		let allValidationsPass = this.state.emailCheck && this.state.passwordCheck

		if (allValidationsPass) {
			try {
				await this.setStateLikeSync({ isLoadingForm: true });

				const response = await fetch(`${config.API_URL}/login`, {
					method: 'post',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
					body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
						email: this.state.email,
						password: this.state.password
					})
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();

				await this.setStateLikeSync({ isLoadingForm: false });
				this.responseLoginResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.user_full, responseJSON.error)
			} catch (error) {
				this.setState({ isLoadingForm: false });
				this.showSnackMessage(`login-fetch-error: ${error}`)
			}
		} else {
			//this.showSnackMessage('not all validations passed - fix and try again')
		}

	}

	render() {
		return (
			<Grid container className="guestContainer" spacing={0}>
				<Snackbar open={this.state.errorForm} autoHideDuration={this.state.errorDuration}>
					<Alert severity="error">
						{this.state.errorMessage}
					</Alert>
				</Snackbar>

				<Grid item xs={5}>
					<Box className="mainFormArea">
						<Grid container justify="center">
							<Grid item xs={12}>
								<div className="logo">
									<a href="/login">
										<img
											src={logo}
											width="150"
											height="43"
										/>
									</a>
								</div>
							</Grid>

							<Grid item xs={12}>
								<form autoComplete="off">
									<Grid
										container
										justify="flex-start"
										xs={6}
										direction="column"
										className="block"
										alignItems="stretch"
										spacing={1}>
										<h2>Login</h2>

										<Grid item xs={12}>
											<TextField
												fullWidth
												required
												label="Email"
												name="email"
												onChange={this.handleChange}
												type="text"
												error={!this.state.emailCheck}
												helperText={!this.state.emailCheck ? 'Invalid Email' : ''}
											/>
										</Grid>

										<Grid item xs={12}>
											<TextField
												fullWidth
												required
												name="password"
												label="Password"
												onChange={this.handleChange}
												type="password"
												error={!this.state.passwordCheck}
												helperText={!this.state.passwordCheck ? 'Invalid Password' : ''}
											/>
										</Grid>

										<Grid item xs={12}>
											<Grid container>
												<Grid item xs={6}>
													<FormGroup>
														<FormControlLabel
															control={
																<Checkbox name="remember_me" />
															}
															label="Remember me"
														/>
													</FormGroup>
												</Grid>
												<Grid item xs={6}>
													<a
														href="/forgot_password"
														className="defaultLink right">
														Forgot password?
													</a>
												</Grid>
											</Grid>
										</Grid>

										<Grid item xs={12}>
											<Button
												variant="contained"
												color="primary"
												size="large"
												type="submit"
												fullWidth
												className="primaryButton"
												startIcon={(this.state.isLoadingForm ? <CircularProgress
													size={20}
												/> : (this.state.successForm ? <CheckRounded /> : <ArrowForward />))}
												onClick={this.handleSubmit}>
												login
											</Button>
										</Grid>
										{/*
										<Grid item xs={12}>
											<label>
												Not registered yet?{" "}
												<a
													href="/signup"
													className="primaryLink">
													Sign up
												</a>
											</label>
										</Grid>*/}
									</Grid>
								</form>
							</Grid>
						</Grid>
					</Box>
				</Grid>
				<Grid item xs={7}>
					<Box className="heroArea">
						<Grid container justify="center">
							<Grid item xs={12}>
								<h1>
									Bringing creative workers and companies together.
								</h1>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>
		)
	}
}

export default Login
