import React from "react"
import { RouteComponentProps } from "react-router"

import {
    Container,
    Grid,
    Drawer,
    Avatar,
    Popover,
    ButtonBase,
    Button,
    GridList,
    Snackbar
} from "@material-ui/core"

import { AvatarGroup, Alert, AlertTitle } from "@material-ui/lab"

import {
    AddRounded,
    ArrowBackRounded,
    OpenInNew
} from "@material-ui/icons"

import "../styles/checkout_pages.scss"
import logo from "../images/logo-smelt.png"

import MemberCard from './components/member_card'
import ViewAnswers from './components/view_answers'
import CheckoutSteps from './components/checkout_steps'

import CoreAnalytics from "../utils/core_analytics"
import { config } from "./../config"

import { BackendProjectFull, BackendFreelancerUserProject, BackendClientUserProject, BackendUserFull, BackendCheckoutFull, BackendCheckout, BackendFreelancerProfile, BackendPreferenceGroupFull, BackendSection, BackendInput, BackendInputExtra, BackendPreferenceGroupAnswers, BackendAnswer, BackendAnswerExtra, BackendFreelancerInvitation, BackendClientInvitation } from "./interfaces/backend"
import { FrontendFreelancerInvitation, FrontendClientInvitation } from "./interfaces/frontend"

interface Params {
    id?: number
    invitationToken?: string
    noRedirect?: boolean
}

interface Props extends RouteComponentProps<{}> { }

interface State {
    freelancer_name: string
    client_name: string
    client_email: string

    freelancer_user_project_id?: number
    client_user_project_id?: number

    project_id: number
    project_name: string
    project_current_state: string
    checkout_id: number
    preference_group_id: number

    freelancers_invitations: FrontendFreelancerInvitation[]
    clients_invitations: FrontendClientInvitation[]
    freelancers_users_projects: BackendFreelancerUserProject[]
    clients_users_projects: BackendClientUserProject[]

    checkout?: BackendCheckout
    freelancerProfile?: BackendFreelancerProfile

    sections: BackendSection[]
    inputs: BackendInput[]
    inputs_extras: BackendInputExtra[]
    answersBackend: BackendAnswer[]
    answersExtrasBackend: BackendAnswerExtra[]

    count_sections: number

    section_selected_id: number
    section_selected_order: number
    section_selected_name: string

    openMembersPopover: boolean
    memberAnchorRef: React.RefObject<HTMLButtonElement>

    is_freelancer: boolean
    is_client: boolean

    errorForm: boolean
    errorMessage: string
    errorDuration: number
}

const currentStateComponentsMap: Record<string, Record<string, any>> = {
    "draft": { "color": "yellowChip" }, // should never happen
    "published": { "color": "greenChip" },
    "archived": { "color": "grayChip" }
}

const regexExpressions: Record<string, RegExp> = {
    invitationEmailCheck: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
}

class ViewProjectBriefing extends React.Component<Props, State> {
    setStateLikeSync(state: any) {
        return new Promise<void>((resolve) => {
            this.setState(state, resolve)
        });
    }

    showSnackMessage(message?: string, duration?: number) {
        if (message == undefined) {
            return
        }

        let actualDuration = duration ? duration : 3000

        this.setState({
            "errorForm": true,
            "errorMessage": message,
            "errorDuration": actualDuration
        })

        setTimeout(() => {
            this.setState({
                "errorForm": false,
                "errorMessage": "",
                "errorDuration": 0
            })
        }, actualDuration)
    }

    constructor(props: Props) {
        super(props)

        this.state = {
            freelancer_name: "",
            client_name: "",
            client_email: "",

            freelancer_user_project_id: undefined,
            client_user_project_id: undefined,

            project_id: -1,
            project_name: "",
            project_current_state: "published",
            checkout_id: -1,
            preference_group_id: -1,

            freelancers_invitations: [],
            clients_invitations: [],
            freelancers_users_projects: [],
            clients_users_projects: [],

            checkout: undefined,
            freelancerProfile: undefined,

            sections: [],
            inputs: [],
            inputs_extras: [],
            answersBackend: [],
            answersExtrasBackend: [],

            count_sections: -1,

            section_selected_id: -1,
            section_selected_order: -1,
            section_selected_name: "",

            openMembersPopover: false,
            memberAnchorRef: React.createRef(),

            is_freelancer: false,
            is_client: false,

            errorForm: false,
            errorMessage: "",
            errorDuration: 0
        }
    }

    async componentDidMount() {
        let persistenceToken = localStorage.getItem("persistence_token")
        if (persistenceToken === null) {
            this.props.history.replace("/login")
            return
        }

        let params = this.parseUrlParams(this.props.location.search)

        if (params.invitationToken) {
            try {
                const response = await fetch(`${config.API_URL}/execute_invitation`, {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
                    body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
                        persistence_token: persistenceToken,
                        invitation_token: params.invitationToken!
                    })
                })
                if (!response.ok) {
                    // this.showSnackMessage(`An error has occured: ${response.status}`)
                }
                const responseJSON = await response.json();
                this.responseExecuteInvitation(responseJSON.response, responseJSON.persistence_token, responseJSON.redirect_to_project_id, responseJSON.error)
            } catch (error) {
                this.showSnackMessage(`execute-invitation-fetch-error: ${error}`)
            }
        } else if (params.id) {
            try {
                const response = await fetch(`${config.API_URL}/read_project?project_id=${encodeURIComponent(params.id!)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
                    method: 'get',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
                })
                if (!response.ok) {
                    // this.showSnackMessage(`An error has occured: ${response.status}`)
                }
                const responseJSON = await response.json();
                let isDone = this.responseReadProjectResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.project_full, responseJSON.error)
                if (isDone) {
                    return
                }
            } catch (error) {
                this.showSnackMessage(`read-project-fetch-error: ${error}`)
            }

            try {
                let persistenceToken = localStorage.getItem("persistence_token")
                let project_id = this.state.project_id

                const response = await fetch(`${config.API_URL}/list_project_invitations?project_id=${encodeURIComponent(project_id)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
                    method: 'get',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
                })
                if (!response.ok) {
                    // this.showSnackMessage(`An error has occured: ${response.status}`)
                }
                const responseJSON = await response.json();
                let isDone = this.responseListProjectInvitationsResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.freelancers_invitations, responseJSON.clients_invitations, responseJSON.error)
                if (isDone) {
                    return
                }
            } catch (error) {
                this.showSnackMessage(`list-project-invitations-fetch-error: ${error}`)
            }

            try {
                let persistenceToken = localStorage.getItem("persistence_token")

                const response = await fetch(`${config.API_URL}/read_user?persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
                    method: 'get',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
                })
                if (!response.ok) {
                    // this.showSnackMessage(`An error has occured: ${response.status}`)
                }
                const responseJSON = await response.json();
                this.responseReadUserResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.user_full, responseJSON.error)
            } catch (error) {
                this.showSnackMessage(`read-user-fetch-error: ${error}`)
            }

            try {
                let persistenceToken = localStorage.getItem("persistence_token")
                let checkout_id = this.state.checkout_id

                const response = await fetch(`${config.API_URL}/read_checkout?checkout_id=${encodeURIComponent(checkout_id)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
                    method: 'get',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
                })
                if (!response.ok) {
                    // this.showSnackMessage(`An error has occured: ${response.status}`)
                }
                const responseJSON = await response.json();
                let isDone = this.responseReadCheckoutResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.checkout_full, responseJSON.error)
                if (isDone) {
                    return
                }
            } catch (error) {
                this.showSnackMessage(`read-checkout-fetch-error: ${error}`)
            }

            let isDone = this.redirect(3, params.noRedirect)
            if (isDone) {
                return
            }

            try {
                let persistenceToken = localStorage.getItem("persistence_token")
                let preference_group_id = this.state.preference_group_id

                const response = await fetch(`${config.API_URL}/read_preference_group?preference_group_id=${encodeURIComponent(preference_group_id)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
                    method: 'get',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
                })
                if (!response.ok) {
                    // this.showSnackMessage(`An error has occured: ${response.status}`)
                }
                const responseJSON = await response.json();
                let isDone = this.responseReadPreferenceGroupResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.preference_group_full, responseJSON.error)
                if (isDone) {
                    return
                }
            } catch (error) {
                this.showSnackMessage(`read-preference-group-fetch-error: ${error}`)
            }

            try {
                let persistenceToken = localStorage.getItem("persistence_token")
                let preference_group_id = this.state.preference_group_id

                const response = await fetch(`${config.API_URL}/read_preference_group_answers?preference_group_id=${encodeURIComponent(preference_group_id)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
                    method: 'get',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
                })
                if (!response.ok) {
                    // this.showSnackMessage(`An error has occured: ${response.status}`)
                }
                const responseJSON = await response.json();
                this.responseReadPreferenceGroupAnswersResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.preference_group_answers, responseJSON.error)
            } catch (error) {
                this.showSnackMessage(`read-preference-group-answers-fetch-error: ${error}`)
            }
        } else {
            this.showSnackMessage("Missing invitation_id or project_id")
            this.props.history.replace("/dashboard")
        }
    }

    parseUrlParams = (query: string): Params => {
        const urlSearchParams = new URLSearchParams(query)

        let params: Params = {
            id: urlSearchParams.get("id") === null ? undefined : Number(urlSearchParams.get("id")!),
            invitationToken: urlSearchParams.get("invitation_token") === null ? undefined : urlSearchParams.get("invitation_token")!,
            noRedirect: urlSearchParams.get("no_redirect") === null ? undefined : (urlSearchParams.get("no_redirect")! == "true")
        }
        return params
    }

    redirect = (currentStep: number, no_redirect?: boolean) => {
        let activeStep = this.getActiveStep()

        if (no_redirect) {
            return false
        }

        if (currentStep == activeStep) {
            return false
        }

        var pushString = undefined

        if (activeStep == 3) {
            pushString = `/view_project_briefing?id=${this.state.project_id}`
        } else if (activeStep == 4) {
            pushString = `/make_proposal?id=${this.state.project_id}`
        } else if (activeStep == 5) {
            pushString = `/check_payment?id=${this.state.project_id}`
        } else if (activeStep == 6) {
            pushString = `/make_delivery?id=${this.state.project_id}`
        } else if (activeStep == 7) {
            pushString = `/make_delivery?id=${this.state.project_id}`
        }

        if (pushString) {
            this.props.history.push(pushString)
        }

        return true
    }

    getActiveStep() {
        if (this.state.checkout?.briefing_step_status != undefined && this.state.checkout?.proposal_step_status == undefined) {
            return 3
        } else if (this.state.checkout?.proposal_step_status != undefined && this.state.checkout?.payment_step_status == undefined) {
            return 4
        } else if (this.state.checkout?.payment_step_status != undefined && this.state.checkout?.delivery_step_status == undefined) {
            return 5
        } else if (this.state.checkout?.delivery_step_status != undefined && this.state.checkout?.release_step_status == undefined) {
            return 6
        } else if (this.state.checkout?.release_step_status != undefined) {
            return 7
        }

        return 0
    }

    responseExecuteInvitation = (response: boolean, persistence_token?: string, redirect_to_project_id?: number, error?: string): void => {
        if (response === true) {
            this.showSnackMessage("invitation successful")
            this.props.history.replace(`/view_project_briefing?id=${redirect_to_project_id!}`)
            window.location.reload()
        } else {
            this.showSnackMessage(error)
        }
    }

    responseReadProjectResolve = (response: boolean, persistence_token?: string, project_full?: BackendProjectFull, error?: string): boolean => {
        if (response === true) {
            if ((project_full!.project.current_state !== "published" && project_full!.project.current_state !== "archived") || project_full!.preferences_groups.length != 1) {
                this.showSnackMessage("Project is not published or archived, or has 0 or more than 1 (not possible) preference group")
                this.props.history.replace("/dashboard")
                return true
            }

            this.setState({
                "freelancer_name": project_full!.freelancers_users_projects[0].first_name,
                "client_name": (project_full!.clients_users_projects.length == 1) ? project_full!.clients_users_projects[0].first_name : project_full!.freelancers_users_projects[0].first_name,
                "client_email": (project_full!.clients_users_projects.length == 1) ? project_full!.clients_users_projects[0].email : project_full!.freelancers_users_projects[0].email,
                "freelancer_user_project_id": project_full!.freelancer_user_project_id,
                "client_user_project_id": project_full!.client_user_project_id,
                "project_id": project_full!.project.id,
                "project_name": project_full!.project.name,
                "project_current_state": project_full!.project.current_state,
                "checkout_id": project_full!.checkouts[0].id,
                "preference_group_id": project_full!.preferences_groups[0].id,
                "clients_users_projects": project_full!.clients_users_projects,
                "freelancers_users_projects": project_full!.freelancers_users_projects,
            })
            localStorage.setItem("persistence_token", persistence_token as string)
            document.title = project_full!.project.name + " - Smelt"
        } else {
            this.showSnackMessage(error)
            this.props.history.replace("/login")
            return true
        }
        return false
    }

    responseListProjectInvitationsResolve = (response: boolean, persistence_token?: string, freelancers_invitations?: BackendFreelancerInvitation[], clients_invitations?: BackendClientInvitation[], error?: string): boolean => {
        if (response === true) {
            this.setState({
                "freelancers_invitations": freelancers_invitations!.map((freelancer_invitation) => ({ "invitation_email": freelancer_invitation.invitation_email, "invitation_email_check": true, "created_at": freelancer_invitation.created_at, "is_saved": true })), // check so there is always at least one section
                "clients_invitations": clients_invitations!.map((client_invitation) => ({ "invitation_email": client_invitation.invitation_email, "invitation_email_check": true, "created_at": client_invitation.created_at, "is_saved": true }))
            })
            localStorage.setItem("persistence_token", persistence_token as string)
        } else {
            this.showSnackMessage(error)
            this.props.history.replace("/login")
            return true
        }
        return false
    }

    responseReadUserResolve = (response: boolean, persistence_token?: string, user_full?: BackendUserFull, error?: string): void => {
        if (response === true) {
            this.setState({
                "is_freelancer": user_full!.is_freelancer,
                "is_client": user_full!.is_client
            })
            localStorage.setItem("persistence_token", persistence_token as string)
        } else {
            this.showSnackMessage(error) // user with token not loaded
            localStorage.removeItem("persistence_token")
            this.props.history.replace("/login")
        }
    }

    responseReadCheckoutResolve = (response: boolean, persistence_token?: string, checkout_full?: BackendCheckoutFull, error?: string): boolean => {
        if (response === true) {
            this.setState({
                "checkout": checkout_full!.checkout,
                "freelancerProfile": checkout_full!.freelancer_profile
            })
            localStorage.setItem("persistence_token", persistence_token as string)
        } else {
            this.showSnackMessage(error)
            this.props.history.replace("/login")
            return true
        }
        return false
    }

    responseReadPreferenceGroupResolve = (response: boolean, persistence_token?: string, preference_group_full?: BackendPreferenceGroupFull, error?: string): boolean => {
        if (response === true) {
            if (preference_group_full!.preference_group.current_state !== "published" && preference_group_full!.preference_group.current_state !== "archived") {
                this.showSnackMessage("Preference group not is not published or archived - should never happen and always match the project published or archived")
                this.props.history.replace("/dashboard")
                return true
            }

            this.setState({
                "section_selected_id": preference_group_full!.sections.filter((section) => section.order == 1)[0].id, // check so there is always at least one section
                "section_selected_order": 1,
                "section_selected_name": preference_group_full!.sections.filter((section) => section.order == 1)[0].name,
                "sections": preference_group_full!.sections.map((section) => ({ "id": section.id, "preference_group_id": section.preference_group_id, "name": section.name, "order": section.order })),
                "inputs": preference_group_full!.inputs.map((input) => ({ "id": input.id, "section_id": input.section_id, "freelancer_project_id": input.freelancer_project_id, "order": input.order, "type": input.type, "is_required": input.is_required, "title": input.title, "thumbnail": JSON.parse(((input.thumbnail ? input.thumbnail : "{}")).replace(/'/g, '"')), "input_extra_id": input.input_extra_id })),
                "inputs_extras": preference_group_full!.inputs_extras.map((input_extra) => ({ "id": input_extra.id, "quote": input_extra.quote, "file_s3_link": input_extra.file_s3_link, "url_link": input_extra.url_link, "specific_part": input_extra.specific_part, "relevance_reason": input_extra.relevance_reason, "options": (input_extra.options ? input_extra.options : []).map(option => JSON.parse(option.replace(/'/g, '"'))) })),
                "count_sections": preference_group_full!.sections.length,
            })
            localStorage.setItem("persistence_token", persistence_token as string)
        } else {
            this.showSnackMessage(error)
            this.props.history.replace("/login")
            return true
        }
        return false
    }

    responseReadPreferenceGroupAnswersResolve = (response: boolean, persistence_token?: string, preference_group_answers?: BackendPreferenceGroupAnswers, error?: string): void => {
        if (response === true) {
            this.setState({
                "answersBackend": preference_group_answers!.answers.map((answer) => ({ "id": answer.id, "input_id": answer.input_id, "client_project_id": answer.client_project_id, "has_skipped": answer.has_skipped, "free_text": answer.free_text, "answer_extra_id": answer.answer_extra_id, "created_at": answer.created_at })), // check so there is always at least one section
                "answersExtrasBackend": preference_group_answers!.answers_extras.map((answer_extra) => ({ "id": answer_extra.id, "like_dislike": answer_extra.like_dislike, "quote": answer_extra.quote, "file_s3_link": answer_extra.file_s3_link, "url_link": answer_extra.url_link, "specific_part": answer_extra.specific_part, "relevance_reason": answer_extra.relevance_reason, "options": (answer_extra.options ? answer_extra.options : []).map(option => JSON.parse(option.replace(/'/g, '"'))) }))
            })
            localStorage.setItem("persistence_token", persistence_token as string)

        } else {
            this.showSnackMessage(error)
            this.props.history.replace("/login")
        }
    }

    responseCreateProjectInvitationResolve = (response: boolean, persistence_token?: string, error?: string, extras?: Record<string, any>): void => {
        if (response === true) {
            let created_at = extras!.created_at

            this.setState(previousState => {
                let newClientsInvitations = previousState.clients_invitations.map((client_invitation) => {
                    var newClientInvitation = client_invitation

                    if (newClientInvitation.created_at === created_at) {
                        newClientInvitation.is_loading_save = false
                        newClientInvitation.is_saved = true
                    }

                    return newClientInvitation
                })

                return { "clients_invitations": newClientsInvitations }
            })
        } else {
            let created_at = extras!.created_at
            this.showSnackMessage(error)
            this.setState(previousState => {
                let newClientsInvitations = previousState.clients_invitations.map((client_invitation) => {
                    var newClientInvitation = client_invitation

                    if (newClientInvitation.created_at === created_at) {
                        newClientInvitation.is_loading_save = false
                    }

                    return newClientInvitation
                })

                return { "clients_invitations": newClientsInvitations }
            })
        }
    }

    responseSaveCheckoutResolve = (response: boolean, persistence_token?: string, checkout_id?: number, error?: string): void => {
        if (response === true) {
            localStorage.setItem("persistence_token", persistence_token as string)
            this.props.history.push(`make_proposal?id=${this.state.project_id}`)
        } else {
            this.showSnackMessage(error) // some regex problem?
        }
    }

    responseSavePreferenceGroupResolve = (response: boolean, persistence_token?: string, error?: string): void => {
        if (response === true) {
            localStorage.setItem("persistence_token", persistence_token as string)
        } else {
            this.showSnackMessage(error) // some regex problem?
        }
    }

    updateInvitation = async (created_at: Date, invitation_email?: string, type?: string, is_saved?: boolean, is_deleted?: boolean) => {
        if (type == "freelancer") { // type will always have to be client on the MVP, so we do not really handle type changes here nor freelancer functions
            return
        }

        if (is_saved) {
            let persistenceToken = localStorage.getItem("persistence_token")
            let project_id = this.state.project_id

            let client_invitation = this.state.clients_invitations.filter((client_invitation) => created_at === client_invitation.created_at)[0]
            let invitationType = "client"
            let email = client_invitation.invitation_email
            let project_permissions = ["is_preferences_responder", "is_checkout_editor"]

            let newClientsInvitations = this.state.clients_invitations.filter((newClientInvitation) => (newClientInvitation.created_at !== client_invitation.created_at || (newClientInvitation.created_at === client_invitation.created_at && !is_deleted))).map((newClientInvitation) => {
                if (newClientInvitation.created_at === client_invitation.created_at) {
                    if (client_invitation.invitation_email) {
                        newClientInvitation.is_loading_save = true
                    }
                }

                return newClientInvitation
            })
            await this.setStateLikeSync({ "clients_invitations": newClientsInvitations })

            await CoreAnalytics.trackProjectInviteMember(this.state.project_id, email);

            try {
                const response = await fetch(`${config.API_URL}/create_project_invitation`, {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
                    body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
                        persistence_token: persistenceToken,
                        project_id: project_id,
                        type: invitationType,
                        email: email,
                        project_permissions: project_permissions
                    })
                })
                if (!response.ok) {
                    // this.showSnackMessage(`An error has occured: ${response.status}`)
                }
                const responseJSON = await response.json();

                this.responseCreateProjectInvitationResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.error, { "created_at": created_at })
            } catch (error) {
                this.showSnackMessage(`create-project-invitations-fetch-error: ${error}`)
            }
        } else {
            let newClientsInvitations = this.state.clients_invitations.filter((client_invitation) => (client_invitation.created_at !== created_at || (client_invitation.created_at === created_at && !is_deleted))).map((client_invitation) => {
                var newClientInvitation = client_invitation

                if (newClientInvitation.created_at === created_at) {
                    if (invitation_email) {
                        newClientInvitation.invitation_email = invitation_email
                        newClientInvitation.invitation_email_check = (RegExp(regexExpressions["invitationEmailCheck"])).test(invitation_email)
                    }
                }

                return newClientInvitation
            })
            await this.setStateLikeSync({ "clients_invitations": newClientsInvitations })
        }
    }

    handleAddInvitation = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        event.preventDefault();

        this.setState(previousState => { // must be of type client
            return { "clients_invitations": previousState.clients_invitations.concat({ "invitation_email": "", "invitation_email_check": false, "created_at": (new Date(Date.now())), is_saved: false }) }
        })
    }

    handleToggle = async () => {
        let newOpenMembersPopover = !this.state.openMembersPopover
        await this.setStateLikeSync({ "openMembersPopover": newOpenMembersPopover })

        if (this.state.memberAnchorRef.current !== null) {
            if (this.state.openMembersPopover === false) {
                this.state.memberAnchorRef.current.focus()
            }
        }
    }

    handleClose = (event: React.MouseEvent<Document | HTMLLIElement | HTMLButtonElement>) => {
        if (null !== this.state.memberAnchorRef.current) {
            if (this.state.memberAnchorRef.current && this.state.memberAnchorRef.current.contains(event.target as Node)) {
                return
            }
        }

        this.setState(({ openMembersPopover: false }))
    }

    handleSaveInvitations = (event: React.MouseEvent<Document | HTMLLIElement | HTMLButtonElement>) => {
        if (null !== this.state.memberAnchorRef.current) {
            if (this.state.memberAnchorRef.current && this.state.memberAnchorRef.current.contains(event.target as Node)) {
                return
            }
        }

        var self = this;
        this.state.clients_invitations.map((client_invitation_obj) => {
            if (client_invitation_obj.is_saved == false) {
                self.updateInvitation(client_invitation_obj.created_at, undefined, undefined, true, undefined)
            }
        });

        // to-do: change for a callback to close it instead of waiting 2s. 2s are important to make sure the customer sees it loading
        setTimeout(() => {
            self.setState(({ openMembersPopover: false }))
        }, 2000);
    }

    handleSeeClientView = (event: React.MouseEvent<Document | HTMLLIElement | HTMLButtonElement>) => {
        window.open(`/answer_project_briefing?id=${this.state.project_id}`, "_blank")
    }

    handleSkipFreelancer = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()

        var persistenceToken = localStorage.getItem("persistence_token")

        try {
            await this.setStateLikeSync({ isLoadingForm: true });

            const response = await fetch(`${config.API_URL}/save_checkout`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
                body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
                    persistence_token: persistenceToken,
                    action: "update",
                    checkout_id: this.state.checkout_id,
                    checkout_params: { "briefing_step_status": "skipped_freelancer", "proposal_step_status": "pending_freelancer" }
                })
            })
            if (!response.ok) {
                // this.showSnackMessage(`An error has occured: ${response.status}`)
            }
            const responseJSON = await response.json();
            await this.setStateLikeSync({ isLoadingForm: false });
            this.responseSaveCheckoutResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.checkout_id, responseJSON.error)
        } catch (error) {
            this.showSnackMessage(`save-checkout-fetch-error: ${error}`)
        }

        let sectionSelectedId = this.state.section_selected_id
        persistenceToken = localStorage.getItem("persistence_token")
    }

    handleMarkAsReviewed = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()

        var persistenceToken = localStorage.getItem("persistence_token")

        try {
            const response = await fetch(`${config.API_URL}/save_preference_group`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
                body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
                    persistence_token: persistenceToken,
                    action: "update",
                    preference_group_id: this.state.preference_group_id,
                    preference_group_params: { "has_been_reviewed": true }
                })
            })
            if (!response.ok) {
                // this.showSnackMessage(`An error has occured: ${response.status}`)
            }
            const responseJSON = await response.json();
            this.responseSavePreferenceGroupResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.error)
        } catch (error) {
            this.showSnackMessage(`save-preference-group-fetch-error: ${error}`)
        }

        persistenceToken = localStorage.getItem("persistence_token")

        try {
            await this.setStateLikeSync({ isLoadingForm: true });

            const response = await fetch(`${config.API_URL}/save_checkout`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
                body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
                    persistence_token: persistenceToken,
                    action: "update",
                    checkout_id: this.state.checkout_id,
                    checkout_params: { "briefing_step_status": "completed", "proposal_step_status": "pending_freelancer" }
                })
            })
            if (!response.ok) {
                // this.showSnackMessage(`An error has occured: ${response.status}`)
            }
            const responseJSON = await response.json();
            await this.setStateLikeSync({ isLoadingForm: false });
            this.responseSaveCheckoutResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.checkout_id, responseJSON.error)
        } catch (error) {
            this.showSnackMessage(`save-checkout-fetch-error: ${error}`)
        }
    }

    goToPreviousSection = () => {
        let previousSection = this.state.sections.find((section) => (section.order == (this.state.section_selected_order - 1)))
        if (!previousSection) {
            return
        }
        this.setState({
            "section_selected_id": previousSection.id,
            "section_selected_order": previousSection.order,
            "section_selected_name": previousSection.name
        })
    }

    goToNextSection = () => {
        let nextSection = this.state.sections.find((section) => (section.order == (this.state.section_selected_order + 1)))

        if (!nextSection) {
            return
        }
        this.setState({
            "section_selected_id": nextSection.id,
            "section_selected_order": nextSection.order,
            "section_selected_name": nextSection.name
        })
    }

    push = (page: string) => {
        this.props.history.push(page)
    }

    render() {
        let MembersCardFreelancers = this.state.freelancers_users_projects.map((freelancer_user_project) => {
            return (
                <MemberCard
                    invitation_email={freelancer_user_project.email}
                    type={"freelancer"}
                    invitation_email_check={true}
                    is_loading_save={false}
                    created_at={undefined}
                    is_saved={true}
                    is_invitation={false}
                    is_self={freelancer_user_project.freelancer_project_id === this.state.freelancer_user_project_id}
                    updateInvitation={this.updateInvitation}
                />
            )
        })

        let MembersCardClients = this.state.clients_users_projects.map((client_user_project) => {
            return (
                <MemberCard
                    invitation_email={client_user_project.email}
                    type={"client"}
                    invitation_email_check={true}
                    is_loading_save={false}
                    created_at={undefined}
                    is_saved={true}
                    is_invitation={false}
                    is_self={client_user_project.client_project_id === this.state.client_user_project_id}
                    updateInvitation={this.updateInvitation}
                />
            )
        })

        let MembersCardFreelancersInvitations = this.state.freelancers_invitations.map((freelancer_invitation) => {
            return (
                <MemberCard
                    invitation_email={freelancer_invitation.invitation_email}
                    type={"freelancer"}
                    invitation_email_check={freelancer_invitation.invitation_email_check}
                    created_at={freelancer_invitation.created_at}
                    is_loading_save={freelancer_invitation.is_loading_save}
                    is_saved={freelancer_invitation.is_saved}
                    is_invitation={true}
                    is_self={false}
                    updateInvitation={this.updateInvitation}
                />
            )
        })

        let MembersCardClientsInvitations = this.state.clients_invitations.map((client_invitation) => {
            return (
                <MemberCard
                    invitation_email={client_invitation.invitation_email}
                    type={"client"}
                    invitation_email_check={client_invitation.invitation_email_check}
                    created_at={client_invitation.created_at}
                    is_loading_save={client_invitation.is_loading_save}
                    is_saved={client_invitation.is_saved}
                    is_invitation={true}
                    is_self={false}
                    updateInvitation={this.updateInvitation}
                />
            )
        })

        let initialsAllMembers = ([] as string[]).concat(this.state.freelancers_users_projects.map((user) => { return String(user!.first_name).charAt(0) }), this.state.clients_users_projects.map((user) => { return String(user!.first_name).charAt(0) }));
        let initialsMembersAvatars = initialsAllMembers.map((initial_member) => {
            return (
                <Avatar alt={initial_member}>
                    {initial_member}
                </Avatar>
            )
        })

        return (
            <div className="mainStyle checkoutStyle checkoutStyleLeft">
                <Snackbar open={this.state.errorForm} autoHideDuration={this.state.errorDuration}>
                    <Alert severity="error">
                        {this.state.errorMessage}
                    </Alert>
                </Snackbar>

                <div className="drawerRoot">
                    <Drawer
                        className="drawerDrawer"
                        variant="permanent"
                        classes={{
                            paper: "drawerDrawerPaper"
                        }}
                        anchor="left">
                        <div className="topButtonMenu">
                            <a href="/dashboard">
                                <ArrowBackRounded /> <span>back</span>
                            </a>
                        </div>

                        <div className="logo">
                            <a href="/dashboard">
                                <img src={logo} width="115" height="32" />
                            </a>
                        </div>


                        <CheckoutSteps
                            id={this.state.checkout?.id}
                            project_id={this.state.checkout?.project_id}
                            briefing_step_status={this.state.checkout?.briefing_step_status}
                            proposal_step_status={this.state.checkout?.proposal_step_status}
                            payment_step_status={this.state.checkout?.payment_step_status}
                            delivery_step_status={this.state.checkout?.delivery_step_status}
                            release_step_status={this.state.checkout?.release_step_status}
                            preference_group_id={this.state.checkout?.preference_group_id}
                            proposal_id={this.state.checkout?.proposal_id}
                            payment_id={this.state.checkout?.payment_id}
                            delivery_id={this.state.checkout?.delivery_id}
                            release_id={this.state.checkout?.release_id}

                            is_freelancer={true}
                            is_client={false}
                            number_of_clients={this.state.clients_users_projects.length}

                            profile_id={this.state.freelancerProfile?.profile_id}
                            profile_image_link={this.state.freelancerProfile?.profile_image_link}
                            freelancer_name={this.state.freelancer_name}
                            job_title={this.state.freelancerProfile?.job_title}
                            city={this.state.freelancerProfile?.city}
                            country={this.state.freelancerProfile?.country}
                            job_description={this.state.freelancerProfile?.job_description}
                            project_name={this.state.project_name}

                            client_name={this.state.client_name}
                            client_email={this.state.client_email}

                            push={this.push}
                        />
                    </Drawer>

                    <main className="drawerContent">
                        <Container
                            maxWidth="lg"
                            className="topCenterContent topContent">
                            <Grid container>
                                <Grid item xs={12}>
                                    <div className="titleBar">
                                        <h1>Understand Project Requirements</h1>

                                        <div className="keyboardTipsBar">
                                            <ButtonBase
                                                ref={this.state.memberAnchorRef}
                                                focusRipple
                                                className="customButton"
                                                onClick={this.handleToggle}>
                                                <label className="avatarGroupLabel left">
                                                    invite customers
                                                </label>
                                                <AvatarGroup max={4} className="left">
                                                    {initialsMembersAvatars}
                                                </AvatarGroup>
                                            </ButtonBase>
                                            <Popover
                                                open={this.state.openMembersPopover}
                                                anchorEl={this.state.memberAnchorRef.current}
                                                className="inviteMembersPopover"
                                                onClose={this.handleClose}
                                                anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "center"
                                                }}
                                                transformOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center"
                                                }}>
                                                <div className="leftTitleBox">
                                                    <h3>Invite new customers</h3>
                                                    <p>
                                                        Send invitation links to team
                                                        members
                                                    </p>
                                                </div>
                                                <div className="clear"></div>
                                                <p></p>
                                                <div className="membersList">
                                                    <GridList
                                                        cellHeight={45}
                                                        className="gridList"
                                                        cols={1}
                                                        spacing={10}>
                                                        {MembersCardFreelancers}
                                                        {MembersCardClients}
                                                        {MembersCardFreelancersInvitations}
                                                        {MembersCardClientsInvitations}
                                                    </GridList>
                                                    <div className="buttons">
                                                        {this.state.freelancer_user_project_id && <Button
                                                            startIcon={<AddRounded />}
                                                            variant="outlined"
                                                            className="outlined left"
                                                            size="medium"
                                                            onClick={this.handleAddInvitation}
                                                        >
                                                            add member
                                                        </Button>}
                                                        <Button onClick={this.handleSaveInvitations}
                                                            variant="contained"
                                                            color="primary"
                                                            size="medium"
                                                            className="primaryButton right">
                                                            save
                                                        </Button>
                                                        <div className="clear"></div>
                                                    </div>
                                                </div>
                                            </Popover>

                                            {this.state.checkout?.briefing_step_status == "pending_client" && <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                className="primaryButton largeButton right"
                                                onClick={this.handleSkipFreelancer}
                                            >
                                                skip and go to proposal
                                            </Button>}

                                            {this.state.checkout?.briefing_step_status == "pending_freelancer" && <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                className="primaryButton largeButton right"
                                                onClick={this.handleMarkAsReviewed}
                                            >
                                                go to proposal
                                            </Button>}

                                            <Button
                                                size="small"
                                                className="smallButton right"
                                                onClick={this.handleSeeClientView}
                                            >
                                                <OpenInNew />
                                                see client view
                                            </Button>
                                        </div>
                                        <div className="clear"></div>

                                        {(this.state.clients_invitations.length == 0 && this.state.clients_users_projects.length == 0) && <Alert severity="info" className="headerMessage">
                                            Tip: Invite a customer to answer your questionnaire and wait for response. Or skip questionnaire and go to proposal.
                                        </Alert>}

                                        {(this.state.clients_invitations.length > 0 || this.state.clients_users_projects.length > 0) && this.state.checkout?.briefing_step_status == "pending_client" && <Alert severity="info" className="headerMessage">
                                            Tip: Wait for your customer's response or skip questionnaire and go to proposal.
                                        </Alert>}

                                        {this.state.checkout?.briefing_step_status == "pending_freelancer" && <Alert severity="info" className="headerMessage">
                                            Tip: See below your customer's expectations for the project. When you're done reviewing it, go to the proposal page to create a custom proposal!
                                        </Alert>}

                                    </div>
                                </Grid>
                            </Grid>

                            <ViewAnswers
                                freelancer_user_project_id={this.state.freelancer_user_project_id}
                                client_user_project_id={this.state.client_user_project_id}

                                project_id={this.state.project_id}
                                project_name={this.state.project_name}
                                project_current_state={this.state.project_current_state}
                                preference_group_id={this.state.preference_group_id}

                                sections={this.state.sections}
                                inputs={this.state.inputs}
                                inputs_extras={this.state.inputs_extras}
                                answers={this.state.answersBackend}
                                answers_extras={this.state.answersExtrasBackend}

                                clients_users_projects={this.state.clients_users_projects}

                                section_selected_id={this.state.section_selected_id}
                                section_selected_name={this.state.section_selected_name}
                                section_selected_order={this.state.section_selected_order}

                                count_sections={this.state.count_sections}

                                goToPreviousSection={this.goToPreviousSection}
                                goToNextSection={this.goToNextSection}

                                showSnackMessage={this.showSnackMessage}
                            />
                        </Container>
                    </main>
                </div>
            </div>
        )
    }
}

export default ViewProjectBriefing