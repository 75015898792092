import React from "react"
import { RouteComponentProps } from "react-router"

import {
	Button,
	Container,
	Drawer,
	Grid,
	TextField,
	Snackbar
} from "@material-ui/core"

import {
	AccountCircle,
	AddIcCallRounded,
	ArrowBackRounded,
	ArrowForward,
	Facebook,
	LinkedIn,
	PublicRounded,
	RadioButtonUnchecked
} from "@material-ui/icons"

import {
	Skeleton,
	Autocomplete,
	AutocompleteChangeReason,
	Alert
} from "@material-ui/lab"

import logo from "../images/logo-smelt.png"
import exampleImage from "../images/smilig-woman-hero-small.png";

import { config } from "../config"

import { BackendFreelancerProfile } from "./interfaces/backend"

interface Props extends RouteComponentProps<{}> { }

interface State {
	jobTitle: string
	jobDescription: string
	city: string
	state: string
	country: string
	profileId: string
	calendly: string,
	welcomeMessage: string
	profileImageLink: string
	linkedinLink: string
	facebookLink: string
	portfolioLink: string

	jobTitleCheck: boolean
	jobDescriptionCheck: boolean
	cityCheck: boolean
	stateCheck: boolean
	countryCheck: boolean
	profileIdCheck: boolean
	calendlyCheck: boolean
	welcomeMessageCheck: boolean
	profileImageLinkCheck: boolean
	linkedinLinkCheck: boolean
	facebookLinkCheck: boolean
	portfolioLinkCheck: boolean

	errorForm: boolean
	errorMessage: string
	errorDuration: number
}

const regexExpressions: Record<string, string> = {
	"jobTitleCheck": "^((.|\n){0,64})$",
	"jobDescriptionCheck": "^((.|\n){0,1024})$",
	"cityCheck": "^([A-Za-z ]{0,32})$",
	"stateCheck": "^([A-Za-z ]{0,32})$",
	"countryCheck": "^([A-Za-z ]{0,32})$",
	"profileIdCheck": "^([A-Za-z0-9+_-]{1,64})$",
	"calendlyCheck": "(^$|https://calendly.com\/([\.\/A-Za-z0-9+_-]{0,256})$)",
	"welcomeMessageCheck": "^((.|\n){0,1024})$",
	"profileImageLinkCheck": "^(.{0,256})$",
	"linkedinLinkCheck": "(^$|linkedin\.com\/in\/([\.\/A-Za-z0-9+_-]{0,256})$)",
	"facebookLinkCheck": "(^$|facebook\.com\/([\.\/A-Za-z0-9+_-]{0,256})$)",
	"portfolioLinkCheck": "(^$|^(.{0,256})$)",
}

class EditFreelancerInfo extends React.Component<Props, State> {
	setStateLikeSync(state: any) {
		return new Promise<void>((resolve) => {
			this.setState(state, resolve)
		});
	}

	showSnackMessage(message?: string, duration?: number) {
		if (message == undefined) {
			return
		}

		let actualDuration = duration ? duration : 3000

		this.setState({
			"errorForm": true,
			"errorMessage": message,
			"errorDuration": actualDuration
		})

		setTimeout(() => {
			this.setState({
				"errorForm": false,
				"errorMessage": "",
				"errorDuration": 0
			})
		}, actualDuration)
	}

	constructor(props: Props) {
		super(props)

		this.state = {
			jobTitle: "",
			jobDescription: "",
			city: "",
			state: "",
			country: "",
			profileId: "",
			calendly: "",
			welcomeMessage: "",
			profileImageLink: "",
			linkedinLink: "",
			facebookLink: "",
			portfolioLink: "",

			jobTitleCheck: true,
			jobDescriptionCheck: true,
			cityCheck: true,
			stateCheck: true,
			countryCheck: true,
			profileIdCheck: true,
			calendlyCheck: true,
			welcomeMessageCheck: true,
			profileImageLinkCheck: true,
			linkedinLinkCheck: true,
			facebookLinkCheck: true,
			portfolioLinkCheck: true,

			errorForm: false,
			errorMessage: "",
			errorDuration: 0
		}
	}

	async componentDidMount() {
		let persistenceToken = localStorage.getItem("persistence_token")
		if (persistenceToken === null) {
			this.props.history.replace("/login")
			return
		}

		try {
			const response = await fetch(`${config.API_URL}/read_freelancer_profile?persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
				method: 'get',
				headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
			})
			if (!response.ok) {
				// this.showSnackMessage(`An error has occured: ${response.status}`)
			}
			const responseJSON = await response.json();
			this.responseReadFreelancerProfileResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.freelancer_name, responseJSON.freelancer_email, responseJSON.freelancer_profile, responseJSON.error)
		} catch (error) {
			this.showSnackMessage(`read-freelancer-profile-fetch-error: ${error}`)
		}

		document.title = "Edit freelancer profile - Smelt"
	}

	responseReadFreelancerProfileResolve = (response: boolean, persistence_token?: string, freelancer_name?: string, freelancer_email?: string, freelancer_profile?: BackendFreelancerProfile, error?: string): void => {
		if (response === true) {
			let profile_share_links_parsed = freelancer_profile?.profile_share_links ? freelancer_profile?.profile_share_links.map(profile_share_link => JSON.parse(profile_share_link.replace(/'/g, '"'))) : []

			let linkedin_share_link_list = profile_share_links_parsed ? profile_share_links_parsed.filter((profile_share_link) => (profile_share_link.name == "Linkedin")) : []
			let facebook_share_link_list = profile_share_links_parsed ? profile_share_links_parsed.filter((profile_share_link) => (profile_share_link.name == "Facebook")) : []
			let portfolio_share_link_list = profile_share_links_parsed ? profile_share_links_parsed.filter((profile_share_link) => (profile_share_link.name == "Portfolio")) : []

			this.setState({
				"jobTitle": freelancer_profile!.job_title,
				"jobDescription": freelancer_profile!.job_description,
				"city": freelancer_profile!.city,
				"state": freelancer_profile!.state,
				"country": freelancer_profile!.country,
				"profileId": freelancer_profile!.profile_id,
				"profileIdCheck": (RegExp(regexExpressions["profileIdCheck"])).test(freelancer_profile!.profile_id ? freelancer_profile!.profile_id : ""),
				"calendly": freelancer_profile!.calendly ? freelancer_profile!.calendly : "",
				"welcomeMessage": freelancer_profile!.welcome_message ? freelancer_profile!.welcome_message : `I'm ${freelancer_name} and I would love to know more about your project. This is my quote page, to understand your project needs, send you proposals and even deliver your project in the end in a streamlined and organized way. To start your project, just ask a quote choosing the project type below that best fits your needs and if you have time, fill out your preferences form before our first call. \n\nTake care, \n${freelancer_name}.`,
				"profileImageLink": freelancer_profile!.profile_image_link,
				"linkedinLink": linkedin_share_link_list.length == 1 ? linkedin_share_link_list[0].url as string : "",
				"facebookLink": facebook_share_link_list.length == 1 ? facebook_share_link_list[0].url as string : "",
				"portfolioLink": portfolio_share_link_list.length == 1 ? portfolio_share_link_list[0].url as string : ""
			})
			localStorage.setItem("persistence_token", persistence_token as string)
		} else {
			this.showSnackMessage(error) // likely not freelancer creator or is client
			this.props.history.replace("/dashboard")
		}
	}

	responseUpdateFreelancerProfileResolve = (response: boolean, persistence_token?: string, error?: string): void => {
		if (response === true) {
			localStorage.setItem("persistence_token", persistence_token as string)
			this.props.history.push("/edit_library_templates")
		} else {
			this.showSnackMessage(error) // some regex problem?			
		}
	}

	handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const key = event.target.name
		const keyCheck = event.target.name + "Check"
		const value = event.target.value
		const valueCheck = (RegExp(regexExpressions[keyCheck])).test(value)


		this.setState({ [key]: value, [keyCheck]: valueCheck } as any)
	}

	handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()

		await this.setStateLikeSync({
			jobTitleCheck: (RegExp(regexExpressions["jobTitleCheck"])).test(this.state.jobTitle),
			jobDescriptionCheck: (RegExp(regexExpressions["jobDescriptionCheck"])).test(this.state.jobDescription),
			cityCheck: (RegExp(regexExpressions["cityCheck"])).test(this.state.city),
			stateCheck: (RegExp(regexExpressions["stateCheck"])).test(this.state.state),
			countryCheck: (RegExp(regexExpressions["countryCheck"])).test(this.state.country),
			profileIdCheck: (RegExp(regexExpressions["profileIdCheck"])).test(this.state.profileId),
			calendlyCheck: (RegExp(regexExpressions["calendlyCheck"])).test(this.state.calendly),
			welcomeMessageCheck: (RegExp(regexExpressions["welcomeMessageCheck"])).test(this.state.welcomeMessage),
			profileImageLinkCheck: (RegExp(regexExpressions["profileImageLinkCheck"])).test(this.state.profileImageLink)
		} as any)

		var profileShareLinks: Record<string, any>[] = []
		if (this.state.linkedinLink) {
			profileShareLinks = profileShareLinks.concat({ "icon": "Linkedin", "name": "Linkedin", "url": this.state.linkedinLink })
		}
		if (this.state.facebookLink) {
			profileShareLinks = profileShareLinks.concat([{ "icon": "Facebook", "name": "Facebook", "url": this.state.facebookLink }])
		}
		if (this.state.portfolioLink) {
			profileShareLinks = profileShareLinks.concat([{ "icon": "Portfolio", "name": "Portfolio", "url": this.state.portfolioLink }])
		}

		let allValidationsPass = this.state.jobTitleCheck && this.state.jobDescriptionCheck && this.state.cityCheck && this.state.stateCheck && this.state.countryCheck && this.state.profileIdCheck && this.state.profileImageLinkCheck

		if (allValidationsPass) {
			try {
				let persistenceToken = localStorage.getItem("persistence_token")

				const response = await fetch(`${config.API_URL}/update_freelancer_profile`, {
					method: 'post',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
					body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
						persistence_token: persistenceToken,
						job_title: this.state.jobTitle,
						job_description: this.state.jobDescription,
						city: this.state.city,
						state: this.state.state,
						country: this.state.country,
						profile_id: this.state.profileId,
						calendly: this.state.calendly,
						welcome_message: this.state.welcomeMessage,
						profile_image_link: this.state.profileImageLink,
						profile_share_links: profileShareLinks
					})
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				this.responseUpdateFreelancerProfileResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.error)
			} catch (error) {
				this.showSnackMessage(`update-freelancer-profile-fetch-error: ${error}`)
			}
		} else {
			//this.showSnackMessage('not all validations passed - fix and try again')
		}
	}

	handleErrorImage = (event: React.SyntheticEvent<HTMLImageElement>) => {
		let imageElement = event.target as HTMLImageElement
		imageElement.src = exampleImage
	}

	handleInputChange = (event: React.ChangeEvent<{}>, value: string | null, reason: AutocompleteChangeReason) => {
		if (value) {
			this.setState({ "country": value, "countryCheck": true })
		} else {
			this.setState({ "countryCheck": false })
		}
	}

	render() {
		return (
			<div className="mainStyle">
				<Snackbar open={this.state.errorForm} autoHideDuration={this.state.errorDuration}>
					<Alert severity="error">
						{this.state.errorMessage}
					</Alert>
				</Snackbar>

				<div className="drawerRoot">
					<Drawer
						className="drawerDrawer"
						variant="permanent"
						classes={{
							paper: "drawerDrawerPaper"
						}}
						anchor="left">
						<div className="topButtonMenu">
							<a href="/dashboard">
								<ArrowBackRounded /> <span>back</span>
							</a>
						</div>

						<div className="logo">
							<a href="/dashboard">
								<img src={logo} width="115" height="32" />
							</a>
						</div>
						<div className="drawerToolbar"></div>

						<div className="menuItems">
							<h3>Edit freelancer profile</h3>

							<div className="stepItem highlight current">
								<RadioButtonUnchecked />
								<div className="stepDescription">
									<h4>profile details</h4>
									<Skeleton />
									<Skeleton />
								</div>
							</div>

							<div className="clear"></div>

							<div className="stepItem">
								<RadioButtonUnchecked />
								<div className="stepDescription">
									<h4>publicly-listed jobs</h4>
									<Skeleton />
									<Skeleton />
									<Skeleton />
								</div>
							</div>
						</div>
					</Drawer>

					<main className="drawerContent">
						<Container maxWidth="sm" className="topCenterContent topContent">
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<h1 className="blue">Profile details</h1>
									<p>Fill out the details that will appear on your public profile. You can edit them whenever you want. Here you can see an example of a profile: <a href={`${config.APP_URL}/profile/victoriabazhul`} className="blue" target="_blank">https://app.smelt.ai/profile/victoriabazhul</a></p>
								</Grid>

								<Grid item xs={12}>
									<form autoComplete="off">
										<Grid
											container
											justify="flex-start"
											xs={12}
											direction="column"
											className="block"
											alignItems="stretch"
											spacing={1}>


											<Grid
												container
												spacing={5}
												direction="column">

												<Grid item xs={12}
													container
													spacing={2}
													direction="column">

													<div className="profileDescription right">
														<img className="picture left" src={this.state.profileImageLink ? this.state.profileImageLink : exampleImage} onError={this.handleErrorImage} />
														<div className="profileLabel left">
															<Grid item xs={12}>
																<TextField
																	required
																	name="profileImageLink"
																	label="Profile Image Link"
																	onChange={this.handleChange}
																	error={!this.state.profileImageLinkCheck}
																	helperText={!this.state.profileImageLinkCheck ? 'Invalid profile image link' : 'Copy and paste your social media image URL here. It must be an image.'}
																	value={this.state.profileImageLink}
																/>
															</Grid>




														</div>
														<div className="clear"></div>
													</div>
												</Grid>

												<Grid item xs={12}
													container
													spacing={2}
													direction="column">

													<Grid item xs={12}>
														<Grid container spacing={1} alignItems="center">
															<Grid item xs={1}>
																<AccountCircle />
															</Grid>
															<Grid item xs={11}>
																<TextField
																	required
																	name="profileId"
																	label="Your Profile ID"
																	onChange={this.handleChange}
																	error={!this.state.profileIdCheck}
																	helperText={!this.state.profileIdCheck ? 'Invalid profile username' : `It will become your profile URL: ${config.APP_URL}/profile/YOUR_PROFILE_ID`}
																	value={this.state.profileId}
																/>
															</Grid>
														</Grid>

														<Grid container spacing={1} alignItems="center">
															<Grid item xs={1}>
																<AddIcCallRounded />
															</Grid>
															<Grid item xs={11}>
																<TextField
																	name="calendly"
																	label="Your Calendly URL"
																	onChange={this.handleChange}
																	error={!this.state.calendlyCheck}
																	helperText={!this.state.calendlyCheck ? 'Invalid calendly URL' : `If you want to enable visitors to schedule calls with you, fill out with your calendly ID: https://calendly.com/YOUR_USERNAME`}
																	value={this.state.calendly}
																/>
															</Grid>
														</Grid>
													</Grid>

												</Grid>


												<Grid item xs={12}
													container
													spacing={2}
													direction="column">

													<h2 className="blue">Job profile</h2>
													<p>Here, you should write a short job title that makes it clear to visitors what you're specialize in, and also a longer description that shows your prior experience in the area and what you're currently looking for.</p>

													<Grid item xs={12}>
														<TextField
															required
															className="outlinedStyle"
															multiline
															name="welcomeMessage"
															label="Welcome Message"
															onChange={this.handleChange}
															error={!this.state.welcomeMessageCheck}
															helperText={!this.state.welcomeMessageCheck ? 'Invalid welcome message' : 'You should write a personal welcome message to make your visitors feel good when they visit your page for the first time!'}
															value={this.state.welcomeMessage}
														/>
													</Grid>

													<Grid item xs={12}>
														<TextField
															required
															name="jobTitle"
															label="Job Title"
															onChange={this.handleChange}
															error={!this.state.jobTitleCheck}
															helperText={!this.state.jobTitleCheck ? 'Invalid job title' : ''}
															value={this.state.jobTitle}
														/>
													</Grid>
													<Grid item xs={12}>
														<TextField
															required
															className="outlinedStyle"
															name="jobDescription"
															label="Profile Description"
															multiline
															onChange={this.handleChange}
															error={!this.state.jobDescriptionCheck}
															helperText={!this.state.jobDescriptionCheck ? 'Invalid job description' : 'You can type a multiple-line description to summarize your prior work experience and what types of skills and jobs you specialize in.'}
															value={this.state.jobDescription}
														/>
													</Grid>

												</Grid>

												<Grid item xs={12}
													container
													spacing={2}
													direction="column">

													<h2 className="blue">Your location</h2>
													<p>Companies are more willing to buy from you if they feel you're real! So, share with them where you're currently based at.</p>



													<Grid item xs={12}>
														<Autocomplete
															value={this.state.country}
															onChange={this.handleInputChange}
															options={[
																"United States",
																"Afghanistan",
																"Albania",
																"Algeria",
																"Andorra",
																"Angola",
																"Antigua & Deps",
																"Argentina",
																"Armenia",
																"Australia",
																"Austria",
																"Azerbaijan",
																"Bahamas",
																"Bahrain",
																"Bangladesh",
																"Barbados",
																"Belarus",
																"Belgium",
																"Belize",
																"Benin",
																"Bhutan",
																"Bolivia",
																"Bosnia Herzegovina",
																"Botswana",
																"Brazil",
																"Brunei",
																"Bulgaria",
																"Burkina",
																"Burundi",
																"Cambodia",
																"Cameroon",
																"Canada",
																"Cape Verde",
																"Central African Rep",
																"Chad",
																"Chile",
																"China",
																"Colombia",
																"Comoros",
																"Congo",
																"Congo {Democratic Rep}",
																"Costa Rica",
																"Croatia",
																"Cuba",
																"Cyprus",
																"Czech Republic",
																"Denmark",
																"Djibouti",
																"Dominica",
																"Dominican Republic",
																"East Timor",
																"Ecuador",
																"Egypt",
																"El Salvador",
																"Equatorial Guinea",
																"Eritrea",
																"Estonia",
																"Ethiopia",
																"Fiji",
																"Finland",
																"France",
																"Gabon",
																"Gambia",
																"Georgia",
																"Germany",
																"Ghana",
																"Greece",
																"Grenada",
																"Guatemala",
																"Guinea",
																"Guinea-Bissau",
																"Guyana",
																"Haiti",
																"Honduras",
																"Hungary",
																"Iceland",
																"India",
																"Indonesia",
																"Iran",
																"Iraq",
																"Ireland {Republic}",
																"Israel",
																"Italy",
																"Ivory Coast",
																"Jamaica",
																"Japan",
																"Jordan",
																"Kazakhstan",
																"Kenya",
																"Kiribati",
																"Korea North",
																"Korea South",
																"Kosovo",
																"Kuwait",
																"Kyrgyzstan",
																"Laos",
																"Latvia",
																"Lebanon",
																"Lesotho",
																"Liberia",
																"Libya",
																"Liechtenstein",
																"Lithuania",
																"Luxembourg",
																"Macedonia",
																"Madagascar",
																"Malawi",
																"Malaysia",
																"Maldives",
																"Mali",
																"Malta",
																"Marshall Islands",
																"Mauritania",
																"Mauritius",
																"Mexico",
																"Micronesia",
																"Moldova",
																"Monaco",
																"Mongolia",
																"Montenegro",
																"Morocco",
																"Mozambique",
																"Myanmar, {Burma}",
																"Namibia",
																"Nauru",
																"Nepal",
																"Netherlands",
																"New Zealand",
																"Nicaragua",
																"Niger",
																"Nigeria",
																"Norway",
																"Oman",
																"Pakistan",
																"Palau",
																"Panama",
																"Papua New Guinea",
																"Paraguay",
																"Peru",
																"Philippines",
																"Poland",
																"Portugal",
																"Qatar",
																"Romania",
																"Russian Federation",
																"Rwanda",
																"St Kitts & Nevis",
																"St Lucia",
																"Saint Vincent & the Grenadines",
																"Samoa",
																"San Marino",
																"Sao Tome & Principe",
																"Saudi Arabia",
																"Senegal",
																"Serbia",
																"Seychelles",
																"Sierra Leone",
																"Singapore",
																"Slovakia",
																"Slovenia",
																"Solomon Islands",
																"Somalia",
																"South Africa",
																"South Sudan",
																"Spain",
																"Sri Lanka",
																"Sudan",
																"Suriname",
																"Swaziland",
																"Sweden",
																"Switzerland",
																"Syria",
																"Taiwan",
																"Tajikistan",
																"Tanzania",
																"Thailand",
																"Togo",
																"Tonga",
																"Trinidad & Tobago",
																"Tunisia",
																"Turkey",
																"Turkmenistan",
																"Tuvalu",
																"Uganda",
																"Ukraine",
																"United Arab Emirates",
																"United Kingdom",
																"Uruguay",
																"Uzbekistan",
																"Vanuatu",
																"Vatican City",
																"Venezuela",
																"Vietnam",
																"Yemen",
																"Zambia",
																"Zimbabwe"]}

															renderInput={(params) => <TextField required {...params} label="Country" />}
														/>
													</Grid>

													<Grid item xs={12}>
														<TextField
															required
															fullWidth
															name="state"
															label="State"
															onChange={this.handleChange}
															error={!this.state.stateCheck}
															helperText={!this.state.stateCheck ? 'Invalid state' : ''}
															value={this.state.state}
														/>

													</Grid>


													<Grid item xs={12}>
														<TextField
															required
															name="city"
															label="City"
															onChange={this.handleChange}
															error={!this.state.cityCheck}
															helperText={!this.state.cityCheck ? 'Invalid city' : ''}
															value={this.state.city}
														/>
													</Grid>




												</Grid>

												<Grid item xs={12}
													container
													spacing={2}
													direction="column">

													<h2 className="blue">Your social media links</h2>
													<p>Share up to 3 social media links to increase trust for visitors who are interested in buying services from you! :)</p>


													<Grid item xs={12}>
														<Grid container spacing={1} alignItems="flex-end">
															<Grid item xs={1}>
																<LinkedIn />
															</Grid>
															<Grid item xs={11}>
																<TextField

																	name="linkedinLink"
																	label="Your Linkedin Profile Link"
																	onChange={this.handleChange}
																	error={!this.state.linkedinLinkCheck}
																	helperText={!this.state.linkedinLinkCheck ? 'Invalid Linkedin link' : ''}
																	value={this.state.linkedinLink}
																/>
															</Grid>
														</Grid>
													</Grid>

													<Grid item xs={12}>
														<Grid container spacing={1} alignItems="flex-end">
															<Grid item xs={1}>
																<Facebook />
															</Grid>
															<Grid item xs={11}>
																<TextField

																	name="facebookLink"
																	label="Your Facebook Profile Link"
																	onChange={this.handleChange}
																	error={!this.state.facebookLinkCheck}
																	helperText={!this.state.facebookLinkCheck ? 'Invalid Facebook link' : ''}
																	value={this.state.facebookLink}
																/>
															</Grid>
														</Grid>
													</Grid>

													<Grid item xs={12}>
														<Grid container spacing={1} alignItems="flex-end">
															<Grid item xs={1}>
																<PublicRounded />
															</Grid>
															<Grid item xs={11}>
																<TextField

																	name="portfolioLink"
																	label="Any Portfolio Website Link"
																	onChange={this.handleChange}
																	error={!this.state.portfolioLinkCheck}
																	helperText={!this.state.portfolioLinkCheck ? 'Invalid portfolio link' : ''}
																	value={this.state.portfolioLink}
																/>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>

											<div className="actionBar">
												<Button
													type="submit"
													variant="contained"
													color="primary"
													size="large"
													fullWidth
													className="primaryButton"
													startIcon={<ArrowForward />}
													onClick={this.handleSubmit}
												>
													next
												</Button>
											</div>
										</Grid>
									</form>
								</Grid>
							</Grid>
						</Container>
					</main>
				</div>
			</div>
		)
	}
}

export default EditFreelancerInfo