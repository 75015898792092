import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab"

import React from "react";
import { RouteComponentProps } from "react-router";
import { config } from "./../config"
import CoreAnalytics from '../utils/core_analytics'
import { BackendUserFull, BackendFreelancerProfile, BackendTemplate } from "./interfaces/backend"
import FreelancerProfile from './components/freelancer_profile'

import "../styles/profile_page.scss"

interface State {
    freelancerName?: string
    freelancerEmail?: string
    freelancerProfile?: BackendFreelancerProfile

    is_freelancer: boolean
    is_client: boolean

    templates: BackendTemplate[]

    errorForm: boolean
    errorMessage: string
    errorDuration: number
}

interface Props extends RouteComponentProps<{}> { }

interface Params {
    profile_id: string
}

class ProfessionalProfile extends React.Component<Props, State> {
    setStateLikeSync(state: any) {
        return new Promise<void>((resolve) => {
            this.setState(state, resolve)
        });
    }

    showSnackMessage(message?: string, duration?: number) {
        if (message == undefined) {
            return
        }

        let actualDuration = duration ? duration : 3000

        this.setState({
            "errorForm": true,
            "errorMessage": message,
            "errorDuration": actualDuration
        })

        setTimeout(() => {
            this.setState({
                "errorForm": false,
                "errorMessage": "",
                "errorDuration": 0
            })
        }, actualDuration)
    }

    constructor(props: Props) {
        super(props)

        this.state = {
            freelancerName: undefined,
            freelancerEmail: undefined,
            freelancerProfile: undefined,

            is_freelancer: false,
            is_client: false,

            templates: [],

            errorForm: false,
            errorMessage: "",
            errorDuration: 0
        }
    }

    async componentDidMount() {
        let params = this.props.match.params as Params

        if (params.profile_id == undefined) {
            this.showSnackMessage("no profile id")
            return
        }

        try {
            const response = await fetch(`${config.API_URL}/read_public_freelancer_profile?profile_id=${encodeURIComponent(params.profile_id)}`, { // todo: create parser function, and interface for params?
                method: 'get',
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
            })
            if (!response.ok) {
                // this.showSnackMessage(`An error has occured: ${response.status}`)
            }
            const responseJSON = await response.json();
            this.responseReadPublicFreelancerProfileResolve(responseJSON.response, responseJSON.freelancer_name, responseJSON.freelancer_email, responseJSON.freelancer_profile)
        } catch (error) {
            this.showSnackMessage(`read-public-freelancer-profile-error: ${error}`)
        }

        let persistenceToken = localStorage.getItem("persistence_token")
        if (persistenceToken != undefined) {
            try {
                const response = await fetch(`${config.API_URL}/read_user?persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
                    method: 'get',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
                })
                if (!response.ok) {
                    // this.showSnackMessage(`An error has occured: ${response.status}`)
                }
                const responseJSON = await response.json();
                this.responseReadUserResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.user_full, responseJSON.error) // todo: think of isDone policy here
            } catch (error) {
                this.showSnackMessage(`read-user-fetch-error: ${error}`)
            }
        }

        try {
            const response = await fetch(`${config.API_URL}/list_published_templates?profile_id=${encodeURIComponent(params.profile_id)}`, { // todo: create parser function, and interface for params?
                method: 'get',
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
            })
            if (!response.ok) {
                // this.showSnackMessage(`An error has occured: ${response.status}`)
            }
            const responseJSON = await response.json();
            this.responseListTemplates(responseJSON.response, responseJSON.templates)
        } catch (error) {
            this.showSnackMessage(`list-templates-fetch-error: ${error}`)
        }

        document.title = `Get a quote from ${this.state.freelancerName} - Smelt`
    }

    // parseUrlParams = (query: string): Params => {
    // 	const urlSearchParams = new URLSearchParams(query)

    // 	let params: Params = {
    // 		profile_id: urlSearchParams.get("profile_id")!
    // 	}

    // 	return params
    // }

    responseReadPublicFreelancerProfileResolve = (response: boolean, freelancer_name?: string, freelancer_email?: string, freelancer_profile?: BackendFreelancerProfile, error?: string) => {
        if (response === true) {
            this.setState({
                "freelancerName": freelancer_name!,
                "freelancerEmail": freelancer_email!,
                "freelancerProfile": freelancer_profile!
            })
        } else {
            this.showSnackMessage(error)
        }
    }

    responseReadUserResolve = (response: boolean, persistence_token?: string, user_full?: BackendUserFull, error?: string) => {
        if (response === true) {
            this.setState({
                "is_freelancer": user_full!.is_freelancer,
                "is_client": user_full!.is_client
            })
            CoreAnalytics.trackUser(user_full!.email, user_full!.email, user_full!.first_name, user_full!.last_name, user_full!.is_client, user_full!.is_freelancer);
            localStorage.setItem("persistence_token", persistence_token as string)
        } else {
            localStorage.removeItem("persistence_token")
        }
    }

    responseListTemplates = (response: boolean, templates?: BackendTemplate[], error?: string) => {
        if (response === true) {
            this.setState({
                "templates": templates!
            })
        } else {
            this.showSnackMessage("error") // user with token not loaded
        }
    }

    replace = (page: string) => {
        this.props.history.replace(page)
    }

    render() {
        return (
            <>
                <Snackbar open={this.state.errorForm} autoHideDuration={this.state.errorDuration}>
                    <Alert severity="error">
                        {this.state.errorMessage}
                    </Alert>
                </Snackbar>

                <FreelancerProfile
                    name={this.state.freelancerName ? this.state.freelancerName : ""}
                    freelancer_email={this.state.freelancerEmail ? this.state.freelancerEmail : ""}
                    job_title={this.state.freelancerProfile ? this.state.freelancerProfile.job_title : ""}
                    job_description={this.state.freelancerProfile ? this.state.freelancerProfile.job_description : ""}
                    city={this.state.freelancerProfile ? this.state.freelancerProfile.city : ""}
                    state={this.state.freelancerProfile ? this.state.freelancerProfile.state : ""}
                    country={this.state.freelancerProfile ? this.state.freelancerProfile.country : ""}
                    profile_id={this.state.freelancerProfile ? this.state.freelancerProfile.profile_id : ""}
                    calendly={this.state.freelancerProfile ? this.state.freelancerProfile.calendly : ""}
                    welcome_message={this.state.freelancerProfile ? this.state.freelancerProfile.welcome_message : ""}
                    profile_image_link={this.state.freelancerProfile ? this.state.freelancerProfile.profile_image_link : ""}
                    profile_share_links={this.state.freelancerProfile?.profile_share_links ? this.state.freelancerProfile.profile_share_links.map(profile_share_link => JSON.parse(profile_share_link.replace(/'/g, '"'))) : []}
                    templates={this.state.templates.filter((template) => (template.current_state == "published"))}
                    is_freelancer={this.state.is_freelancer}
                    is_client={this.state.is_client}
                    replace={this.replace}
                    is_public_profile={true}
                    showSnackMessage={this.showSnackMessage}
                />
            </>
        )
    }
}

export default ProfessionalProfile