import React from "react";
import "../styles/not_found.scss"

class NotFound extends React.Component {
    componentDidMount() {
        document.title = "Could not found - Smelt"

    }

    render() {
        return (
            <div className="notFoundPage">
                <div className="mainContainer">
                    <a
                        href="/"
                        className="primaryLink contained">
                        go back to home
                    </a>
                </div>
            </div>
        )
    }
}

export default NotFound