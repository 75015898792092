import React from "react"
import { RouteComponentProps } from "react-router"

import {
	Button,
	Container,
	Drawer,
	Grid,
	GridList,
	CircularProgress,
	GridListTile,
	TextField,
	RadioGroup,
	FormControlLabel,
	Radio,
	Snackbar
} from "@material-ui/core"

import { Alert } from "@material-ui/lab"

import {
	ArrowForward,
	CheckRounded
} from "@material-ui/icons"

import {
	loadStripe,
	Stripe,
	StripeElements
} from '@stripe/stripe-js';

import {
	Elements,
	CardElement,
	ElementsConsumer
} from '@stripe/react-stripe-js';

import CheckoutSteps from './components/checkout_steps'
import "../styles/checkout_pages.scss"

import { config } from "../config"

import { BackendProjectFull, BackendCheckoutFull, BackendCheckout, BackendUserFull, BackendClientUserProject, BackendFreelancerProfile, BackendProposal, BackendPayment } from "./interfaces/backend"
import CoreAnalytics from "../utils/core_analytics";

const paymentsComponentsClientMap: Record<string, Record<string, any>> = {
	"direct": { "title": "Direct with service provider", "client_fee": 0, "freelancer_fee": 0, "description": <div className="textPaymentDescription"><p>This payment method allows you to hire the project directly with the service provider. If you choose this option, you should contact the service provider to understand what would be the best payment method to do the transaction directly.</p><p>This payment method does not include quality protection by Smelt Technologies Inc.</p></div>, "extra_label": <p className="insuranceLabel">No insurance or<br /> quality guarantee</p> },
	"credit_card": { "title": "Credit Card", "client_fee": 0, "freelancer_fee": 0.02, "description": <div className="textPaymentDescription"><p>This payment method allows you to pay using a credit card with the money then being deposited to the freelancer provided bank account.</p><p>This payment method does not include quality protection by Smelt Technologies Inc.</p></div>, "extra_label": <p className="insuranceLabel">No insurance or<br /> quality guarantee</p> },
	"basic_escrow": { "title": "Basic Escrow", "client_fee": 0.03, "freelancer_fee": 0.02, "description": <div className="textPaymentDescription"><p>Basic escrow is great if you have the capability to evaluate the delivered project yourself.</p><p>Smelt Technologies Inc may resolve any conflicts between the parties, but will evaluate based on facts and arguments, and not based on the actual project delivered.</p></div>, "extra_label": <p className="insuranceLabel">Dispute-based Escrow</p> },
	"assurance_escrow": { "title": "Technical-assurance Escrow", "client_fee": 0.05, "freelancer_fee": 0.02, "description": <div className="textPaymentDescription"><p>Technical-assurance escrow guarantees technical quality to you.</p> <p>Before sending the project to you and releasing payments to the service provider, our technical team will evaluate the project after it has been delivered to check if it matches your proposal. Based on that you can then open a dispute or accept the deliverables.</p></div>, "extra_label": <p className="insuranceLabel">Technical-quality guaranteed</p> },
	"flexible": { "title": "Flexible (buy-now pay-later)", "client_fee": -1, "freelancer_fee": -1, "description": <div className="textPaymentDescription"><p>Flexible options are evaluated on a case-by-case basis and it allows you to not only guarantee quality of the project, but also pay only after you have received the project done.</p></div>, "extra_label": <p className="insuranceLabel">Technical-quality guaranteed<br />Pay after project delivered</p> }
}

const regexExpressions: Record<string, string> = {
	"cardNameCheck": "^((.|\n){0,512})$",
	"cardEmailheck": "^((.|\n){0,512})$",
	"cardAddressCheck": "^((.|\n){0,512})$",
	"cardCityCheck": "^((.|\n){0,512})$",
	"cardStateCheck": "^((.|\n){0,512})$",
	"cardCountryCheck": "^((.|\n){0,512})$",
	"cardZipCheck": "^((.|\n){0,512})$"
}

const stripePromise = loadStripe(config.STRIPE_PUBLISHABLE_KEY);

interface Params {
	id?: number
	noRedirect?: boolean
}

interface Props extends RouteComponentProps<{}> {
	stripe: Stripe,
	elements: StripeElements
}

interface State {
	freelancer_user_project_id?: number
	client_user_project_id?: number

	freelancer_email: string
	freelancer_name: string

	client_name: string

	isLoadingForm: boolean
	successForm: boolean

	paymentMethodIndex: string

	cardName: string
	cardNameCheck: boolean
	cardEmail: string
	cardEmailCheck: boolean
	cardAddress: string
	cardAddressCheck: boolean
	cardCity: string
	cardCityCheck: boolean
	cardState: string
	cardStateCheck: boolean
	cardCountry: string
	cardCountryCheck: boolean
	cardZip: string
	cardZipCheck: boolean

	flexibleExplanation: string
	flexibleExplanationCheck: boolean

	project_id: number
	project_name: string
	project_current_state: string
	checkout_id: number
	payment_id?: number

	clients_users_projects: BackendClientUserProject[]

	checkout?: BackendCheckout
	freelancerProfile?: BackendFreelancerProfile
	proposal?: BackendProposal
	payment?: BackendPayment

	is_freelancer: boolean
	is_client: boolean

	errorForm: boolean
	errorMessage: string
	errorDuration: number
}

class MakePayment extends React.Component<Props, State> {
	setStateLikeSync(state: any) {
		return new Promise<void>((resolve) => {
			this.setState(state, resolve)
		});
	}

	showSnackMessage(message?: string, duration?: number) {
		if (message == undefined) {
			return
		}

		let actualDuration = duration ? duration : 3000

		this.setState({
			"errorForm": true,
			"errorMessage": message,
			"errorDuration": actualDuration
		})

		setTimeout(() => {
			this.setState({
				"errorForm": false,
				"errorMessage": "",
				"errorDuration": 0
			})
		}, actualDuration)
	}

	constructor(props: Props) {
		super(props)

		this.state = {
			freelancer_user_project_id: undefined,
			client_user_project_id: undefined,

			freelancer_email: "",
			freelancer_name: "",

			client_name: "",

			isLoadingForm: false,
			successForm: false,

			paymentMethodIndex: "",

			cardName: "",
			cardNameCheck: true,
			cardEmail: "",
			cardEmailCheck: true,
			cardAddress: "",
			cardAddressCheck: true,
			cardCity: "",
			cardCityCheck: true,
			cardState: "",
			cardStateCheck: true,
			cardCountry: "",
			cardCountryCheck: true,
			cardZip: "",
			cardZipCheck: true,

			flexibleExplanation: "",
			flexibleExplanationCheck: true,

			project_id: -1,
			project_name: "",
			project_current_state: "",
			checkout_id: -1,
			payment_id: undefined,

			clients_users_projects: [],

			checkout: undefined,
			freelancerProfile: undefined,
			proposal: undefined,
			payment: undefined,

			is_freelancer: false,
			is_client: false,

			errorForm: false,
			errorMessage: "",
			errorDuration: 0
		}
	}

	async componentDidMount() {
		let persistenceToken = localStorage.getItem("persistence_token")
		if (persistenceToken === null) {
			this.props.history.replace("/login")
			return
		}

		let params = this.parseUrlParams(this.props.location.search)

		if (params.id) {
			try {
				const response = await fetch(`${config.API_URL}/read_project?project_id=${encodeURIComponent(params.id!)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
					method: 'get',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				this.responseReadProjectResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.project_full, responseJSON.error)
			} catch (error) {
				this.showSnackMessage(`read-project-fetch-error: ${error}`)
			}

			try {
				let persistenceToken = localStorage.getItem("persistence_token")

				const response = await fetch(`${config.API_URL}/read_user?persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
					method: 'get',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				this.responseReadUserResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.user_full, responseJSON.error)
			} catch (error) {
				this.showSnackMessage(`read-user-fetch-error: ${error}`)
			}

			try {
				let persistenceToken = localStorage.getItem("persistence_token")
				let checkout_id = this.state.checkout_id

				const response = await fetch(`${config.API_URL}/read_checkout?checkout_id=${encodeURIComponent(checkout_id)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
					method: 'get',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				let isDone = this.responseReadCheckoutResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.checkout_full, responseJSON.error)
				if (isDone) {
					return
				}
			} catch (error) {
				this.showSnackMessage(`read-checkout-fetch-error: ${error}`)
			}

			let isDone = this.redirect(5, params.noRedirect)
			if (isDone) {
				return
			}

			try {
				let persistenceToken = localStorage.getItem("persistence_token")
				let proposal_id = this.state.checkout?.proposal_id ? this.state.checkout?.proposal_id! : ""

				const response = await fetch(`${config.API_URL}/read_proposal?proposal_id=${encodeURIComponent(proposal_id)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
					method: 'get',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				let isDone = this.responseReadProposalResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.proposal, responseJSON.error)
				if (isDone) {
					return
				}
			} catch (error) {
				this.showSnackMessage(`read-checkout-fetch-error: ${error}`)
			}

			let payment_id = this.state.checkout?.payment_id
			if (payment_id) {
				try {
					let persistenceToken = localStorage.getItem("persistence_token")

					const response = await fetch(`${config.API_URL}/read_payment?payment_id=${encodeURIComponent(payment_id)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
						method: 'get',
						headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
					})
					if (!response.ok) {
						// this.showSnackMessage(`An error has occured: ${response.status}`)
					}
					const responseJSON = await response.json();
					this.responseReadPaymentResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.payment, responseJSON.error)
				} catch (error) {
					this.showSnackMessage(`read-payment-fetch-error: ${error}`)
				}
			}
		} else {
			this.showSnackMessage("Missing project_id")
			this.props.history.replace("/dashboard")
		}
	}

	parseUrlParams = (query: string): Params => {
		const urlSearchParams = new URLSearchParams(query)

		let params: Params = {
			id: urlSearchParams.get("id") === null ? undefined : Number(urlSearchParams.get("id")!),
			noRedirect: urlSearchParams.get("no_redirect") === null ? undefined : (urlSearchParams.get("no_redirect")! == "true")
		}
		return params
	}

	redirect = (currentStep: number, no_redirect?: boolean) => {
		let activeStep = this.getActiveStep()

		if (no_redirect) {
			return false
		}

		if (currentStep == activeStep) {
			return false
		}

		var pushString = undefined

		if (activeStep == 3) {
			pushString = `/answer_project_briefing?id=${this.state.project_id}`
		} else if (activeStep == 4) {
			pushString = `/view_proposal?id=${this.state.project_id}`
		} else if (activeStep == 5) {
			pushString = `/make_payment?id=${this.state.project_id}`
		} else if (activeStep == 6) {
			pushString = `/check_delivery?id=${this.state.project_id}`
		} else if (activeStep == 7) {
			pushString = `/check_delivery?id=${this.state.project_id}`
		}

		if (pushString) {
			this.props.history.push(pushString)
		}

		return true
	}

	getActiveStep() {
		if (this.state.checkout?.briefing_step_status != undefined && this.state.checkout?.proposal_step_status == undefined) {
			return 3
		} else if (this.state.checkout?.proposal_step_status != undefined && this.state.checkout?.payment_step_status == undefined) {
			return 4
		} else if (this.state.checkout?.payment_step_status != undefined && this.state.checkout?.delivery_step_status == undefined) {
			return 5
		} else if (this.state.checkout?.delivery_step_status != undefined && this.state.checkout?.release_step_status == undefined) {
			return 6
		} else if (this.state.checkout?.release_step_status != undefined) {
			return 7
		}

		return 0
	}

	responseReadProjectResolve = (response: boolean, persistence_token?: string, project_full?: BackendProjectFull, error?: string): void => {
		if (response === true) {
			if ((project_full!.project.current_state !== "published") || project_full!.preferences_groups.length != 1) {
				this.showSnackMessage("Project is not published, or has 0 or more than 1 (not possible) preference group")
				this.props.history.replace("/dashboard")
				return
			}

			this.setState({
				"freelancer_email": project_full!.freelancers_users_projects[0].email,
				"freelancer_name": project_full!.freelancers_users_projects[0].first_name,
				"client_name": (project_full!.clients_users_projects.length == 1) ? project_full!.clients_users_projects[0].first_name : project_full!.freelancers_users_projects[0].first_name,
				"freelancer_user_project_id": project_full!.freelancer_user_project_id,
				"client_user_project_id": project_full!.client_user_project_id,
				"project_id": project_full!.project.id,
				"project_name": project_full!.project.name,
				"project_current_state": project_full!.project.current_state,
				"checkout_id": project_full!.checkouts[0].id,
				"payment_id": project_full!.checkouts[0].payment_id,
				"clients_users_projects": project_full!.clients_users_projects
			})
			localStorage.setItem("persistence_token", persistence_token as string)
			document.title = this.state.project_name + " - Smelt"
		} else {
			this.showSnackMessage(error)
			this.props.history.replace("/dashboard")
		}
	}

	responseReadUserResolve = (response: boolean, persistence_token?: string, user_full?: BackendUserFull, error?: string): void => {
		if (response === true) {
			this.setState({
				"is_freelancer": user_full!.is_freelancer,
				"is_client": user_full!.is_client
			})
			localStorage.setItem("persistence_token", persistence_token as string)
		} else {
			this.showSnackMessage(error) // user with token not loaded
			localStorage.removeItem("persistence_token")
			this.props.history.replace("/login")
		}
	}

	responseReadCheckoutResolve = (response: boolean, persistence_token?: string, checkout_full?: BackendCheckoutFull, error?: string): boolean => {
		if (response === true) {
			this.setState({
				"checkout": checkout_full!.checkout,
				"freelancerProfile": checkout_full!.freelancer_profile
			})
			localStorage.setItem("persistence_token", persistence_token as string)
		} else {
			this.showSnackMessage(error)
			this.props.history.replace("/dashboard")
			return true
		}
		return false
	}

	responseReadProposalResolve = (response: boolean, persistence_token?: string, proposal?: BackendProposal, error?: string): boolean => {
		if (response === true) {
			this.setState({
				"proposal": proposal!,
				"paymentMethodIndex": proposal!.payment_options.length > 0 ? JSON.parse(proposal!.payment_options[0].replace(/'/g, '"')).name : "direct"
			})
			localStorage.setItem("persistence_token", persistence_token as string)
		} else {
			this.showSnackMessage(error)
			this.props.history.replace("/dashboard")
			return true
		}
		return false
	}

	responseReadPaymentResolve = (response: boolean, persistence_token?: string, payment?: BackendPayment, error?: string) => {
		if (response === true) {
			this.setState((previousState) => ({
				"payment": payment ? payment : undefined,
				"paymentMethodIndex": payment ? JSON.parse(payment!.payment_option.replace(/'/g, '"')).name : previousState.paymentMethodIndex
			}))
			localStorage.setItem("persistence_token", persistence_token as string)
		} else {
			this.showSnackMessage(error)
		}
	}

	responseSaveCheckoutResolve = (response: boolean, persistence_token?: string, checkout_id?: number, error?: string): void => {
		if (response === true) {
			localStorage.setItem("persistence_token", persistence_token as string)
			this.setState({ successForm: true });
			if (this.state.paymentMethodIndex == "direct" || this.state.paymentMethodIndex == "credit_card") {
				this.props.history.push(`check_delivery?id=${this.state.project_id}`)
			} else {
				window.location.reload()
			}
		} else {
			this.setState({ successForm: false });
			this.showSnackMessage(error) // some regex problem?
		}
	}

	responseSavePaymentResolve = async (response: boolean, persistence_token?: string, payment_id?: number, stripe_payment_intent_client_secret?: string, error?: string): Promise<boolean> => {
		if (response === true) {
			if (this.state.paymentMethodIndex == "credit_card" || this.state.paymentMethodIndex == "basic_escrow" || this.state.paymentMethodIndex == "assurance_escrow") {
				let billingDetails = {
					name: this.state.cardName,
					email: this.state.cardEmail,
					address: {
						city: this.state.cardCity,
						line1: this.state.cardAddress,
						state: this.state.cardState,
						postal_code: this.state.cardZip
					}
				}
				let cardElement = this.props.elements.getElement(CardElement)

				let paymentMethodReq = await this.props.stripe.createPaymentMethod({
					type: "card",
					card: cardElement!,
					billing_details: billingDetails
				})
				if (paymentMethodReq.error) {
					this.showSnackMessage(paymentMethodReq.error.message)
					this.setStateLikeSync({ isLoadingForm: false });
					this.setStateLikeSync({ successForm: false });
					return true
				}

				let confirmedCardPayment = await this.props.stripe.confirmCardPayment(stripe_payment_intent_client_secret!, {
					payment_method: paymentMethodReq.paymentMethod?.id
				})
				if (confirmedCardPayment.error) {
					this.showSnackMessage(confirmedCardPayment.error.message)
					this.setStateLikeSync({ isLoadingForm: false });
					this.setStateLikeSync({ successForm: false });
					return true
				}
			}

			this.setStateLikeSync({
				"payment_id": payment_id!
			})
			localStorage.setItem("persistence_token", persistence_token as string)
			this.setState({ successForm: true });
		} else {
			this.setState({ successForm: false });
			this.setState({ isLoadingForm: false });
			this.showSnackMessage(error) // some regex problem?
			return true
		}

		return false
	}

	handleChangePaymentMethod = (event: React.ChangeEvent<any>, newValue: string) => {
		this.setState({ paymentMethodIndex: newValue });
	}

	handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const key = event.target.name
		const keyCheck = event.target.name + "Check"
		const value = event.target.value
		const valueCheck = (RegExp(regexExpressions[keyCheck])).test(value)

		this.setState({ [key]: value, [keyCheck]: valueCheck } as any)
	}

	handleMakePayment = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()

		var persistenceToken = localStorage.getItem("persistence_token")
		let price = this.state.proposal?.price ? this.state.proposal?.price : 0

		let allValidationsPass = this.state.cardNameCheck && this.state.cardEmailCheck && this.state.cardAddressCheck && this.state.cardCityCheck && this.state.cardStateCheck && this.state.cardCountryCheck && this.state.cardZipCheck && this.state.flexibleExplanationCheck

		if (allValidationsPass) {
			let paymentOption = this.state.proposal?.payment_options.find((payment_option) => (JSON.parse(payment_option.replace(/'/g, '"')).name == this.state.paymentMethodIndex))

			try {
				await this.setStateLikeSync({ isLoadingForm: true });

				const response = await fetch(`${config.API_URL}/save_payment`, {
					method: 'post',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
					body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
						persistence_token: persistenceToken,
						action: "create",
						payment_params: { "checkout_id": this.state.checkout_id, "payment_option": paymentOption, "amount": price, "client_fee": this.getClientFee(this.state.proposal?.price ? this.state.proposal?.price : 0), "freelancer_fee": this.getFreelancerFee(this.state.proposal?.price ? this.state.proposal?.price : 0), "flexible_explanation": this.state.paymentMethodIndex == "flexible" ? this.state.flexibleExplanation : undefined }
					})
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();


				await CoreAnalytics.trackProjectMakePayment(this.state.project_id, this.state.project_name, this.state.freelancer_email, this.state.freelancer_name);

				let isDone = await this.responseSavePaymentResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.payment_id, responseJSON.stripe_payment_intent_client_secret, responseJSON.error)
				if (isDone) {
					return
				}
			} catch (error) {
				await this.setStateLikeSync({ isLoadingForm: false });
				this.showSnackMessage(`save-payment-fetch-error: ${error}`)
				return
			}

			persistenceToken = localStorage.getItem("persistence_token")
			let payment_id = this.state.payment_id

			var payment_step_status = undefined
			var delivery_step_status = undefined

			if (this.state.paymentMethodIndex == "direct") {
				payment_step_status = "completed"
				delivery_step_status = "pending_freelancer"
			} else if (this.state.paymentMethodIndex == "credit_card") {
				payment_step_status = "completed"
				delivery_step_status = "pending_freelancer"
			} else if (this.state.paymentMethodIndex == "basic_escrow" || this.state.paymentMethodIndex == "assurance_escrow" || this.state.paymentMethodIndex == "flexible") {
				payment_step_status = "pending_internal"
			}

			try {
				const response = await fetch(`${config.API_URL}/save_checkout`, {
					method: 'post',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
					body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
						persistence_token: persistenceToken,
						action: "update",
						checkout_id: this.state.checkout_id,
						checkout_params: { "payment_step_status": payment_step_status, "delivery_step_status": delivery_step_status, "payment_id": payment_id }
					})
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				await this.setStateLikeSync({ isLoadingForm: false });
				this.responseSaveCheckoutResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.checkout_id, responseJSON.error)
			} catch (error) {
				await this.setStateLikeSync({ isLoadingForm: false });
				this.showSnackMessage(`save-checkout-fetch-error: ${error}`)
			}
		}
	}

	getClientFee = (price: number) => {
		if (this.state.paymentMethodIndex == "direct" || this.state.paymentMethodIndex == "credit_card") {
			return 0
		} else if (this.state.paymentMethodIndex == "basic_escrow") {
			return paymentsComponentsClientMap["basic_escrow"].client_fee * price
		} else if (this.state.paymentMethodIndex == "assurance_escrow") {
			return paymentsComponentsClientMap["assurance_escrow"].client_fee * price
		} else if (this.state.paymentMethodIndex == "flexible") {
			return undefined
		}
	}

	getFreelancerFee = (price: number) => {
		if (this.state.paymentMethodIndex == "direct") {
			return 0
		} else if (this.state.paymentMethodIndex == "credit_card") {
			return paymentsComponentsClientMap["credit_card"].freelancer_fee * price
		} else if (this.state.paymentMethodIndex == "basic_escrow") {
			return paymentsComponentsClientMap["basic_escrow"].freelancer_fee * price
		} else if (this.state.paymentMethodIndex == "assurance_escrow") {
			return paymentsComponentsClientMap["assurance_escrow"].freelancer_fee * price
		} else if (this.state.paymentMethodIndex == "flexible") {
			return undefined
		}
	}

	push = (page: string) => {
		this.props.history.push(page)
	}

	render() {
		let payment_option_name = this.state.payment?.payment_option ? JSON.parse(this.state.payment?.payment_option.replace(/'/g, '"')).name : "" // only if already paid

		let payment_options_names = (this.state.proposal?.payment_options ? this.state.proposal?.payment_options : []).map(payment_option => JSON.parse(payment_option.replace(/'/g, '"')).name)

		return (
			<div className="mainStyle checkoutStyle">
				<Snackbar open={this.state.errorForm} autoHideDuration={this.state.errorDuration}>
					<Alert severity="error">
						{this.state.errorMessage}
					</Alert>
				</Snackbar>

				<div className="drawerRoot">
					<Drawer
						className="drawerDrawer"
						variant="permanent"
						classes={{
							paper: "drawerDrawerPaper"
						}}
						anchor="left">

						<div className="drawerToolbar" />

						<CheckoutSteps
							id={this.state.checkout?.id}
							project_id={this.state.checkout?.project_id}
							briefing_step_status={this.state.checkout?.briefing_step_status}
							proposal_step_status={this.state.checkout?.proposal_step_status}
							payment_step_status={this.state.checkout?.payment_step_status}
							delivery_step_status={this.state.checkout?.delivery_step_status}
							release_step_status={this.state.checkout?.release_step_status}
							preference_group_id={this.state.checkout?.preference_group_id}
							proposal_id={this.state.checkout?.proposal_id}
							payment_id={this.state.checkout?.payment_id}
							delivery_id={this.state.checkout?.delivery_id}
							release_id={this.state.checkout?.release_id}

							is_freelancer={false}
							is_client={true}

							profile_id={this.state.freelancerProfile?.profile_id}
							profile_image_link={this.state.freelancerProfile?.profile_image_link}
							freelancer_name={this.state.freelancer_name}
							job_title={this.state.freelancerProfile?.job_title}
							city={this.state.freelancerProfile?.city}
							country={this.state.freelancerProfile?.country}
							job_description={this.state.freelancerProfile?.job_description}
							project_name={this.state.project_name}

							push={this.push}
						/>
					</Drawer>

					<main className="drawerContent">
						<Container
							maxWidth="md"
							className="topCenterContent topCenterContentVerticalScroll">

							<Grid container spacing={3}>
								<Grid item xs={12}>

									<div className="clientHeader">
										<h2>Hi, {this.state.client_name}!</h2>


										{!this.state.payment && <Alert severity="info">We have thought about different payment options for you to hire this project. Please, contact <a href={this.state.freelancer_email}>{this.state.freelancer_email}</a> if you have any questions or reach out to us.</Alert>}
										{this.state.payment && <Alert severity="info">Please, contact <a href={this.state.freelancer_email}>{this.state.freelancer_email}</a> if you have any questions or reach out to us.</Alert>}

									</div>

									<div className="titleBar">
										<div className="clear"></div>
									</div>

									<form autoComplete="off" onSubmit={e => e.preventDefault()}>
										<Grid
											container
											justify="flex-start"
											xs={12}
											direction="column"
											className="block"
											alignItems="stretch"
											spacing={1}>
											<Grid item xs={12}>

												<GridList
													cellHeight="auto"
													className="gridList"
													cols={1}
													spacing={10}>

													{this.state.payment && <GridListTile
														cols={1} className="noBorder">
														<div className="questionBox">

															<div className="investmentDescription left">
																<div className="clear"></div>

																<h3>Chosen Payment Method{this.state.checkout?.payment_step_status == "pending_internal" ? " - Pending Internal Approval" : ""}</h3>

																<p><strong>{paymentsComponentsClientMap[payment_option_name].title}</strong></p>
																{paymentsComponentsClientMap[payment_option_name].description}
															</div>
															<div className="investmentBox right">
																<h3>Total Investment</h3>
																<p><span className="price">U$ <strong>{((this.state.payment?.amount ? this.state.payment?.amount : 0) + (this.state.payment?.client_fee ? this.state.payment?.client_fee : 0)) / 100}</strong>.00</span></p>
															</div>
															<div className="clear"></div>
														</div>
													</GridListTile>}

													{!this.state.payment && <GridListTile
														cols={1} className="noBorder">
														<div className="questionBox">

															<div className="clear"></div>

															<div className="investmentDescription left">
																<div className="clear"></div>

																<h3>Quality guaranteed or your money back</h3>
																<p>If you choose to pay using our Escrow payment method or Flexible (buy-now pay-later) feature, Smelt Technologies Inc. guarantees the quality of your project or your money back.</p>
															</div>
															<div className="investmentBox right">
																<h3>Investment</h3>
																<p><span className="price">U$ <strong>{(this.state.proposal?.price ? this.state.proposal?.price : 0) / 100}</strong>.00</span></p>
															</div>

															<div className="clear"></div>

															<h3>Billing Address</h3>

															<TextField
																className=""
																name="cardName"
																label="Name"
																required
																type="text"
																onChange={this.handleChange}
																helperText={!this.state.cardNameCheck ? 'Invalid card name' : ''}
																value={this.state.cardName}
															/>

															<TextField
																className=""
																name="cardEmail"
																label="Email"
																required
																type="text"
																onChange={this.handleChange}
																helperText={!this.state.cardEmailCheck ? 'Invalid card email' : ''}
																value={this.state.cardEmail}
															/>

															<TextField
																className=""
																name="cardAddress"
																label="Address"
																required
																type="text"
																onChange={this.handleChange}
																helperText={!this.state.cardAddressCheck ? 'Invalid card address' : ''}
																value={this.state.cardAddress}
															/>

															<TextField
																className=""
																name="cardCity"
																label="City"
																required
																type="text"
																onChange={this.handleChange}
																helperText={!this.state.cardCityCheck ? 'Invalid card city' : ''}
																value={this.state.cardCity}
															/>

															<TextField
																className=""
																name="cardState"
																label="State"
																required
																type="text"
																onChange={this.handleChange}
																helperText={!this.state.cardStateCheck ? 'Invalid card state' : ''}
																value={this.state.cardState}
															/>

															<TextField
																className=""
																name="cardCountry"
																label="Country"
																required
																type="text"
																onChange={this.handleChange}
																helperText={!this.state.cardCountryCheck ? 'Invalid card country' : ''}
																value={this.state.cardCountry}
															/>

															<TextField
																className=""
																name="cardZip"
																label="ZIP"
																required
																type="text"
																onChange={this.handleChange}
																helperText={!this.state.cardZipCheck ? 'Invalid card zip' : ''}
																value={this.state.cardZip}
															/>

															<RadioGroup name="paymentOptions" className="paymentOptions" value={this.state.paymentMethodIndex} onChange={this.handleChangePaymentMethod}>
																<h3>Payment Methods</h3>

																{payment_options_names.indexOf("direct") != -1 && <FormControlLabel disabled={this.state.payment != undefined} value="direct" control={<Radio />} label={paymentsComponentsClientMap["direct"].title} />}
																{this.state.paymentMethodIndex == 'direct' && <div>
																	{paymentsComponentsClientMap["direct"].description}
																	<div className="totalInvestment">
																		<p>
																			<span>Fee</span>
																			<span className="price"><strong>{paymentsComponentsClientMap["direct"].client_fee * 100}</strong>%</span>
																		</p>

																		<p>
																			<span>Total investment</span>
																			<span className="price">USD <strong>${(this.state.proposal?.price ? this.state.proposal?.price : 0) * (1 + paymentsComponentsClientMap["direct"].client_fee) / 100}</strong>.00</span>
																		</p>

																		{paymentsComponentsClientMap["direct"].extra_label}
																	</div>

																	<div className="clear"></div>
																</div>}

																{payment_options_names.indexOf("credit_card") != -1 && <FormControlLabel disabled={this.state.payment != undefined} value="credit_card" control={<Radio />} label={paymentsComponentsClientMap["credit_card"].title} />}
																{this.state.paymentMethodIndex == 'credit_card' && <div>
																	{paymentsComponentsClientMap["credit_card"].description}
																	<div className="totalInvestment">
																		<p>
																			<span>Fee</span>
																			<span className="price"><strong>{paymentsComponentsClientMap["credit_card"].client_fee * 100}</strong>%</span>
																		</p>

																		<p>
																			<span>Total investment</span>
																			<span className="price">USD <strong>${(this.state.proposal?.price ? this.state.proposal?.price : 0) * (1 + paymentsComponentsClientMap["credit_card"].client_fee) / 100}</strong>.00</span>
																		</p>

																		{paymentsComponentsClientMap["credit_card"].extra_label}
																	</div>

																	<div className="clear"></div>

																	<div className="cardInfo">
																		<h4>Credit card information</h4>
																		<CardElement options={{
																			hidePostalCode: true,
																			style: {
																				base: {
																					fontSize: '16px',
																					color: '#424770',
																					'::placeholder': {
																						color: '#aab7c4',
																					},
																				},
																				invalid: {
																					color: '#9e2146',
																				},
																			},
																		}} />
																	</div>
																</div>}

																{payment_options_names.indexOf("basic_escrow") != -1 && <FormControlLabel disabled={this.state.payment != undefined} value="basic_escrow" control={<Radio />} label={paymentsComponentsClientMap["basic_escrow"].title} />}
																{this.state.paymentMethodIndex == 'basic_escrow' && <div>
																	{paymentsComponentsClientMap["basic_escrow"].description}
																	<div className="totalInvestment">
																		<p>
																			<span>Fee</span>
																			<span className="price"><strong>{paymentsComponentsClientMap["basic_escrow"].client_fee * 100}</strong>%</span>
																		</p>

																		<p>
																			<span>Total investment</span>
																			<span className="price">USD <strong>${(this.state.proposal?.price ? this.state.proposal?.price : 0) * (1 + paymentsComponentsClientMap["basic_escrow"].client_fee) / 100}</strong>.00</span>
																		</p>

																		{paymentsComponentsClientMap["basic_escrow"].extra_label}
																	</div>

																	<div className="clear"></div>

																	<div className="cardInfo">
																		<h4>Credit card information</h4>
																		<CardElement options={{
																			hidePostalCode: true,
																			style: {
																				base: {
																					fontSize: '16px',
																					color: '#424770',
																					'::placeholder': {
																						color: '#aab7c4',
																					},
																				},
																				invalid: {
																					color: '#9e2146',
																				},
																			},
																		}} />
																	</div>
																</div>}

																{payment_options_names.indexOf("assurance_escrow") != -1 && <FormControlLabel disabled={this.state.payment != undefined} value="assurance_escrow" control={<Radio />} label={paymentsComponentsClientMap["assurance_escrow"].title} />}
																{this.state.paymentMethodIndex == 'assurance_escrow' && <div>
																	{paymentsComponentsClientMap["assurance_escrow"].description}
																	<div className="totalInvestment">
																		<p>
																			<span>Fee</span>
																			<span className="price"><strong>{paymentsComponentsClientMap["assurance_escrow"].client_fee * 100}</strong>%</span>
																		</p>

																		<p>
																			<span>Total investment</span>
																			<span className="price">USD <strong>${(this.state.proposal?.price ? this.state.proposal?.price : 0) * (1 + paymentsComponentsClientMap["assurance_escrow"].client_fee) / 100}</strong>.00</span>
																		</p>

																		{paymentsComponentsClientMap["assurance_escrow"].extra_label}
																	</div>

																	<div className="clear"></div>

																	<div className="cardInfo">
																		<h4>Credit card information</h4>
																		<CardElement options={{
																			hidePostalCode: true,
																			style: {
																				base: {
																					fontSize: '16px',
																					color: '#424770',
																					'::placeholder': {
																						color: '#aab7c4',
																					},
																				},
																				invalid: {
																					color: '#9e2146',
																				},
																			},
																		}} />
																	</div>
																</div>}

																{payment_options_names.indexOf("flexible") != -1 && <FormControlLabel disabled={this.state.payment != undefined} value="flexible" control={<Radio />} label={paymentsComponentsClientMap["flexible"].title} />}
																{this.state.paymentMethodIndex == 'flexible' && <div>
																	{paymentsComponentsClientMap["assurance_escrow"].description}
																	<div className="totalInvestment">
																		<p>
																			<span>Fee</span>
																			<span className="price">varies</span>
																		</p>

																		<p>
																			<span>Total investment</span>
																			<span className="price">varies</span>
																		</p>

																		{paymentsComponentsClientMap["flexible"].extra_label}
																	</div>
																	<TextField
																		className="fullWidth"
																		name="flexibleExplanation"
																		label="Please, explain your case (needs and payment timeline) and we will review it shortly"
																		multiline
																		onChange={this.handleChange}
																		type="text"
																		helperText={!this.state.flexibleExplanationCheck ? 'Invalid flexible explanation' : ''}
																		value={this.state.flexibleExplanation}
																	/>
																</div>}
															</RadioGroup>
															<div className="clear"></div>
														</div>
													</GridListTile>}
												</GridList>
											</Grid>

											{this.state.checkout?.payment_step_status == "pending_client" && <div className="actionBar">
												<Button
													disabled={this.state.isLoadingForm}
													variant="contained"
													color="primary"
													size="large"
													fullWidth
													className="primaryButton"
													startIcon={(this.state.isLoadingForm ? <CircularProgress
														size={14}
													/> : (this.state.successForm ? <CheckRounded /> : <ArrowForward />))}
													onClick={this.handleMakePayment}
												>
													make payment
												</Button>
											</div>}
										</Grid>
									</form>
								</Grid>
							</Grid>
						</Container>
					</main>
				</div>
			</div>
		)
	}
}

class StripeInjectedMakePayment extends React.Component<RouteComponentProps<{}>, State> {
	render() {
		return (
			<Elements stripe={stripePromise} options={{ locale: "en" }}>
				<ElementsConsumer>
					{({ stripe, elements }) => (
						<MakePayment
							history={this.props.history}
							location={this.props.location}
							match={this.props.match}
							stripe={stripe!}
							elements={elements!}
						/>
					)}
				</ElementsConsumer>
			</Elements>
		)
	}
}

export default StripeInjectedMakePayment