import React from "react"
import { RouteComponentProps } from "react-router"
import parse from 'html-react-parser'

import {
	Button,
	CircularProgress,
	Container,
	Drawer,
	Grid,
	GridList,
	GridListTile,
	Tab,
	Tabs,
	Accordion,
	AccordionSummary,
	Typography,
	AccordionDetails,
	Snackbar
} from "@material-ui/core"

import { Alert } from "@material-ui/lab"
import "../styles/checkout_pages.scss"

import {
	ArrowForward,
	CheckRounded
} from "@material-ui/icons"

import CheckoutSteps from './components/checkout_steps'

import { config } from "../config"

import { BackendProjectFull, BackendCheckoutFull, BackendCheckout, BackendClientUserProject, BackendUserFull, BackendFreelancerProfile, BackendPreferenceGroupFull, BackendPreferenceGroupAnswers, BackendSection, BackendInput, BackendInputExtra, BackendAnswer, BackendAnswerExtra, BackendProposal, BackendPayment, BackendDelivery } from "./interfaces/backend"

const paymentsComponentsMap: Record<string, Record<string, any>> = {
	"direct": { "title": "Direct with service provider", "client_fee": 0, "freelancer_fee": 0, "description": <div className="textPaymentDescription"><p>This payment method allows you to hire the project directly with the service provider. If you choose this option, you should contact the service provider to understand what would be the best payment method to do the transaction directly.</p><p>This payment method does not include quality protection by Smelt Technologies Inc.</p></div>, "extra_label": <p className="insuranceLabel">No insurance or<br /> quality guarantee</p> },
	"credit_card": { "title": "Credit Card", "client_fee": 0, "freelancer_fee": 0.02, "description": <div className="textPaymentDescription"><p>This payment method allows you to pay using a credit card with the money then being deposited to the freelancer provided bank account.</p><p>This payment method does not include quality protection by Smelt Technologies Inc.</p></div>, "extra_label": <p className="insuranceLabel">No insurance or<br /> quality guarantee</p> },
	"basic_escrow": { "title": "Basic Escrow", "client_fee": 0.03, "freelancer_fee": 0.02, "description": <div className="textPaymentDescription"><p>Basic escrow is great if you have the capability to evaluate the delivered project yourself.</p><p>Smelt Technologies Inc may resolve any conflicts between the parties, but will evaluate based on facts and arguments, and not based on the actual project delivered.</p></div>, "extra_label": <p className="insuranceLabel">Dispute-based Escrow</p> },
	"assurance_escrow": { "title": "Technical-assurance Escrow", "client_fee": 0.05, "freelancer_fee": 0.02, "description": <div className="textPaymentDescription"><p>Technical-assurance escrow guarantees technical quality to you.</p> <p>Before sending the project to you and releasing payments to the service provider, our technical team will evaluate the project after it has been delivered to check if it matches your proposal. Based on that you can then open a dispute or accept the deliverables.</p></div>, "extra_label": <p className="insuranceLabel">Technical-quality guaranteed</p> },
	"flexible": { "title": "Flexible (buy-now pay-later)", "client_fee": -1, "freelancer_fee": -1, "description": <div className="textPaymentDescription"><p>Flexible options are evaluated on a case-by-case basis and it allows you to not only guarantee quality of the project, but also pay only after you have received the project done.</p></div>, "extra_label": <p className="insuranceLabel">Technical-quality guaranteed<br />Pay after project delivered</p> }
}

interface Params {
	id?: number
	noRedirect?: boolean
}

interface Props extends RouteComponentProps<{}> { }

interface State {
	freelancer_user_project_id?: number
	client_user_project_id?: number

	freelancer_email: string
	freelancer_name: string

	client_name: string

	isLoadingForm: boolean
	successForm: boolean

	activeTabIndex: number
	activeAccordionIndex: number

	project_id: number
	project_name: string
	project_current_state: string
	checkout_id: number
	delivery_id?: number

	clients_users_projects: BackendClientUserProject[]

	checkout?: BackendCheckout
	freelancerProfile?: BackendFreelancerProfile

	sections: BackendSection[]
	inputs: BackendInput[]
	inputs_extras: BackendInputExtra[]
	answersBackend: BackendAnswer[]
	answersExtrasBackend: BackendAnswerExtra[]

	proposal?: BackendProposal
	payment?: BackendPayment
	delivery?: BackendDelivery

	is_freelancer: boolean
	is_client: boolean

	errorForm: boolean
	errorMessage: string
	errorDuration: number
}

class CheckDelivery extends React.Component<Props, State> {
	setStateLikeSync(state: any) {
		return new Promise<void>((resolve) => {
			this.setState(state, resolve)
		});
	}

	showSnackMessage(message?: string, duration?: number) {
		if (message == undefined) {
			return
		}

		let actualDuration = duration ? duration : 3000

		this.setState({
			"errorForm": true,
			"errorMessage": message,
			"errorDuration": actualDuration
		})

		setTimeout(() => {
			this.setState({
				"errorForm": false,
				"errorMessage": "",
				"errorDuration": 0
			})
		}, actualDuration)
	}

	constructor(props: Props) {
		super(props)

		this.state = {
			freelancer_user_project_id: undefined,
			client_user_project_id: undefined,

			freelancer_email: "",
			freelancer_name: "",

			client_name: "",

			isLoadingForm: false,
			successForm: false,

			activeTabIndex: 1,
			activeAccordionIndex: -1,

			project_id: -1,
			project_name: "",
			project_current_state: "",
			checkout_id: -1,
			delivery_id: undefined,

			clients_users_projects: [],

			checkout: undefined,
			freelancerProfile: undefined,

			sections: [],
			inputs: [],
			inputs_extras: [],
			answersBackend: [],
			answersExtrasBackend: [],

			proposal: undefined,
			payment: undefined,
			delivery: undefined,

			is_freelancer: false,
			is_client: false,

			errorForm: false,
			errorMessage: "",
			errorDuration: 0
		}
	}

	async componentDidMount() {
		let persistenceToken = localStorage.getItem("persistence_token")
		if (persistenceToken === null) {
			this.props.history.replace("/login")
			return
		}

		let params = this.parseUrlParams(this.props.location.search)

		if (params.id) {
			try {
				const response = await fetch(`${config.API_URL}/read_project?project_id=${encodeURIComponent(params.id!)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
					method: 'get',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				this.responseReadProjectResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.project_full, responseJSON.error)
			} catch (error) {
				this.showSnackMessage(`read-project-fetch-error: ${error}`)
			}

			try {
				let persistenceToken = localStorage.getItem("persistence_token")

				const response = await fetch(`${config.API_URL}/read_user?persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
					method: 'get',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				this.responseReadUserResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.user_full, responseJSON.error)
			} catch (error) {
				this.showSnackMessage(`read-user-fetch-error: ${error}`)
			}

			try {
				let persistenceToken = localStorage.getItem("persistence_token")
				let checkout_id = this.state.checkout_id

				const response = await fetch(`${config.API_URL}/read_checkout?checkout_id=${encodeURIComponent(checkout_id)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
					method: 'get',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				let isDone = this.responseReadCheckoutResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.checkout_full, responseJSON.error)
				if (isDone) {
					return
				}
			} catch (error) {
				this.showSnackMessage(`read-checkout-fetch-error: ${error}`)
			}

			let isDone = this.redirect(6, params.noRedirect)
			if (isDone) {
				return
			}

			try {
				let persistenceToken = localStorage.getItem("persistence_token")
				let preference_group_id = this.state.checkout?.preference_group_id ? this.state.checkout?.preference_group_id! : ""

				const response = await fetch(`${config.API_URL}/read_preference_group?preference_group_id=${encodeURIComponent(preference_group_id)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
					method: 'get',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				this.responseReadPreferenceGroupResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.preference_group_full, responseJSON.error)
			} catch (error) {
				this.showSnackMessage(`read-preference-group-fetch-error: ${error}`)
			}

			try {
				let persistenceToken = localStorage.getItem("persistence_token")
				let preference_group_id = this.state.checkout?.preference_group_id ? this.state.checkout?.preference_group_id! : ""

				const response = await fetch(`${config.API_URL}/read_preference_group_answers?preference_group_id=${encodeURIComponent(preference_group_id)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
					method: 'get',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				this.responseReadPreferenceGroupAnswersResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.preference_group_answers, responseJSON.error)
			} catch (error) {
				this.showSnackMessage(`read-preference-group-answers-fetch-error: ${error}`)
			}

			try {
				let persistenceToken = localStorage.getItem("persistence_token")
				let proposal_id = this.state.checkout?.proposal_id ? this.state.checkout?.proposal_id! : ""

				const response = await fetch(`${config.API_URL}/read_proposal?proposal_id=${encodeURIComponent(proposal_id)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
					method: 'get',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				let isDone = this.responseReadProposalResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.proposal, responseJSON.error)
				if (isDone) {
					return
				}
			} catch (error) {
				this.showSnackMessage(`read-proposal-fetch-error: ${error}`)
			}

			try {
				let persistenceToken = localStorage.getItem("persistence_token")
				let payment_id = this.state.checkout?.payment_id ? this.state.checkout?.payment_id! : ""

				const response = await fetch(`${config.API_URL}/read_payment?payment_id=${encodeURIComponent(payment_id)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
					method: 'get',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				this.responseReadPaymentResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.payment, responseJSON.error)
			} catch (error) {
				this.showSnackMessage(`read-payment-fetch-error: ${error}`)
			}

			let delivery_id = this.state.checkout?.delivery_id
			if (delivery_id) {
				try {
					let persistenceToken = localStorage.getItem("persistence_token")

					const response = await fetch(`${config.API_URL}/read_delivery?delivery_id=${encodeURIComponent(delivery_id)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
						method: 'get',
						headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
					})
					if (!response.ok) {
						// this.showSnackMessage(`An error has occured: ${response.status}`)
					}
					const responseJSON = await response.json();
					this.responseReadDeliveryResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.delivery, responseJSON.error)
				} catch (error) {
					this.showSnackMessage(`read-delivery-fetch-error: ${error}`)
				}
			}
		} else {
			this.showSnackMessage("Missing invitation_id")
			this.props.history.replace("/dashboard")
		}
	}

	parseUrlParams = (query: string): Params => {
		const urlSearchParams = new URLSearchParams(query)

		let params: Params = {
			id: urlSearchParams.get("id") === null ? undefined : Number(urlSearchParams.get("id")!),
			noRedirect: urlSearchParams.get("no_redirect") === null ? undefined : (urlSearchParams.get("no_redirect")! == "true")
		}
		return params
	}

	redirect = (currentStep: number, no_redirect?: boolean) => {
		let activeStep = this.getActiveStep()

		if (no_redirect) {
			return false
		}

		if (currentStep == activeStep) {
			return false
		}

		var pushString = undefined

		if (activeStep == 3) {
			pushString = `/answer_project_briefing?id=${this.state.project_id}`
		} else if (activeStep == 4) {
			pushString = `/view_proposal?id=${this.state.project_id}`
		} else if (activeStep == 5) {
			pushString = `/make_payment?id=${this.state.project_id}`
		} else if (activeStep == 6) {
			pushString = `/check_delivery?id=${this.state.project_id}`
		} else if (activeStep == 7) {
			pushString = `/check_delivery?id=${this.state.project_id}`
		}

		if (pushString) {
			this.props.history.push(pushString)
		}

		return true
	}

	getActiveStep() {
		if (this.state.checkout?.briefing_step_status != undefined && this.state.checkout?.proposal_step_status == undefined) {
			return 3
		} else if (this.state.checkout?.proposal_step_status != undefined && this.state.checkout?.payment_step_status == undefined) {
			return 4
		} else if (this.state.checkout?.payment_step_status != undefined && this.state.checkout?.delivery_step_status == undefined) {
			return 5
		} else if (this.state.checkout?.delivery_step_status != undefined && this.state.checkout?.release_step_status == undefined) {
			return 6
		} else if (this.state.checkout?.release_step_status != undefined) {
			return 7
		}

		return 0
	}

	responseExecuteInvitation = (response: boolean, persistence_token?: string, redirect_to_project_id?: number, error?: string): void => {
		if (response === true) {
			this.props.history.replace(`/answer_project_briefing?id=${redirect_to_project_id!}`)
			window.location.reload()
		} else {
			this.showSnackMessage(error)
		}
	}

	responseReadProjectResolve = (response: boolean, persistence_token?: string, project_full?: BackendProjectFull, error?: string): void => {
		if (response === true) {
			if ((project_full!.project.current_state !== "published") || project_full!.preferences_groups.length != 1) {
				this.showSnackMessage("Project is not published, or has 0 or more than 1 (not possible) preference group")
				this.props.history.replace("/dashboard")
				return
			}

			this.setState({
				"freelancer_email": project_full!.freelancers_users_projects[0].email,
				"freelancer_name": project_full!.freelancers_users_projects[0].first_name,
				"client_name": (project_full!.clients_users_projects.length == 1) ? project_full!.clients_users_projects[0].first_name : project_full!.freelancers_users_projects[0].first_name,
				"freelancer_user_project_id": project_full!.freelancer_user_project_id,
				"client_user_project_id": project_full!.client_user_project_id,
				"project_id": project_full!.project.id,
				"project_name": project_full!.project.name,
				"project_current_state": project_full!.project.current_state,
				"checkout_id": project_full!.checkouts[0].id,
				"delivery_id": project_full!.checkouts[0].delivery_id,
				"clients_users_projects": project_full!.clients_users_projects
			})
			localStorage.setItem("persistence_token", persistence_token as string)
			document.title = this.state.project_name + " - Smelt"
		} else {
			this.showSnackMessage(error)
			this.props.history.replace("/dashboard")
		}
	}

	responseReadUserResolve = (response: boolean, persistence_token?: string, user_full?: BackendUserFull, error?: string): void => {
		if (response === true) {
			this.setState({
				"is_freelancer": user_full!.is_freelancer,
				"is_client": user_full!.is_client
			})
			localStorage.setItem("persistence_token", persistence_token as string)
		} else {
			this.showSnackMessage(error) // user with token not loaded
			localStorage.removeItem("persistence_token")
			this.props.history.replace("/login")
		}
	}

	responseReadCheckoutResolve = (response: boolean, persistence_token?: string, checkout_full?: BackendCheckoutFull, error?: string): boolean => {
		if (response === true) {
			this.setState({
				"checkout": checkout_full!.checkout,
				"freelancerProfile": checkout_full!.freelancer_profile
			})
			localStorage.setItem("persistence_token", persistence_token as string)
		} else {
			this.showSnackMessage(error)
			this.props.history.replace("/dashboard")
			return true
		}
		return false
	}

	responseReadPreferenceGroupResolve = (response: boolean, persistence_token?: string, preference_group_full?: BackendPreferenceGroupFull, error?: string): boolean => {
		if (response === true) {
			if (preference_group_full!.preference_group.current_state !== "published" && preference_group_full!.preference_group.current_state !== "archived") {
				this.showSnackMessage("Preference group not is not published or archived - should never happen and always match the project published or archived")
				this.props.history.replace("/dashboard")
				return true
			}

			this.setState({
				"sections": preference_group_full!.sections.map((section) => ({ "id": section.id, "preference_group_id": section.preference_group_id, "name": section.name, "order": section.order })),
				"inputs": preference_group_full!.inputs.map((input) => ({ "id": input.id, "section_id": input.section_id, "freelancer_project_id": input.freelancer_project_id, "order": input.order, "type": input.type, "is_required": input.is_required, "title": input.title, "thumbnail": JSON.parse(((input.thumbnail ? input.thumbnail : "{}")).replace(/'/g, '"')), "input_extra_id": input.input_extra_id })),
				"inputs_extras": preference_group_full!.inputs_extras.map((input_extra) => ({ "id": input_extra.id, "quote": input_extra.quote, "file_s3_link": input_extra.file_s3_link, "url_link": input_extra.url_link, "specific_part": input_extra.specific_part, "relevance_reason": input_extra.relevance_reason, "options": (input_extra.options ? input_extra.options : []).map(option => JSON.parse(option.replace(/'/g, '"'))) }))
			})
			localStorage.setItem("persistence_token", persistence_token as string)
		} else {
			this.showSnackMessage(error)
			this.props.history.replace("/dashboard")
			return true
		}
		return false
	}

	responseReadPreferenceGroupAnswersResolve = (response: boolean, persistence_token?: string, preference_group_answers?: BackendPreferenceGroupAnswers, error?: string): void => {
		if (response === true) {
			this.setState({
				"answersBackend": preference_group_answers!.answers.map((answer) => ({ "id": answer.id, "input_id": answer.input_id, "client_project_id": answer.client_project_id, "has_skipped": answer.has_skipped, "free_text": answer.free_text, "answer_extra_id": answer.answer_extra_id, "created_at": answer.created_at })), // check so there is always at least one section
				"answersExtrasBackend": preference_group_answers!.answers_extras.map((answer_extra) => ({ "id": answer_extra.id, "like_dislike": answer_extra.like_dislike, "quote": answer_extra.quote, "file_s3_link": answer_extra.file_s3_link, "url_link": answer_extra.url_link, "specific_part": answer_extra.specific_part, "relevance_reason": answer_extra.relevance_reason, "options": (answer_extra.options ? answer_extra.options : []).map(option => JSON.parse(option.replace(/'/g, '"'))) }))
			})
			localStorage.setItem("persistence_token", persistence_token as string)

		} else {
			this.showSnackMessage(error)
			this.props.history.replace("/dashboard")
		}
	}

	responseReadProposalResolve = (response: boolean, persistence_token?: string, proposal?: BackendProposal, error?: string): boolean => {
		if (response === true) {
			this.setState({
				"proposal": proposal!
			})
			localStorage.setItem("persistence_token", persistence_token as string)
		} else {
			this.showSnackMessage(error)
			this.props.history.replace("/dashboard")
			return true
		}
		return false
	}

	responseReadPaymentResolve = (response: boolean, persistence_token?: string, payment?: BackendPayment, error?: string) => {
		if (response === true) {
			this.setState({
				payment: payment!
			})
			localStorage.setItem("persistence_token", persistence_token as string)
		} else {
			this.showSnackMessage(error)
		}
	}

	responseReadDeliveryResolve = (response: boolean, persistence_token?: string, delivery?: BackendDelivery, error?: string) => {
		if (response === true) {
			this.setState({
				delivery: delivery!
			})
			localStorage.setItem("persistence_token", persistence_token as string)
		} else {
			this.showSnackMessage(error)
		}
	}

	responseSaveCheckoutResolve = (response: boolean, persistence_token?: string, checkout_id?: number, error?: string): void => {
		if (response === true) {
			localStorage.setItem("persistence_token", persistence_token as string)
			this.setState({ successForm: true });
			window.location.reload() // change this soon
		} else {
			this.setState({ successForm: false });
			this.showSnackMessage(error) // some regex problem?
		}
	}

	responseSaveDeliveryResolve = async (response: boolean, persistence_token?: string, delivery_id?: number, error?: string): Promise<boolean> => {
		if (response === true) {
			this.setStateLikeSync({
				"delivery_id": delivery_id!
			})
			localStorage.setItem("persistence_token", persistence_token as string)
			this.setState({ successForm: true });
		} else {
			this.setState({ successForm: false });
			this.showSnackMessage(error) // some regex problem?
		}

		return false
	}

	format = (date?: number) => {
		let dateRaw = date ? date : 0
		let dateDate = new Date(dateRaw)
		return dateDate.toDateString()
	}

	handleChangeTab = (event: React.ChangeEvent<any>, newValue: number) => {
		this.setState({ activeTabIndex: newValue + 1 });
	}

	handleChangeAccordion = (newValue: number) => {
		this.setState((previousState) => ({ activeAccordionIndex: previousState.activeAccordionIndex == newValue ? -1 : newValue }));
	}

	handleDeemCompleted = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()

		var persistenceToken = localStorage.getItem("persistence_token")
		let price = this.state.proposal?.price ? this.state.proposal?.price : 0

		let allValidationsPass = true

		if (allValidationsPass) {
			try {
				await this.setStateLikeSync({ isLoadingForm: true });

				const response = await fetch(`${config.API_URL}/save_delivery`, {
					method: 'post',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
					body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
						persistence_token: persistenceToken,
						action: "update",
						"delivery_id": this.state.delivery_id,
						delivery_params: { "deemed_completed_client": true }
					})
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				let isDone = await this.responseSaveDeliveryResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.delivery_id, responseJSON.error)
				if (isDone) {
					return
				}
			} catch (error) {
				this.showSnackMessage(`save-delivery-fetch-error: ${error}`)
			}

			persistenceToken = localStorage.getItem("persistence_token")

			let payment_option_name = this.state.payment?.payment_option ? JSON.parse(this.state.payment?.payment_option.replace(/'/g, '"')).name : ""
			var delivery_step_status = undefined
			var release_step_status = undefined

			if (payment_option_name == "basic_escrow") {
				delivery_step_status = "completed"
				release_step_status = "pending_internal"
			} else if (payment_option_name == "assurance_escrow" || payment_option_name == "flexible") {
				delivery_step_status = "completed"
				release_step_status = "pending_internal"
			}

			try {
				const response = await fetch(`${config.API_URL}/save_checkout`, {
					method: 'post',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
					body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
						persistence_token: persistenceToken,
						action: "update",
						checkout_id: this.state.checkout_id,
						checkout_params: { "delivery_step_status": delivery_step_status, "release_step_status": release_step_status }
					})
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				this.responseSaveCheckoutResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.checkout_id, responseJSON.error)
			} catch (error) {
				this.showSnackMessage(`save-checkout-fetch-error: ${error}`)
			}
		}
	}

	push = (page: string) => {
		this.props.history.push(page)
	}

	render() {
		let payment_option_name = this.state.payment?.payment_option ? JSON.parse(this.state.payment?.payment_option.replace(/'/g, '"')).name : ""

		let SectionsSummariesCards = this.state.sections.sort((section1, section2) => section1.order > section2.order ? 1 : -1).map((section) => {
			let InputsAnswersCards = this.state.inputs.filter((input) => input.section_id === section.id).sort((input1, input2) => input1.order > input2.order ? 1 : -1).map((input) => {
				// let input_extra = this.state.inputs_extras.filter((input_extra) => input_extra.id == input.input_extra_id)
				let answers = this.state.answersBackend.filter((answer) => answer.input_id == input.id)
				let answers_extras_ids = answers.map((answer) => answer.answer_extra_id)

				let answers_extras = this.state.answersExtrasBackend.filter((answer_extra) => answers_extras_ids.includes(answer_extra.id))

				return (
					<ul>{input.title}
						<br />
						<>
							{
								{
									question: (
										answers.map((answer: BackendAnswer) => {
											return (<p>{answer.free_text}</p>)
										})
									),
									checkbox: (
										answers.map((answer) => (
											answers_extras.filter((answer_extra) => (answer_extra.id == answer.answer_extra_id)).map((answer_extra) => (
												answer_extra.options?.map((option) => (
													<p>{option.name}</p>
												))
											))
										))
									),
									radio: (
										answers.map((answer) => (
											answers_extras.filter((answer_extra) => (answer_extra.id == answer.answer_extra_id)).map((answer_extra) => (
												answer_extra.options?.map((option) => (
													<p>{option.name}</p>
												))
											))
										))
									),
									ask_feedback: (
										answers.map((answer) => (
											answers_extras.filter((answer_extra) => (answer_extra.id == answer.answer_extra_id)).map((answer_extra) => (
												<>
													<p>{answer_extra.like_dislike}</p>
													<p>{answer.free_text}</p>
												</>
											))
										))
									),
									ask_example: (
										answers.map((answer) => (
											answers_extras.filter((answer_extra) => (answer_extra.id == answer.answer_extra_id)).map((answer_extra) => (
												<>
													<p>{answer_extra.quote}{answer_extra.url_link}{answer_extra.file_s3_link}</p>
													<p>{answer_extra.specific_part}</p>
													<p>{answer_extra.relevance_reason}</p>
												</>
											))
										))
									),
									multiline: (
										answers.map((answer) => (
											answers_extras.filter((answer_extra) => (answer_extra.id == answer.answer_extra_id)).map((answer_extra) => (
												answer_extra.options?.map((option) => (
													<p>{option.name}</p>
												))
											))
										))
									)
								}[input.type]
							}
						</>
						<br />
					</ul>
				)
			})

			return (
				<Accordion expanded={this.state.activeAccordionIndex == section.order} onChange={() => { this.handleChangeAccordion(section.order) }}>
					<AccordionSummary>
						<Typography>{section.name}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							{InputsAnswersCards}
						</Typography>
					</AccordionDetails>
				</Accordion>
			)
		})

		return (
			<div className="mainStyle checkoutStyle">
				<Snackbar open={this.state.errorForm} autoHideDuration={this.state.errorDuration}>
					<Alert severity="error">
						{this.state.errorMessage}
					</Alert>
				</Snackbar>

				<div className="drawerRoot">
					<Drawer
						className="drawerDrawer"
						variant="permanent"
						classes={{
							paper: "drawerDrawerPaper"
						}}
						anchor="left">

						<div className="drawerToolbar" />

						<CheckoutSteps
							id={this.state.checkout?.id}
							project_id={this.state.checkout?.project_id}
							briefing_step_status={this.state.checkout?.briefing_step_status}
							proposal_step_status={this.state.checkout?.proposal_step_status}
							payment_step_status={this.state.checkout?.payment_step_status}
							delivery_step_status={this.state.checkout?.delivery_step_status}
							release_step_status={this.state.checkout?.release_step_status}
							preference_group_id={this.state.checkout?.preference_group_id}
							proposal_id={this.state.checkout?.proposal_id}
							payment_id={this.state.checkout?.payment_id}
							delivery_id={this.state.checkout?.delivery_id}
							release_id={this.state.checkout?.release_id}

							is_freelancer={false}
							is_client={true}

							profile_id={this.state.freelancerProfile?.profile_id}
							profile_image_link={this.state.freelancerProfile?.profile_image_link}
							freelancer_name={this.state.freelancer_name}
							job_title={this.state.freelancerProfile?.job_title}
							city={this.state.freelancerProfile?.city}
							country={this.state.freelancerProfile?.country}
							job_description={this.state.freelancerProfile?.job_description}
							project_name={this.state.project_name}

							push={this.push}
						/>
					</Drawer>

					<main className="drawerContent">

						<Container
							maxWidth="md"
							className="topCenterContent topCenterContentVerticalScroll">

							<Grid container spacing={3}>
								<Grid item xs={12}>

									<div className="clientHeader">
										<h2>Hi, {this.state.client_name}</h2>
										<Alert severity="info">{this.state.freelancer_name} is working on your project and you should get it by {this.format(this.state.proposal?.deadline)}. Please, contact <a href={this.state.freelancer_email}>{this.state.freelancer_email}</a> if you have any questions.</Alert>
									</div>

									<div className="titleBar">
										<h2 className="left">Hire Project</h2>

										<div className="clear"></div>
									</div>

									<form autoComplete="off" onSubmit={e => e.preventDefault()}>
										<Grid
											container
											justify="flex-start"
											xs={12}
											direction="column"
											className="block"
											alignItems="stretch"
											spacing={1}>
											<Grid item xs={12}>
												<Tabs
													value={this.state.activeTabIndex - 1}
													indicatorColor="primary"
													textColor="primary"
													onChange={this.handleChangeTab}
												>
													<Tab label="Project Delivery"></Tab>
													<Tab label="Deliverables"> </Tab>
													<Tab label="Payment"> </Tab>
												</Tabs>
												<GridList
													cellHeight="auto"
													className="gridList"
													cols={1}
													spacing={10}>

													{this.state.activeTabIndex == 1 && <GridListTile
														cols={1} className="noBorder">
														<div className="questionBox">
															<Grid
																container
																spacing={8}
																direction="row">

																<h3>Project Delivery{this.state.checkout?.delivery_step_status == "pending_internal" ? " - Pending Internal Approval" : ""}</h3>
																<Grid item xs={12}>
																	{this.state.checkout?.delivery_step_status == "pending_freelancer" && <p className="stepPending">Your project has not been uploaded yet - we will let you know when it's done! :)</p>}

																	{this.state.checkout?.delivery_step_status != "pending_freelancer" && this.state.checkout?.delivery_step_status != undefined &&
																		<>
																			<a>{this.state.delivery?.link}</a>
																			<p>{this.state.delivery?.free_text}</p>
																		</>
																	}
																</Grid>
															</Grid>
														</div>
													</GridListTile>}

													{this.state.activeTabIndex == 2 && <GridListTile
														cols={1} className="noBorder">
														<div className="questionBox">
															<div className="clear"></div>

															<h3>Project Deliverables</h3>
															{parse(this.state.proposal?.deliverable ? this.state.proposal?.deliverable : "")}

															<h3>Due Date</h3>
															<p>{this.format(this.state.proposal?.deadline)}</p>

															<h3>Summary of Changes on Project Requirements</h3>
															{parse(this.state.proposal?.summary_briefing_changes ? this.state.proposal?.summary_briefing_changes : "")}

															<h3>Initial Project Requirements Summary</h3>

															<p>Below is a summary of your initial thoughts for this project.</p>

															{SectionsSummariesCards}
															<div className="clear"></div>
														</div>
													</GridListTile>}

													{this.state.activeTabIndex == 3 && <GridListTile
														cols={1} className="noBorder">
														<div className="questionBox">

															<div className="investmentDescription left">
																<div className="clear"></div>

																<h3>Chosen Payment Method</h3>

																<p><strong>{paymentsComponentsMap[payment_option_name].title}</strong></p>
																{paymentsComponentsMap[payment_option_name].description}
															</div>
															<div className="investmentBox right">
																<h3>Total Investment</h3>
																<p><span className="price">U$ <strong>{((this.state.payment?.amount ? this.state.payment?.amount : 0) + (this.state.payment?.client_fee ? this.state.payment?.client_fee : 0)) / 100}</strong>.00</span></p>
															</div>
															<div className="clear"></div>
														</div>
													</GridListTile>}
												</GridList>
											</Grid>

											{(this.state.checkout?.delivery_step_status == "pending_client") && (payment_option_name == "basic_escrow" || payment_option_name == "assurance_escrow" || payment_option_name == "flexible") && <div className="actionBar">
												<Button
													disabled={this.state.isLoadingForm}
													variant="contained"
													color="primary"
													size="large"
													fullWidth
													className="primaryButton"
													startIcon={(this.state.isLoadingForm ? <CircularProgress
														size={14}
													/> : (this.state.successForm ? <CheckRounded /> : <ArrowForward />))}
													onClick={this.handleDeemCompleted}
												>
													deem completed
												</Button>
											</div>}
										</Grid>
									</form>
								</Grid>
							</Grid>
						</Container>
					</main>
				</div>
			</div>
		)
	}
}

export default CheckDelivery