import React from "react"

import {
    Grid,
    GridListTile,
    Avatar,
    Chip,
    IconButton,
    ButtonBase,
    ClickAwayListener,
    Grow,
    Paper,
    Popper,
    MenuItem,
    MenuList
} from "@material-ui/core"
import {
    Edit,
    EditRounded,
    Archive,
    ArchiveRounded,
    Done,
    DoneRounded,
    MoreHoriz
} from "@material-ui/icons"
import AvatarGroup from "@material-ui/lab/AvatarGroup"

import CoreAnalytics from "../../utils/core_analytics"

import { config } from "./../../config"

interface Props {
    id: number,
    name: string,
    type_template: string,
    description: string,
    initials_members: string[],
    current_state: string,
    created_at: Date,

    has_preferences_groups: boolean,
    has_archive_permission: boolean,
    is_client: boolean,
    has_answered_first_briefing?: boolean,

    reload: () => void

    showSnackMessage: (message?: string, duration?: number) => void
}

interface State {
    openMore: boolean
    anchorRef: React.RefObject<HTMLButtonElement>
}

const monthNames: Array<string> = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
const currentStateComponentsMap: Record<string, Record<string, any>> = {
    "draft": { "color": "yellow", "mainIcon": <Edit />, "avatarIcon": <EditRounded /> },
    "published": { "color": "green", "mainIcon": <Done />, "avatarIcon": <DoneRounded /> },
    "archived": { "color": "gray", "mainIcon": <Archive />, "avatarIcon": <ArchiveRounded /> }
}

class ProjectCard extends React.Component<Props, State> {
    setStateLikeSync(state: any) {
        return new Promise<void>((resolve) => {
            this.setState(state, resolve)
        });
    }

    constructor(props: Props) {
        super(props)

        this.state = {
            openMore: false,
            anchorRef: React.createRef()
        }
    }

    getCardinalString = (number: number): string => {
        if (number == 1) {
            return "st"
        } else if (number == 2) {
            return "nd"
        } else if (number == 3) {
            return "rd"
        } else {
            return "nd"
        }
    }

    getDateString = (timestamp: Date): string => {
        let dateObject = new Date(timestamp);
        let monthName = monthNames[dateObject.getMonth()]
        let day = dateObject.getDate()
        let cardinalString = this.getCardinalString(day)
        let year = dateObject.getFullYear()

        return monthName + " " + day + cardinalString + ", " + year
    }

    responseSaveProjectResolve = (response: boolean, persistence_token?: string, error?: string): void => {
        if (response === true) {
            //this.props.showSnackMessage("Success")
            localStorage.setItem("persistence_token", persistence_token as string)
            this.props.reload()
        } else {
            this.props.showSnackMessage(error)
        }
    }

    archive = async () => {
        this.setState(({ openMore: false }))
        let persistenceToken = localStorage.getItem("persistence_token")

        await CoreAnalytics.trackArchiveProject(this.props.id);

        try {
            const response = await fetch(`${config.API_URL}/save_project`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
                body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
                    persistence_token: persistenceToken,
                    action: "update",
                    project_id: this.props.id,
                    project_params: { "current_state": "archived" }
                })
            })
            if (!response.ok) {
                // this.props.showSnackMessage(`An error has occured: ${response.status}`)
            }
            const responseJSON = await response.json();
            this.responseSaveProjectResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.error)
        } catch (error) {
            this.props.showSnackMessage(`save-project-fetch-error: ${error}`)
        }
    }

    handleToggle = async () => {
        let newOpenMore = !this.state.openMore
        await this.setStateLikeSync({ "openMore": newOpenMore })

        if (this.state.anchorRef.current !== null) {
            if (this.state.openMore === false) {
                this.state.anchorRef.current.focus()
            }
        }
    }

    handleClose = (event: React.MouseEvent<Document | HTMLLIElement>) => {
        if (null !== this.state.anchorRef.current) {
            if (this.state.anchorRef.current && this.state.anchorRef.current.contains(event.target as Node)) {
                return
            }
        }

        this.setState(({ openMore: false }))
    }

    handleListKeyDown(event: React.KeyboardEvent<HTMLUListElement>) {
        if (event.key === "Tab") {
            event.preventDefault()
            this.setState(({ openMore: false }))
        }
    }

    getClickableRef(): string {
        if (this.props.current_state == "draft") {
            if (this.props.has_preferences_groups) {
                return `/edit_project_briefing?id=${this.props.id}`
            } else {
                return `/create_project_basics?id=${this.props.id}`
            }
        } else if (this.props.current_state == "published" || this.props.current_state == "archived") {
            if (this.props.is_client) {
                return `answer_project_briefing?id=${this.props.id}`
            }
            return `view_project_briefing?id=${this.props.id}`
        }

        return "/dashboard"
    }

    render() {
        let initialsMembersAvatars = this.props.initials_members.map((initial_member) => {
            return (
                <Avatar alt={initial_member}>
                    {initial_member}
                </Avatar>
            )
        })

        return (
            <GridListTile cols={1}>
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <a href={this.getClickableRef()}><IconButton className={`ovalButton ${currentStateComponentsMap[this.props.current_state].color}`}>
                            {currentStateComponentsMap[this.props.current_state].mainIcon}
                        </IconButton></a>
                    </Grid>
                    <Grid item xs={3}>
                        <div className="projectDescription">
                            <a href={this.getClickableRef()}>
                                <h3>
                                    {this.props.name}
                                </h3>
                                <span className={`projectType ${currentStateComponentsMap[this.props.current_state].color}`}>
                                    {this.props.type_template}
                                </span>
                                <p>
                                    {this.props.description}
                                </p>
                            </a>
                        </div>
                    </Grid>
                    <Grid item xs={2}>
                        <AvatarGroup max={4}>
                            {initialsMembersAvatars}
                        </AvatarGroup>
                    </Grid>
                    <Grid item xs={2}>
                        <Chip
                            avatar={currentStateComponentsMap[this.props.current_state].avatarIcon}
                            size="medium"
                            className={currentStateComponentsMap[this.props.current_state].color}
                            label={this.props.current_state}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <div className="projectCreation">
                            <span>created on</span>
                            <span>
                                <strong>
                                    {this.getDateString(this.props.created_at)}
                                </strong>
                            </span>
                        </div>
                    </Grid>
                    {this.props.has_archive_permission && <Grid item xs={1}>
                        <div>
                            <ButtonBase
                                ref={this.state.anchorRef}
                                focusRipple
                                focusVisibleClassName="focusclass"
                                aria-controls={this.state.openMore ? "menu-list-grow" : undefined}
                                aria-haspopup="true"
                                onClick={this.handleToggle}>
                                <IconButton>
                                    <MoreHoriz />
                                </IconButton>
                            </ButtonBase>
                            <Popper
                                open={this.state.openMore}
                                anchorEl={this.state.anchorRef.current}
                                role={undefined}
                                transition
                                disablePortal>
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin:
                                                placement === "bottom"
                                                    ? "center top"
                                                    : "center bottom"
                                        }}>
                                        <Paper className="userProfileMenu">
                                            <ClickAwayListener
                                                onClickAway={this.handleClose}>
                                                <MenuList
                                                    autoFocusItem={this.state.openMore}
                                                    id="menu-list-grow"
                                                    onKeyDown={this.handleListKeyDown}>
                                                    <MenuItem onClick={this.archive}>
                                                        Archive
                                                    </MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </div>
                    </Grid>}
                </Grid>
            </GridListTile>
        )
    }
}

export default ProjectCard