import React from "react"
import { RouteComponentProps } from "react-router"

import {
	Button,
	CircularProgress,
	Container,
	Drawer,
	Grid,
	GridList,
	Snackbar
} from "@material-ui/core"

import {
	Add,
	ArrowBackRounded,
	ArrowForward,
	RadioButtonUnchecked,
	CheckCircleRounded,
	CheckRounded
} from "@material-ui/icons"

import {
	Skeleton,
	Alert
} from "@material-ui/lab"

import SectionCard from './components/section_card'
import logo from "../images/logo-smelt.png"

import CoreAnalytics from "../utils/core_analytics"
import { config } from "./../config"

import { BackendProjectFull } from "./interfaces/backend"
import { FrontendSectionOption } from "./interfaces/frontend"

const regexExpressions: Record<string, string> = {
	sectionNameCheck: "^(.{0,32})$"
}

interface Params {
	id?: number
}

interface Props extends RouteComponentProps<{}> { }

interface State {
	id: number
	name: string

	preference_group_id: number
	checkout_id: number

	isLoadingForm: boolean
	successForm: boolean

	description: string
	typeTemplate: string
	sectionsOptions: FrontendSectionOption[]

	errorForm: boolean
	errorMessage: string
	errorDuration: number
}

class CreateProjectBriefing extends React.Component<Props, State> {
	setStateLikeSync(state: any) {
		return new Promise<void>((resolve) => {
			this.setState(state, resolve)
		});
	}

	showSnackMessage(message?: string, duration?: number) {
		if (message == undefined) {
			return
		}

		let actualDuration = duration ? duration : 3000

		this.setState({
			"errorForm": true,
			"errorMessage": message,
			"errorDuration": actualDuration
		})

		setTimeout(() => {
			this.setState({
				"errorForm": false,
				"errorMessage": "",
				"errorDuration": 0
			})
		}, actualDuration)
	}

	constructor(props: Props) {
		super(props)

		this.state = {
			id: -1,
			name: "",

			preference_group_id: -1,
			checkout_id: -1,

			isLoadingForm: false,
			successForm: false,

			description: "",
			typeTemplate: "Blog Writing",
			sectionsOptions: [],

			errorForm: false,
			errorMessage: "",
			errorDuration: 0
		}
	}

	async componentDidMount() {
		let persistenceToken = localStorage.getItem("persistence_token")
		if (persistenceToken === null) {
			this.props.history.replace("/login")
			return
		}

		let params = this.parseUrlParams(this.props.location.search)
		if (params.id) {
			try {
				const response = await fetch(`${config.API_URL}/read_project?project_id=${encodeURIComponent(params.id!)}&persistence_token=${encodeURIComponent(persistenceToken)}`, {
					method: "get",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json"
					}
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				this.responseReadProjectResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.project_full, responseJSON.error)
			} catch (error) {
				this.showSnackMessage(`read-project-fetch-error: ${error}`)
			}
		} else {
			this.props.history.replace("/dashboard")
			return
		}

		document.title = "Create a new project - Smelt"
	}

	parseUrlParams = (query: string): Params => {
		const urlSearchParams = new URLSearchParams(query)

		let params: Params = {
			id: urlSearchParams.get("id") === null ? undefined : Number(urlSearchParams.get("id")!)
		}
		return params
	}

	getTypeTemplateSectionsOptions = (typeTemplate: string): FrontendSectionOption[] => {
		var typeTemplateSections = []

		if (typeTemplate == "Blog Writing") {
			typeTemplateSections = require('./../library_templates/blog_writing.json')
		} else if (typeTemplate == "E-commerce Website") {
			typeTemplateSections = require('./../library_templates/ecommerce_website.json')
		}
		else if (typeTemplate == "SEO Optimization") {
			typeTemplateSections = require('./../library_templates/seo_optimization.json')
		}
		else if (typeTemplate == "Custom Professional Website") {
			typeTemplateSections = require('./../library_templates/custom_professional_website.json')
		}
		else if (typeTemplate == "Digital Marketing") {
			typeTemplateSections = require('./../library_templates/digital_marketing.json')
		}

		return typeTemplateSections.map((typeTemplateSection: Record<string, any>, index: number) => {
			return { "order": typeTemplateSection.order as number, "name": typeTemplateSection.name as string, "inputs_params": typeTemplateSection.inputs_params, "is_checked": typeTemplateSection.default, "is_from_template": true, "is_saved": true, "name_check": true } as FrontendSectionOption
		})
	}

	responseReadProjectResolve = (response: boolean, persistence_token?: string, project_full?: BackendProjectFull, error?: string): void => {
		if (response === true) {
			if (!project_full!.is_freelancer_creator) {
				//this.showSnackMessage("Not project creator")
				this.props.history.replace("/dashboard")
				return
			}

			if (project_full!.project.current_state !== "draft") {
				//this.showSnackMessage("Project not in draft anymore")
				this.props.history.replace("/dashboard")
				return
			}

			if (project_full!.project.current_state !== "draft" || project_full!.preferences_groups.length > 0) {
				//this.showSnackMessage("Project not in draft anymore or already has at least one preference group")
				this.props.history.replace("/dashboard")
				return
			}

			var typeTemplateSectionsOptions: FrontendSectionOption[] = []

			if (project_full!.project.type_template != "Other") {
				typeTemplateSectionsOptions = this.getTypeTemplateSectionsOptions(project_full!.project.type_template)
			}

			this.setState({
				id: project_full!.project.id,
				name: project_full!.project.name,
				description: project_full!.project.description,
				typeTemplate: project_full!.project.type_template,
				sectionsOptions: typeTemplateSectionsOptions
			})
			localStorage.setItem("persistence_token", persistence_token as string)
		} else {
			//this.showSnackMessage(error)
			this.props.history.replace("/login")
		}
	}

	responseSaveCheckoutResolve = (response: boolean, persistence_token?: string, checkout_id?: number, error?: string): void => {
		if (response === true) {
			this.setState({ successForm: true });
			localStorage.setItem("persistence_token", persistence_token as string)
			var el = this;
			setTimeout(() => {
				el.props.history.push(`/edit_project_briefing?id=${el.state.id.toString()}`)
			}, 500)
		} else {
			this.showSnackMessage(error)
			this.setState({ successForm: false });
		}
	}

	responseSavePreferenceGroupResolve = (response: boolean, persistence_token?: string, project_id?: number, preference_group_id?: number, checkout_id?: number, error?: string): void => {
		if (response === true) {
			let sectionsNames: string = this.state.sectionsOptions.map((section) => {
				if (section.is_checked) return section.name;
			}).join(',')

			this.setStateLikeSync({
				"preference_group_id": preference_group_id!,
				"checkout_id": checkout_id!
			});

			CoreAnalytics.trackCreateProjectBriefing(this.state.id, this.state.name, sectionsNames);
		} else {
			this.showSnackMessage(error)
		}
	}

	updateSectionOption = (order: number, name?: string, is_checked?: boolean, is_saved?: boolean, is_deleted?: boolean) => {
		this.setState(previousState => {
			let sectionsOptions = previousState.sectionsOptions.filter((sectionOption) => ((sectionOption.order != order) || (sectionOption.order == order && !is_deleted))).sort((sectionOption1, sectionOption2) => sectionOption1.order > sectionOption2.order ? 1 : -1).map((sectionOption, index) => {
				var newSectionOption = sectionOption
				newSectionOption.order = index + 1

				if (order === sectionOption.order) {
					if (name !== undefined) {
						newSectionOption.name = name
						newSectionOption.name_check = (RegExp(regexExpressions["sectionNameCheck"])).test(name)
					}
					if (is_checked !== undefined) {
						newSectionOption.is_checked = is_checked
					}
					if (is_saved !== undefined) {
						newSectionOption.is_saved = is_saved
					}
				}

				return newSectionOption
			})

			return { "sectionsOptions": sectionsOptions }
		})
	}

	handleAddSection = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => { //todo: check regex
		event.preventDefault();

		this.setState(previousState => {
			return { "sectionsOptions": previousState.sectionsOptions.concat({ "order": previousState.sectionsOptions.length + 1, "name": "", "inputs_params": [], "is_checked": true, "is_from_template": false, "is_saved": false, "name_check": true }) }
		})
	}

	handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()

		await this.setStateLikeSync({ sectionsCheck: [true] } as any)

		let allValidationsPass = true

		if (allValidationsPass) {
			var persistenceToken = localStorage.getItem("persistence_token")

			let sectionsParams = this.state.sectionsOptions.filter((sectionOption) => sectionOption.is_saved && sectionOption.is_checked).sort((sectionOption1, sectionOption2) => sectionOption1.order > sectionOption2.order ? 1 : -1).map((sectionOption, index) => {
				return { "name": sectionOption.name, "order": index + 1, "inputs_params": sectionOption.is_from_template ? sectionOption.inputs_params : [] }
			})
			try {
				await this.setStateLikeSync({ isLoadingForm: true });

				const response = await fetch(`${config.API_URL}/save_preference_group`, {
					method: 'post',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
					body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
						persistence_token: persistenceToken,
						action: "create",
						preference_group_params: { "project_id": this.state.id, "name": "default", "sections_params": sectionsParams }
					})
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();

				await this.setStateLikeSync({ isLoadingForm: false });
				this.responseSavePreferenceGroupResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.project_id, responseJSON.preference_group_id, responseJSON.checkout_id, responseJSON.error)
			} catch (error) {
				this.showSnackMessage(`save-preference-group-fetch-error: ${error}`)
			}

			persistenceToken = localStorage.getItem("persistence_token")

			try {
				await this.setStateLikeSync({ isLoadingForm: true });

				const response = await fetch(`${config.API_URL}/save_checkout`, {
					method: 'post',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
					body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
						persistence_token: persistenceToken,
						action: "update",
						checkout_id: this.state.checkout_id,
						checkout_params: { "preference_group_id": this.state.preference_group_id }
					})
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();

				await this.setStateLikeSync({ isLoadingForm: false });
				this.responseSaveCheckoutResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.checkout_id, responseJSON.error)
			} catch (error) {
				this.showSnackMessage(`save-checkout-fetch-error: ${error}`)
			}
		} else {
			//this.showSnackMessage("not all validations passed - fix and try again")
		}
	}

	render() {
		let sectionsCards = this.state.sectionsOptions.sort((sectionOption1, sectionOption2) => sectionOption1.order > sectionOption2.order ? 1 : -1).map((sectionOption) => {
			return (
				<SectionCard
					order={sectionOption.order}
					name={sectionOption.name}
					is_checked={sectionOption.is_checked}
					is_saved={sectionOption.is_saved}
					name_check={sectionOption.name_check}
					updateSectionOption={this.updateSectionOption}
				/>
			)
		})

		return (
			<div className="mainStyle">
				<Snackbar open={this.state.errorForm} autoHideDuration={this.state.errorDuration}>
					<Alert severity="error">
						{this.state.errorMessage}
					</Alert>
				</Snackbar>

				<div className="drawerRoot">
					<Drawer
						className="drawerDrawer"
						variant="permanent"
						classes={{
							paper: "drawerDrawerPaper"
						}}
						anchor="left">
						<div className="topButtonMenu">
							<a href="/dashboard">
								<ArrowBackRounded /> <span>back</span>
							</a>
						</div>

						<div className="logo">
							<a href="/dashboard">
								<img src={logo} width="115" height="32" />
							</a>
						</div>
						<div className="drawerToolbar" />

						<div className="menuItems">
							<h3>Create a new project</h3>

							<div className="stepItem highlight">
								<CheckCircleRounded />
								<div className="stepDescription">
									<h4>basics</h4>
									<p>{this.state.name}</p>
								</div>
							</div>

							<div className="clear"></div>

							<div className="stepItem highlight">
								<RadioButtonUnchecked />
								<div className="stepDescription ">
									<h4>sections</h4>
									<Skeleton />
									<Skeleton />
									<Skeleton />
								</div>
							</div>
						</div>
					</Drawer>

					<main className="drawerContent">
						<Container maxWidth="sm" className="topCenterContent">
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<h1>
										Sections ({this.state.sectionsOptions.filter((sectionOption) => sectionOption.is_checked).length})
									</h1>
								</Grid>

								<Grid item xs={12}>
									<form autoComplete="off">
										<Grid
											container
											justify="flex-start"
											xs={12}
											direction="column"
											className="block"
											alignItems="stretch"
											spacing={1}>
											<Grid item xs={12}>
												<GridList
													cellHeight={80}
													className="gridList sectionList"
													cols={1}
													spacing={10}>
													{sectionsCards}
												</GridList>

												<div className="outlinedButton">

													<a href="#" onClick={this.handleAddSection}>
														<Add />{" "}
														<span>
															add section
														</span>
													</a>

												</div>
											</Grid>

											<div className="actionBar">
												<Button
													type="submit"
													variant="contained"
													color="primary"
													size="large"
													fullWidth
													className="primaryButton"
													startIcon={(this.state.isLoadingForm ? <CircularProgress
														size={20}
													/> : (this.state.successForm ? <CheckRounded /> : <ArrowForward />))}
													onClick={this.handleSubmit}
												>
													create
												</Button>
											</div>
										</Grid>
									</form>
								</Grid>
							</Grid>
						</Container>
					</main>
				</div>
			</div>
		)
	}
}

export default CreateProjectBriefing
