import React from "react"

import {
    Button,
    FormGroup,
    FormControl,
    Select,
    MenuItem,
    GridListTile,
    TextField,
    IconButton,
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    MenuList,
    Grid,
    ButtonBase,
    FormControlLabel,
    Checkbox,
    CardMedia,
    Modal,
    Fade,
    Backdrop
} from "@material-ui/core"

import {
    MoreHoriz,
    AddRounded,
    InsertDriveFile,
    TextFormat,
    FormatQuoteRounded,
    InsertLinkRounded
} from "@material-ui/icons"

import {
    Autocomplete
} from "@material-ui/lab"

import OptionCard from './option_card'

import { config } from "./../../config"

import { FrontendInputExtra, FrontendOption } from "./../interfaces/frontend"

const regexExpressions: Record<string, string> = {
    quote_check: "^(.{0,1024})$",
    file_s3_link_check: "^(.{0,128})$",
    url_link_check: "^(.{0,256})$",
    specific_part_check: "^(.{0,256})$",
    relevance_reason_check: "^(.{0,256})$",
    option_check: "^(.{0,32})$"
}

interface Props {
    order: number
    title: string
    type: string
    is_required: boolean
    thumbnail?: Record<string, any>
    input_extras?: FrontendInputExtra

    is_saved: boolean

    type_check: boolean
    title_check: boolean

    updateInput: (order: number, title?: string, type?: string, is_required?: boolean, is_saved?: boolean, is_deleted?: boolean, input_extra_params?: Record<string, any>) => void
    updateExtraInput: (order: number, quote?: string, file_s3_link?: string, url_link?: string, specific_part?: string, relevance_reason?: string, options?: Record<string, any>[]) => void

    showSnackMessage: (message?: string, duration?: number) => void
}

interface State {
    quote?: string
    file_s3_link?: string
    url_link?: string
    specific_part?: string
    relevance_reason?: string
    options?: FrontendOption[]

    quote_check: boolean
    file_s3_link_check: boolean
    url_link_check: boolean
    specific_part_check: boolean
    relevance_reason_check: boolean
    options_check: boolean

    openMore: boolean
    anchorRef: React.RefObject<HTMLButtonElement>

    // only for examples inputs
    exampleType?: string
    openPictureModal: boolean

    // only for url link preview
    url_preview_title?: string
    url_preview_description?: string
    url_preview_image?: string
}

class InputCard extends React.Component<Props, State> {
    setStateLikeSync(state: any) {
        return new Promise<void>((resolve) => {
            this.setState(state, resolve)
        });
    }

    constructor(props: Props) {
        super(props)

        this.state = {
            quote: undefined,
            file_s3_link: undefined,
            url_link: undefined,
            specific_part: undefined,
            relevance_reason: undefined,
            options: [],

            quote_check: true,
            file_s3_link_check: true,
            url_link_check: true,
            specific_part_check: true,
            relevance_reason_check: true,
            options_check: true,

            openMore: false,
            anchorRef: React.createRef(),

            exampleType: undefined,
            openPictureModal: false,

            url_preview_title: undefined,
            url_preview_description: undefined,
            url_preview_image: undefined
        }
    }


    async updateComponentType() {
        if (this.state.exampleType == null) {
            if (this.props.input_extras?.file_s3_link) {
                this.setState({ "exampleType": "image" })
            }
            else if (this.props.input_extras?.url_link) {
                this.setState({ "exampleType": "link" })

                let url_link = this.props.input_extras.url_link
                let persistenceToken = localStorage.getItem("persistence_token")

                const response = await fetch(`${config.API_URL}/get_url_preview?url_link=${url_link}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
                    method: 'get',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
                })
                if (!response.ok) {
                    // this.props.showSnackMessage(`An error has occured: ${response.status}`)
                }
                const responseJSON = await response.json();
                this.responseGetUrlPreviewResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.url_preview_title, responseJSON.url_preview_description, responseJSON.url_preview_image, responseJSON.error)
            }
            else {
                if (this.props.input_extras?.quote) {
                    this.setState({ "exampleType": "text" })
                }
            }
        }
    }

    async componentDidUpdate() {
        this.updateComponentType();
    }

    async componentDidMount() {
        this.updateComponentType();
    }

    responseGetUrlPreviewResolve = (response: boolean, persistence_token?: string, url_preview_title?: string, url_preview_description?: string, url_preview_image?: string, error?: string): void => {
        if (response === true) {
            localStorage.setItem("persistence_token", persistence_token as string)
            this.setState({
                "url_preview_title": url_preview_title!,
                "url_preview_description": url_preview_description!,
                "url_preview_image": url_preview_image!
            })
        } else {
            this.props.showSnackMessage(error) // likely a library problem loading url
        }
    }

    handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.updateInput(this.props.order, event.target.value, undefined, undefined, undefined, undefined, undefined)
    }

    handleChangeType = (event: React.MouseEvent<HTMLLIElement>) => {
        const clickedElement = event.target as HTMLDivElement
        const clickedElementValue = clickedElement.getAttribute("data-value")

        this.props.updateInput(this.props.order, undefined, clickedElementValue!, undefined, undefined, undefined, undefined)
    }

    handleCheck = (event: React.ChangeEvent<{}>, checked: boolean) => {
        this.props.updateInput(this.props.order, undefined, undefined, checked, undefined, undefined, undefined)
    }

    handleSave = async (event: React.MouseEvent<HTMLButtonElement>) => {
        this.props.updateInput(this.props.order, undefined, undefined, undefined, true, undefined, undefined)
        if (["radio", "checkbox", "ask_feedback"].includes(this.props.type)) {
            this.props.updateExtraInput(this.props.order, this.state.quote, this.state.file_s3_link, this.state.url_link, this.state.specific_part, this.state.relevance_reason, (this.state.options ? this.state.options : [])?.map((option) => ({ "name": option.name, "order": option.order })))

            if (this.state.exampleType === 'link') {
                let url_link = this.state.url_link
                let persistenceToken = localStorage.getItem("persistence_token")

                try {
                    const response = await fetch(`${config.API_URL}/get_url_preview?url_link=${url_link}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
                        method: 'get',
                        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
                    })
                    if (!response.ok) {
                        // this.props.showSnackMessage(`An error has occured: ${response.status}`)
                    }
                    const responseJSON = await response.json();
                    this.responseGetUrlPreviewResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.url_preview_title, responseJSON.url_preview_description, responseJSON.url_preview_image, responseJSON.error)
                } catch (error) {
                    this.props.showSnackMessage(`get-url-preview-error: ${error}`)
                }
            }
        }
    }

    handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.props.updateInput(this.props.order, undefined, undefined, undefined, undefined, true, undefined)
    }

    handleDelete = (event: React.MouseEvent<HTMLLIElement>) => {
        event.stopPropagation();
        this.props.updateInput(this.props.order, undefined, undefined, undefined, undefined, true, undefined)
    }

    handleAddOption = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState(previousState => {
            return { "options": previousState.options!.concat({ "name": "", "order": previousState.options!.length + 1, "name_check": true, "is_saved": false, "is_deleted": false, "is_added": true }) }
        })
    }

    updateOption = (order: number, name?: string, is_saved?: boolean, is_deleted?: boolean) => {
        this.setState(previousState => {
            let options = previousState.options!.filter((option) => ((option.order != order) || (option.order == order && !is_deleted))).sort((option1, option2) => option1.order > option2.order ? 1 : -1).map((option, index) => {
                var newOption = option
                newOption.order = index + 1

                if (order === option.order) {
                    if (name !== undefined) {
                        newOption.name = name
                        newOption.name_check = (RegExp(regexExpressions["option_check"])).test(name) // do some errors showing with it
                    }
                    if (is_saved !== undefined) {
                        newOption.is_saved = is_saved
                    }
                }

                return newOption
            })

            return { "options": options, options_check: options.every((option) => (RegExp(regexExpressions["option_check"])).test(option.name)) }
        })
    }

    handleSelectExampleType = (type: string) => {
        this.setState({
            exampleType: type,

            quote: undefined,
            file_s3_link: undefined,
            url_link: undefined,
            specific_part: undefined,
            relevance_reason: undefined,
            options: undefined,

            quote_check: true,
            file_s3_link_check: true,
            url_link_check: true,
            specific_part_check: true,
            relevance_reason_check: true,
            options_check: true,
        });
    }

    handleChangeInputExtras = (event: React.ChangeEvent<HTMLInputElement>) => {
        const key = event.target.name
        const keyCheck = event.target.name + "_check"
        const value = event.target.value
        const valueCheck = (RegExp(regexExpressions[keyCheck])).test(value)

        this.setState({ [key]: value, [keyCheck]: valueCheck } as any)
    }

    handleToggle = async () => {
        let newOpenMore = !this.state.openMore
        await this.setStateLikeSync({ "openMore": newOpenMore })

        if (this.state.anchorRef.current !== null) {
            if (this.state.openMore === false) {
                this.state.anchorRef.current.focus()
            }
        }
    }

    handleClose = (event: React.MouseEvent<Document | HTMLLIElement>) => {
        if (null !== this.state.anchorRef.current) {
            if (this.state.anchorRef.current && this.state.anchorRef.current.contains(event.target as Node)) {
                return
            }
        }

        this.setState(({ openMore: false }))
    }

    handleListKeyDown(event: React.KeyboardEvent<HTMLUListElement>) {
        if (event.key === "Tab") {
            event.preventDefault()
            this.setState(({ openMore: false }))
        }
    }

    handleOpenPictureModal = () => {
        this.setState({ openPictureModal: true });
    };

    handleClosePictureModal = () => {
        this.setState({ openPictureModal: false });
    };

    render() {
        var optionsCards = undefined

        optionsCards = this.state.options ? this.state.options.filter((option) => !option.is_deleted).sort((option1, option2) => option1.order > option2.order ? 1 : -1).map((option) => {
            return (
                <OptionCard
                    order={option.order}
                    name={option.name}
                    type={this.props.type}
                    thumbnail_img={option.thumbnail_img}
                    thumbnail_url={option.thumbnail_url}
                    name_check={option.name_check}
                    is_saved={option.is_saved}
                    updateOption={this.updateOption}
                />
            )
        }) : []

        return (
            <GridListTile cols={1}>
                {this.props.is_saved &&
                    <>
                        {
                            {
                                question:
                                    <div className="inlineQuestionEdit">
                                        <h3>{this.props.title}</h3>

                                        <IconButton ref={this.state.anchorRef} onClick={this.handleToggle} className="right">
                                            <MoreHoriz />
                                        </IconButton>
                                        {this.props.is_required ? <label className="right requiredLabel">Required</label> : <label className="right requiredLabel">Optional</label>}
                                        <Popper
                                            open={this.state.openMore}
                                            anchorEl={this.state.anchorRef.current}
                                            role={undefined}
                                            transition
                                            disablePortal>
                                            {({ TransitionProps, placement }) => (
                                                <Grow
                                                    {...TransitionProps}
                                                    style={{
                                                        transformOrigin:
                                                            placement === "bottom"
                                                                ? "center top"
                                                                : "center bottom"
                                                    }}>
                                                    <Paper className="userProfileMenu">
                                                        <ClickAwayListener
                                                            onClickAway={this.handleClose}>
                                                            <MenuList
                                                                autoFocusItem={this.state.openMore}
                                                                id="menu-list-grow"
                                                                onKeyDown={this.handleListKeyDown}>
                                                                <MenuItem onClick={this.handleDelete}>
                                                                    Delete
                                                                </MenuItem>
                                                            </MenuList>
                                                        </ClickAwayListener>
                                                    </Paper>
                                                </Grow>
                                            )}
                                        </Popper>

                                        <div className="clear"></div>
                                        <label className="helperLabel">
                                            Open-Ended Question
                                        </label>
                                        <div className="clear"></div>
                                        {this.props.thumbnail && <div className="inputGroupOptionImage">

                                            <div className="illustration">

                                                <CardMedia
                                                    image={this.props.thumbnail.thumbnail_img}
                                                    onClick={this.handleOpenPictureModal} />
                                                <Modal
                                                    className="modalPicture"
                                                    aria-labelledby="transition-modal-title"
                                                    aria-describedby="transition-modal-description"
                                                    open={this.state.openPictureModal}
                                                    onClose={this.handleClosePictureModal}
                                                    closeAfterTransition
                                                    BackdropComponent={Backdrop}
                                                    BackdropProps={{ timeout: 500 }}
                                                >
                                                    <Fade in={this.state.openPictureModal}>
                                                        <div className="paperModal">
                                                            <img src={this.props.thumbnail.thumbnail_img}></img>
                                                        </div>
                                                    </Fade>
                                                </Modal>
                                            </div>

                                            <label>{this.props.thumbnail.name}</label>

                                            {this.props.thumbnail.thumbnail_url && <div className="anchor">
                                                <InsertLinkRounded className="left" />
                                                <a href={this.props.thumbnail.thumbnail_url} className="left" target="_blank">{this.props.thumbnail.thumbnail_url}</a>
                                            </div>}

                                            <div className="clear"></div>

                                        </div>}
                                        <div className="clear"></div>
                                    </div>,
                                checkbox:
                                    <div className="inlineQuestionEdit">
                                        <h3>{this.props.title}</h3>
                                        <IconButton ref={this.state.anchorRef} onClick={this.handleToggle} className="right">
                                            <MoreHoriz />
                                        </IconButton>
                                        {this.props.is_required ? <label className="right requiredLabel">Required</label> : <label className="right requiredLabel">Optional</label>}

                                        <Popper
                                            open={this.state.openMore}
                                            anchorEl={this.state.anchorRef.current}
                                            role={undefined}
                                            transition
                                            disablePortal>
                                            {({ TransitionProps, placement }) => (
                                                <Grow
                                                    {...TransitionProps}
                                                    style={{
                                                        transformOrigin:
                                                            placement === "bottom"
                                                                ? "center top"
                                                                : "center bottom"
                                                    }}>
                                                    <Paper className="userProfileMenu">
                                                        <ClickAwayListener
                                                            onClickAway={this.handleClose}>
                                                            <MenuList
                                                                autoFocusItem={this.state.openMore}
                                                                id="menu-list-grow"
                                                                onKeyDown={this.handleListKeyDown}>
                                                                <MenuItem onClick={this.handleDelete}>
                                                                    Delete
                                                                </MenuItem>
                                                            </MenuList>
                                                        </ClickAwayListener>
                                                    </Paper>
                                                </Grow>
                                            )}
                                        </Popper>

                                        <div className="clear"></div>
                                        <label className="helperLabel">
                                            Checkbox
                                        </label>
                                        <div className="clear"></div>
                                        {this.props.thumbnail && <div className="inputGroupOptionImage">

                                            <div className="illustration">

                                                <CardMedia
                                                    image={this.props.thumbnail.thumbnail_img}
                                                    onClick={this.handleOpenPictureModal} />
                                                <Modal
                                                    className="modalPicture"
                                                    aria-labelledby="transition-modal-title"
                                                    aria-describedby="transition-modal-description"
                                                    open={this.state.openPictureModal}
                                                    onClose={this.handleClosePictureModal}
                                                    closeAfterTransition
                                                    BackdropComponent={Backdrop}
                                                    BackdropProps={{ timeout: 500 }}
                                                >
                                                    <Fade in={this.state.openPictureModal}>
                                                        <div className="paperModal">
                                                            <img src={this.props.thumbnail.thumbnail_img}></img>
                                                        </div>
                                                    </Fade>
                                                </Modal>
                                            </div>

                                            <label>{this.props.thumbnail.name}</label>

                                            {this.props.thumbnail.thumbnail_url && <div className="anchor">
                                                <InsertLinkRounded className="left" />
                                                <a href={this.props.thumbnail.thumbnail_url} className="left" target="_blank">{this.props.thumbnail.thumbnail_url}</a>
                                            </div>}

                                            <div className="clear"></div>

                                        </div>}
                                        <div className="clear"></div>
                                        <label className="helperLabel optionsLabel">
                                            {this.props.input_extras?.options ? this.props.input_extras.options.map((option) => {
                                                return (
                                                    <OptionCard
                                                        order={option.order}
                                                        name={option.name}
                                                        type="checkbox"
                                                        is_view={true}
                                                        thumbnail_img={option.thumbnail_img}
                                                        thumbnail_url={option.thumbnail_url}
                                                        name_check={option.name_check}
                                                        is_saved={true}
                                                        updateOption={this.updateOption}
                                                    />
                                                )
                                            }) : ""}
                                        </label>
                                        <div className="clear"></div>
                                    </div>,
                                radio: <div className="inlineQuestionEdit">
                                    <h3>{this.props.title}</h3>

                                    <IconButton ref={this.state.anchorRef} onClick={this.handleToggle} className="right">
                                        <MoreHoriz />
                                    </IconButton>

                                    {this.props.is_required ? <label className="right requiredLabel">Required</label> : <label className="right requiredLabel">Optional</label>}

                                    <Popper
                                        open={this.state.openMore}
                                        anchorEl={this.state.anchorRef.current}
                                        role={undefined}
                                        transition
                                        disablePortal>
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{
                                                    transformOrigin:
                                                        placement === "bottom"
                                                            ? "center top"
                                                            : "center bottom"
                                                }}>
                                                <Paper className="userProfileMenu">
                                                    <ClickAwayListener
                                                        onClickAway={this.handleClose}>
                                                        <MenuList
                                                            autoFocusItem={this.state.openMore}
                                                            id="menu-list-grow"
                                                            onKeyDown={this.handleListKeyDown}>
                                                            <MenuItem onClick={this.handleDelete}>
                                                                Delete
                                                            </MenuItem>
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>

                                    <div className="clear"></div>
                                    <label className="helperLabel">
                                        Radio
                                    </label>
                                    <div className="clear"></div>
                                    {this.props.thumbnail && <div className="inputGroupOptionImage">

                                        <div className="illustration">

                                            <CardMedia
                                                image={this.props.thumbnail.thumbnail_img}
                                                onClick={this.handleOpenPictureModal} />
                                            <Modal
                                                className="modalPicture"
                                                aria-labelledby="transition-modal-title"
                                                aria-describedby="transition-modal-description"
                                                open={this.state.openPictureModal}
                                                onClose={this.handleClosePictureModal}
                                                closeAfterTransition
                                                BackdropComponent={Backdrop}
                                                BackdropProps={{ timeout: 500 }}
                                            >
                                                <Fade in={this.state.openPictureModal}>
                                                    <div className="paperModal">
                                                        <img src={this.props.thumbnail.thumbnail_img}></img>
                                                    </div>
                                                </Fade>
                                            </Modal>
                                        </div>

                                        <label>{this.props.thumbnail.name}</label>

                                        {this.props.thumbnail.thumbnail_url && <div className="anchor">
                                            <InsertLinkRounded className="left" />
                                            <a href={this.props.thumbnail.thumbnail_url} className="left" target="_blank">{this.props.thumbnail.thumbnail_url}</a>
                                        </div>}

                                        <div className="clear"></div>

                                    </div>}
                                    <div className="clear"></div>
                                    <label className="helperLabel optionsLabel">
                                        {this.props.input_extras?.options ? this.props.input_extras.options.map((option) => {
                                            return (
                                                <OptionCard
                                                    order={option.order}
                                                    name={option.name}
                                                    type="radio"
                                                    is_view={true}
                                                    thumbnail_img={option.thumbnail_img}
                                                    thumbnail_url={option.thumbnail_url}
                                                    name_check={option.name_check}
                                                    is_saved={true}
                                                    updateOption={this.updateOption}
                                                />
                                            )
                                        }) : ""}
                                    </label>
                                    <div className="clear"></div>
                                </div>,
                                ask_feedback: <div className="inlineQuestionEdit">
                                    <h3>{this.props.title}</h3>

                                    <IconButton ref={this.state.anchorRef} onClick={this.handleToggle} className="right">
                                        <MoreHoriz />
                                    </IconButton>

                                    {this.props.is_required ? <label className="right requiredLabel">Required</label> : <label className="right requiredLabel">Optional</label>}


                                    <Popper
                                        open={this.state.openMore}
                                        anchorEl={this.state.anchorRef.current}
                                        role={undefined}
                                        transition
                                        disablePortal>
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{
                                                    transformOrigin:
                                                        placement === "bottom"
                                                            ? "center top"
                                                            : "center bottom"
                                                }}>
                                                <Paper className="userProfileMenu">
                                                    <ClickAwayListener
                                                        onClickAway={this.handleClose}>
                                                        <MenuList
                                                            autoFocusItem={this.state.openMore}
                                                            id="menu-list-grow"
                                                            onKeyDown={this.handleListKeyDown}>
                                                            <MenuItem onClick={this.handleDelete}>
                                                                Delete
                                                            </MenuItem>
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>

                                    <div className="clear"></div>
                                    <label className="helperLabel">
                                        Ask for feedback
                                    </label>
                                    <div className="clear"></div>
                                    {this.props.thumbnail && <div className="inputGroupOptionImage">

                                        <div className="illustration">

                                            <CardMedia
                                                image={this.props.thumbnail.thumbnail_img}
                                                onClick={this.handleOpenPictureModal} />
                                            <Modal
                                                className="modalPicture"
                                                aria-labelledby="transition-modal-title"
                                                aria-describedby="transition-modal-description"
                                                open={this.state.openPictureModal}
                                                onClose={this.handleClosePictureModal}
                                                closeAfterTransition
                                                BackdropComponent={Backdrop}
                                                BackdropProps={{ timeout: 500 }}
                                            >
                                                <Fade in={this.state.openPictureModal}>
                                                    <div className="paperModal">
                                                        <img src={this.props.thumbnail.thumbnail_img}></img>
                                                    </div>
                                                </Fade>
                                            </Modal>
                                        </div>

                                        <label>{this.props.thumbnail.name}</label>

                                        {this.props.thumbnail.thumbnail_url && <div className="anchor">
                                            <InsertLinkRounded className="left" />
                                            <a href={this.props.thumbnail.thumbnail_url} className="left" target="_blank">{this.props.thumbnail.thumbnail_url}</a>
                                        </div>}

                                        <div className="clear"></div>

                                    </div>}

                                    <div className="exampleView">
                                        <div className="exampleContainer">
                                            <div className="left quoteArea">
                                                <div className="specificPart"><p>{this.props.input_extras?.specific_part}</p></div>
                                                <div className="opinion"><p>{this.props.input_extras?.relevance_reason}</p></div>

                                                {this.props.input_extras?.quote && <FormatQuoteRounded className="left" />}
                                                {this.props.input_extras?.quote && <div className="quote">{this.props.input_extras?.quote}</div>}

                                                <div className="clear"></div>

                                                {this.state.exampleType === 'link' && <div className="linkDescription">
                                                    <InsertLinkRounded className="left" />
                                                    <a href={this.props.input_extras?.url_link} className="left" target="_blank">{this.props.input_extras?.url_link}</a>
                                                    <a href={this.props.input_extras?.url_link} className="right regularLink" target="_blank">visit link</a>
                                                    <div className="clear"></div>
                                                </div>}
                                            </div>
                                            {(this.state.exampleType === 'link' || this.state.exampleType === 'image') && <div className="illustration right">
                                                <CardMedia
                                                    image={this.state.url_preview_image}
                                                    onClick={this.handleOpenPictureModal}
                                                />
                                                <Modal
                                                    className="modalPicture"
                                                    aria-labelledby="transition-modal-title"
                                                    aria-describedby="transition-modal-description"
                                                    open={this.state.openPictureModal}
                                                    onClose={this.handleClosePictureModal}
                                                    closeAfterTransition
                                                    BackdropComponent={Backdrop}
                                                    BackdropProps={{ timeout: 500 }}
                                                >
                                                    <Fade in={this.state.openPictureModal}>
                                                        <div className="paperModal">
                                                            <img src={this.state.url_preview_image}></img>
                                                        </div>
                                                    </Fade>
                                                </Modal>
                                            </div>}
                                            <div className="clear"></div>
                                        </div>
                                    </div>
                                    <div className="clear"></div>
                                </div>,
                                ask_example: <div className="inlineQuestionEdit">
                                    <h3>{this.props.title}</h3>

                                    <IconButton ref={this.state.anchorRef} onClick={this.handleToggle} className="right">
                                        <MoreHoriz />
                                    </IconButton>
                                    {this.props.is_required ? <label className="right requiredLabel">Required</label> : <label className="right requiredLabel">Optional</label>}

                                    <Popper
                                        open={this.state.openMore}
                                        anchorEl={this.state.anchorRef.current}
                                        role={undefined}
                                        transition
                                        disablePortal>
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{
                                                    transformOrigin:
                                                        placement === "bottom"
                                                            ? "center top"
                                                            : "center bottom"
                                                }}>
                                                <Paper className="userProfileMenu">
                                                    <ClickAwayListener
                                                        onClickAway={this.handleClose}>
                                                        <MenuList
                                                            autoFocusItem={this.state.openMore}
                                                            id="menu-list-grow"
                                                            onKeyDown={this.handleListKeyDown}>
                                                            <MenuItem onClick={this.handleDelete}>
                                                                Delete
                                                            </MenuItem>
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>

                                    <div className="clear"></div>
                                    <label className="helperLabel">
                                        Request an example
                                    </label>
                                    <div className="clear"></div>
                                    {this.props.thumbnail && <div className="inputGroupOptionImage">

                                        <div className="illustration">

                                            <CardMedia
                                                image={this.props.thumbnail.thumbnail_img}
                                                onClick={this.handleOpenPictureModal} />
                                            <Modal
                                                className="modalPicture"
                                                aria-labelledby="transition-modal-title"
                                                aria-describedby="transition-modal-description"
                                                open={this.state.openPictureModal}
                                                onClose={this.handleClosePictureModal}
                                                closeAfterTransition
                                                BackdropComponent={Backdrop}
                                                BackdropProps={{ timeout: 500 }}
                                            >
                                                <Fade in={this.state.openPictureModal}>
                                                    <div className="paperModal">
                                                        <img src={this.props.thumbnail.thumbnail_img}></img>
                                                    </div>
                                                </Fade>
                                            </Modal>
                                        </div>

                                        <label>{this.props.thumbnail.name}</label>

                                        {this.props.thumbnail.thumbnail_url && <div className="anchor">
                                            <InsertLinkRounded className="left" />
                                            <a href={this.props.thumbnail.thumbnail_url} className="left" target="_blank">{this.props.thumbnail.thumbnail_url}</a>
                                        </div>}

                                        <div className="clear"></div>

                                    </div>}
                                    <div className="clear"></div>
                                </div>,
                                multiline: <div className="inlineQuestionEdit">
                                    <h3>{this.props.title}</h3>
                                    <IconButton ref={this.state.anchorRef} onClick={this.handleToggle} className="right">
                                        <MoreHoriz />
                                    </IconButton>
                                    {this.props.is_required ? <label className="right requiredLabel">Required</label> : <label className="right requiredLabel">Optional</label>}

                                    <Popper
                                        open={this.state.openMore}
                                        anchorEl={this.state.anchorRef.current}
                                        role={undefined}
                                        transition
                                        disablePortal>
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{
                                                    transformOrigin:
                                                        placement === "bottom"
                                                            ? "center top"
                                                            : "center bottom"
                                                }}>
                                                <Paper className="userProfileMenu">
                                                    <ClickAwayListener
                                                        onClickAway={this.handleClose}>
                                                        <MenuList
                                                            autoFocusItem={this.state.openMore}
                                                            id="menu-list-grow"
                                                            onKeyDown={this.handleListKeyDown}>
                                                            <MenuItem onClick={this.handleDelete}>
                                                                Delete
                                                            </MenuItem>
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>

                                    <div className="clear"></div>
                                    <label className="helperLabel">
                                        Multiline
                                    </label>
                                    <div className="clear"></div>
                                    {this.props.thumbnail && <div className="inputGroupOptionImage">

                                        <div className="illustration">

                                            <CardMedia
                                                image={this.props.thumbnail.thumbnail_img}
                                                onClick={this.handleOpenPictureModal} />
                                            <Modal
                                                className="modalPicture"
                                                aria-labelledby="transition-modal-title"
                                                aria-describedby="transition-modal-description"
                                                open={this.state.openPictureModal}
                                                onClose={this.handleClosePictureModal}
                                                closeAfterTransition
                                                BackdropComponent={Backdrop}
                                                BackdropProps={{ timeout: 500 }}
                                            >
                                                <Fade in={this.state.openPictureModal}>
                                                    <div className="paperModal">
                                                        <img src={this.props.thumbnail.thumbnail_img}></img>
                                                    </div>
                                                </Fade>
                                            </Modal>
                                        </div>

                                        <label>{this.props.thumbnail.name}</label>

                                        {this.props.thumbnail.thumbnail_url && <div className="anchor">
                                            <InsertLinkRounded className="left" />
                                            <a href={this.props.thumbnail.thumbnail_url} className="left" target="_blank">{this.props.thumbnail.thumbnail_url}</a>
                                        </div>}

                                        <div className="clear"></div>

                                    </div>}
                                    <div className="clear"></div>
                                    <label className="helperLabel">
                                        <Autocomplete
                                            disabled
                                            multiple
                                            defaultValue={["Select multiple options or create new ones."]}
                                            options={[]}
                                            freeSolo
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Please, type your answer"
                                                />
                                            )
                                            }
                                        />
                                        {this.props.input_extras?.options ? <span className="multilineOptions"><strong>Options: </strong>{this.props.input_extras.options.map((option) => (option.name)).join(", ")}</span> : ""}
                                    </label>
                                    <div className="clear"></div>
                                </div>,
                            }[this.props.type]
                        }
                    </>
                }
                {!this.props.is_saved &&
                    <div className="inlineQuestionEdit">
                        <div className="questionForm">

                            <TextField
                                multiline
                                className="noOutline"
                                name={this.props.title}
                                label="Type your question here"
                                onChange={this.handleChangeTitle}
                                type="text"
                                error={!this.props.title_check}
                                helperText={!this.props.title_check ? 'Invalid Title' : ''}
                            />
                            {
                                {
                                    question:
                                        <div></div>,
                                    checkbox:
                                        <FormGroup>
                                            {optionsCards}
                                            <Button
                                                size="medium"
                                                startIcon={<AddRounded />}
                                                className="outlinedSmallButton"
                                                onClick={this.handleAddOption}
                                            >
                                                add option
                                            </Button>
                                        </FormGroup>,
                                    radio:
                                        <FormGroup>
                                            {optionsCards}

                                            <Button
                                                size="medium"
                                                startIcon={<AddRounded />}
                                                className="outlinedSmallButton"
                                                onClick={this.handleAddOption}
                                            >
                                                add option
                                            </Button>
                                        </FormGroup>,
                                    ask_feedback:
                                        <FormGroup>
                                            <div className="addExamplePart1">
                                                <ButtonBase className={this.state.exampleType == 'link' ? "selectExampleType selectedExampleType" : "selectExampleType"} onClick={() => this.handleSelectExampleType('link')}>
                                                    <InsertDriveFile />
                                                    <label>Link</label>
                                                </ButtonBase>
                                                <ButtonBase className={this.state.exampleType == 'text' ? "selectExampleType selectedExampleType" : "selectExampleType"} onClick={() => this.handleSelectExampleType('text')}>
                                                    <TextFormat />
                                                    <label>Text</label>
                                                </ButtonBase>
                                            </div>
                                            <div className="addExamplePart2">
                                                <h3>Example details</h3>
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    direction="row">
                                                    {this.state.exampleType == 'link' && <Grid item xs={12}>
                                                        <TextField
                                                            required
                                                            className="outlinedStyle"
                                                            name="url_link"
                                                            label="Copy your link here"
                                                            variant="outlined"
                                                            onChange={this.handleChangeInputExtras}
                                                            error={!this.state.url_link_check}
                                                            helperText={!this.state.url_link_check ? 'Invalid url link' : ''}
                                                        />
                                                    </Grid>}
                                                    {this.state.exampleType == 'text' && <Grid item xs={12}>
                                                        <TextField
                                                            required
                                                            className="outlinedStyle"
                                                            name="quote"
                                                            label="Copy your text here"
                                                            multiline
                                                            rows={2}
                                                            variant="outlined"
                                                            onChange={this.handleChangeInputExtras}
                                                            error={!this.state.quote_check}
                                                            helperText={!this.state.quote_check ? 'Invalid quote' : ''}
                                                        />
                                                    </Grid>}
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            className="outlinedStyle"
                                                            name="specific_part"
                                                            label="Would you like that your customer give special attention to something on this example?"
                                                            variant="outlined"
                                                            multiline
                                                            rows={2}
                                                            onChange={this.handleChangeInputExtras}
                                                            error={!this.state.specific_part_check}
                                                            helperText={!this.state.specific_part_check ? 'Invalid specific part' : ''}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField

                                                            className="outlinedStyle"
                                                            name="relevance_reason"
                                                            multiline
                                                            rows={2}
                                                            label="Explain the reason why you would like to add this example / or what it illustrates."
                                                            variant="outlined"
                                                            onChange={this.handleChangeInputExtras}
                                                            error={!this.state.relevance_reason_check}
                                                            helperText={!this.state.relevance_reason_check ? 'Invalid reason relevance reason' : ''}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </FormGroup>
                                }[this.props.type]
                            }
                            <div className="clear"></div>
                        </div>
                        <div className="right rightButtons">
                            <FormControlLabel
                                className="left requiredCheck"
                                value="required"
                                control={<Checkbox name="required_checkbox" onChange={this.handleCheck} />}
                                label="Required"
                                labelPlacement="end"
                            />
                            <FormControl className="left">
                                <Select
                                    defaultValue="question"
                                    value={this.props.type}
                                    className="noOutline">
                                    <MenuItem onClick={this.handleChangeType} value="question">
                                        Open-Ended Question
                                    </MenuItem>
                                    <MenuItem onClick={this.handleChangeType} value="checkbox">
                                        Checkbox
                                    </MenuItem>
                                    <MenuItem onClick={this.handleChangeType} value="radio">
                                        Radio
                                    </MenuItem>
                                    <MenuItem onClick={this.handleChangeType} value="ask_feedback">
                                        Ask
                                        Feedback
                                    </MenuItem>
                                    <MenuItem onClick={this.handleChangeType} value="ask_example">
                                        Request
                                        Example
                                    </MenuItem>
                                </Select>
                            </FormControl>


                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                className="primaryButton"
                                onClick={this.handleSave}
                                disabled={!this.props.title_check || !this.props.type_check || !this.state.quote_check || !this.state.file_s3_link_check || !this.state.url_link_check || !this.state.specific_part_check || !this.state.relevance_reason_check || !this.state.options_check} // check this experience
                            >
                                save
                            </Button>

                            <Button className="smallButton" onClick={this.handleCancel}>
                                cancel
                            </Button>
                        </div>

                        <div className="clear"></div>
                    </div>
                }
            </GridListTile>
        )
    }
}

export default InputCard