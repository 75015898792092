import React from "react"

import {
    Stepper,
    Step,
    StepLabel
} from "@material-ui/core"

import { config } from "../../config"

interface Props {
    id?: number
    project_id?: number
    briefing_step_status?: string
    proposal_step_status?: string
    payment_step_status?: string
    delivery_step_status?: string
    release_step_status?: string
    preference_group_id?: number
    proposal_id?: number
    payment_id?: number
    delivery_id?: number
    release_id?: number

    is_freelancer?: boolean
    is_client?: boolean
    number_of_clients?: number

    profile_id?: string
    profile_image_link?: string
    freelancer_name?: string
    job_title?: string
    city?: string
    country?: string
    job_description?: string
    project_name?: string

    client_name?: string
    client_email?: string

    push: (page: string) => void
}

interface State { }

class CheckoutSteps extends React.Component<Props, State> {
    pushStep = (stepClicked: number) => {
        let activeStep = this.getActiveStep()
        if (stepClicked > activeStep) {
            return
        }
        var pushString = undefined

        if (stepClicked == 3) {
            pushString = this.props.is_client ? `/answer_project_briefing?id=${this.props.project_id}` : (this.props.is_freelancer ? `/view_project_briefing?id=${this.props.project_id}` : undefined)
        } else if (stepClicked == 4) {
            pushString = this.props.is_client ? `/view_proposal?id=${this.props.project_id}` : (this.props.is_freelancer ? `/make_proposal?id=${this.props.project_id}` : undefined)
        } else if (stepClicked == 5) {
            pushString = this.props.is_client ? `/make_payment?id=${this.props.project_id}` : (this.props.is_freelancer ? `/check_payment?id=${this.props.project_id}` : undefined)
        } else if (stepClicked == 6) {
            pushString = this.props.is_client ? `/check_delivery?id=${this.props.project_id}` : (this.props.is_freelancer ? `/make_delivery?id=${this.props.project_id}` : undefined)
        } else if (stepClicked == 7) {
            pushString = `/see_receipt?id=${this.props.project_id}`
        }

        if (pushString) {
            this.props.push(pushString+"&no_redirect=true")
        }
    }

    getActiveStep() {
        if (this.props.briefing_step_status != undefined && this.props.proposal_step_status == undefined) {
            return 3
        } else if (this.props.proposal_step_status != undefined && this.props.payment_step_status == undefined) {
            if(this.props.proposal_step_status == "pending_freelancer" && this.props.is_client) {
                return 3
            }
            return 4
        } else if (this.props.payment_step_status != undefined && this.props.delivery_step_status == undefined) {
            return 5
        } else if (this.props.delivery_step_status != undefined && this.props.release_step_status == undefined) {
            return 6
        } else if (this.props.release_step_status != undefined) {
            return 7
        }

        return 0
    }

    render() {
        return (
            <div className="menuItems">
                {this.props.is_client && <div className="profileDescription">
                    <img className="picture left" src={this.props.profile_image_link} />
                    <div className="profileLabel left">
                        <h2>{this.props.freelancer_name}</h2>
                        <h3>{this.props.job_title}</h3>
                        <span className="location">{`${this.props.city},${this.props.country}`}</span>
                    </div>
                    <div className="clear"></div>
                    <p>{this.props.job_description}</p>
                </div>}

                {(this.props.number_of_clients ? this.props.number_of_clients : 0) > 0 && this.props.is_freelancer && <div className="clientDescription">
                    <h2>{this.props.client_name}</h2>
                    <h4>{this.props.client_email}</h4>

                    <div className="clear"></div>
                </div>}

                <h3>{this.props.project_name}</h3>

                <div className="clear"></div>

                <div className="header">
                    <div className="container">
                        <div className="steps">
                            <Stepper activeStep={this.getActiveStep()-1} orientation="vertical">
                                <Step onClick={() => { this.pushStep(1) }}>
                                    <StepLabel><label className="stepLabel">Step 01</label><br />{this.props.is_client ? "Find a Professional" : (this.props.is_freelancer ? "Find a Customer" : "")}</StepLabel>
                                </Step>
                                <Step onClick={() => { this.pushStep(2) }}>
                                    <StepLabel><label className="stepLabel">Step 02</label><br />{this.props.is_client ? "Ask a Quote" : (this.props.is_freelancer ? "Get a Quote Request" : "")}</StepLabel>
                                </Step>
                                <Step onClick={() => { this.pushStep(3) }} className={this.getActiveStep() >= 2 ? "clickable" : ""}>
                                    <StepLabel><label className="stepLabel">Step 03</label><br />{this.props.is_client ? "Explain Project Requirements" : (this.props.is_freelancer ? "Understand Project Requirements" : "")}</StepLabel>
                                </Step>
                                <Step onClick={() => { this.pushStep(4) }} className={this.getActiveStep() >= 3 ? "clickable" : ""}>
                                    <StepLabel><label className="stepLabel">Step 04</label><br />{this.props.is_client ? "Hire Project" : (this.props.is_freelancer ? "Make Proposal" : "")}</StepLabel>
                                </Step>
                                <Step onClick={() => { this.pushStep(5) }} className={this.getActiveStep() >= 4 ? "clickable" : ""}>
                                    <StepLabel><label className="stepLabel">Step 05</label><br />{this.props.is_client ? "Make Payment" : (this.props.is_freelancer ? "Wait Payment Confirmation" : "")}</StepLabel>
                                </Step>
                                <Step onClick={() => { this.pushStep(6) }} className={this.getActiveStep() >= 5 ? "clickable" : ""}>
                                    <StepLabel><label className="stepLabel">Step 06</label><br />{this.props.is_client ? "Check Delivery" : (this.props.is_freelancer ? "Deliver Project" : "")}</StepLabel>
                                </Step>
                                {/* <Step onClick={() => { this.pushStep(7) }} className={this.getActiveStep() <= 7 ? "clickable" : ""}>
                                    <StepLabel><label className="stepLabel">Step 07</label><br />See Receipt</StepLabel>
                                </Step> */}
                            </Stepper>
                        </div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CheckoutSteps