import React from "react"
import { RouteComponentProps } from "react-router"

import Grid from "@material-ui/core/Grid"
import ArrowForward from "@material-ui/icons/ArrowForward"
import { TextField, Button, Snackbar, CircularProgress } from "@material-ui/core"
import Box from "@material-ui/core/Box"

import "../styles/general.scss"
import "../styles/unauthenticated.scss"
import logo from "../images/logo-smelt.png"
import { Alert } from "@material-ui/lab"
import { CheckRounded } from "@material-ui/icons"
import CoreAnalytics from "../utils/core_analytics"
import { config } from "./../config"

interface Params { }

interface Props extends RouteComponentProps<{}> { }

interface State {
	email: string
	emailCheck: boolean

	isLoadingForm: boolean
	successForm: boolean

	errorForm: boolean
	errorMessage: string
	errorDuration: number
}

const regexExpressions: Record<string, RegExp | string> = {
	emailCheck: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
}

class Login extends React.Component<Props, State> {
	setStateLikeSync(state: any) {
		return new Promise<void>((resolve) => {
			this.setState(state, resolve)
		});
	}

	showSnackMessage(message?: string, duration?: number) {
		if (message == undefined) {
			return
		}

		let actualDuration = duration ? duration : 3000

		this.setState({
			"errorForm": true,
			"errorMessage": message,
			"errorDuration": actualDuration
		})

		setTimeout(() => {
			this.setState({
				"errorForm": false,
				"errorMessage": "",
				"errorDuration": 0
			})
		}, actualDuration)
	}

	constructor(props: any) {
		super(props)

		this.state = {
			email: "",

			isLoadingForm: false,
			successForm: false,
			emailCheck: true,

			errorForm: false,
			errorMessage: "",
			errorDuration: 0
		}
	}

	responseForgotPasswordResolve = (response: boolean, error?: string): void => {
		if (response === true) {
			this.setState({ successForm: true });
			CoreAnalytics.trackAskForgetPassword(this.state.email)
		} else {
			this.setState({ successForm: false });
			if (error == 'no-user-for-email') {
				this.setState({ emailCheck: false });
			}
		}
	}

	handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const key = event.target.name
		const keyCheck = event.target.name + "Check"
		const value = event.target.value
		const valueCheck = RegExp(regexExpressions[keyCheck]).test(value)

		this.setState({ [key]: value, [keyCheck]: valueCheck } as any)
	}

	handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()

		await this.setStateLikeSync({
			emailCheck: (RegExp(regexExpressions["emailCheck"])).test(this.state.email)
		})

		let allValidationsPass = this.state.emailCheck

		if (allValidationsPass) {
			try {
				await this.setStateLikeSync({ isLoadingForm: true });

				const response = await fetch(`${config.API_URL}/forgot_password`, {
					method: 'post',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
					body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
						email: this.state.email
					})
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();

				await this.setStateLikeSync({ isLoadingForm: false });
				this.responseForgotPasswordResolve(responseJSON.response, responseJSON.error)
			} catch (error) {
				this.setState({ isLoadingForm: false });
				this.showSnackMessage(`forgot-password-fetch-error: ${error}`)
			}
		} else {
			//this.showSnackMessage('not all validations passed - fix and try again')
		}
	}

	componentDidMount() {
		document.title = "Forgot your password - Smelt";

	}

	render() {
		return (
			<Grid container className="guestContainer" spacing={0}>
				<Snackbar open={this.state.errorForm} autoHideDuration={this.state.errorDuration}>
					<Alert severity="error">
						{this.state.errorMessage}
					</Alert>
				</Snackbar>

				<Snackbar open={this.state.successForm} autoHideDuration={3000}>
					<Alert severity="success">
						You have received an e-mail with instructions to reset your password.
					</Alert>
				</Snackbar>

				<Grid item xs={5}>
					<Box className="mainFormArea">
						<Grid container justify="center">
							<Grid item xs={12}>
								<div className="logo">
									<a href="/login">
										<img
											src={logo}
											width="150"
											height="43"
										/>
									</a>
								</div>
							</Grid>

							<Grid item xs={12}>
								<form autoComplete="off">
									<Grid
										container
										justify="flex-start"
										xs={6}
										direction="column"
										className="block"
										alignItems="stretch"
										spacing={1}>
										<h2>Forgot your password?</h2>

										<Grid item xs={12}>
											<p>
												We'll help you reset your
												password and get back on track.
											</p>
										</Grid>

										<Grid item xs={12}>
											<TextField
												fullWidth
												required
												name="email"
												label="Email"
												onChange={this.handleChange}
												error={!this.state.emailCheck}
												helperText={!this.state.emailCheck ? 'Invalid Email' : ''}
											/>
										</Grid>

										<Grid item xs={12}>
											<Button
												variant="contained"
												color="primary"
												type="submit"
												size="large"
												fullWidth
												className="primaryButton marginButtonTop"
												startIcon={(this.state.isLoadingForm ? <CircularProgress
													size={20}
												/> : (this.state.successForm ? <CheckRounded /> : <ArrowForward />))}
												onClick={this.handleSubmit}>
												reset my password
											</Button>
										</Grid>

										<Grid item xs={12}>
											<label>
												<a
													href="/login"
													className="primaryLink">
													Back to login
												</a>
											</label>
										</Grid>
									</Grid>
								</form>
							</Grid>
						</Grid>
					</Box>
				</Grid>
				<Grid item xs={7}>
					<Box className="heroArea">
						<Grid container justify="center">
							<Grid item xs={12}>
								<h1>
									Bringing creative workers and companies together.
								</h1>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>
		)
	}
}

export default Login
