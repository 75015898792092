import React from "react"
import { RouteComponentProps } from "react-router"

import {
    Container,
    Grid,
    GridList
} from "@material-ui/core"

import AvatarGroup from "@material-ui/lab/AvatarGroup"

import {
    NavigateBeforeRounded,
    NavigateNextRounded
} from "@material-ui/icons"

import InputCardView from './input_card_view'

import { BackendClientUserProject, BackendSection, BackendInput, BackendInputExtra, BackendAnswer, BackendAnswerExtra } from "./../interfaces/backend"

interface Props {
    freelancer_user_project_id?: number
    client_user_project_id?: number

    project_id: number
    project_name: string
    project_current_state: string
    preference_group_id: number

    sections: BackendSection[]
    inputs: BackendInput[]
    inputs_extras: BackendInputExtra[]
    answers: BackendAnswer[]
    answers_extras: BackendAnswerExtra[]

    clients_users_projects: BackendClientUserProject[]

    section_selected_id: number
    section_selected_name: string
    section_selected_order: number

    count_sections: number

    goToPreviousSection: () => void
    goToNextSection: () => void

    showSnackMessage: (message?: string, duration?: number) => void
}

interface State { }

class ViewAnswers extends React.Component<Props, State> {
    setStateLikeSync(state: any) {
        return new Promise<void>((resolve) => {
            this.setState(state, resolve)
        });
    }

    render() {
        let InputsCards = this.props.inputs.filter((input) => input.section_id === this.props.section_selected_id).sort((input1, input2) => input1.order > input2.order ? 1 : -1).map((input) => {
            let input_extra = this.props.inputs_extras.filter((input_extra) => input_extra.id == input.input_extra_id)
            let answers = this.props.answers.filter((answer) => answer.input_id == input.id)
            let answers_extras_ids = answers.map((answer) => answer.answer_extra_id)
            let clients_projects_ids = answers.map((answer) => answer.client_project_id)

            let answers_extras = this.props.answers_extras.filter((answer_extra) => answers_extras_ids.includes(answer_extra.id))
            let answers_clients_users_projects = this.props.clients_users_projects.filter((client_user_project) => clients_projects_ids.includes(client_user_project.client_project_id))

            return (
                <InputCardView
                    order={input.order}
                    type={input.type}
                    is_required={input.is_required}
                    title={input.title}
                    thumbnail={Object.keys(input.thumbnail).length !== 0 ? input.thumbnail : undefined}
                    input_extras={input_extra.length == 1 ? input_extra[0] : undefined}
                    answers={answers}
                    answers_extras={answers_extras}
                    answers_clients_users_projects={answers_clients_users_projects}
                    
                    showSnackMessage={this.props.showSnackMessage}
                />
            )
        })

        return (
            <Container
                maxWidth="md"
                className="topCenterContent topContent">
                <Grid container>
                    <Grid item xs={12} className="questionsListTable">
                        <form autoComplete="off">
                            <Grid
                                container
                                justify="flex-start"
                                xs={12}
                                direction="column"
                                className="block"
                                alignItems="stretch"
                                spacing={1}>
                                <Grid item xs={12}>
                                    <h2 className="left">{this.props.section_selected_name} <span className="detailLabel">(section {this.props.section_selected_order} of {this.props.count_sections})</span></h2>
                                    <div className="clear"></div>
                                    <a onClick={() => { this.props.goToPreviousSection() }} className={`left navigationButton ${this.props.section_selected_order == 1 ? "disabledNavigationButton" : ""}`}><NavigateBeforeRounded className="left" />  <span className="left">view previous section</span></a>
                                    <a onClick={() => { this.props.goToNextSection() }} className="right navigationButton"><NavigateNextRounded className="right" />  <span className="left">view next section</span></a>
                                    <div className="clear"></div>
                                </Grid>

                                <Grid item xs={12}>
                                    <GridList
                                        cellHeight="auto"
                                        className="gridList"
                                        cols={1}
                                        spacing={10}>
                                        {InputsCards}
                                    </GridList>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}

export default ViewAnswers