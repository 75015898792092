import React from "react"
import { RouteComponentProps } from "react-router"

import {
	Button,
	Container,
	Drawer,
	Grid,
	GridList,
	CircularProgress,
	Snackbar
} from "@material-ui/core"

import {
	ArrowForward,
	CheckRounded,
	NavigateNextRounded
} from "@material-ui/icons"

import { Alert } from "@material-ui/lab"

import InputCardAnswer from './components/input_card_answer'
import CheckoutSteps from './components/checkout_steps'
import ViewAnswers from './components/view_answers'

import CoreAnalytics from "../utils/core_analytics"
import { config } from "../config"
import "../styles/checkout_pages.scss"

import { BackendUserFull, BackendProjectFull, BackendCheckout, BackendCheckoutFull, BackendFreelancerProfile, BackendPreferenceGroupFull, BackendSection, BackendInput, BackendInputExtra, BackendPreferenceGroupAnswers, BackendAnswer, BackendAnswerExtra, BackendClientUserProject } from "./interfaces/backend"
import { FrontendAnswer, FrontendAnswerExtra } from "./interfaces/frontend"

interface Params {
	id?: number
	invitationToken?: string
	noRedirect?: boolean
}

interface Props extends RouteComponentProps<{}> { }

interface State {
	freelancer_user_project_id?: number
	client_user_project_id?: number

	freelancer_email: string
	freelancer_name: string

	client_name: string

	isLoadingForm: boolean
	successForm: boolean

	project_id: number
	project_name: string
	project_current_state: string
	checkout_id: number
	preference_group_id: number

	clients_users_projects: BackendClientUserProject[]

	checkout?: BackendCheckout
	freelancerProfile?: BackendFreelancerProfile

	sections: BackendSection[]
	inputs: BackendInput[]
	inputs_extras: BackendInputExtra[]
	answers: FrontendAnswer[]
	answers_extras: FrontendAnswerExtra[]

	answersBackend: BackendAnswer[]
	answersExtrasBackend: BackendAnswerExtra[]

	section_selected_id: number
	section_selected_order: number
	section_selected_name: string
	current_input_id: number

	count_sections: number
	all_answers_done: boolean

	is_freelancer: boolean
	is_client: boolean

	errorForm: boolean
	errorMessage: string
	errorDuration: number
}

const regexExpressions: Record<string, string> = {
	free_text_check: "^((.|\n){0,1024})$",
	quote_check: "^(.{0,1024})$",
	like_dislike_check: "^like$|^dislike$",
	file_s3_link_check: "^(.{0,256})$",
	url_link_check: "^(.{0,256})$",
	specific_part_check: "^((.|\n){0,1024})$",
	relevance_reason_check: "^((.|\n){0,1024})$",
	option_check: "^(.{0,256})$"
}

class AnswerProjectBriefing extends React.Component<Props, State> {
	setStateLikeSync(state: any) {
		return new Promise<void>((resolve) => {
			this.setState(state, resolve)
		});
	}

	showSnackMessage(message?: string, duration?: number) {
		if(message == undefined) {
			return
		}
		
		let actualDuration = duration ? duration : 3000
		
		this.setState({
			"errorForm": true,
			"errorMessage": message,
			"errorDuration": actualDuration
		})

		setTimeout(() => {
			this.setState({
				"errorForm": false,
				"errorMessage": "",
				"errorDuration": 0
			})
		}, actualDuration)
	}

	constructor(props: Props) {
		super(props)

		this.state = {
			isLoadingForm: false,
			successForm: false,

			freelancer_user_project_id: undefined, // should never be diferent as only for clients
			freelancer_email: "",
			freelancer_name: "",

			client_name: "",
			client_user_project_id: undefined,

			project_id: -1,
			project_name: "",
			project_current_state: "published",
			checkout_id: -1,
			preference_group_id: -1,

			clients_users_projects: [],

			checkout: undefined,
			freelancerProfile: undefined,

			sections: [],
			inputs: [],
			inputs_extras: [],
			answers: [],
			answers_extras: [],

			answersBackend: [],
			answersExtrasBackend: [],

			section_selected_id: -1,
			section_selected_order: -1,
			section_selected_name: "",
			current_input_id: -1,
			count_sections: -1,
			all_answers_done: false,

			is_freelancer: false,
			is_client: false,

			errorForm: false,
			errorMessage: "",
			errorDuration: 0
		}
	}

	async componentDidMount() {
		let persistenceToken = localStorage.getItem("persistence_token")
		if (persistenceToken === null) {
			this.props.history.replace("/login")
			return
		}

		let params = this.parseUrlParams(this.props.location.search)

		if (params.invitationToken) {
			try {
				const response = await fetch(`${config.API_URL}/execute_invitation`, {
					method: 'post',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
					body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
						persistence_token: persistenceToken,
						invitation_token: params.invitationToken!
					})
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				this.responseExecuteInvitation(responseJSON.response, responseJSON.persistence_token, responseJSON.redirect_to_project_id, responseJSON.error)
			} catch (error) {
				this.showSnackMessage(`execute-invitation-fetch-error: ${error}`)
			}
		} else if (params.id) {
			try {
				const response = await fetch(`${config.API_URL}/read_project?project_id=${encodeURIComponent(params.id!)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
					method: 'get',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				this.responseReadProjectResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.project_full, responseJSON.error)
			} catch (error) {
				this.showSnackMessage(`read-project-fetch-error: ${error}`)
			}

			try {
				let persistenceToken = localStorage.getItem("persistence_token")

				const response = await fetch(`${config.API_URL}/read_user?persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
					method: 'get',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				this.responseReadUserResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.user_full, responseJSON.error)
			} catch (error) {
				this.showSnackMessage(`read-user-fetch-error: ${error}`)
			}

			try {
				let persistenceToken = localStorage.getItem("persistence_token")
				let checkout_id = this.state.checkout_id

				const response = await fetch(`${config.API_URL}/read_checkout?checkout_id=${encodeURIComponent(checkout_id)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
					method: 'get',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				let isDone = this.responseReadCheckoutResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.checkout_full, responseJSON.error)
				if (isDone) {
					return
				}
			} catch (error) {
				this.showSnackMessage(`read-checkout-fetch-error: ${error}`)
			}

			let isDone = this.redirect(3, params.noRedirect)
			if (isDone) {
				return
			}

			try {
				let persistenceToken = localStorage.getItem("persistence_token")
				let preference_group_id = this.state.preference_group_id

				const response = await fetch(`${config.API_URL}/read_preference_group?preference_group_id=${encodeURIComponent(preference_group_id)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
					method: 'get',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				this.responseReadPreferenceGroupResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.preference_group_full, responseJSON.error)
			} catch (error) {
				this.showSnackMessage(`read-preference-group-fetch-error: ${error}`)
			}

			try {
				let persistenceToken = localStorage.getItem("persistence_token")
				let preference_group_id = this.state.preference_group_id

				const response = await fetch(`${config.API_URL}/read_preference_group_answers?preference_group_id=${encodeURIComponent(preference_group_id)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
					method: 'get',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				this.responseReadPreferenceGroupAnswersResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.preference_group_answers, responseJSON.error)
			} catch (error) {
				this.showSnackMessage(`read-preference-group-answers-fetch-error: ${error}`)
			}
		} else {
			this.showSnackMessage("Missing invitation_id or project_id")
			this.props.history.replace("/dashboard")
		}
	}

	parseUrlParams = (query: string): Params => {
		const urlSearchParams = new URLSearchParams(query)

		let params: Params = {
			id: urlSearchParams.get("id") === null ? undefined : Number(urlSearchParams.get("id")!),
			invitationToken: urlSearchParams.get("invitation_token") === null ? undefined : urlSearchParams.get("invitation_token")!,
			noRedirect: urlSearchParams.get("no_redirect") === null ? undefined : (urlSearchParams.get("no_redirect")! == "true")
		}
		return params
	}

	redirect = (currentStep: number, no_redirect?: boolean) => {
		let activeStep = this.getActiveStep()

		if (no_redirect) {
			return false
		}

		if (currentStep == activeStep) {
			return false
		}

		var pushString = undefined

		if (activeStep == 3) {
			pushString = `/answer_project_briefing?id=${this.state.project_id}`
		} else if (activeStep == 4) {
			pushString = `/view_proposal?id=${this.state.project_id}`

			if ((this.state.checkout?.proposal_step_status == "pending_freelancer" && this.state.is_client)) { // exceptional rule to hold client on answers until there is an actual proposal for him
				return false
			}
		} else if (activeStep == 5) {
			pushString = `/make_payment?id=${this.state.project_id}`
		} else if (activeStep == 6) {
			pushString = `/check_delivery?id=${this.state.project_id}`
		} else if (activeStep == 7) {
			pushString = `/check_delivery?id=${this.state.project_id}`
		}

		if (pushString) {
			this.props.history.push(pushString)
		}

		return true
	}

	getActiveStep() {
		if (this.state.checkout?.briefing_step_status != undefined && this.state.checkout?.proposal_step_status == undefined) {
			return 3
		} else if (this.state.checkout?.proposal_step_status != undefined && this.state.checkout?.payment_step_status == undefined) {
			return 4
		} else if (this.state.checkout?.payment_step_status != undefined && this.state.checkout?.delivery_step_status == undefined) {
			return 5
		} else if (this.state.checkout?.delivery_step_status != undefined && this.state.checkout?.release_step_status == undefined) {
			return 6
		} else if (this.state.checkout?.release_step_status != undefined) {
			return 7
		}

		return 0
	}

	responseExecuteInvitation = (response: boolean, persistence_token?: string, redirect_to_project_id?: number, error?: string): void => {
		if (response === true) {
			this.props.history.replace(`/answer_project_briefing?id=${redirect_to_project_id!}`)
			window.location.reload()
		} else {
			this.showSnackMessage(error)
		}
	}

	responseReadProjectResolve = (response: boolean, persistence_token?: string, project_full?: BackendProjectFull, error?: string): void => {
		if (response === true) {
			if ((project_full!.project.current_state !== "published") || project_full!.preferences_groups.length != 1) {
				this.showSnackMessage("Project is not published, or has 0 or more than 1 (not possible) preference group")
				this.props.history.replace("/dashboard")
				return
			}

			let client_user_project_list = project_full!.clients_users_projects.filter((client_user_project) => client_user_project.client_project_id === project_full!.client_user_project_id)

			if (this.state.is_client && (client_user_project_list.length != 1)) {
				this.showSnackMessage("More than one client (not possible) or none valid are trying to answer briefing for project id")
				this.props.history.replace("/dashboard")
				return
			}

			this.setState({
				"freelancer_email": project_full!.freelancers_users_projects[0].email,
				"freelancer_name": project_full!.freelancers_users_projects[0].first_name,
				"client_name": (project_full!.clients_users_projects.length == 1) ? project_full!.clients_users_projects[0].first_name : project_full!.freelancers_users_projects[0].first_name,
				"freelancer_user_project_id": project_full!.freelancer_user_project_id,
				"client_user_project_id": project_full!.client_user_project_id,
				"project_id": project_full!.project.id,
				"project_name": project_full!.project.name,
				"project_current_state": project_full!.project.current_state,
				"checkout_id": project_full!.checkouts[0].id,
				"preference_group_id": project_full!.preferences_groups[0].id,
				"clients_users_projects": project_full!.clients_users_projects
			})
			localStorage.setItem("persistence_token", persistence_token as string)
			document.title = this.state.project_name + " - Smelt"
		} else {
			this.showSnackMessage(error)
			this.props.history.replace("/login")
		}
	}

	responseReadCheckoutResolve = (response: boolean, persistence_token?: string, checkout_full?: BackendCheckoutFull, error?: string): boolean => {
		if (response === true) {
			this.setState({
				"checkout": checkout_full!.checkout,
				"freelancerProfile": checkout_full!.freelancer_profile
			})
			localStorage.setItem("persistence_token", persistence_token as string)
		} else {
			this.showSnackMessage(error)
			this.props.history.replace("/login")
			return true
		}
		return false
	}

	responseReadPreferenceGroupResolve = (response: boolean, persistence_token?: string, preference_group_full?: BackendPreferenceGroupFull, error?: string): void => {
		if (response === true) {
			if (preference_group_full!.preference_group.current_state !== "published" && preference_group_full!.preference_group.current_state !== "archived") {
				this.showSnackMessage("Preference group not is not published or archived - should never happen and always match the project published or archived")
				this.props.history.replace("/dashboard")
				return
			}

			let sectionSelectedId = preference_group_full!.sections.filter((section) => section.order == 1)[0].id
			let inputs = preference_group_full!.inputs.map((input) => ({ "id": input.id, "section_id": input.section_id, "freelancer_project_id": input.freelancer_project_id, "order": input.order, "type": input.type, "is_required": input.is_required, "title": input.title, "thumbnail": JSON.parse(((input.thumbnail ? input.thumbnail : "{}")).replace(/'/g, '"')), "input_extra_id": input.input_extra_id }))

			this.setState({
				"section_selected_id": sectionSelectedId, // check so there is always at least one section
				"section_selected_order": 1,
				"section_selected_name": preference_group_full!.sections.filter((section) => section.order == 1)[0].name,
				"sections": preference_group_full!.sections.map((section) => ({ "id": section.id, "preference_group_id": section.preference_group_id, "name": section.name, "order": section.order, "is_answered": false })),
				"inputs": inputs,
				"inputs_extras": preference_group_full!.inputs_extras.map((input_extra) => ({ "id": input_extra.id, "quote": input_extra.quote, "file_s3_link": input_extra.file_s3_link, "url_link": input_extra.url_link, "specific_part": input_extra.specific_part, "relevance_reason": input_extra.relevance_reason, "options": (input_extra.options ? input_extra.options : []).map(option => JSON.parse(option.replace(/'/g, '"'))) })),
				"answers": preference_group_full!.inputs.map((input) => ({ "id": undefined, "input_id": input.id, "client_project_id": this.state.client_user_project_id!, "has_skipped": undefined, "free_text": "", "answer_extra_id": undefined, "created_at": (new Date(Date.now())), "free_text_check": true, "is_saved": false, "is_completed": false })),
				"answers_extras": preference_group_full!.inputs.map((input) => ({ "id": undefined, "input_id": input.id, "like_dislike": undefined, "quote": undefined, "file_s3_link": undefined, "url_link": undefined, "specific_part": undefined, "relevance_reason": undefined, "options": undefined, "like_dislike_check": true, "quote_check": true, "file_s3_link_check": true, "url_link_check": true, "specific_part_check": true, "relevance_reason_check": true, "options_check": true })),
				"current_input_id": inputs.filter((input) => (input.section_id == sectionSelectedId && input.order == 1))[0].id,
				"count_sections": preference_group_full!.sections.length,
			})
			localStorage.setItem("persistence_token", persistence_token as string)
		} else {
			this.showSnackMessage(error)
			this.props.history.replace("/login")
		}
	}

	responseReadPreferenceGroupAnswersResolve = (response: boolean, persistence_token?: string, preference_group_answers?: BackendPreferenceGroupAnswers, error?: string): void => {
		if (response === true) {
			if (this.state.checkout?.briefing_step_status == "pending_client") {
				this.setState(previousState => {
					let newAnswers = previousState.answers.map((answer) => {
						var newAnswer = answer

						let answerLoadedList = preference_group_answers!.answers.filter((answerLoaded) => answerLoaded.input_id == answer.input_id)
						if (answerLoadedList.length == 1) {
							let answerLoaded = answerLoadedList[0]
							newAnswer.id = answerLoaded.id
							newAnswer.input_id = answerLoaded.input_id // shoulad already be the same
							newAnswer.client_project_id = answerLoaded.client_project_id
							newAnswer.has_skipped = answerLoaded.has_skipped
							newAnswer.free_text = answerLoaded.free_text
							newAnswer.answer_extra_id = answerLoaded.answer_extra_id
							newAnswer.created_at = answerLoaded.created_at
							newAnswer.free_text_check = true
							newAnswer.is_saved = true
							newAnswer.is_completed = true
						}

						return newAnswer
					})

					let newAnswersExtras = previousState.answers_extras.map((answer_extra) => {
						var newAnswerExtra = answer_extra

						let answerLoadedList = preference_group_answers!.answers.filter((answerLoaded) => answerLoaded.input_id == answer_extra.input_id)
						if (answerLoadedList.length == 1) {
							let answerLoaded = answerLoadedList[0]
							let answerExtraLoadedList = preference_group_answers!.answers_extras.filter((answerExtraLoaded) => answerExtraLoaded.id == answerLoaded.answer_extra_id)

							if (answerExtraLoadedList.length == 1) {
								let answerExtraLoaded = answerExtraLoadedList[0]
								newAnswerExtra.id = answerExtraLoaded.id
								newAnswerExtra.like_dislike = answerExtraLoaded.like_dislike
								newAnswerExtra.quote = answerExtraLoaded.quote
								newAnswerExtra.file_s3_link = answerExtraLoaded.file_s3_link
								newAnswerExtra.url_link = answerExtraLoaded.url_link
								newAnswerExtra.specific_part = answerExtraLoaded.specific_part
								newAnswerExtra.relevance_reason = answerExtraLoaded.relevance_reason
								newAnswerExtra.options = answerExtraLoaded.options

								newAnswerExtra.like_dislike_check = true
								newAnswerExtra.quote_check = true
								newAnswerExtra.file_s3_link_check = true
								newAnswerExtra.url_link_check = true
								newAnswerExtra.specific_part_check = true
								newAnswerExtra.relevance_reason_check = true
								newAnswerExtra.options_check = true
							}
						}
						return newAnswerExtra
					})

					let answered_inputs_ids = newAnswers.filter((answer) => answer.is_saved).map((answer) => {
						return answer.input_id
					})
					let answered_sections_ids = previousState.inputs.filter((input) => answered_inputs_ids.includes(input.id)).map((input) => {
						return input.section_id
					})
					let unique_answered_sections_ids = answered_sections_ids.filter((section_id, index, self) => self.indexOf(section_id) === index)
					let sections = previousState.sections.map((section) => ({ "id": section.id, "preference_group_id": section.preference_group_id, "name": section.name, "order": section.order, "is_answered": unique_answered_sections_ids.includes(section.id) }))

					let sections_not_answered_orders = sections.filter((section) => !section.is_answered).map((section) => section.order).sort()
					let new_section_selected_order = sections_not_answered_orders[0]
					let new_section_selected_list = sections.filter((section) => section.order == new_section_selected_order)
					let new_section_selected = new_section_selected_list[0] // see if this can ever break

					let new_current_input_id_list = previousState.inputs.filter((input) => (input.section_id == previousState.section_selected_id && input.order == 1))
					var current_input_id = undefined
					if (new_current_input_id_list.length == 1) {
						current_input_id = new_current_input_id_list[0].id // check so there is always at least one input_id
					} else {
						current_input_id = previousState.current_input_id
					}

					return {
						"answers": newAnswers,
						"answers_extras": newAnswersExtras,
						"sections": sections,
						"section_selected_id": new_section_selected ? new_section_selected.id : previousState.section_selected_id,
						"section_selected_order": new_section_selected ? new_section_selected.order : previousState.section_selected_order,
						"section_selected_name": new_section_selected ? new_section_selected.name : previousState.section_selected_name,
						"current_input_id": current_input_id
					}
				})
				localStorage.setItem("persistence_token", persistence_token as string)
			} else {
				this.setState({
					"answersBackend": preference_group_answers!.answers.map((answer) => ({ "id": answer.id, "input_id": answer.input_id, "client_project_id": answer.client_project_id, "has_skipped": answer.has_skipped, "free_text": answer.free_text, "answer_extra_id": answer.answer_extra_id, "created_at": answer.created_at })), // check so there is always at least one section
					"answersExtrasBackend": preference_group_answers!.answers_extras.map((answer_extra) => ({ "id": answer_extra.id, "like_dislike": answer_extra.like_dislike, "quote": answer_extra.quote, "file_s3_link": answer_extra.file_s3_link, "url_link": answer_extra.url_link, "specific_part": answer_extra.specific_part, "relevance_reason": answer_extra.relevance_reason, "options": (answer_extra.options ? answer_extra.options : []).map(option => JSON.parse(option.replace(/'/g, '"'))) }))
				})
				localStorage.setItem("persistence_token", persistence_token as string)
			}
		} else {
			this.showSnackMessage(error)
			this.props.history.replace("/dashboard")
		}
	}

	responseReadUserResolve = (response: boolean, persistence_token?: string, user_full?: BackendUserFull, error?: string): void => {
		if (response === true) {
			CoreAnalytics.trackUser(user_full!.email, user_full!.email, user_full!.first_name, user_full!.last_name, user_full!.is_client, user_full!.is_freelancer);
			this.setState({
				"is_freelancer": user_full!.is_freelancer,
				"is_client": user_full!.is_client
			})
			localStorage.setItem("persistence_token", persistence_token as string)
		} else {
			this.showSnackMessage(error) // user with token not loaded
			localStorage.removeItem("persistence_token")
			this.props.history.replace("/login")
		}
	}

	updateAnswer = (input_id: number, free_text?: string, is_saved?: boolean, is_skipped?: boolean, is_completed?: boolean, is_current?: boolean, answer_extra_params?: Record<string, any>) => {
		if (is_skipped) {
			//	this.showSnackMessage(input_id);
		}

		this.setState(previousState => {
			let newAnswers = previousState.answers.map((answer) => {
				var newAnswer = answer

				if (newAnswer.input_id == input_id) {
					if (free_text !== undefined) {
						newAnswer.free_text = free_text!
						newAnswer.free_text_check = (RegExp(regexExpressions["free_text_check"])).test(free_text!)
					}
					if (is_saved !== undefined) {
						newAnswer.is_saved = is_saved!
					}
					if (is_skipped !== undefined) {
						let current_input_skipped = previousState.inputs.filter((input) => input.id == input_id)[0] // check if can ever break
						let total_inputs_sections = previousState.inputs.filter((input) => input.section_id == previousState.section_selected_id).length // check if can ever break

						if (current_input_skipped.order + 1 <= total_inputs_sections) {
							let next_input = previousState.inputs.filter((input) => (input.order == current_input_skipped.order + 1))[0]
							this.setState({ "current_input_id": next_input.id })
						}
					}
					if (is_current !== undefined) {
						this.setState({ "current_input_id": input_id })
					}
					if (answer_extra_params !== undefined) {
						newAnswer.answer_extra_params = answer_extra_params!
					}

					var is_completed = false
					let input = previousState.inputs.filter((input) => input.id == input_id)[0] // will always be there as answer can only be for an input

					if (["radio", "checkbox", "ask_feedback", "ask_example", "multiline"].includes(input.type)) {
						let answer_extras = previousState.answers_extras.filter((answer_extras) => answer_extras.input_id == input_id)[0] // will always be there given answer_extra_params 
						is_completed = newAnswer.free_text_check && (answer_extras.like_dislike_check && answer_extras.quote_check && answer_extras.file_s3_link_check && answer_extras.url_link_check && answer_extras.specific_part_check && answer_extras.relevance_reason_check && answer_extras.options_check)
					} else {
						is_completed = newAnswer.free_text_check
					}

					newAnswer.is_completed = is_completed
				}
				return newAnswer
			})

			let inputs_ids = previousState.inputs.filter((input) => input.section_id == previousState.section_selected_id).map((input) => input.id)
			let answers_done = previousState.answers.filter((answer) => inputs_ids.includes(answer.input_id)).map((answer) => (answer.is_completed || !previousState.inputs.filter((input) => input.id == answer.input_id)[0].is_required))
			let all_answers_done = answers_done.every((answer_done) => answer_done) // think about its impact on current_input_id

			return {
				"answers": newAnswers,
				"all_answers_done": all_answers_done,
				"current_input_id": all_answers_done ? -1 : previousState.current_input_id
			}
		})
	}

	updateExtraAnswer = (input_id: number, like_dislike?: string, quote?: string, file_s3_link?: string, url_link?: string, specific_part?: string, relevance_reason?: string, options?: Record<string, any>[]) => {
		this.setState(previousState => {
			let newAnswersExtras = previousState.answers_extras.map((answer_extra) => {
				var newAnswerExtra = answer_extra

				if (newAnswerExtra.input_id == input_id) {
					if (like_dislike !== undefined) {
						newAnswerExtra.like_dislike = like_dislike!
						newAnswerExtra.like_dislike_check = (RegExp(regexExpressions["like_dislike_check"])).test(like_dislike!)
					}
					if (quote !== undefined) {
						newAnswerExtra.quote = quote!
						newAnswerExtra.quote_check = (RegExp(regexExpressions["quote_check"])).test(quote!)
					}
					if (file_s3_link !== undefined) {
						newAnswerExtra.file_s3_link = file_s3_link!
						newAnswerExtra.file_s3_link_check = (RegExp(regexExpressions["file_s3_link_check"])).test(file_s3_link!)
					}
					if (url_link !== undefined) {
						newAnswerExtra.url_link = url_link!
						newAnswerExtra.url_link_check = (RegExp(regexExpressions["url_link_check"])).test(url_link!)
					}
					if (specific_part !== undefined) {
						newAnswerExtra.specific_part = specific_part!
						newAnswerExtra.specific_part_check = (RegExp(regexExpressions["specific_part_check"])).test(specific_part!)
					}
					if (relevance_reason !== undefined) {
						newAnswerExtra.relevance_reason = relevance_reason!
						newAnswerExtra.relevance_reason_check = (RegExp(regexExpressions["relevance_reason_check"])).test(relevance_reason!)
					}
					if (options != undefined) {
						newAnswerExtra.options = options!
						newAnswerExtra.options_check = true // improve this
					}
					this.updateAnswer(input_id, undefined, undefined, undefined, undefined, undefined, { "like_dislike": newAnswerExtra.like_dislike, "quote": newAnswerExtra.quote, "file_s3_link": newAnswerExtra.file_s3_link, "url_link": newAnswerExtra.url_link, "specific_part": newAnswerExtra.specific_part, "relevance_reason": newAnswerExtra.relevance_reason, "options": options?.map((option) => ({ "name": option.name, "order": option.order })) })
				}
				return newAnswerExtra
			})

			return { "answers_extras": newAnswersExtras }
		})
	}

	handleSkipBriefing = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()

		var persistenceToken = localStorage.getItem("persistence_token")

		try {
			await this.setStateLikeSync({ isLoadingForm: true });

			const response = await fetch(`${config.API_URL}/save_checkout`, {
				method: 'post',
				headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
				body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
					persistence_token: persistenceToken,
					action: "update",
					checkout_id: this.state.checkout_id,
					checkout_params: { "briefing_step_status": "skipped_client", "proposal_step_status": "pending_freelancer" }
				})
			})
			if (!response.ok) {
				// this.showSnackMessage(`An error has occured: ${response.status}`)
			}
			const responseJSON = await response.json();
			await CoreAnalytics.trackProjectSkipAnswer(this.state.project_id, this.state.project_name, this.state.freelancer_email, this.state.freelancer_name);

			await this.setStateLikeSync({ isLoadingForm: false });
			this.responseSaveCheckoutResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.checkout_id, responseJSON.error)
		} catch (error) {
			this.showSnackMessage(`save-checkout-fetch-error: ${error}`)
		}

		let sectionSelectedId = this.state.section_selected_id
		persistenceToken = localStorage.getItem("persistence_token")

		try {
			await this.setStateLikeSync({ isLoadingForm: true });

			const response = await fetch(`${config.API_URL}/create_answers`, {
				method: 'post',
				headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
				body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
					persistence_token: persistenceToken,
					section_id: sectionSelectedId,
					is_last_section: true
				})
			})
			if (!response.ok) {
				// this.showSnackMessage(`An error has occured: ${response.status}`)
			}
			const responseJSON = await response.json();
			await this.setStateLikeSync({ isLoadingForm: false });
			this.responseCreateAnswersResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.error)
		} catch (error) {
			this.showSnackMessage(`create-answers-fetch-error: ${error}`)
		}
	}

	handleSave = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()

		if (!this.state.all_answers_done) {
			this.showSnackMessage("You have to answer all required questions before proceeding to the next section.", 3000)
			return false;
		}

		let allValidationsPass = true // improve this

		if (allValidationsPass) {
			let answered_inputs_ids = this.state.inputs.filter((input) => input.section_id == this.state.section_selected_id).map((input) => {
				return input.id
			})

			let answersParams = this.state.answers.filter((answer) => answered_inputs_ids.includes(answer.input_id)).map((answer) => { // all completed - only not the optional ones
				if (answer.is_completed) {
					return { "input_id": answer.input_id, "free_text": answer.free_text, "answer_extra_params": answer.answer_extra_params }
				} else {
					return { "input_id": answer.input_id, "free_text": "", "has_skipped": true } // must be given conditions
				}
			})

			let sectionSelectedId = this.state.section_selected_id

			CoreAnalytics.trackProjectAnswerSection(this.state.project_id, this.state.project_name, this.state.section_selected_name);

			let persistenceToken = localStorage.getItem("persistence_token")
			try {
				await this.setStateLikeSync({ isLoadingForm: true });

				const response = await fetch(`${config.API_URL}/create_answers`, {
					method: 'post',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
					body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
						persistence_token: persistenceToken,
						answers_params: answersParams,
						section_id: sectionSelectedId
					})
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				await this.setStateLikeSync({ isLoadingForm: false });
				this.responseCreateAnswersResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.error)
			} catch (error) {
				this.showSnackMessage(`create-answers-fetch-error: ${error}`)
			}
		} else {
			//this.showSnackMessage("check validations")
		}
	}

	handleFinish = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()

		if (!this.state.all_answers_done) {
			this.showSnackMessage("You have to answer all required questions before proceeding to the next section.", 3000)
			return false;
		}

		let allValidationsPass = true // improve this

		if (allValidationsPass) {
			var persistenceToken = localStorage.getItem("persistence_token")

			try {
				await this.setStateLikeSync({ isLoadingForm: true });

				const response = await fetch(`${config.API_URL}/save_checkout`, {
					method: 'post',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
					body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
						persistence_token: persistenceToken,
						action: "update",
						checkout_id: this.state.checkout_id,
						checkout_params: { "briefing_step_status": "pending_freelancer" }
					})
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				await this.setStateLikeSync({ isLoadingForm: false });
				this.responseSaveCheckoutResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.checkout_id, responseJSON.error)
			} catch (error) {
				this.showSnackMessage(`save-checkout-fetch-error: ${error}`)
			}

			let answered_inputs_ids = this.state.inputs.filter((input) => input.section_id == this.state.section_selected_id).map((input) => {
				return input.id
			})

			let answersParams = this.state.answers.filter((answer) => answered_inputs_ids.includes(answer.input_id)).map((answer) => { // all completed - only not the optional ones
				if (answer.is_completed) {
					return { "input_id": answer.input_id, "free_text": answer.free_text, "answer_extra_params": answer.answer_extra_params }
				} else {
					return { "input_id": answer.input_id, "free_text": "", "has_skipped": true } // must be given conditions
				}
			})

			let sectionSelectedId = this.state.section_selected_id

			CoreAnalytics.trackProjectFinishAnswer(this.state.project_id, this.state.project_name, this.state.section_selected_name, this.state.freelancer_email, this.state.freelancer_name);

			persistenceToken = localStorage.getItem("persistence_token")
			try {
				await this.setStateLikeSync({ isLoadingForm: true });

				const response = await fetch(`${config.API_URL}/create_answers`, {
					method: 'post',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
					body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
						persistence_token: persistenceToken,
						answers_params: answersParams,
						section_id: sectionSelectedId,
						is_last_section: true
					})
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				await this.setStateLikeSync({ isLoadingForm: false });
				this.responseCreateAnswersResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.error)
			} catch (error) {
				this.showSnackMessage(`create-answers-fetch-error: ${error}`)
			}
		} else {
			//this.showSnackMessage("check validations")
		}
	}

	responseCreateAnswersResolve = (response: boolean, persistence_token?: string, error?: string): void => {
		if (response === true) {
			localStorage.setItem("persistence_token", persistence_token as string)
			this.setState({ successForm: true });
			window.scrollTo(0, 0);
			window.location.reload()
		} else {
			this.setState({ successForm: false });
			this.showSnackMessage(error) // some regex problem?
		}
	}

	responseSaveCheckoutResolve = (response: boolean, persistence_token?: string, checkout_id?: number, error?: string): void => {
		if (response === true) {
			localStorage.setItem("persistence_token", persistence_token as string)
			this.setState({ successForm: true });
		} else {
			this.setState({ successForm: false });
			this.showSnackMessage(error) // some regex problem?
		}
	}

	push = (page: string) => {
		this.props.history.push(page)
	}

	goToPreviousSection = () => {
		let previousSection = this.state.sections.find((section) => (section.order == (this.state.section_selected_order - 1)))
		if (!previousSection) {
			return
		}
		this.setState({
			"section_selected_id": previousSection.id,
			"section_selected_order": previousSection.order,
			"section_selected_name": previousSection.name
		})
	}

	goToNextSection = () => {
		let nextSection = this.state.sections.find((section) => (section.order == (this.state.section_selected_order + 1)))

		if (!nextSection) {
			return
		}
		this.setState({
			"section_selected_id": nextSection.id,
			"section_selected_order": nextSection.order,
			"section_selected_name": nextSection.name
		})
	}

	render() {
		let InputsCardsAnswer = this.state.inputs.filter((input) => input.section_id === this.state.section_selected_id).sort((input1, input2) => input1.order > input2.order ? 1 : -1).map((input) => {
			let total_questions_section = this.state.inputs.filter((inputSectionCount) => inputSectionCount.section_id == input.section_id).length
			let input_extra_list = this.state.inputs_extras.filter((input_extra) => input_extra.id == input.input_extra_id)

			let answers_list = this.state.answers.filter((answer) => answer.input_id == input.id)
			if (answers_list.length == 1) {
				let answer = answers_list[0] // this one should always be, but check later

				var answers_extras_list = this.state.answers_extras.filter((answer_extra) => (answer_extra.input_id == answer.input_id))

				return (
					<InputCardAnswer
						input_id={input.id}
						input_order={input.order}
						total_questions_section={total_questions_section}
						type={input.type}
						is_required={input.is_required}
						title={input.title}
						thumbnail={Object.keys(input.thumbnail).length !== 0 ? input.thumbnail : undefined}
						input_extras={input_extra_list.length == 1 ? input_extra_list[0] : undefined}

						free_text={answer ? answer.free_text : ""}
						answer_extras={answers_extras_list.length == 1 ? answers_extras_list[0] : undefined}

						free_text_check={answer ? answer.free_text_check : true}
						is_saved={answer ? answer.is_saved : false}
						is_completed={answer ? answer.is_completed : false}
						is_current={answer ? answer.input_id == this.state.current_input_id : false}

						updateAnswer={this.updateAnswer}
						updateExtraAnswer={this.updateExtraAnswer}

						showSnackMessage={this.showSnackMessage}
					/>
				)
			}
		})

		return (
			<div className="mainStyle checkoutStyle">
				<Snackbar open={this.state.errorForm} autoHideDuration={this.state.errorDuration}>
					<Alert severity="error">
						{this.state.errorMessage}
					</Alert>
				</Snackbar>

				<Snackbar open={this.state.is_freelancer} autoHideDuration={3000}>
					<Alert severity="warning">
						Preview mode: your actions on this page won't be saved.
					</Alert>
				</Snackbar>

				<div className="drawerRoot">
					<Drawer
						className="drawerDrawer"
						variant="permanent"
						classes={{
							paper: "drawerDrawerPaper"
						}}
						anchor="left">

						<div className="drawerToolbar" />

						<CheckoutSteps
							id={this.state.checkout?.id}
							project_id={this.state.checkout?.project_id}
							briefing_step_status={this.state.checkout?.briefing_step_status}
							proposal_step_status={this.state.checkout?.proposal_step_status}
							payment_step_status={this.state.checkout?.payment_step_status}
							delivery_step_status={this.state.checkout?.delivery_step_status}
							release_step_status={this.state.checkout?.release_step_status}
							preference_group_id={this.state.checkout?.preference_group_id}
							proposal_id={this.state.checkout?.proposal_id}
							payment_id={this.state.checkout?.payment_id}
							delivery_id={this.state.checkout?.delivery_id}
							release_id={this.state.checkout?.release_id}

							is_freelancer={false}
							is_client={true}

							profile_id={this.state.freelancerProfile?.profile_id}
							profile_image_link={this.state.freelancerProfile?.profile_image_link}
							freelancer_name={this.state.freelancer_name}
							job_title={this.state.freelancerProfile?.job_title}
							city={this.state.freelancerProfile?.city}
							country={this.state.freelancerProfile?.country}
							job_description={this.state.freelancerProfile?.job_description}
							project_name={this.state.project_name}

							push={this.push}
						/>
					</Drawer>

					<main className="drawerContent">
						<Container
							maxWidth="md"
							className="topCenterContent topCenterContentVerticalScroll">
							{(this.state.is_freelancer || (this.state.is_client && (this.state.checkout?.briefing_step_status == "pending_client"))) && <Grid container spacing={3}>
								<Grid item xs={12}>
									<div className="clientHeader">
										<h2>Hi, {this.state.client_name}!</h2>
										<Alert severity="info">
											Fill out the project requirements below to get a quote or wait and {this.state.freelancer_name} will be in touch with you to hear about your project.
										</Alert>
									</div>

									<div className="titleBar">
										<h2 className="left">{this.state.section_selected_name}</h2>

										<Button
											className="defaultSmallButton right"
											endIcon={<NavigateNextRounded />}
											size="small"
											onClick={this.state.is_client ? this.handleSkipBriefing : (this.state.is_freelancer ? () => { } : () => { })}
										>
											Skip questionnaire and wait for proposal
										</Button>

										<div className="progressSectionBar left">
											<div className="progressCircle left">
												<CircularProgress
													size="24px"
													thickness={5}
													className="fullCircle"
													variant="determinate"
													value={100}
												/>
												<CircularProgress
													size="24px"
													thickness={5}
													className="loadedCircle"
													variant="determinate"
													value={100 * (this.state.section_selected_order / this.state.count_sections)}
												/>
											</div>
											<span className="left">Section {this.state.section_selected_order} of {this.state.count_sections}</span>
										</div>
										<div className="clear"></div>
									</div>

									<form autoComplete="off" onSubmit={e => e.preventDefault()}>
										<Grid
											container
											justify="flex-start"
											xs={12}
											direction="column"
											className="block"
											alignItems="stretch"
											spacing={1}>
											<Grid item xs={12}>
												<GridList
													cellHeight="auto"
													className="gridList"
													cols={1}
													spacing={10}>
													{InputsCardsAnswer}
												</GridList>
											</Grid>

											{(this.state.section_selected_order !== this.state.sections.length) && <div className="actionBar">
												<Button
													variant="contained"
													color="primary"
													size="large"
													fullWidth
													className="primaryButton"
													startIcon={(this.state.isLoadingForm ? <CircularProgress
														size={14}
													/> : (this.state.successForm ? <CheckRounded /> : <ArrowForward />))}
													onClick={this.state.is_client ? this.handleSave : (this.state.is_freelancer ? this.goToNextSection : () => { })}
												>
													next section
												</Button>
											</div>}

											{(this.state.section_selected_order === this.state.sections.length) && <div className="actionBar">
												<Button
													variant="contained"
													color="primary"
													size="large"
													fullWidth
													className="primaryButton"
													startIcon={(this.state.isLoadingForm ? <CircularProgress
														size={20}
													/> : (this.state.successForm ? <CheckRounded /> : <ArrowForward />))}
													onClick={this.state.is_client ? this.handleFinish : (this.state.is_freelancer ? () => { } : () => { })}
												>
													finish
												</Button>
											</div>}
										</Grid>
									</form>
								</Grid>
							</Grid>}
							{this.state.is_client && (this.state.checkout?.briefing_step_status == "pending_freelancer" || this.state.checkout?.briefing_step_status == "skipped_client" || this.state.checkout?.briefing_step_status == "skipped_freelancer" || this.state.checkout?.briefing_step_status == "completed") && <Grid container spacing={3}>
								<Grid item xs={12}>
									<div className="clientHeader">
										<h2>Hi, {this.state.client_name}!</h2>
										{this.state.checkout?.proposal_step_status == undefined && <Alert severity="info">
											{this.state.freelancer_name} (<a href={this.state.freelancer_email}>{this.state.freelancer_email}</a>) will be in touch soon to proceed with next steps given project requirements.
										</Alert>}
										{this.state.checkout?.proposal_step_status == "pending_freelancer" && <Alert severity="info">
											Initial project requirements done - {this.state.freelancer_name} (<a href={this.state.freelancer_email}>{this.state.freelancer_email}</a>) should send you a project proposal soon.
										</Alert>}
									</div>

									<ViewAnswers
										freelancer_user_project_id={this.state.freelancer_user_project_id}
										client_user_project_id={this.state.client_user_project_id}

										project_id={this.state.project_id}
										project_name={this.state.project_name}
										project_current_state={this.state.project_current_state}
										preference_group_id={this.state.preference_group_id}

										sections={this.state.sections}
										inputs={this.state.inputs}
										inputs_extras={this.state.inputs_extras}
										answers={this.state.answersBackend}
										answers_extras={this.state.answersExtrasBackend}

										clients_users_projects={this.state.clients_users_projects}

										section_selected_id={this.state.section_selected_id}
										section_selected_name={this.state.section_selected_name}
										section_selected_order={this.state.section_selected_order}

										count_sections={this.state.count_sections}

										goToPreviousSection={this.goToPreviousSection}
										goToNextSection={this.goToNextSection}

										showSnackMessage={this.showSnackMessage}
									/>
								</Grid>
							</Grid>}
						</Container>
					</main>
				</div>
			</div>
		)
	}
}

export default AnswerProjectBriefing