import React from "react"
import { RouteComponentProps } from "react-router"

import {
	Button,
	Container,
	Drawer,
	Grid,
	TextField, Snackbar
} from "@material-ui/core"

import { Alert } from "@material-ui/lab"

import {
	ArrowBackRounded,
	ArrowForward,
	RadioButtonUnchecked
} from "@material-ui/icons"

import {
	Skeleton,
	ToggleButton,
	ToggleButtonGroup
} from "@material-ui/lab"

import logo from "../images/logo-smelt.png"

import CoreAnalytics from "../utils/core_analytics"
import { config } from "./../config"

import { BackendProjectFull } from "./interfaces/backend"

interface Params {
	id?: number
}

interface Props extends RouteComponentProps<{}> { }

interface State {
	id?: number
	name: string
	description: string
	typeTemplate: string

	idCheck: boolean
	nameCheck: boolean
	descriptionCheck: boolean
	typeTemplateCheck: boolean

	errorForm: boolean
	errorMessage: string
	errorDuration: number
}

const regexExpressions: Record<string, string> = {
	"nameCheck": "^(.{1,32})$",
	"descriptionCheck": "^(.{0,512})$",
	"typeCheck": "^Blog Writing$|^E-commerce Website$|^Custom Professional Website$|^Digital Marketing$|^SEO Optimization$|^Blank$",
}

class CreateProjectBasics extends React.Component<Props, State> {
	setStateLikeSync(state: any) {
		return new Promise<void>((resolve) => {
			this.setState(state, resolve)
		});
	}

	showSnackMessage(message?: string, duration?: number) {
		if (message == undefined) {
			return
		}

		let actualDuration = duration ? duration : 3000

		this.setState({
			"errorForm": true,
			"errorMessage": message,
			"errorDuration": actualDuration
		})

		setTimeout(() => {
			this.setState({
				"errorForm": false,
				"errorMessage": "",
				"errorDuration": 0
			})
		}, actualDuration)
	}

	constructor(props: Props) {
		super(props)

		this.state = {
			id: undefined,
			name: "",
			description: "",
			typeTemplate: "E-commerce Website",

			idCheck: true,
			nameCheck: true,
			descriptionCheck: true,
			typeTemplateCheck: true,

			errorForm: false,
			errorMessage: "",
			errorDuration: 0
		}
	}

	async componentDidMount() {
		let persistenceToken = localStorage.getItem("persistence_token")
		if (persistenceToken === null) {
			this.props.history.replace("/login")
			return
		}

		let params = this.parseUrlParams(this.props.location.search)
		if (params.id) {
			try {
				const response = await fetch(`${config.API_URL}/read_project?project_id=${encodeURIComponent(params.id!)}&persistence_token=${encodeURIComponent(persistenceToken)}`, { // todo: create parser function, and interface for params?
					method: 'get',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();

				this.responseReadProjectResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.project_full, responseJSON.error)
			} catch (error) {
				this.showSnackMessage(`read-project-fetch-error: ${error}`)
			}
		}

		document.title = "Create a new project - Smelt"
	}

	parseUrlParams = (query: string): Params => {
		const urlSearchParams = new URLSearchParams(query)

		let params: Params = {
			id: urlSearchParams.get("id") === null ? undefined : Number(urlSearchParams.get("id")!),
		}
		return params
	}

	responseReadProjectResolve = (response: boolean, persistence_token?: string, project_full?: BackendProjectFull, error?: string): void => {
		if (response === true) {
			if (!project_full!.is_freelancer_creator) {

				this.props.history.replace("/dashboard")
				return
			}

			if (project_full!.project.current_state !== "draft" || project_full!.preferences_groups.length > 0) {

				this.props.history.replace("/dashboard")
				return
			}

			this.setState({
				"id": project_full!.project.id,
				"name": project_full!.project.name,
				"description": project_full!.project.description,
				"typeTemplate": project_full!.project.type_template
			})
			localStorage.setItem("persistence_token", persistence_token as string)
		} else {
			this.showSnackMessage(error) // likely not freelancer creator or is client
			this.props.history.replace("/dashboard")
		}
	}

	responseSaveProjectResolve = (response: boolean, persistence_token?: string, project_id?: number, error?: string): void => {
		if (response === true) {
			CoreAnalytics.trackCreateProject(this.state.name, this.state.typeTemplate)
			localStorage.setItem("persistence_token", persistence_token as string)
			this.props.history.push(`/create_project_briefing?id=${project_id!.toString()}`)
		} else {
			this.showSnackMessage(error) // some regex problem?			
		}
	}

	logout = () => {
		localStorage.removeItem("persistence_token")
		this.props.history.push("/login")
	}

	handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const key = event.target.name
		const keyCheck = event.target.name + "Check"
		const value = event.target.value
		const valueCheck = (RegExp(regexExpressions[keyCheck])).test(value)


		this.setState({ [key]: value, [keyCheck]: valueCheck } as any)
	}

	handleTypeTemplateSelect = (event: React.MouseEvent<HTMLElement>) => {
		const clickedElement = event.target as HTMLDivElement
		const clickedElementValue = clickedElement.textContent
		this.setState({ "typeTemplate": clickedElementValue } as any)
	}

	handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()

		await this.setStateLikeSync({
			nameCheck: (RegExp(regexExpressions["nameCheck"])).test(this.state.name),
			descriptionCheck: (RegExp(regexExpressions["descriptionCheck"])).test(this.state.description),
			typeTemplateCheck: (RegExp(regexExpressions["typeTemplateCheck"])).test(this.state.typeTemplate)
		} as any)

		let allValidationsPass = this.state.idCheck && this.state.nameCheck && this.state.descriptionCheck && this.state.typeTemplateCheck

		if (allValidationsPass) {
			try {
				let persistenceToken = localStorage.getItem("persistence_token")

				const response = await fetch(`${config.API_URL}/save_project`, {
					method: 'post',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
					body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
						persistence_token: persistenceToken,
						action: this.state.id ? "update" : "create",
						project_id: this.state.id ? this.state.id! : undefined,
						project_params: { "name": this.state.name, "description": this.state.description, "type_template": this.state.typeTemplate }
					})
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				this.responseSaveProjectResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.project_id, responseJSON.error)
			} catch (error) {
				this.showSnackMessage(`save-project-fetch-error: ${error}`)
			}
		} else {
			//this.showSnackMessage('not all validations passed - fix and try again')
		}
	}

	render() {
		return (
			<div className="mainStyle">
				<Snackbar open={this.state.errorForm} autoHideDuration={this.state.errorDuration}>
					<Alert severity="error">
						{this.state.errorMessage}
					</Alert>
				</Snackbar>

				<div className="drawerRoot">
					<Drawer
						className="drawerDrawer"
						variant="permanent"
						classes={{
							paper: "drawerDrawerPaper"
						}}
						anchor="left">
						<div className="topButtonMenu">
							<a href="/dashboard">
								<ArrowBackRounded /> <span>back</span>
							</a>
						</div>

						<div className="logo">
							<a href="/dashboard">
								<img src={logo} width="115" height="32" />
							</a>
						</div>
						<div className="drawerToolbar"></div>

						<div className="menuItems">
							<h3>Create a new project</h3>

							<div className="stepItem highlight current">
								<RadioButtonUnchecked />
								<div className="stepDescription">
									<h4>basics</h4>
									<Skeleton />
									<Skeleton />
								</div>
							</div>

							<div className="clear"></div>

							<div className="stepItem">
								<RadioButtonUnchecked />
								<div className="stepDescription">
									<h4>sections</h4>
									<Skeleton />
									<Skeleton />
									<Skeleton />
								</div>
							</div>
						</div>
					</Drawer>

					<main className="drawerContent">
						<Container maxWidth="sm" className="topCenterContent">
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<h1>Basics</h1>
								</Grid>

								<Grid item xs={12}>
									<form autoComplete="off">
										<Grid
											container
											justify="flex-start"
											xs={12}
											direction="column"
											className="block"
											alignItems="stretch"
											spacing={1}>
											<Grid item xs={12}>
												<TextField
													required
													name="name"
													label="Project Name"
													onChange={this.handleChange}
													error={!this.state.nameCheck}
													helperText={!this.state.nameCheck ? 'Invalid name' : ''}
													value={this.state.name}
												/>
											</Grid>

											<Grid item xs={12}>
												<TextField

													name="description"
													label="Description"
													onChange={this.handleChange}
													error={!this.state.descriptionCheck}
													helperText={!this.state.descriptionCheck ? 'Invalid description' : ''}
													value={this.state.description}
												/>
											</Grid>

											<Grid item xs={12}>
												<ToggleButtonGroup
													size="large"
													value={this.state.typeTemplate}
													exclusive
													onChange={this.handleTypeTemplateSelect}>
													<ToggleButton value="E-commerce Website">
														<label>E-commerce Website</label>
													</ToggleButton>
													<ToggleButton value="Custom Professional Website">
														<label>Custom Professional Website</label>
													</ToggleButton>
													<ToggleButton value="Blog Writing">
														<label>Blog Writing</label>
													</ToggleButton>
													<ToggleButton value="Digital Marketing">
														<label>Digital Marketing</label>
													</ToggleButton>
													<ToggleButton value="SEO Optimization">
														<label>SEO Optimization</label>
													</ToggleButton>
													<ToggleButton value="Blank">
														<label>Blank</label>
													</ToggleButton>
												</ToggleButtonGroup>
											</Grid>

											<div className="actionBar">
												<Button
													type="submit"
													variant="contained"
													color="primary"
													size="large"
													fullWidth
													className="primaryButton"
													startIcon={<ArrowForward />}
													onClick={this.handleSubmit}
												>
													next
												</Button>
											</div>
										</Grid>
									</form>
								</Grid>
							</Grid>
						</Container>
					</main>
				</div>
			</div>
		)
	}
}

export default CreateProjectBasics