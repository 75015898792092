import React from "react"

import {
    Button,
    Checkbox,
    FormGroup,
    FormControlLabel,
    GridListTile,
    TextField,
} from "@material-ui/core"

interface Props {
    order: number
    name: string
    is_checked: boolean
    is_saved: boolean

    name_check: boolean

    updateSectionOption: (order: number, name?: string, is_checked?: boolean, is_saved?: boolean, is_deleted?: boolean) => void
}

interface State {
    checked: boolean
}

class SectionCard extends React.Component<Props, State> {
    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.updateSectionOption(this.props.order, event.target.value, undefined, undefined, undefined)
    }

    handleCheck = (event: React.ChangeEvent<{}>, checked: boolean) => {
        this.props.updateSectionOption(this.props.order, undefined, checked, undefined, undefined)
    }

    handleSave = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.props.updateSectionOption(this.props.order, undefined, undefined, true, undefined)
    }

    handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.props.updateSectionOption(this.props.order, undefined, undefined, undefined, true)
    }

    render() {

        return (
            <GridListTile cols={1}>
                {this.props.is_saved && <FormGroup>
                    <FormControlLabel control={<Checkbox checked={this.props.is_checked} onChange={this.handleCheck} />} label={this.props.name} />
                </FormGroup>}
                {!this.props.is_saved && <FormGroup>
                    <Checkbox checked={this.props.is_checked} className="alignLabel" onChange={this.handleCheck} />
                    <TextField
                        className="noOutline"
                        required
                        label="New section"
                        onChange={this.handleChange}
                        type="text"
                        error={!this.props.name_check}
                        helperText={!this.props.name_check ? 'Invalid Section Name' : ''}
                    />
                    <Button
                        className="primarySmallButton"
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={this.handleSave}>
                        save
                    </Button>
                    <Button
                        className="defaultSmallButton"
                        onClick={this.handleCancel}>
                        cancel
                    </Button>
                </FormGroup>}
            </GridListTile>
        )
    }
}

export default SectionCard