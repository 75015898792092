import React from "react"
import { RouteComponentProps } from "react-router"

import {
	Button,
	CircularProgress,
	Container,
	Drawer,
	Grid,
	GridList,
	Snackbar
} from "@material-ui/core"

import {
	Add,
	ArrowBackRounded,
	ArrowForward,
	RadioButtonUnchecked,
	CheckCircleRounded,
	CheckRounded
} from "@material-ui/icons"

import {
	Skeleton,
	Alert
} from "@material-ui/lab"

import TemplateCard from './components/section_card'
import logo from "../images/logo-smelt.png"

import { config } from "../config"

import { BackendTemplate } from "./interfaces/backend"
import { FrontendTemplateOption } from "./interfaces/frontend"

const regexExpressions: Record<string, string> = {
	templateNameCheck: "^(.{0,32})$"
}

interface Props extends RouteComponentProps<{}> { }

interface State {
	isLoadingForm: boolean
	successForm: boolean

	templates: BackendTemplate[]
	templatesOptions: FrontendTemplateOption[]

	errorForm: boolean
	errorMessage: string
	errorDuration: number
}

class EditLibraryTemplates extends React.Component<Props, State> {
	setStateLikeSync(state: any) {
		return new Promise<void>((resolve) => {
			this.setState(state, resolve)
		});
	}

	showSnackMessage(message?: string, duration?: number) {
		if (message == undefined) {
			return
		}

		let actualDuration = duration ? duration : 3000

		this.setState({
			"errorForm": true,
			"errorMessage": message,
			"errorDuration": actualDuration
		})

		setTimeout(() => {
			this.setState({
				"errorForm": false,
				"errorMessage": "",
				"errorDuration": 0
			})
		}, actualDuration)
	}

	constructor(props: Props) {
		super(props)

		this.state = {
			isLoadingForm: false,
			successForm: false,

			templates: [],
			templatesOptions: [],

			errorForm: false,
			errorMessage: "",
			errorDuration: 0
		}
	}

	async componentDidMount() {
		let persistenceToken = localStorage.getItem("persistence_token")
		if (persistenceToken === null) {
			this.props.history.replace("/login")
			return
		}

		try {
			const response = await fetch(`${config.API_URL}/list_templates?persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
				method: 'get',
				headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
			})
			if (!response.ok) {
				// this.showSnackMessage(`An error has occured: ${response.status}`)
			}
			const responseJSON = await response.json();
			this.responseListTemplates(responseJSON.response, responseJSON.templates)
		} catch (error) {
			this.showSnackMessage(`list-templates-fetch-error: ${error}`)
		}

		if (this.state.templates.length == 0) {
			let templatesParams = [
				{ "name": "Blog Writing", "description": "< 5 min to specify project requirements", "current_state": "archived", "origin_library_template": "Blog Writing", "sections_params": this.getTemplateSectionsParams("Blog Writing") },
				{ "name": "Custom Professional Website", "description": "< 4 min to specify project requirements", "current_state": "archived", "origin_library_template": "Custom Professional Website", "sections_params": this.getTemplateSectionsParams("Custom Professional Website") },
				{ "name": "Digital Marketing", "description": "< 3 min to specify project requirements", "current_state": "archived", "origin_library_template": "Digital Marketing", "sections_params": this.getTemplateSectionsParams("Digital Marketing") },
				{ "name": "E-commerce Website", "description": "< 4 min to specify project requirements", "current_state": "archived", "origin_library_template": "E-commerce Website", "sections_params": this.getTemplateSectionsParams("E-commerce Website") },
				{ "name": "SEO Optimization", "description": "< 3 min to specify project requirements", "current_state": "archived", "origin_library_template": "SEO Optimization", "sections_params": this.getTemplateSectionsParams("SEO Optimization") },
			]

			try {
				for (const templateParams of templatesParams) {
					let persistenceToken = localStorage.getItem("persistence_token")

					const response = await fetch(`${config.API_URL}/save_template`, {
						method: 'post',
						headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
						body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
							persistence_token: persistenceToken,
							action: "create",
							template_params: templateParams
						})
					})
					if (!response.ok) {
						// this.showSnackMessage(`An error has occured: ${response.status}`)
					}
					const responseJSON = await response.json();
					this.responseSaveTemplateResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.template_id, responseJSON.error)
				}
			} catch (error) {
				this.showSnackMessage(`save-template-fetch-error: ${error}`)
			}

			try {
				const response = await fetch(`${config.API_URL}/list_templates?persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
					method: 'get',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				this.responseListTemplates(responseJSON.response, responseJSON.templates)
			} catch (error) {
				this.showSnackMessage(`list-templates-fetch-error: ${error}`)
			}
		}

		const templatesPublishedNames = this.state.templates.filter((template) => (template.current_state == "published")).map(template => template.name)

		this.setState({
			templatesOptions: [
				{ "order": 1, "name": "Blog Writing", "description": "< 5 min to specify project requirements", "is_checked": templatesPublishedNames.includes("Blog Writing"), "is_from_library": true, "is_saved": true, "name_check": true },
				{ "order": 2, "name": "Custom Professional Website", "description": "< 4 min to specify project requirements", "is_checked": templatesPublishedNames.includes("Custom Professional Website"), "is_from_library": true, "is_saved": true, "name_check": true },
				{ "order": 3, "name": "Digital Marketing", "description": "< 3 min to specify project requirements", "is_checked": templatesPublishedNames.includes("Digital Marketing"), "is_from_library": true, "is_saved": true, "name_check": true },
				{ "order": 4, "name": "E-commerce Website", "description": "< 4 min to specify project requirements", "is_checked": templatesPublishedNames.includes("E-commerce Website"), "is_from_library": true, "is_saved": true, "name_check": true },
				{ "order": 5, "name": "SEO Optimization", "description": "< 3 min to specify project requirements", "is_checked": templatesPublishedNames.includes("SEO Optimization"), "is_from_library": true, "is_saved": true, "name_check": true }
			]
		})

		document.title = "Create a new project - Smelt"
	}

	responseListTemplates = (response: boolean, templates?: BackendTemplate[], error?: string) => {
		if (response === true) {
			this.setStateLikeSync({
				"templates": templates!
			})
		} else {
			this.showSnackMessage("error") // user with token not loaded
		}
	}

	responseSaveTemplateResolve = (response: boolean, persistence_token?: string, template_id?: number, error?: string): void => {
		if (response === true) {
			localStorage.setItem("persistence_token", persistence_token as string)
			this.setState({ successForm: true });
		} else {
			this.setState({ successForm: false });
			//this.showSnackMessage(error)
		}
	}

	getTemplateSectionsParams = (typeTemplate: string): Record<string, any>[] => {
		var typeTemplateSectionsParams = []

		if (typeTemplate == "Blog Writing") {
			typeTemplateSectionsParams = require('./../library_templates/blog_writing.json')
		} else if (typeTemplate == "Custom Professional Website") {
			typeTemplateSectionsParams = require('./../library_templates/custom_professional_website.json')
		} else if (typeTemplate == "Digital Marketing") {
			typeTemplateSectionsParams = require('./../library_templates/digital_marketing.json')
		} else if (typeTemplate == "E-commerce Website") {
			typeTemplateSectionsParams = require('./../library_templates/ecommerce_website.json')
		} else if (typeTemplate == "SEO Optimization") {
			typeTemplateSectionsParams = require('./../library_templates/seo_optimization.json')
		}

		return typeTemplateSectionsParams
	}

	updateTemplateOption = (order: number, name?: string, is_checked?: boolean, is_saved?: boolean, is_deleted?: boolean) => {
		this.setState(previousState => {
			let templatesOptions = previousState.templatesOptions.filter((templateOption) => ((templateOption.order != order) || (templateOption.order == order && !is_deleted))).sort((templateOption1, templateOption2) => templateOption1.order > templateOption2.order ? 1 : -1).map((templateOption, index) => {
				var newTemplateOption = templateOption
				newTemplateOption.order = index + 1

				if (order === templateOption.order) {
					if (name !== undefined) {
						newTemplateOption.name = name
						newTemplateOption.name_check = (RegExp(regexExpressions["templateNameCheck"])).test(name)
					}
					if (is_checked !== undefined) {
						newTemplateOption.is_checked = is_checked
					}
					if (is_saved !== undefined) {
						newTemplateOption.is_saved = is_saved
					}
				}

				return newTemplateOption
			})

			return { "templatesOptions": templatesOptions }
		})
	}

	// handleAddTemplate = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => { //todo: check regex
	// 	event.preventDefault();

	// 	this.setState(previousState => {
	// 		return { "templatesOptions": previousState.templatesOptions.concat({ "order": previousState.templatesOptions.length + 1, "name": "", "description": "", "is_checked": true, "is_from_library": false, "is_saved": false, "name_check": true }) }
	// 	})
	// }

	handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()

		await this.setStateLikeSync({ templatesCheck: [true] } as any)

		let allValidationsPass = true

		if (allValidationsPass) {
			let templatesIdsParams = this.state.templatesOptions.sort((templateOption1, templateOption2) => templateOption1.order > templateOption2.order ? 1 : -1).map((templateOption, index) => {
				let template = this.state.templates.filter((template) => (template.name == templateOption.name))[0]

				return {
					"id": template?.id,
					"params": {
						"current_state": (templateOption.is_saved && templateOption.is_checked) ? "published" : "archived"
					}
				}
			})

			try {
				await this.setStateLikeSync({ isLoadingForm: true });

				for (const templateIdParams of templatesIdsParams) {
					let persistenceToken = localStorage.getItem("persistence_token")
					console.log({
						persistence_token: persistenceToken,
						action: "update",
						template_id: templateIdParams.id,
						template_params: templateIdParams.params
					})

					const response = await fetch(`${config.API_URL}/save_template`, {
						method: 'post',
						headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
						body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
							persistence_token: persistenceToken,
							action: "update",
							template_id: templateIdParams.id,
							template_params: templateIdParams.params
						})
					})
					if (!response.ok) {
						// this.showSnackMessage(`An error has occured: ${response.status}`)
					}
					const responseJSON = await response.json();
					this.responseSaveTemplateResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.template_id, responseJSON.error)
				}

				await this.setStateLikeSync({ isLoadingForm: false });
				setTimeout(() => {
					this.props.history.push(`/dashboard`)
				}, 500)
			} catch (error) {
				this.showSnackMessage(`save-template-fetch-error: ${error}`)
			}
		} else {
			//this.showSnackMessage("not all validations passed - fix and try again")
		}
	}

	render() {
		let templatesCards = this.state.templatesOptions.sort((templateOption1, templateOption2) => templateOption1.order > templateOption2.order ? 1 : -1).map((templateOption) => {
			return (
				<TemplateCard
					order={templateOption.order}
					name={templateOption.name}
					is_checked={templateOption.is_checked}
					is_saved={templateOption.is_saved}
					name_check={templateOption.name_check}
					updateSectionOption={this.updateTemplateOption}
				/>
			)
		})

		return (
			<div className="mainStyle">
				<Snackbar open={this.state.errorForm} autoHideDuration={this.state.errorDuration}>
					<Alert severity="error">
						{this.state.errorMessage}
					</Alert>
				</Snackbar>

				<div className="drawerRoot">
					<Drawer
						className="drawerDrawer"
						variant="permanent"
						classes={{
							paper: "drawerDrawerPaper"
						}}
						anchor="left">
						<div className="topButtonMenu">
							<a href="/dashboard">
								<ArrowBackRounded /> <span>back</span>
							</a>
						</div>

						<div className="logo">
							<a href="/dashboard">
								<img src={logo} width="115" height="32" />
							</a>
						</div>
						<div className="drawerToolbar" />

						<div className="menuItems">
							<h3>Add library templates</h3>

							<div className="stepItem highlight">
								<CheckCircleRounded />
								<div className="stepDescription">
									<h4>info</h4>
								</div>
							</div>

							<div className="clear"></div>

							<div className="stepItem highlight">
								<RadioButtonUnchecked />
								<div className="stepDescription ">
									<h4>templates</h4>
									<Skeleton />
									<Skeleton />
									<Skeleton />
								</div>
							</div>
						</div>
					</Drawer>

					<main className="drawerContent">
						<Container maxWidth="sm" className="topCenterContent">
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<h1>
										Templates ({this.state.templatesOptions.filter((templateOption) => templateOption.is_checked).length})
									</h1>
								</Grid>

								<Grid item xs={12}>
									<form autoComplete="off">
										<Grid
											container
											justify="flex-start"
											xs={12}
											direction="column"
											className="block"
											alignItems="stretch"
											spacing={1}>
											<Grid item xs={12}>
												<GridList
													cellHeight={80}
													className="gridList sectionList"
													cols={1}
													spacing={10}>
													{templatesCards}
												</GridList>

												{/* <div className="outlinedButton">
													<a href="#" onClick={this.handleAddTemplate}>
														<Add />{" "}
														<span>
															add template
														</span>
													</a>
												</div> */}

											</Grid>

											<div className="actionBar">
												<Button
													type="submit"
													variant="contained"
													color="primary"
													size="large"
													fullWidth
													className="primaryButton"
													startIcon={(this.state.isLoadingForm ? <CircularProgress
														size={20}
													/> : (this.state.successForm ? <CheckRounded /> : <ArrowForward />))}
													onClick={this.handleSubmit}
												>
													done
												</Button>
											</div>
										</Grid>
									</form>
								</Grid>
							</Grid>
						</Container>
					</main>
				</div>
			</div>
		)
	}
}

export default EditLibraryTemplates
