import React from "react"
import { RouteComponentProps } from "react-router"
import parse from 'html-react-parser'

import {
	Button,
	Container,
	Drawer,
	Grid,
	GridList,
	CircularProgress,
	GridListTile,
	Tab,
	Tabs,
	Accordion,
	AccordionSummary,
	Snackbar,
	Typography,
	AccordionDetails
} from "@material-ui/core"

import {
	ArrowForward,
	CheckRounded
} from "@material-ui/icons"

import "../styles/checkout_pages.scss"

import { Alert } from "@material-ui/lab"

import CheckoutSteps from './components/checkout_steps'

import CoreAnalytics from "../utils/core_analytics"
import { config } from "../config"

import { BackendUserFull, BackendProjectFull, BackendPreferenceGroupFull, BackendInput, BackendCheckoutFull, BackendCheckout, BackendInputExtra, BackendPreferenceGroupAnswers, BackendSection, BackendClientUserProject, BackendFreelancerProfile, BackendAnswer, BackendAnswerExtra, BackendProposal } from "./interfaces/backend"

interface Params {
	id?: number
	invitationToken?: string
	noRedirect?: boolean
}

interface Props extends RouteComponentProps<{}> { }

interface State {
	freelancer_user_project_id?: number
	client_user_project_id?: number

	freelancer_email: string
	freelancer_name: string

	client_name: string

	isLoadingForm: boolean
	successForm: boolean

	activeTabIndex: number
	activeAccordionIndex: number

	project_id: number
	project_name: string
	project_current_state: string
	checkout_id: number
	preference_group_id: number
	proposal_id?: number

	clients_users_projects: BackendClientUserProject[]

	checkout?: BackendCheckout
	freelancerProfile?: BackendFreelancerProfile

	sections: BackendSection[]
	inputs: BackendInput[]
	inputs_extras: BackendInputExtra[]
	answersBackend: BackendAnswer[]
	answersExtrasBackend: BackendAnswerExtra[]

	proposal?: BackendProposal

	is_freelancer: boolean
	is_client: boolean

	errorForm: boolean
	errorMessage: string
	errorDuration: number
}

class ViewProposal extends React.Component<Props, State> {
	setStateLikeSync(state: any) {
		return new Promise<void>((resolve) => {
			this.setState(state, resolve)
		});
	}

	showSnackMessage(message?: string, duration?: number) {
		if (message == undefined) {
			return
		}

		let actualDuration = duration ? duration : 3000

		this.setState({
			"errorForm": true,
			"errorMessage": message,
			"errorDuration": actualDuration
		})

		setTimeout(() => {
			this.setState({
				"errorForm": false,
				"errorMessage": "",
				"errorDuration": 0
			})
		}, actualDuration)
	}

	constructor(props: Props) {
		super(props)

		this.state = {
			freelancer_user_project_id: undefined,
			client_user_project_id: undefined,

			freelancer_email: "",
			freelancer_name: "",

			client_name: "",

			isLoadingForm: false,
			successForm: false,

			activeTabIndex: 1,
			activeAccordionIndex: -1,

			project_id: -1,
			project_name: "",
			project_current_state: "",
			checkout_id: -1,
			preference_group_id: -1,
			proposal_id: undefined,

			clients_users_projects: [],

			checkout: undefined,
			freelancerProfile: undefined,

			sections: [],
			inputs: [],
			inputs_extras: [],
			answersBackend: [],
			answersExtrasBackend: [],

			proposal: undefined,

			is_freelancer: false,
			is_client: false,

			errorForm: false,
			errorMessage: "",
			errorDuration: 0
		}
	}

	async componentDidMount() {
		let persistenceToken = localStorage.getItem("persistence_token")
		if (persistenceToken === null) {
			this.props.history.replace("/login")
			return
		}

		let params = this.parseUrlParams(this.props.location.search)

		if (params.invitationToken) {
			try {
				const response = await fetch(`${config.API_URL}/execute_invitation`, {
					method: 'post',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
					body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
						persistence_token: persistenceToken,
						invitation_token: params.invitationToken!
					})
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				this.responseExecuteInvitation(responseJSON.response, responseJSON.persistence_token, responseJSON.redirect_to_project_id, responseJSON.error)
			} catch (error) {
				this.showSnackMessage(`execute-invitation-fetch-error: ${error}`)
			}
		} else if (params.id) {
			try {
				const response = await fetch(`${config.API_URL}/read_project?project_id=${encodeURIComponent(params.id!)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
					method: 'get',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				this.responseReadProjectResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.project_full, responseJSON.error)
			} catch (error) {
				this.showSnackMessage(`read-project-fetch-error: ${error}`)
			}

			try {
				let persistenceToken = localStorage.getItem("persistence_token")

				const response = await fetch(`${config.API_URL}/read_user?persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
					method: 'get',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				this.responseReadUserResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.user_full, responseJSON.error)
			} catch (error) {
				this.showSnackMessage(`read-user-fetch-error: ${error}`)
			}

			try {
				let persistenceToken = localStorage.getItem("persistence_token")
				let checkout_id = this.state.checkout_id

				const response = await fetch(`${config.API_URL}/read_checkout?checkout_id=${encodeURIComponent(checkout_id)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
					method: 'get',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				let isDone = this.responseReadCheckoutResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.checkout_full, responseJSON.error)
				if (isDone) {
					return
				}
			} catch (error) {
				this.showSnackMessage(`read-checkout-fetch-error: ${error}`)
			}

			let isDone = this.redirect(4, params.noRedirect)
			if (isDone) {
				return
			}

			try {
				let persistenceToken = localStorage.getItem("persistence_token")
				let preference_group_id = this.state.preference_group_id

				const response = await fetch(`${config.API_URL}/read_preference_group?preference_group_id=${encodeURIComponent(preference_group_id)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
					method: 'get',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				this.responseReadPreferenceGroupResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.preference_group_full, responseJSON.error)
			} catch (error) {
				this.showSnackMessage(`read-preference-group-fetch-error: ${error}`)
			}

			try {
				let persistenceToken = localStorage.getItem("persistence_token")
				let preference_group_id = this.state.preference_group_id

				const response = await fetch(`${config.API_URL}/read_preference_group_answers?preference_group_id=${encodeURIComponent(preference_group_id)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
					method: 'get',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();
				this.responseReadPreferenceGroupAnswersResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.preference_group_answers, responseJSON.error)
			} catch (error) {
				this.showSnackMessage(`read-preference-group-answers-fetch-error: ${error}`)
			}

			let proposal_id = this.state.checkout?.proposal_id
			if (proposal_id) {
				try {
					let persistenceToken = localStorage.getItem("persistence_token")

					const response = await fetch(`${config.API_URL}/read_proposal?proposal_id=${encodeURIComponent(proposal_id)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
						method: 'get',
						headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
					})
					if (!response.ok) {
						// this.showSnackMessage(`An error has occured: ${response.status}`)
					}
					const responseJSON = await response.json();
					let isDone = this.responseReadProposalResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.proposal, responseJSON.error)
					if (isDone) {
						return
					}
				} catch (error) {
					this.showSnackMessage(`read-checkout-fetch-error: ${error}`)
				}
			}
		} else {
			this.showSnackMessage("Missing invitation_id or project_id")
			this.props.history.replace("/dashboard")
		}
	}

	parseUrlParams = (query: string): Params => {
		const urlSearchParams = new URLSearchParams(query)

		let params: Params = {
			id: urlSearchParams.get("id") === null ? undefined : Number(urlSearchParams.get("id")!),
			invitationToken: urlSearchParams.get("invitation_token") === null ? undefined : urlSearchParams.get("invitation_token")!,
			noRedirect: urlSearchParams.get("no_redirect") === null ? undefined : (urlSearchParams.get("no_redirect")! == "true")
		}
		return params
	}

	redirect = (currentStep: number, no_redirect?: boolean) => {
		let activeStep = this.getActiveStep()

		if (no_redirect) {
			return false
		}

		if ((currentStep == activeStep) && (this.state.checkout?.proposal_step_status != "pending_freelancer" || this.state.is_freelancer)) { // exceptional rule to hold client on answers until there is an actual proposal for him
			return false
		}

		var pushString = undefined

		if (activeStep == 3 || (this.state.checkout?.proposal_step_status == "pending_freelancer" && this.state.is_client)) { // exceptional rule to hold client on answers until there is an actual proposal for him
			pushString = `/answer_project_briefing?id=${this.state.project_id}`
		} else if (activeStep == 4) {
			pushString = `/view_proposal?id=${this.state.project_id}`
		} else if (activeStep == 5) {
			pushString = `/make_payment?id=${this.state.project_id}`
		} else if (activeStep == 6) {
			pushString = `/check_delivery?id=${this.state.project_id}`
		} else if (activeStep == 7) {
			pushString = `/check_delivery?id=${this.state.project_id}`
		}

		if (pushString) {
			this.props.history.push(pushString)
		}

		return true
	}

	getActiveStep() {
		if (this.state.checkout?.briefing_step_status != undefined && this.state.checkout?.proposal_step_status == undefined) {
			return 3
		} else if (this.state.checkout?.proposal_step_status != undefined && this.state.checkout?.payment_step_status == undefined) {
			return 4
		} else if (this.state.checkout?.payment_step_status != undefined && this.state.checkout?.delivery_step_status == undefined) {
			return 5
		} else if (this.state.checkout?.delivery_step_status != undefined && this.state.checkout?.release_step_status == undefined) {
			return 6
		} else if (this.state.checkout?.release_step_status != undefined) {
			return 7
		}

		return 0
	}

	responseExecuteInvitation = (response: boolean, persistence_token?: string, redirect_to_project_id?: number, error?: string): void => {
		if (response === true) {
			this.props.history.replace(`/answer_project_briefing?id=${redirect_to_project_id!}`)
			window.location.reload()
		} else {
			this.showSnackMessage(error)
		}
	}

	responseReadProjectResolve = (response: boolean, persistence_token?: string, project_full?: BackendProjectFull, error?: string): void => {
		if (response === true) {
			if ((project_full!.project.current_state !== "published") || project_full!.preferences_groups.length != 1) {
				this.showSnackMessage("Project is not published, or has 0 or more than 1 (not possible) preference group")
				this.props.history.replace("/dashboard")
				return
			}

			let client_user_project_list = project_full!.clients_users_projects.filter((client_user_project) => client_user_project.client_project_id === project_full!.client_user_project_id)

			this.setState({
				"freelancer_email": project_full!.freelancers_users_projects[0].email,
				"freelancer_name": project_full!.freelancers_users_projects[0].first_name,
				"client_name": (project_full!.clients_users_projects.length == 1) ? project_full!.clients_users_projects[0].first_name : project_full!.freelancers_users_projects[0].first_name,
				"freelancer_user_project_id": project_full!.freelancer_user_project_id,
				"client_user_project_id": project_full!.client_user_project_id,
				"project_id": project_full!.project.id,
				"project_name": project_full!.project.name,
				"project_current_state": project_full!.project.current_state,
				"checkout_id": project_full!.checkouts[0].id,
				"preference_group_id": project_full!.preferences_groups[0].id,
				"proposal_id": project_full!.checkouts[0].proposal_id,
				"clients_users_projects": project_full!.clients_users_projects
			})
			localStorage.setItem("persistence_token", persistence_token as string)
			document.title = this.state.project_name + " - Smelt"
		} else {
			this.showSnackMessage(error)
			this.props.history.replace("/dashboard")
		}
	}

	responseReadUserResolve = (response: boolean, persistence_token?: string, user_full?: BackendUserFull, error?: string): void => {
		if (response === true) {
			CoreAnalytics.trackUser(user_full!.email, user_full!.email, user_full!.first_name, user_full!.last_name, user_full!.is_client, user_full!.is_freelancer);
			this.setState({
				"is_freelancer": user_full!.is_freelancer,
				"is_client": user_full!.is_client
			})
			localStorage.setItem("persistence_token", persistence_token as string)
		} else {
			this.showSnackMessage(error) // user with token not loaded
			localStorage.removeItem("persistence_token")
			this.props.history.replace("/login")
		}
	}

	handleChangeTab = (event: React.ChangeEvent<any>, newValue: number) => {
		this.setState({ activeTabIndex: newValue + 1 });
	}

	handleChangeAccordion = (newValue: number) => {
		this.setState((previousState) => ({ activeAccordionIndex: previousState.activeAccordionIndex == newValue ? -1 : newValue }));
	}

	responseReadCheckoutResolve = (response: boolean, persistence_token?: string, checkout_full?: BackendCheckoutFull, error?: string): boolean => {
		if (response === true) {
			this.setState({
				"checkout": checkout_full!.checkout,
				"freelancerProfile": checkout_full!.freelancer_profile
			})
			localStorage.setItem("persistence_token", persistence_token as string)
		} else {
			this.showSnackMessage(error)
			this.props.history.replace("/dashboard")
			return true
		}
		return false
	}

	responseReadProposalResolve = (response: boolean, persistence_token?: string, proposal?: BackendProposal, error?: string): boolean => {
		if (response === true) {
			this.setState({
				"proposal": proposal!
			})
			localStorage.setItem("persistence_token", persistence_token as string)
		} else {
			this.showSnackMessage(error)
			this.props.history.replace("/dashboard")
			return true
		}
		return false
	}

	responseReadPreferenceGroupResolve = (response: boolean, persistence_token?: string, preference_group_full?: BackendPreferenceGroupFull, error?: string): boolean => {
		if (response === true) {
			if (preference_group_full!.preference_group.current_state !== "published" && preference_group_full!.preference_group.current_state !== "archived") {
				this.showSnackMessage("Preference group not is not published or archived - should never happen and always match the project published or archived")
				this.props.history.replace("/dashboard")
				return true
			}

			this.setState({
				"sections": preference_group_full!.sections.map((section) => ({ "id": section.id, "preference_group_id": section.preference_group_id, "name": section.name, "order": section.order })),
				"inputs": preference_group_full!.inputs.map((input) => ({ "id": input.id, "section_id": input.section_id, "freelancer_project_id": input.freelancer_project_id, "order": input.order, "type": input.type, "is_required": input.is_required, "title": input.title, "thumbnail": JSON.parse(((input.thumbnail ? input.thumbnail : "{}")).replace(/'/g, '"')), "input_extra_id": input.input_extra_id })),
				"inputs_extras": preference_group_full!.inputs_extras.map((input_extra) => ({ "id": input_extra.id, "quote": input_extra.quote, "file_s3_link": input_extra.file_s3_link, "url_link": input_extra.url_link, "specific_part": input_extra.specific_part, "relevance_reason": input_extra.relevance_reason, "options": (input_extra.options ? input_extra.options : []).map(option => JSON.parse(option.replace(/'/g, '"'))) }))
			})
			localStorage.setItem("persistence_token", persistence_token as string)
		} else {
			this.showSnackMessage(error)
			this.props.history.replace("/dashboard")
			return true
		}
		return false
	}

	responseReadPreferenceGroupAnswersResolve = (response: boolean, persistence_token?: string, preference_group_answers?: BackendPreferenceGroupAnswers, error?: string): void => {
		if (response === true) {
			this.setState({
				"answersBackend": preference_group_answers!.answers.map((answer) => ({ "id": answer.id, "input_id": answer.input_id, "client_project_id": answer.client_project_id, "has_skipped": answer.has_skipped, "free_text": answer.free_text, "answer_extra_id": answer.answer_extra_id, "created_at": answer.created_at })), // check so there is always at least one section
				"answersExtrasBackend": preference_group_answers!.answers_extras.map((answer_extra) => ({ "id": answer_extra.id, "like_dislike": answer_extra.like_dislike, "quote": answer_extra.quote, "file_s3_link": answer_extra.file_s3_link, "url_link": answer_extra.url_link, "specific_part": answer_extra.specific_part, "relevance_reason": answer_extra.relevance_reason, "options": (answer_extra.options ? answer_extra.options : []).map(option => JSON.parse(option.replace(/'/g, '"'))) }))
			})
			localStorage.setItem("persistence_token", persistence_token as string)

		} else {
			this.showSnackMessage(error)
			this.props.history.replace("/dashboard")
		}
	}

	responseSaveCheckoutResolve = (response: boolean, persistence_token?: string, checkout_id?: number, error?: string): void => {
		if (response === true) {
			localStorage.setItem("persistence_token", persistence_token as string)
			this.setState({ successForm: true });
			this.props.history.push(`make_payment?id=${this.state.project_id}`)
		} else {
			this.setState({ successForm: false });
			this.showSnackMessage(error) // some regex problem?
		}
	}

	responseSaveProposalResolve = (response: boolean, persistence_token?: string, proposal_id?: number, error?: string): void => {
		if (response === true) {
			this.setStateLikeSync({
				"proposal_id": proposal_id!
			})
			localStorage.setItem("persistence_token", persistence_token as string)
			this.setState({ successForm: true });
		} else {
			this.setState({ successForm: false });
			this.showSnackMessage(error) // some regex problem?
		}
	}

	handleHireProject = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()

		var persistenceToken = localStorage.getItem("persistence_token")

		try {
			await this.setStateLikeSync({ isLoadingForm: true });

			const response = await fetch(`${config.API_URL}/save_proposal`, {
				method: 'post',
				headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
				body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
					persistence_token: persistenceToken,
					action: "update",
					"proposal_id": this.state.proposal_id,
					proposal_params: { "has_been_accepted": true }
				})
			})
			if (!response.ok) {
				// this.showSnackMessage(`An error has occured: ${response.status}`)
			}
			await CoreAnalytics.trackProjectAcceptProposal(this.state.project_id, this.state.project_name, this.state.freelancer_email, this.state.freelancer_name);

			const responseJSON = await response.json();
			await this.setStateLikeSync({ isLoadingForm: false });
			this.responseSaveProposalResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.proposal_id, responseJSON.error)
		} catch (error) {
			this.showSnackMessage(`save-proposal-fetch-error: ${error}`)
		}

		persistenceToken = localStorage.getItem("persistence_token")

		try {
			await this.setStateLikeSync({ isLoadingForm: true });

			const response = await fetch(`${config.API_URL}/save_checkout`, {
				method: 'post',
				headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
				body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
					persistence_token: persistenceToken,
					action: "update",
					checkout_id: this.state.checkout_id,
					checkout_params: { "proposal_step_status": "completed", "payment_step_status": "pending_client" }
				})
			})
			if (!response.ok) {
				// this.showSnackMessage(`An error has occured: ${response.status}`)
			}
			const responseJSON = await response.json();
			await this.setStateLikeSync({ isLoadingForm: false });
			this.responseSaveCheckoutResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.checkout_id, responseJSON.error)
		} catch (error) {
			this.showSnackMessage(`save-checkout-fetch-error: ${error}`)
		}
	}

	push = (page: string) => {
		this.props.history.push(page)
	}

	format = (date?: number) => {
		let dateRaw = date ? date : 0
		let dateDate = new Date(dateRaw)
		return dateDate.toDateString()
	}

	render() {
		let payment_options_names = (this.state.proposal?.payment_options ? this.state.proposal?.payment_options : []).map(payment_option => JSON.parse(payment_option.replace(/'/g, '"')).name)

		let SectionsSummariesCards = this.state.sections.sort((section1, section2) => section1.order > section2.order ? 1 : -1).map((section) => {
			let InputsAnswersCards = this.state.inputs.filter((input) => input.section_id === section.id).sort((input1, input2) => input1.order > input2.order ? 1 : -1).map((input) => {
				// let input_extra = this.state.inputs_extras.filter((input_extra) => input_extra.id == input.input_extra_id)
				let answers = this.state.answersBackend.filter((answer) => answer.input_id == input.id)
				let answers_extras_ids = answers.map((answer) => answer.answer_extra_id)

				let answers_extras = this.state.answersExtrasBackend.filter((answer_extra) => answers_extras_ids.includes(answer_extra.id))

				return (
					<ul>{input.title}
						<br />
						<>
							{
								{
									question: (
										answers.map((answer: BackendAnswer) => {
											return (<p>{answer.free_text}</p>)
										})
									),
									checkbox: (
										answers.map((answer) => (
											answers_extras.filter((answer_extra) => (answer_extra.id == answer.answer_extra_id)).map((answer_extra) => (
												answer_extra.options?.map((option) => (
													<p>{option.name}</p>
												))
											))
										))
									),
									radio: (
										answers.map((answer) => (
											answers_extras.filter((answer_extra) => (answer_extra.id == answer.answer_extra_id)).map((answer_extra) => (
												answer_extra.options?.map((option) => (
													<p>{option.name}</p>
												))
											))
										))
									),
									ask_feedback: (
										answers.map((answer) => (
											answers_extras.filter((answer_extra) => (answer_extra.id == answer.answer_extra_id)).map((answer_extra) => (
												<>
													<p>{answer_extra.like_dislike}</p>
													<p>{answer.free_text}</p>
												</>
											))
										))
									),
									ask_example: (
										answers.map((answer) => (
											answers_extras.filter((answer_extra) => (answer_extra.id == answer.answer_extra_id)).map((answer_extra) => (
												<>
													<p>{answer_extra.quote}{answer_extra.url_link}{answer_extra.file_s3_link}</p>
													<p>{answer_extra.specific_part}</p>
													<p>{answer_extra.relevance_reason}</p>
												</>
											))
										))
									),
									multiline: (
										answers.map((answer) => (
											answers_extras.filter((answer_extra) => (answer_extra.id == answer.answer_extra_id)).map((answer_extra) => (
												answer_extra.options?.map((option) => (
													<p>{option.name}</p>
												))
											))
										))
									)
								}[input.type]
							}
						</>
						<br />
					</ul>
				)
			})

			return (
				<Accordion expanded={this.state.activeAccordionIndex == section.order} onChange={() => { this.handleChangeAccordion(section.order) }}>
					<AccordionSummary>
						<Typography>{section.name}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							{InputsAnswersCards}
						</Typography>
					</AccordionDetails>
				</Accordion>
			)
		})

		return (
			<div className="mainStyle checkoutStyle">
				<Snackbar open={this.state.errorForm} autoHideDuration={this.state.errorDuration}>
					<Alert severity="error">
						{this.state.errorMessage}
					</Alert>
				</Snackbar>

				<Snackbar open={this.state.is_freelancer} autoHideDuration={3000}>
					<Alert severity="warning">
						Preview mode: your actions on this page won't be saved.
					</Alert>
				</Snackbar>

				<div className="drawerRoot">
					<Drawer
						className="drawerDrawer"
						variant="permanent"
						classes={{
							paper: "drawerDrawerPaper"
						}}
						anchor="left">

						<div className="drawerToolbar" />

						<CheckoutSteps
							id={this.state.checkout?.id}
							project_id={this.state.checkout?.project_id}
							briefing_step_status={this.state.checkout?.briefing_step_status}
							proposal_step_status={this.state.checkout?.proposal_step_status}
							payment_step_status={this.state.checkout?.payment_step_status}
							delivery_step_status={this.state.checkout?.delivery_step_status}
							release_step_status={this.state.checkout?.release_step_status}
							preference_group_id={this.state.checkout?.preference_group_id}
							proposal_id={this.state.checkout?.proposal_id}
							payment_id={this.state.checkout?.payment_id}
							delivery_id={this.state.checkout?.delivery_id}
							release_id={this.state.checkout?.release_id}

							is_freelancer={false}
							is_client={true}

							profile_id={this.state.freelancerProfile?.profile_id}
							profile_image_link={this.state.freelancerProfile?.profile_image_link}
							freelancer_name={this.state.freelancer_name}
							job_title={this.state.freelancerProfile?.job_title}
							city={this.state.freelancerProfile?.city}
							country={this.state.freelancerProfile?.country}
							job_description={this.state.freelancerProfile?.job_description}
							project_name={this.state.project_name}

							push={this.push}
						/>
					</Drawer>

					<main className="drawerContent">
						<Container
							maxWidth="md"
							className="topCenterContent topCenterContentVerticalScroll">
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<div className="clientHeader">
										<h2>Hi, {this.state.client_name}!</h2>
										<Alert severity="info">
											You can see a proposal below for your project. Please, contact <a href={this.state.freelancer_email}>{this.state.freelancer_email}</a> if you have any questions.
										</Alert>
									</div>

									<div className="titleBar">
										<h2 className="left">Proposal for {this.state.project_name}</h2>
										<div className="clear"></div>
									</div>

									<form autoComplete="off" onSubmit={e => e.preventDefault()}>
										<Grid
											container
											justify="flex-start"
											xs={12}
											direction="column"
											className="block"
											alignItems="stretch"
											spacing={1}>
											<Grid item xs={12}>
												<Tabs
													value={this.state.activeTabIndex - 1}
													indicatorColor="primary"
													textColor="primary"
													onChange={this.handleChangeTab}
												>
													<Tab label="Cover Letter"></Tab>
													<Tab label="Project Deliverables"> </Tab>
													<Tab label="Why me?"> </Tab>
													<Tab label="Project Investment"> </Tab>
												</Tabs>
												<GridList
													cellHeight="auto"
													className="gridList"
													cols={1}
													spacing={10}>
													{this.state.activeTabIndex == 1 && <GridListTile
														cols={1} className="noBorder">
														<div className="questionBox">
															{!this.state.proposal && <p className="stepPending">No proposal has been sent yet - we will let you know when it has! :)</p>}
															{this.state.proposal && <>
																<div className="clear"></div>
																{parse(this.state.proposal?.cover_letter ? this.state.proposal?.cover_letter : "")}
																<div className="clear"></div>
															</>}
														</div>
													</GridListTile>}

													{this.state.activeTabIndex == 2 && <GridListTile
														cols={1} className="noBorder">
														<div className="questionBox">
															{!this.state.proposal && <p className="stepPending">No proposal has been sent yet - we will let you know when it has! :)</p>}
															{this.state.proposal && <>
																<div className="clear"></div>

																<h3>Project Deliverables</h3>
																{parse(this.state.proposal?.deliverable ? this.state.proposal?.deliverable : "")}

																<h3>Due Date</h3>
																<p>{this.format(this.state.proposal?.deadline)}</p>

																<h3>Summary of Changes on Project Requirements</h3>
																{parse(this.state.proposal?.summary_briefing_changes ? this.state.proposal?.summary_briefing_changes : "")}

																<h3>Initial Project Requirements Summary</h3>

																<p>Below is a summary of your initial thoughts for this project.</p>

																{SectionsSummariesCards}
																<div className="clear"></div>
															</>}
														</div>
													</GridListTile>}

													{this.state.activeTabIndex == 3 && <GridListTile
														cols={1} className="noBorder">
														<div className="questionBox">
															{!this.state.proposal && <p className="stepPending">No proposal has been sent yet - we will let you know when it has! :)</p>}
															{this.state.proposal && <>
																<div className="clear"></div>

																{parse(this.state.proposal?.why_me ? this.state.proposal?.why_me : "")}

																<div className="clear"></div>
															</>}
														</div>
													</GridListTile>}

													{this.state.activeTabIndex == 4 && <GridListTile
														cols={1} className="noBorder">
														<div className="questionBox">
															{!this.state.proposal && <p className="stepPending">No proposal has been sent yet - we will let you know when it has! :)</p>}
															{this.state.proposal && <>

																<div className="investmentDescription left">
																	<div className="clear"></div>

																	<h3>Quality guaranteed or your money back</h3>
																	<p>If you choose to pay using our Escrow payment method or with the Flexible (buy-now pay-later) feature, Smelt Technologies Inc. guarantees the quality of your project or your money back.</p>


																	<h3>Payment Methods</h3>

																	{payment_options_names.indexOf("direct") != -1 && <p><strong>Direct</strong><br />Wire transfer, ACH, International payments</p>}

																	{payment_options_names.indexOf("credit_card") != -1 && <p><strong>Credit card</strong><br />Mastercard, Visa, Amex</p>}

																	{payment_options_names.indexOf("basic_escrow") != -1 && <p><strong>Escrow payment</strong><br />Your money goesto asafe account and released only after you mark it as completed or open a dispute</p>}

																	{payment_options_names.indexOf("assurance_escrow") != -1 && <p><strong>Quality Assured Payment</strong><br />Smelt checks proactively the project preferences and so guarantees quality of deliverables based on them beforethe payment goes through</p>}

																	{payment_options_names.indexOf("flexible") != -1 && <p><strong>Flexible (buy-now pay-later)</strong><br />You only pay in 30-60 days after project delivered or installments every x days</p>}

																</div>
																<div className="investmentBox right">
																	<h3>Investment</h3>
																	<p><span className="price">U$ <strong>{(this.state.proposal?.price ? this.state.proposal?.price : 0) / 100}</strong>.00</span></p>
																</div>

																<div className="clear"></div>
															</>}
														</div>
													</GridListTile>}
												</GridList>
											</Grid>

											{(this.state.is_freelancer || (this.state.is_client && this.state.checkout?.proposal_step_status == "pending_client")) && <div className="actionBar">
												<Button
													variant="contained"
													color="primary"
													size="large"
													fullWidth
													className="primaryButton"
													startIcon={(this.state.isLoadingForm ? <CircularProgress
														size={14}
													/> : (this.state.successForm ? <CheckRounded /> : <ArrowForward />))}
													onClick={this.state.is_client ? this.handleHireProject : (this.state.is_freelancer ? () => { } : () => { })}
												>
													hire project
												</Button>
											</div>}

										</Grid>
									</form>
								</Grid>
							</Grid>
						</Container>
					</main>
				</div>
			</div>
		)
	}
}

export default ViewProposal