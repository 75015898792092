import {
    Button,
    ButtonBase,
    Grid,
    GridList,
    GridListTile,
    IconButton,
    Modal,
    Step,
    StepLabel,
    Stepper,
    Tab,
    Tabs,
    TextField
} from "@material-ui/core"

import {
    ArrowForwardIosOutlined,
    Close,
    Facebook,
    LinkedIn,
    PublicRounded
} from "@material-ui/icons"

import React from "react"
import logo from "../../images/logo-smelt.png"
import { BackendTemplate, BackendUserFull } from "../interfaces/backend"
import { config } from "./../../config"

import CoreAnalytics from './../../utils/core_analytics'

interface State {
    firstName: string
    lastName: string
    email: string

    activeTabIndex: number

    firstNameCheck: boolean
    lastNameCheck: boolean
    emailCheck: boolean

    isOpenModal: boolean
    chosenTemplateId: number
    chosenTemplateName: string

    hasSignedUp: boolean
}

interface Props {
    name: string
    freelancer_email: string
    job_title: string
    job_description: string
    city: string
    state: string
    country: string
    profile_id: string
    calendly?: string
    welcome_message?: string
    profile_image_link: string
    profile_share_links: Record<string, any>[]

    templates: BackendTemplate[]

    is_freelancer: boolean
    is_client: boolean

    replace: (page: string) => void
    is_public_profile: boolean

    showSnackMessage: (message?: string, duration?: number) => void
}

const regexExpressions: Record<string, string | RegExp> = {
    "firstNameCheck": "^([A-Za-z ]{1,32})$",
    "lastNameCheck": "^([A-Za-z ]{1,32})$",
    "emailCheck": /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
}

class FreelancerProfile extends React.Component<Props, State> {
    setStateLikeSync(state: any) {
        return new Promise<void>((resolve) => {
            this.setState(state, resolve)
        });
    }

    constructor(props: Props) {
        super(props)

        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            activeTabIndex: 0,

            firstNameCheck: true,
            lastNameCheck: true,
            emailCheck: true,

            isOpenModal: false,
            chosenTemplateId: -1,
            chosenTemplateName: "",

            hasSignedUp: false
        }
    }

    handleCloseModal = () => {
        this.setState({ isOpenModal: false });
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const key = event.target.name
        const keyCheck = event.target.name + "Check"
        const value = event.target.value
        const valueCheck = (RegExp(regexExpressions[keyCheck])).test(value)

        this.setState({ [key]: value, [keyCheck]: valueCheck } as any)
    }

    handleSignupInstantiate = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()

        await this.setStateLikeSync({
            firstNameCheck: (RegExp(regexExpressions["firstNameCheck"])).test(this.state.firstName),
            lastNameCheck: (RegExp(regexExpressions["lastNameCheck"])).test(this.state.lastName),
            emailCheck: (RegExp(regexExpressions["emailCheck"])).test(this.state.email)
        })

        let allValidationsPass = this.state.firstNameCheck && this.state.lastNameCheck && this.state.emailCheck

        if (allValidationsPass) {
            try {
                const response = await fetch(`${config.API_URL}/signup`, {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
                    body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
                        first_name: this.state.firstName,
                        last_name: this.state.lastName,
                        email: this.state.email,
                        random_password: true,
                        type: "client"
                    })
                })
                if (!response.ok) {
                    // this.props.showSnackMessage(`An error has occured: ${response.status}`)
                }
                const responseJSON = await response.json();
                this.responseSignupResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.user_full, responseJSON.redirect_to_project_id, responseJSON.error)
            } catch (error) {
                this.props.showSnackMessage(`signup-fetch-error: ${error}`)
            }

            try {
                let persistenceToken = localStorage.getItem("persistence_token")
                let template_id = this.state.chosenTemplateId

                const response = await fetch(`${config.API_URL}/instantiate_template`, {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
                    body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
                        persistence_token: persistenceToken,
                        template_id: template_id
                    })
                })
                if (!response.ok) {
                    // this.props.showSnackMessage(`An error has occured: ${response.status}`)
                }
                const responseJSON = await response.json();
                this.responseInstantiateTemplateResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.redirect_to_project_id, responseJSON.error)
            } catch (error) {
                this.props.showSnackMessage(`instantiate-template-fetch-error: ${error}`)
            }
        } else {
            //this.props.showSnackMessage('not all validations passed - fix and try again')
        }
    }

    responseInstantiateTemplateResolve = (response: boolean, persistence_token?: string, redirect_to_project_id?: number, error?: string) => {
        if (response === true) {
            localStorage.setItem("persistence_token", persistence_token as string)

            

            if (this.props.is_freelancer) {
                this.props.replace(`/view_project_briefing?id=${redirect_to_project_id!}`)
            } else if (this.props.is_client || this.state.hasSignedUp) {
                CoreAnalytics.trackProjectCreatedByCustomer(redirect_to_project_id, this.state.chosenTemplateName, this.props.freelancer_email, this.props.name);
                this.props.replace(`/answer_project_briefing?id=${redirect_to_project_id!}`)
            } else {
                this.props.showSnackMessage("user-type-invalid") // should never happen, always one or the other, not both
                return
            }
        } else {
            this.props.showSnackMessage(error) // user with weird token
        }
    }

    responseSignupResolve = (response: boolean, persistence_token?: string, user_full?: BackendUserFull, redirect_to_project_id?: number, error?: string) => {
        if (response === true) {
            this.setState({
                "hasSignedUp": true
            })
            CoreAnalytics.trackSignUp(this.state.email, this.state.email, this.state.firstName, this.state.lastName, undefined, user_full!.is_client, user_full!.is_freelancer)
            localStorage.setItem("persistence_token", persistence_token as string)
        } else {
            this.props.showSnackMessage(error)
        }
    }

    handleChangeTab = (event: React.ChangeEvent<any>, newValue: number) => {
        this.setState({ activeTabIndex: newValue });
        if (newValue == 1) {
            const script = document.createElement("script");

            script.src = "https://assets.calendly.com/assets/external/widget.js";
            script.async = true;

            document.body.appendChild(script);
        }
    }

    handleClickTemplate = async (template_id: number, template_name: string) => {
        if (!this.props.is_public_profile) {
            return
        }

        await this.setStateLikeSync({ chosenTemplateId: template_id, chosenTemplateName: template_name })

        let persistenceToken = localStorage.getItem("persistence_token")
        if (persistenceToken == undefined) {
            this.setState({ isOpenModal: true });
            return
        }

        try {
            const response = await fetch(`${config.API_URL}/instantiate_template`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
                body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
                    persistence_token: persistenceToken,
                    template_id: template_id
                })
            })
            if (!response.ok) {
                // this.props.showSnackMessage(`An error has occured: ${response.status}`)
            }
            const responseJSON = await response.json();
            this.responseInstantiateTemplateResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.redirect_to_project_id, responseJSON.error)
        } catch (error) {
            this.props.showSnackMessage(`instantiate-template-fetch-error: ${error}`)
        }
    }

    render() {
        let freelancerLinksItems = this.props.profile_share_links?.map((profile_share_link) => {
            if (profile_share_link.icon == "Facebook") {
                return (
                    <li><a href={profile_share_link.url} target="_blank"><Facebook /> {profile_share_link.name}</a></li>
                )
            } else if (profile_share_link.icon == "Linkedin") {
                return (
                    <li><a href={profile_share_link.url} target="_blank"><LinkedIn /> {profile_share_link.name}</a></li>
                )
            } else {
                return (
                    <li><a href={profile_share_link.url} target="_blank"><PublicRounded /> {profile_share_link.name}</a></li>
                )
            }
        })

        let templateCards = this.props.templates.map((template) => {
            return (
                <GridListTile cols={1}>
                    <ButtonBase name={`${template.id}`} onClick={() => this.handleClickTemplate(template.id, template.name)}>
                        <div className="left">
                            <label className="projectTitle">{template.name}</label>
                            <div className="clear"></div>
                            <label className="small">{template.description}</label>
                        </div>
                        <ArrowForwardIosOutlined className="right" />
                        <div className="clear"></div>
                    </ButtonBase>
                </GridListTile>
            )
        })

        return (
            <div className="profilePage">
                <div className="header">
                    <div className="container">

                        <div className="steps">
                            <Stepper alternativeLabel={true} activeStep={1}>
                                <Step>
                                    <StepLabel>Find a Professional</StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>Ask a Quote</StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>Explain Project Requirements</StepLabel>
                                </Step>
                                <Step className="desktopOnly">
                                    <StepLabel>Hire Project</StepLabel>
                                </Step>
                                <Step className="desktopOnly">
                                    <StepLabel>Make Payment</StepLabel>
                                </Step>
                                <Step className="desktopOnly">
                                    <StepLabel>Check Delivery</StepLabel>
                                </Step>
                            </Stepper>
                        </div>
                        <div className="clear"></div>
                    </div>
                </div>

                <div className="mainContainer">

                    <div className="clear"></div>
                    <div className="jobsForm left">
                        <h1>Hi! Welcome!</h1>

                        <p>{this.props.welcome_message}</p>

                        <Tabs
                            value={this.state.activeTabIndex}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={this.handleChangeTab}
                        >
                            <Tab label="Get a quote"></Tab>
                            {this.props.calendly && <Tab label="Schedule a call"> </Tab>}
                        </Tabs>


                        {this.state.activeTabIndex == 0 && <div className="content-tab1">
                            <p className="small">{this.props.name} will be in touch with you to deliver a proposal in a few hours.</p>

                            <h3 id="quote">What are you looking for?</h3>

                            <form autoComplete="off">
                                <Grid
                                    container
                                    justify="flex-start"
                                    xs={12}
                                    direction="column"
                                    className="block"
                                    alignItems="stretch"
                                    spacing={1}>
                                    <Grid item xs={12}>
                                        <GridList
                                            cellHeight={80}
                                            className="gridList"
                                            cols={1}
                                            spacing={10}>
                                            {templateCards}
                                        </GridList>
                                    </Grid>
                                </Grid>

                                {this.props.is_public_profile && <Modal open={this.state.isOpenModal}
                                    onClose={this.handleCloseModal} className="modalSignupProfile"><div className="paper">
                                        <h2 className="left">Quote for {this.state.chosenTemplateName}</h2>
                                        <IconButton onClick={this.handleCloseModal} className="right">
                                            <Close />
                                        </IconButton>
                                        <div className="clear"></div>
                                        <p>Please, type your name and e-mail address to receive a free quote. </p>

                                        <TextField
                                            required
                                            size="small"
                                            variant="outlined"
                                            className="nameInput left"
                                            name="firstName"
                                            label="First Name"
                                            onChange={this.handleChange}
                                            error={!this.state.firstNameCheck}
                                            helperText={!this.state.firstNameCheck ? 'Invalid First Name' : ''}
                                        />
                                        <TextField
                                            required
                                            size="small"
                                            variant="outlined"
                                            className="lastNameInput left"
                                            name="lastName"
                                            label="Last Name"
                                            onChange={this.handleChange}
                                            error={!this.state.lastNameCheck}
                                            helperText={!this.state.lastNameCheck ? 'Invalid Last Name' : ''}
                                        />
                                        <TextField
                                            required
                                            size="small"
                                            variant="outlined"
                                            className="emailInput left"
                                            name="email"
                                            label="E-mail address"
                                            onChange={this.handleChange}
                                            error={!this.state.emailCheck}
                                            helperText={!this.state.emailCheck ? 'Invalid E-mail Address' : ''}
                                        />
                                        <Button onClick={this.handleSignupInstantiate} variant="contained" color="primary" className="right primaryButton">
                                            Next
                                        </Button>
                                    </div>
                                </Modal>}

                            </form>
                        </div>}

                        {this.state.activeTabIndex == 1 && this.props.calendly && <div className="content-tab2">
                            {this.props.calendly && <div className="calendly-inline-widget" data-url={this.props.calendly}></div>}
                            {!this.props.calendly && <div className="calendly-inline-widget"><div className="no-calendly">No calendly setup</div></div>} 
                        </div>}

                    </div>
                    <div className="profileDescription right">
                        <img className="picture left" src={this.props.profile_image_link} />
                        <div className="profileLabel left">
                            <h2>{this.props.name}</h2>
                            <h3>{this.props.job_title}</h3>
                            <span className="location">{`${this.props.city}, ${this.props.country}`}</span>
                        </div>
                        <div className="clear"></div>
                        <p>{this.props.job_description}</p>
                        <ul>
                            {freelancerLinksItems}
                        </ul>
                    </div>

                    <div className="paymentOptions">
                        <h3>Quality guaranteed or your money back</h3>
                        <ul>
                            <li>Direct<br /><small>Wire transfer, ACH, International payments</small></li>
                            <li>Credit card<br /><small>Mastercard, Visa, Amex</small></li>
                            <li>Escrow payment<br /><small>Start a money disute if you find that project requirements were not met</small></li>
                            <li>Technical-assurance Escrow<br /><small>Get project technical quality proactively guaranteed by Smelt or your money back</small></li>
                            <li>Flexible (buy-now pay-later)<br /><small>Installments and other payment arrangements analyzed case by case</small></li>
                        </ul>
                    </div>

                    <div className="clear"></div>

                </div>

                <div className="footer">
                    <div className="container">
                        <div className="logo left">
                            <a href="/">
                                <img
                                    src={logo}
                                    width="87"
                                    height="25"
                                />
                            </a>
                        </div>

                        <p className="right">
                            Copyright © Smelt Technologies Inc.<br />
                            2 Belgrave Ave -
                            San Francisco, California</p>


                        <div className="clear"></div>


                    </div>

                </div>
            </div>
        )
    }
}

export default FreelancerProfile