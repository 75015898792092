import React from "react"
import { RouteComponentProps } from "react-router"

import Grid from "@material-ui/core/Grid"
import ArrowForward from "@material-ui/icons/ArrowForward"
import {
	Box,
	TextField,
	Button,
	InputLabel,
	FormControl,
	Select,
	MenuItem,
	FormHelperText,
	CircularProgress,
	Snackbar
} from "@material-ui/core"

import { Alert } from "@material-ui/lab"

import "../styles/general.scss"
import "../styles/unauthenticated.scss"

import logo from "../images/logo-smelt.png"
import { CheckRounded } from "@material-ui/icons"

import CoreAnalytics from "../utils/core_analytics"
import { config } from "./../config"

import { BackendUserFull } from "./interfaces/backend"

interface Params {
	type: string
	validUser: boolean
	invitationToken?: string
}

interface Props extends RouteComponentProps<{}> { }

interface State {
	firstName: string
	lastName: string
	email: string
	password: string
	type: string

	job?: [string?]
	invitationToken?: string

	isLoadingRequest: boolean
	hasSuceededSignup: boolean

	firstNameCheck: boolean
	lastNameCheck: boolean
	emailCheck: boolean
	passwordCheck: boolean
	typeCheck: boolean

	jobCheck: boolean
	invitationTokenCheck?: boolean

	errorForm: boolean
	errorMessage: string
	errorDuration: number
}

const regexExpressions: Record<string, string | RegExp> = {
	"firstNameCheck": "^([A-Za-z ]{1,32})$",
	"lastNameCheck": "^([A-Za-z ]{1,32})$",
	"emailCheck": /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	"passwordCheck": /^.{6,64}$/,
	"typeCheck": "^freelancer$|^client$",
}

class Signup extends React.Component<Props, State> {
	setStateLikeSync(state: any) {
		return new Promise<void>((resolve) => {
			this.setState(state, resolve)
		});
	}

	showSnackMessage(message?: string, duration?: number) {
		if (message == undefined) {
			return
		}

		let actualDuration = duration ? duration : 3000

		this.setState({
			"errorForm": true,
			"errorMessage": message,
			"errorDuration": actualDuration
		})

		setTimeout(() => {
			this.setState({
				"errorForm": false,
				"errorMessage": "",
				"errorDuration": 0
			})
		}, actualDuration)
	}

	constructor(props: Props) {
		super(props)

		this.state = {
			firstName: "",
			lastName: "",
			email: "",
			password: "",
			type: "freelancer",

			job: undefined,
			invitationToken: undefined,

			isLoadingRequest: false,
			hasSuceededSignup: false,

			firstNameCheck: true,
			lastNameCheck: true,
			emailCheck: true,
			passwordCheck: true,
			typeCheck: true,

			jobCheck: true,
			invitationTokenCheck: true,

			errorForm: false,
			errorMessage: "",
			errorDuration: 0
		}
	}

	componentDidMount() {
		document.title = "Sign up - Smelt"

		let params = this.parseUrlParams(this.props.location.search)

		if (params.invitationToken == undefined && params.validUser == false) {
			this.props.history.push('/login');
		}

		this.setState({
			"type": params.type,
			"invitationToken": params.invitationToken,

			"typeCheck": (RegExp(regexExpressions["type"])).test(params.type),
			"invitationTokenCheck": typeof params.invitationToken === "undefined" ? true : true
		})
	}

	parseUrlParams = (query: string): Params => {
		const urlSearchParams = new URLSearchParams(query)

		let params: Params = {
			type: urlSearchParams.get("type") != "client" ? "freelancer" : "client",
			validUser: (urlSearchParams.get("token") == "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9"),
			invitationToken: urlSearchParams.get("invitation_token") === null ? undefined : urlSearchParams.get("invitation_token")!,
		}

		return params
	}

	responseSignupResolve = (response: boolean, persistence_token?: string, user_full?: BackendUserFull, redirect_to_project_id?: number, error?: string) => {
		if (response === true) {
			CoreAnalytics.trackSignUp(this.state.email, this.state.email, this.state.firstName, this.state.lastName, this.state.job, user_full!.is_client, user_full!.is_freelancer)
			this.setState({ hasSuceededSignup: true });
			localStorage.setItem("persistence_token", persistence_token as string)
			var el = this;
			setTimeout(() => {
				if (redirect_to_project_id) {
					if (user_full!.is_freelancer) {
						el.props.history.push(`/view_project_briefing?id=${redirect_to_project_id}`);
					} else if (user_full!.is_client) {
						el.props.history.push(`/answer_project_briefing?id=${redirect_to_project_id}`);
					} else {
						this.showSnackMessage("user-type-invalid") // should never happen, always one or the other, not both
						return
					}
				} else {
					if (user_full!.is_freelancer) {
						el.props.history.push('/edit_freelancer_profile');
					} else if (user_full!.is_client) {
						el.props.history.push('/dashboard');
					} else {
						this.showSnackMessage("user-type-invalid") // should never happen, always one or the other, not both
						return
					}
				}
			}, 500);

		} else {
			this.setState({ hasSuceededSignup: false });
			if (error == 'user-already-exists') {
				this.setState({
					emailCheck: false
				});
			}
			else if (error == 'invitation-token-provided-invalid') {
				this.setState({
					invitationTokenCheck: false
				});
			}
			else if (error == 'invitation-already-concluded') {
				this.setState({
					invitationTokenCheck: false
				});
			}
		}
	}

	handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const key = event.target.name
		const keyCheck = event.target.name + "Check"
		const value = event.target.value
		const valueCheck = (RegExp(regexExpressions[keyCheck])).test(value)

		this.setState({ [key]: value, [keyCheck]: valueCheck } as any)
	}

	handleJobSelect = (event: React.MouseEvent<HTMLLIElement>) => {
		const clickedElement = event.target as HTMLDivElement
		const clickedElementValue = clickedElement.getAttribute("data-value")

		this.setState({ "job": [clickedElementValue], jobCheck: (typeof clickedElementValue === 'string') } as any)
	}

	handleSubmit = async (event: React.MouseEvent<any>) => { // todo: change any to submit type
		event.preventDefault()

		await this.setStateLikeSync({
			firstNameCheck: (RegExp(regexExpressions["firstNameCheck"])).test(this.state.firstName),
			lastNameCheck: (RegExp(regexExpressions["lastNameCheck"])).test(this.state.lastName),
			emailCheck: (RegExp(regexExpressions["emailCheck"])).test(this.state.email),
			passwordCheck: (RegExp(regexExpressions["passwordCheck"])).test(this.state.password),
			typeCheck: (RegExp(regexExpressions["typeCheck"])).test(this.state.type),

			jobCheck: (this.state.type == "client") ? true : (Array.isArray(this.state.job) && (this.state.job).every(item => typeof item === "string")),
			invitationTokenCheck: true // todo: implement actual tokenCheck
		})

		let allValidationsPass = this.state.firstNameCheck && this.state.lastNameCheck && this.state.emailCheck && this.state.passwordCheck && this.state.typeCheck && this.state.jobCheck && this.state.invitationTokenCheck

		if (allValidationsPass) {
			try {
				await this.setStateLikeSync({ isLoadingRequest: true });

				const response = await fetch(`${config.API_URL}/signup`, {
					method: 'post',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
					body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
						first_name: this.state.firstName,
						last_name: this.state.lastName,
						email: this.state.email,
						password: this.state.password,

						type: this.state.type,
						job: this.state.job,
						invitation_token: this.state.invitationToken
					})
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();

				await this.setStateLikeSync({ isLoadingRequest: false });
				this.responseSignupResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.user_full, responseJSON.redirect_to_project_id, responseJSON.error)
			} catch (error) {
				this.setState({ isLoadingRequest: false });
				this.showSnackMessage(`signup-fetch-error: ${error}`)
			}
		} else {
			//this.showSnackMessage('not all validations passed - fix and try again')
		}
	}

	render() {
		return (
			<Grid container className="guestContainer" spacing={0}>
				<Snackbar open={this.state.errorForm} autoHideDuration={this.state.errorDuration}>
					<Alert severity="error">
						{this.state.errorMessage}
					</Alert>
				</Snackbar>

				<Grid item xs={5}>
					<Box className={this.state.type == 'client' ? "mainFormArea invitationArea" : "mainFormArea"}>
						<Grid container justify="center">
							<Grid item xs={12}>
								<div className="logo">
									<a href={"/signup/" + this.state.type}>
										<img
											src={logo}
											width="150"
											height="43"
										/>
									</a>
								</div>
							</Grid>

							<Grid item xs={12}>
								<form autoComplete="off">
									<Grid
										container
										justify="flex-start"
										xs={6}
										direction="column"
										className="block"
										alignItems="stretch"
										spacing={1}>

										<h2>Sign up</h2>


										{/*this.state.type == 'client' 
											<Grid item xs={12}>
												<Paper variant="outlined" className="invitationCard">
													
													<p>
														<strong>Renner Lucena</strong> invited you to the project <u>Crypto blogging with 2 lines in the headline</u>.
													</p>
												</Paper>
											</Grid>
											*/}


										<Grid item xs={12}>
											<Grid
												container
												spacing={2}
												direction="row">
												<Grid item xs={6}>
													<TextField
														required
														name="firstName"
														label="First Name"
														onChange={this.handleChange}
														error={!this.state.firstNameCheck}
														helperText={!this.state.firstNameCheck ? 'Invalid First Name' : ''}
													/>
												</Grid>
												<Grid item xs={6}>
													<TextField
														required
														name="lastName"
														label="Last Name"
														onChange={this.handleChange}
														error={!this.state.lastNameCheck}
														helperText={!this.state.lastNameCheck ? 'Invalid Last Name' : ''}
													/>
												</Grid>
											</Grid>
										</Grid>

										{(this.state.type == "freelancer") &&
											<Grid item xs={12}>
												<FormControl fullWidth>
													<InputLabel className={!this.state.jobCheck ? 'errorLabel' : ''} >
														Main Job
													</InputLabel>

													<Select required>
														<MenuItem onClick={this.handleJobSelect} value={"Writer"}>
															Writer
														</MenuItem>
														<MenuItem onClick={this.handleJobSelect} value={"Designer"}>
															Designer
														</MenuItem>
														<MenuItem onClick={this.handleJobSelect} value={"Web Developer"}>
															Web Developer
														</MenuItem>
														<MenuItem onClick={this.handleJobSelect} value={"Other"}>
															<em>Other</em>
														</MenuItem>

													</Select>
													{!this.state.jobCheck && <FormHelperText className="errorLabel">Invalid Main Job</FormHelperText>} {/* improve this not standard error */}
												</FormControl>
											</Grid>}

										<Grid item xs={12}>
											<TextField
												fullWidth
												required
												label="Email"
												name="email"
												onChange={this.handleChange}
												type="text"
												error={!this.state.emailCheck}
												helperText={!this.state.emailCheck ? 'Invalid Email' : ''}
											/>
										</Grid>

										<Grid item xs={12}>
											<TextField
												fullWidth
												required
												label="Password"
												name="password"
												onChange={this.handleChange}
												type="password"
												error={!this.state.passwordCheck}
												helperText={!this.state.passwordCheck ? 'Invalid Password. Passwords must contain at least 6 characters. A good password should contain a mix of capital and lowercase letters, numbers, and symbols.' : 'Passwords must contain at least 6 characters. A good password should contain a mix of capital and lowercase letters, numbers, and symbols.'}
											/>
										</Grid>

										<Grid item xs={12}>
											<Button
												variant="contained"
												color="primary"
												size="large"
												type="submit"
												fullWidth
												className="primaryButton marginButtonTop"
												startIcon={(this.state.isLoadingRequest ? <CircularProgress
													size={20}
												/> : (this.state.hasSuceededSignup ? <CheckRounded /> : <ArrowForward />))}
												onClick={this.handleSubmit}>
												sign up

											</Button>
										</Grid>

										<Grid item xs={12}>
											<label>
												Already a member?{" "}
												<a
													href="/login"
													className="primaryLink">
													Login
												</a>
											</label>
										</Grid>
									</Grid>
								</form>
							</Grid>
						</Grid>
					</Box>
				</Grid>
				<Grid item xs={7}>
					<Box className="heroArea">
						<Grid container justify="center">
							<Grid item xs={12}>
								<h1>
									Bringing creative workers and companies together.
								</h1>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>
		)
	}
}

export default Signup
