import React from "react"

import {
    Grid,
    GridList,
    GridListTile,
    Container
} from "@material-ui/core"

import {
    Add
} from "@material-ui/icons"

import ProjectCard from './../components/project_card'
import { BackendProjectFull } from "./../interfaces/backend"

interface Props {
    projectsFull?: BackendProjectFull[]
    projectsCurrentState: string
    is_client: boolean
    is_freelancer: boolean

    showSnackMessage: (message?: string, duration?: number) => void
}

interface State {}

class Projects extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
    }

    filter_projects_current_state = (current_state: string, current_state_pattern: string): boolean => {
        if (current_state_pattern == "all") {
            return (current_state != "archived")
        }
        return (current_state === current_state_pattern)
    }

    reload = () => {
        window.location.reload()
    }

    render() {
        let projectsCards = this.props.projectsFull?.filter((projectFull) => this.filter_projects_current_state(projectFull.project.current_state, this.props.projectsCurrentState)).sort((projectFull1, projectFull2) => projectFull1.project.created_at < projectFull2.project.created_at ? 1 : -1).map((projectFull) => {
            var has_answered_first_briefing = undefined
            if (this.props.is_client) {
                let client_user_project_list = projectFull.clients_users_projects.filter((clients_user_project) => clients_user_project.client_project_id === projectFull.client_user_project_id)

                if (client_user_project_list.length == 1) {
                    has_answered_first_briefing = client_user_project_list[0].has_answered_first_briefing
                }
            }

            return (
                <ProjectCard
                    id={projectFull.project.id}
                    name={projectFull.project.name}
                    type_template={projectFull.project.type_template}
                    description={projectFull.project.description}
                    initials_members={projectFull.freelancers_users_projects.map((freelancer_user) => { return String(freelancer_user!.first_name).charAt(0) }).concat(projectFull.clients_users_projects.map((client_user) => { return String(client_user!.first_name).charAt(0) }))}
                    current_state={projectFull.project.current_state}
                    has_preferences_groups={projectFull.preferences_groups.length > 0}
                    has_archive_permission={projectFull.is_freelancer_creator}
                    is_client={this.props.is_client}
                    has_answered_first_briefing={has_answered_first_briefing}
                    created_at={projectFull.project.created_at}
                    reload={this.reload}

                    showSnackMessage={this.props.showSnackMessage}
                />
            )
        })

        return (
            <main className="drawerContent">
                <Container maxWidth="lg">
                    <Grid container>
                        <Grid item xs={9}>
                            <h1>Your projects</h1>
                        </Grid>
                        {this.props.is_freelancer && <Grid item xs={3}>
                            <a
                                href="/create_project_basics"
                                className="primaryLink contained right">
                                onboard a new customer
                            </a>
                        </Grid>}

                        <Grid item xs={12}>
                            <GridList
                                cellHeight={160}
                                className="gridList projectList"
                                cols={1}
                                spacing={10}>
                                {projectsCards}
                                {this.props.projectsFull?.filter((projectFull) => this.filter_projects_current_state(projectFull.project.current_state, this.props.projectsCurrentState)).length == 0 &&

                                    <GridListTile cols={1} className="emptyProjectRow">
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <h4>No customers yet?<br />
                                                    <strong>Share your profile to get customers and they will be listed here as soon as they fill out a quote form. Or click on the button above to onboard your customers by inviting them manually!</strong>
                                                </h4>
                                            </Grid>
                                        </Grid>
                                    </GridListTile>
                                }
                            </GridList>

                            {this.props.is_freelancer && <div className="outlinedButton">
                                <a href="/create_project_basics">
                                    <Add /> <span>onboard a new customer</span>
                                </a>
                            </div>}
                        </Grid>
                    </Grid>
                </Container>
            </main>
        )
    }
}

export default Projects