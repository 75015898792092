import React from "react"
import { RouteComponentProps } from "react-router"

import {
    ButtonBase,
    Avatar,
    Chip,
    Drawer,
    ClickAwayListener,
    Grow,
    Paper,
    Popper,
    MenuItem,
    MenuList,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    TextField,
    Button,
    Snackbar
} from "@material-ui/core"

import {
    Settings,
    ListRounded,
    EditRounded,
    ArchiveRounded,
    DoneRounded,
    OpenInNew
} from "@material-ui/icons"

import { Alert } from "@material-ui/lab"

import Projects from './components/projects'

import logo from "../images/logo-smelt.png"

import CoreAnalytics from '../utils/core_analytics'
import { config } from "./../config"

import { BackendUserFull, BackendFreelancerProfile, BackendTemplate, BackendProjectFull } from "./interfaces/backend"

interface Props extends RouteComponentProps<{}> { }

interface State {
    is_freelancer: boolean
    is_client: boolean
    firstName: string
    lastName: string
    pictureS3Link: string
    email: string
    initial: string
    projectsCurrentState: string

    freelancerName?: string
    freelancerEmail?: string
    freelancerProfile?: BackendFreelancerProfile
    templates: BackendTemplate[]
    projectsFull?: BackendProjectFull[]

    openLogout: boolean
    anchorRef: React.RefObject<HTMLButtonElement>

    copySuccess: boolean

    errorForm: boolean
    errorMessage: string
    errorDuration: number
}

// const regexExpressions: Record<string, string> = {}

class Dashboard extends React.Component<Props, State> {
    setStateLikeSync(state: any) {
        return new Promise<void>((resolve) => {
            this.setState(state, resolve)
        });
    }

    showSnackMessage(message?: string, duration?: number) {
        if (message == undefined) {
            return
        }

        let actualDuration = duration ? duration : 3000

        this.setState({
            "errorForm": true,
            "errorMessage": message,
            "errorDuration": actualDuration
        })

        setTimeout(() => {
            this.setState({
                "errorForm": false,
                "errorMessage": "",
                "errorDuration": 0
            })
        }, actualDuration)
    }

    constructor(props: Props) {
        super(props)

        this.state = {
            is_freelancer: false,
            is_client: false,
            firstName: "",
            lastName: "",
            pictureS3Link: "",
            email: "",
            initial: "",
            projectsCurrentState: "all",

            freelancerName: undefined,
            freelancerEmail: undefined,
            freelancerProfile: undefined,
            templates: [],
            projectsFull: undefined,

            openLogout: false,
            anchorRef: React.createRef(),

            copySuccess: false,

            errorForm: false,
            errorMessage: "",
            errorDuration: 0
        }
    }

    async componentDidMount() {
        let persistenceToken = localStorage.getItem("persistence_token")
        if (persistenceToken === null) {
            this.props.history.replace("/login")
            return
        }

        document.title = "Dashboard - Smelt"

        try {
            const response = await fetch(`${config.API_URL}/read_user?persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
                method: 'get',
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
            })
            if (!response.ok) {
                // this.showSnackMessage(`An error has occured: ${response.status}`)
            }
            const responseJSON = await response.json();
            let isDone = this.responseReadUserResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.user_full, responseJSON.error)
            if (isDone) {
                return
            }
        } catch (error) {
            this.showSnackMessage(`read-user-fetch-error: ${error}`)
        }

        if (this.state.is_freelancer) {
            try {
                const response = await fetch(`${config.API_URL}/read_freelancer_profile?persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
                    method: 'get',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
                })
                if (!response.ok) {
                    // this.showSnackMessage(`An error has occured: ${response.status}`)
                }
                const responseJSON = await response.json();
                this.responseReadFreelancerProfileResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.freelancer_name, responseJSON.freelancer_name, responseJSON.freelancer_profile, responseJSON.error)
            } catch (error) {
                this.showSnackMessage(`read-freelancer-profile-fetch-error: ${error}`)
            }

            try {
                const response = await fetch(`${config.API_URL}/list_templates?persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
                    method: 'get',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
                })
                if (!response.ok) {
                    // this.showSnackMessage(`An error has occured: ${response.status}`)
                }
                const responseJSON = await response.json();
                this.responseListTemplates(responseJSON.response, responseJSON.templates)
            } catch (error) {
                this.showSnackMessage(`list-templates-fetch-error: ${error}`)
            }
        }

        try {
            const response = await fetch(`${config.API_URL}/list_projects?persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
                method: 'get',
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
            })
            if (!response.ok) {
                // this.showSnackMessage(`An error has occured: ${response.status}`)
            }
            const responseJSON = await response.json();
            this.responseListProjectsResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.projects_full, responseJSON.error)
        } catch (error) {
            this.showSnackMessage(`list-projects-fetch-error: ${error}`)
        }
    }

    responseReadUserResolve = (response: boolean, persistence_token?: string, user_full?: BackendUserFull, error?: string): boolean => {
        if (response === true) {
            this.setState({
                "is_freelancer": user_full!.is_freelancer,
                "is_client": user_full!.is_client,
                "firstName": user_full!.first_name,
                "lastName": user_full!.last_name,
                "pictureS3Link": user_full!.picture_s3_link,
                "email": user_full!.email,
                "initial": String(user_full!.first_name).charAt(0)
            })

            CoreAnalytics.trackUser(user_full!.email, user_full!.email, user_full!.first_name, user_full!.last_name, user_full!.is_client, user_full!.is_freelancer);
            localStorage.setItem("persistence_token", persistence_token as string)
        } else {
            localStorage.removeItem("persistence_token")
            this.props.history.replace("/login")
            return true
        }
        return false
    }

    responseReadFreelancerProfileResolve = (response: boolean, persistence_token?: string, freelancer_name?: string, freelancer_email?: string, freelancer_profile?: BackendFreelancerProfile, error?: string): boolean => {
        if (response === true) {
            this.setState({
                "freelancerName": freelancer_name!,
                "freelancerEmail": freelancer_email!,
                "freelancerProfile": freelancer_profile!
            })

            // CoreAnalytics.trackUser();
            localStorage.setItem("persistence_token", persistence_token as string)
        } else {
            localStorage.removeItem("persistence_token")
            this.props.history.replace("/login")
            return true
        }
        return false
    }

    responseListTemplates = (response: boolean, templates?: BackendTemplate[], error?: string) => {
        if (response === true) {
            this.setState({
                "templates": templates!
            })
        } else {
            this.showSnackMessage(error) // user with token not loaded
        }
    }

    responseListProjectsResolve = (response: boolean, persistence_token?: string, projects_full?: BackendProjectFull[], error?: string): void => {
        if (response === true) {
            this.setState({
                "projectsFull": projects_full as BackendProjectFull[]
            })
            localStorage.setItem("persistence_token", persistence_token as string)
        } else {
            this.showSnackMessage(error) // should never happen if user was correctly loaded, could not load projects to set projectsFull - maybe log user out too
        }
    }

    logout = () => {
        localStorage.removeItem("persistence_token")
        this.props.history.push("/login")
    }

    filter_projects_current_state = (current_state: string, current_state_pattern: string): boolean => {
        if (current_state_pattern == "all") {
            return (current_state != "archived")
        }
        return (current_state === current_state_pattern)
    }

    handleToggle = async () => {
        let newOpenLogout = !this.state.openLogout
        await this.setStateLikeSync({ "openLogout": newOpenLogout })

        if (this.state.anchorRef.current !== null) {
            if (this.state.openLogout === false) {
                this.state.anchorRef.current.focus()
            }
        }
    }

    handleClose = (event: React.MouseEvent<Document | HTMLLIElement>) => {
        if (null !== this.state.anchorRef.current) {
            if (this.state.anchorRef.current && this.state.anchorRef.current.contains(event.target as Node)) {
                return
            }
        }

        this.setState(({ openLogout: false }))
    }

    handleListKeyDown(event: React.KeyboardEvent<HTMLUListElement>) {
        if (event.key === "Tab") {
            event.preventDefault()
            this.setState(({ openLogout: false }))
        }
    }

    handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const clickedElement = event.target as HTMLDivElement
        if (clickedElement.textContent != null) {
            const clickedElementValue = clickedElement.textContent.replace(/[0-9]/g, '')
            this.setState({ "projectsCurrentState": clickedElementValue } as any)
        }
    }

    handleCopyLinkClick = (event: React.MouseEvent<Document | HTMLLIElement | HTMLButtonElement>) => {
        if (this.state.freelancerProfile?.profile_id) {
            navigator.clipboard.writeText(`${config.APP_URL}/profile/${this.state.freelancerProfile?.profile_id}`)
            this.setState({ copySuccess: true })
            setTimeout(() => {
                this.setState({ copySuccess: false })
            }, 3000)
        }
    }

    replace = (page: string) => {
        this.props.history.replace(page)
    }

    render() {
        return (
            <div className="mainStyle">
                <Snackbar open={this.state.errorForm} autoHideDuration={this.state.errorDuration}>
                    <Alert severity="error">
                        {this.state.errorMessage}
                    </Alert>
                </Snackbar>
                
                <div className="drawerRoot">
                    <Drawer
                        className="drawerDrawer"
                        variant="permanent"
                        classes={{
                            paper: "drawerDrawerPaper"
                        }}
                        anchor="left">
                        <div className="drawerInternalContent">
                            <div className="logo">
                                <a href="/dashboard">
                                    <img src={logo} width="115" height="32" />
                                </a>
                            </div>
                            <div className="drawerToolbar" />

                            <List component="nav" className="menu">
                                <ListItem button className="headerMenu">
                                    <ListItemText primary="Projects" />
                                </ListItem>

                                <List component="div" disablePadding>
                                    <ListItem button className={`nested ${this.state.projectsCurrentState === "all" ? "selected" : ""}`} onClick={this.handleClick}>
                                        <ListItemIcon>
                                            <ListRounded />
                                        </ListItemIcon>
                                        <ListItemText primary="all" />
                                        <Chip color="primary" size="small" label={this.state.projectsFull?.length} />
                                    </ListItem>
                                    <ListItem button className={`nested ${this.state.projectsCurrentState === "draft" ? "selected" : ""}`} onClick={this.handleClick}>
                                        <ListItemIcon>
                                            <EditRounded />
                                        </ListItemIcon>
                                        <ListItemText primary="draft" />
                                        <Chip color="primary" size="small" label={this.state.projectsFull?.filter(projectFull => projectFull.project.current_state === "draft").length} />
                                    </ListItem>
                                    <ListItem button className={`nested ${this.state.projectsCurrentState === "published" ? "selected" : ""}`} onClick={this.handleClick}>
                                        <ListItemIcon>
                                            <DoneRounded />
                                        </ListItemIcon>
                                        <ListItemText primary="published" />
                                        <Chip color="primary" size="small" label={this.state.projectsFull?.filter(projectFull => projectFull.project.current_state === "published").length} />
                                    </ListItem>
                                    <ListItem button className={`nested ${this.state.projectsCurrentState === "archived" ? "selected" : ""}`} onClick={this.handleClick}>
                                        <ListItemIcon>
                                            <ArchiveRounded />
                                        </ListItemIcon>
                                        <ListItemText primary="archived" />
                                        <Chip color="primary" size="small" label={this.state.projectsFull?.filter(projectFull => projectFull.project.current_state === "archived").length} />
                                    </ListItem>
                                </List>
                            </List>


                            {this.state.is_freelancer && <div className="shareProfileBox">
                                <h2>Do you want to get more customers?</h2>

                                <p>Add your public profile to your favorite social media profile description, website and more.</p>

                                <TextField
                                    id="public-profile-link"
                                    label="Your profile link"
                                    value={`${config.APP_URL}/profile/${this.state.freelancerProfile?.profile_id}`}
                                    variant="outlined"

                                    disabled
                                    InputProps={{ endAdornment: <Button onClick={this.handleCopyLinkClick}>Copy</Button> }}
                                />

                                <Snackbar open={this.state.copySuccess} autoHideDuration={3000}>
                                    <Alert severity="success">
                                        Successfully copied to clipboard
                                    </Alert>
                                </Snackbar>

                                <a
                                    href={`${config.APP_URL}/profile/${this.state.freelancerProfile?.profile_id}`} target="_blank"
                                    className="primaryLink contained">
                                    preview profile <OpenInNew />
                                </a>


                            </div>}
                        </div>

                        <div className="userProfile">
                            <ButtonBase
                                ref={this.state.anchorRef}
                                focusRipple
                                focusVisibleClassName="focusclass"
                                aria-controls={this.state.openLogout ? "menu-list-grow" : undefined}
                                aria-haspopup="true"
                                onClick={this.handleToggle}>
                                <Avatar alt="user">{this.state.initial}</Avatar>
                                <div>
                                    <span className="name">{this.state.firstName} {this.state.lastName}</span>
                                    <span className="email">
                                        {this.state.email}
                                    </span>
                                </div>

                                <Settings className="icon" />
                            </ButtonBase>
                            <Popper
                                open={this.state.openLogout}
                                anchorEl={this.state.anchorRef.current}
                                role={undefined}
                                transition
                                disablePortal>
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin:
                                                placement === "bottom"
                                                    ? "center top"
                                                    : "center bottom"
                                        }}>
                                        <Paper className="userProfileMenu">
                                            <ClickAwayListener
                                                onClickAway={this.handleClose}>
                                                <MenuList
                                                    autoFocusItem={this.state.openLogout}
                                                    id="menu-list-grow"
                                                    onKeyDown={this.handleListKeyDown}>
                                                    {this.state.is_freelancer && <MenuItem><a href="/dashboard?product_tour_id=244374">Product tour</a></MenuItem>}
                                                    {this.state.is_freelancer && <MenuItem><a href="/edit_freelancer_profile">Edit profile</a></MenuItem>}

                                                    <MenuItem onClick={this.logout}>
                                                        Logout
                                                    </MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </div>
                    </Drawer>

                    <Projects
                        projectsFull={this.state.projectsFull}
                        projectsCurrentState={this.state.projectsCurrentState}
                        is_client={this.state.is_client}
                        is_freelancer={this.state.is_freelancer}

                        showSnackMessage={this.showSnackMessage}
                    />

                </div>
            </div>
        )
    }
}

export default Dashboard