import React from "react"

import {
    IconButton,
    ListItem,
    ListItemText,
    Paper,
    Popper,
    ClickAwayListener,
    MenuItem,
    MenuList,
    TextField,
    Grow
} from "@material-ui/core"

import {
    MoreHoriz,
    Done,
    DeleteRounded
} from "@material-ui/icons"

interface Props {
    order: number
    name: string

    is_saved: boolean
    is_selected: boolean

    name_check: boolean

    updateSection: (order: number, name?: string, is_saved?: boolean, is_deleted?: boolean, inputs_params?: Record<string, any>[], is_selected?: boolean) => void
}

interface State {
    openMore: boolean
    anchorRef: React.RefObject<HTMLButtonElement>
}

class SectionMenuCard extends React.Component<Props, State> {
    setStateLikeSync(state: any) {
        return new Promise<void>((resolve) => {
            this.setState(state, resolve)
        });
    }

    constructor(props: Props) {
        super(props)

        this.state = {
            openMore: false,
            anchorRef: React.createRef()
        }
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.updateSection(this.props.order, event.target.value, undefined, undefined, undefined)
    }

    handleSave = () => {
        this.props.updateSection(this.props.order, undefined, true, undefined, undefined, undefined)
    }

    handleCancel = () => {
        this.props.updateSection(this.props.order, undefined, undefined, true, undefined, undefined)
    }

    handleDelete = (event: React.MouseEvent<HTMLLIElement>) => {
        this.props.updateSection(this.props.order, undefined, undefined, true, undefined, undefined)
    }

    handleSelect = (event: React.MouseEvent<HTMLDivElement>) => {
        this.props.updateSection(this.props.order, undefined, undefined, undefined, undefined, true)
    }

    handleToggle = async () => {
        let newOpenMore = !this.state.openMore
        await this.setStateLikeSync({ "openMore": newOpenMore })

        if (this.state.anchorRef.current !== null) {
            if (this.state.openMore === false) {
                this.state.anchorRef.current.focus()
            }
        }
    }

    handleClose = (event: React.MouseEvent<Document | HTMLLIElement>) => {
        if (null !== this.state.anchorRef.current) {
            if (this.state.anchorRef.current && this.state.anchorRef.current.contains(event.target as Node)) {
                return
            }
        }

        this.setState(({ openMore: false }))
    }

    handleListKeyDown(event: React.KeyboardEvent<HTMLUListElement>) {
        if (event.key === "Tab") {
            event.preventDefault()
            this.setState(({ openMore: false }))
        }
    }

    render() {
        // 
        return (
            <div>
                {this.props.is_saved &&
                    <ListItem button onClick={this.handleSelect} className={this.props.is_selected ? "sectionCurrentMenu" : ""}>
                        <ListItemText primary={this.props.name} />
                        {this.props.is_selected &&
                            <IconButton ref={this.state.anchorRef} onClick={this.handleToggle} className="right">
                                <MoreHoriz />
                            </IconButton>}
                        <Popper
                            open={this.state.openMore}
                            anchorEl={this.state.anchorRef.current}
                            role={undefined}
                            transition
                            disablePortal>
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === "bottom"
                                                ? "center top"
                                                : "center bottom"
                                    }}>
                                    <Paper className="userProfileMenu">
                                        <ClickAwayListener
                                            onClickAway={this.handleClose}>
                                            <MenuList
                                                autoFocusItem={this.state.openMore}
                                                id="menu-list-grow"
                                                onKeyDown={this.handleListKeyDown}>
                                                <MenuItem onClick={this.handleDelete}>
                                                    Delete
                                                </MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </ListItem>
                }
                {!this.props.is_saved &&
                    <ListItem>
                        <TextField
                            className="noOutline"
                            label="New section"
                            onChange={this.handleChange}
                            type="text"
                            error={!this.props.name_check}
                            helperText={!this.props.name_check ? 'Invalid Section Name' : ''}
                        />
                        <IconButton onClick={this.handleSave} className="right">
                            <Done />
                        </IconButton>
                        <IconButton onClick={this.handleCancel} className="right">
                            <DeleteRounded />
                        </IconButton>
                    </ListItem>
                }
                <div className="clear"></div>
            </div>
        )
    }
}

export default SectionMenuCard