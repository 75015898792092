import React from "react"
import { RouteComponentProps } from "react-router"

import {
    Drawer,
    Container,
    Grid,
    GridList,
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Snackbar
} from "@material-ui/core"

import {
    AddRounded,
    Add,
    ArrowBackRounded,
    SaveAltRounded
} from "@material-ui/icons"

import { Alert } from "@material-ui/lab"

import SectionMenuCard from './components/section_menu_card'
import InputCard from './components/input_card'
import logo from "../images/logo-smelt.png"

import CoreAnalytics from "../utils/core_analytics"
import { config } from "./../config"

import { BackendProjectFull, BackendPreferenceGroupFull } from "./interfaces/backend"
import { FrontendSection, FrontendInput, FrontendInputExtra } from "./interfaces/frontend"

const regexExpressions: Record<string, string> = {
    sectionNameCheck: "^(.{0,32})$",
    inputTitleCheck: "^((.|\n){0,255})$",
    inputTypeCheck: "^question$|^checkbox$|^radio$|^ask_feedback$|^ask_example$|^multiline$"
}

interface Params {
    id?: number
}

interface Props extends RouteComponentProps<{}> { }

interface State {
    project_id: number
    project_name: string
    preference_group_id: number
    freelancer_project_id: number
    sections: FrontendSection[]
    inputs: FrontendInput[]
    inputs_extras: FrontendInputExtra[]
    section_selected_order: number
    section_selected_name: string

    errorForm: boolean
    errorMessage: string
    errorDuration: number
}

class EditProjectBriefing extends React.Component<Props, State> {
    setStateLikeSync(state: any) {
        return new Promise<void>((resolve) => {
            this.setState(state, resolve)
        });
    }

    showSnackMessage(message?: string, duration?: number) {
        if (message == undefined) {
            return
        }

        let actualDuration = duration ? duration : 3000

        this.setState({
            "errorForm": true,
            "errorMessage": message,
            "errorDuration": actualDuration
        })

        setTimeout(() => {
            this.setState({
                "errorForm": false,
                "errorMessage": "",
                "errorDuration": 0
            })
        }, actualDuration)
    }

    constructor(props: Props) {
        super(props)

        this.state = {
            project_id: -1,
            project_name: "",
            preference_group_id: -1,
            freelancer_project_id: -1,
            sections: [],
            inputs: [],
            inputs_extras: [],
            section_selected_order: 1,
            section_selected_name: "",

            errorForm: false,
            errorMessage: "",
            errorDuration: 0
        }
    }

    async componentDidMount() {
        let persistenceToken = localStorage.getItem("persistence_token")
        if (persistenceToken === null) {
            this.props.history.replace("/login")
            return
        }

        let params = this.parseUrlParams(this.props.location.search)
        if (params.id) {
            try {
                const response = await fetch(`${config.API_URL}/read_project?project_id=${encodeURIComponent(params.id!)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
                    method: 'get',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
                })
                if (!response.ok) {
                    // this.showSnackMessage(`An error has occured: ${response.status}`)
                }
                const responseJSON = await response.json();
                this.responseReadProjectResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.project_full, responseJSON.error)
            } catch (error) {
                this.showSnackMessage(`read-project-fetch-error: ${error}`)
            }

            try {
                let preference_group_id = this.state.preference_group_id
                let persistenceToken = localStorage.getItem("persistence_token")

                const response = await fetch(`${config.API_URL}/read_preference_group?preference_group_id=${encodeURIComponent(preference_group_id)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
                    method: 'get',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
                })
                if (!response.ok) {
                    // this.showSnackMessage(`An error has occured: ${response.status}`)
                }

                const responseJSON = await response.json();
                console.log(responseJSON);
                this.responseReadPreferenceGroupResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.preference_group_full, responseJSON.error)
            } catch (error) {
                console.log(error);
                this.showSnackMessage(`read-preference-group-fetch-error: ${error}`)
            }
        }
    }

    parseUrlParams = (query: string): Params => {
        const urlSearchParams = new URLSearchParams(query)

        let params: Params = {
            id: urlSearchParams.get("id") === null ? undefined : Number(urlSearchParams.get("id")!),
        }
        return params
    }

    responseReadProjectResolve = (response: boolean, persistence_token?: string, project_full?: BackendProjectFull, error?: string): void => {
        if (response === true) {
            if (!project_full!.is_freelancer_creator) {
                this.showSnackMessage("Not project creator")
                this.props.history.replace("/dashboard")
                return
            }

            if (project_full!.project.current_state !== "draft" || project_full!.preferences_groups.length != 1) {
                this.showSnackMessage("Project has 0 or more than 1 (not possible) preference group")
                this.props.history.replace("/dashboard")
                return
            }

            this.setState({
                "project_id": project_full!.project.id,
                "project_name": project_full!.project.name,
                "preference_group_id": project_full!.preferences_groups[0].id
            })

            document.title = this.state.project_name + " - Smelt"
            localStorage.setItem("persistence_token", persistence_token as string)


        } else {
            this.showSnackMessage(error) // likely not freelancer creator or is client
            this.props.history.replace("/dashboard")
        }
    }

    responseReadPreferenceGroupResolve = (response: boolean, persistence_token?: string, preference_group_full?: BackendPreferenceGroupFull, error?: string): void => {
        if (response === true) {
            if (!preference_group_full!.is_freelancer_creator) {
                this.showSnackMessage("Not preference group creator - should never happen if project creator is valid, since they are the same on MVP")
                this.props.history.replace("/dashboard")
                return
            }

            if (preference_group_full!.preference_group.current_state !== "draft") {
                this.showSnackMessage("Preference group not in draft anymore somehow - should never happen and always match the project one in draft")
                this.props.history.replace("/dashboard")
                return
            }


            let sectionsParsed = preference_group_full!.sections.map((section) => ({ "id": section.id, "preference_group_id": section.preference_group_id, "name": section.name, "order": section.order, "name_check": true, "is_saved": true, "is_deleted": false, "is_added": false, "inputs_params": [] }))

            let inputsParsed = preference_group_full!.inputs.map((input) => ({ "id": input.id, "section_id": input.section_id, "section_order": sectionsParsed.filter((section) => section.id == input.section_id)[0].order, "freelancer_project_id": input.freelancer_project_id, "order": input.order, "type": input.type, "is_required": input.is_required, "title": input.title, "thumbnail": JSON.parse(((input.thumbnail ? input.thumbnail : "{}")).replace(/'/g, '"')), "input_extra_id": input.input_extra_id, "title_check": true, "type_check": true, "is_saved": true, "is_deleted": false, "is_added": false, "input_extra_params": undefined }))

            let inputsExtras = preference_group_full!.inputs_extras.map((input_extra) => ({ "id": input_extra.id, "section_order": inputsParsed.filter((input) => input.input_extra_id == input_extra.id)[0].section_order, "input_order": preference_group_full!.inputs.filter((input) => input.input_extra_id == input_extra.id)[0].order, "quote": input_extra.quote, "file_s3_link": input_extra.file_s3_link, "url_link": input_extra.url_link, "specific_part": input_extra.specific_part, "relevance_reason": input_extra.relevance_reason, "options": (input_extra.options ? input_extra.options : []).map(option => JSON.parse(option.replace(/'/g, '"'))), "quote_check": true, "file_s3_link_check": true, "url_link_check": true, "specific_part_check": true, "relevance_reason_check": true, "options_check": true }));

            localStorage.setItem("persistence_token", persistence_token as string)
            this.setState({
                "section_selected_name": preference_group_full!.sections.filter((section) => section.order == this.state.section_selected_order)[0].name,
                "preference_group_id": preference_group_full!.preference_group.id,
                "freelancer_project_id": preference_group_full!.preference_group.creator_freelancer_project_id,
                "sections": sectionsParsed,
                "inputs": inputsParsed,
                "inputs_extras": inputsExtras
            })

        } else {
            this.showSnackMessage(error) // should never happen if project has been successfully read - send back to dashboard if fails reading preference group
            this.props.history.replace("/dashboard")
        }
    }

    responseSavePreferenceGroupResolve = (response: boolean, persistence_token?: string, error?: string): void => {
        if (response === true) {
            localStorage.setItem("persistence_token", persistence_token as string)
            window.location.reload()
        } else {
            this.showSnackMessage(error) // some regex problem?
        }
    }

    responseSavePreferenceGroupResolvePublish = (response: boolean, persistence_token?: string, error?: string): void => {
        if (response === true) {
            localStorage.setItem("persistence_token", persistence_token as string)
            this.props.history.push(`/view_project_briefing?id=${this.state.project_id.toString()}`)
        } else {
            this.showSnackMessage(error) // some regex problem?
        }
    }

    updateSection = (order: number, name?: string, is_saved?: boolean, is_deleted?: boolean, inputs_params?: Record<string, any>[], is_selected?: boolean) => {
        var project_id = this.state.project_id;

        this.setState(previousState => {
            var incrementingOrder = 0
            var newSectionSelectedOrder: number | undefined = undefined

            let newSectionsWithOrders = previousState.sections.sort((section1, section2) => {
                if (section1.order === undefined) {
                    return 1
                }
                if (section2.order === undefined) {
                    return -1
                }

                return section1.order! > section2.order! ? 1 : -1
            }).map((section) => {
                var newSection = section
                if (section.is_deleted) {
                    return { "new_section": newSection, "old_section_order": undefined, "new_section_order": undefined }
                }

                if (order === section.order) {
                    if (name !== undefined) {
                        newSection.name = name
                        newSection.name_check = (RegExp(regexExpressions["sectionNameCheck"])).test(name)
                    }
                    if (is_saved !== undefined) {
                        CoreAnalytics.trackProjectCreateSection(project_id, newSection.name);
                        newSection.is_saved = is_saved
                    }
                    if (is_deleted !== undefined) {
                        if (order === this.state.section_selected_order) {
                            newSectionSelectedOrder = 1
                        }
                        newSection.is_deleted = is_deleted
                        newSection.order = undefined
                        CoreAnalytics.trackProjectDeleteSection(project_id, newSection.name);
                        return { "new_section": newSection, "old_section_order": order, "new_section_order": undefined }
                    }
                    if (inputs_params !== undefined) {
                        newSection.inputs_params = inputs_params
                    }
                    if (is_selected !== undefined) {
                        newSectionSelectedOrder = incrementingOrder + 1
                    }
                }

                incrementingOrder += 1
                newSection.order = incrementingOrder

                return { "new_section": newSection, "old_section_order": section.order, "new_section_order": newSection.order }
            })

            newSectionsWithOrders.forEach((newSectionWithOrders) => {
                let new_section_order = newSectionWithOrders.new_section_order
                let old_section_order = newSectionWithOrders.old_section_order

                if (new_section_order !== old_section_order) {
                    this.setState(previousState2 => {
                        let inputs = previousState2.inputs.map((input) => {
                            var newInput = input
                            if (input.section_order == old_section_order) {
                                newInput.section_order = new_section_order
                            }
                            return newInput
                        })

                        return { "inputs": inputs }
                    })

                    this.setState(previousState2 => {
                        let inputs_extras = previousState2.inputs_extras.map((input_extras) => {
                            var newInputExtras = input_extras
                            if (input_extras.section_order == old_section_order) {
                                newInputExtras.section_order = new_section_order
                            }
                            return newInputExtras
                        })

                        return { "inputs_extras": inputs_extras }
                    })
                }
            })
            let newSections = newSectionsWithOrders.map((newSectionWithOrders) => newSectionWithOrders.new_section)
            return { "sections": newSections, "section_selected_order": newSectionSelectedOrder ? newSectionSelectedOrder! : previousState.section_selected_order, "section_selected_name": newSectionSelectedOrder ? newSections.filter((section) => section.order == newSectionSelectedOrder!)[0].name : previousState.section_selected_name }
        })
    }

    handleAddSection = (event: React.MouseEvent<HTMLDivElement>) => { //todo: check regex

        this.setState(previousState => {
            return { "sections": previousState.sections.concat({ "id": undefined, "preference_group_id": this.state.preference_group_id, "name": "", "order": previousState.sections.filter((section) => !section.is_deleted).length + 1, "name_check": true, "is_saved": false, "is_deleted": false, "is_added": true, "inputs_params": [] }) }
        })
    }

    // later allow to update thumbnail
    updateInput = (order: number, title?: string, type?: string, is_required?: boolean, is_saved?: boolean, is_deleted?: boolean, input_extra_params?: Record<string, any>) => {
        var project_id = this.state.project_id;

        this.setState(previousState => {
            var incrementingOrder = 0

            let newInputsWithOrders = previousState.inputs.sort((input1, input2) => {
                if (input1.order === undefined) {
                    return 1
                }
                if (input2.order === undefined) {
                    return -1
                }

                return input1.order! > input2.order! ? 1 : -1
            }).map((input) => {
                var newInput = input
                if (input.is_deleted) {
                    return { "new_input": newInput, "old_input_order": undefined, "new_input_order": undefined }
                }

                if (input.section_order === this.state.section_selected_order) {
                    if (order === input.order) {
                        if (title !== undefined) {
                            newInput.title = title
                            newInput.title_check = (RegExp(regexExpressions["inputTitleCheck"])).test(title)
                        }
                        if (type !== undefined) {
                            newInput.type = type
                            newInput.type_check = (RegExp(regexExpressions["inputTypeCheck"])).test(type)
                        }
                        if (is_required !== undefined) {
                            newInput.is_required = is_required
                        }
                        if (is_saved !== undefined) {
                            newInput.is_saved = is_saved
                            CoreAnalytics.trackProjectCreateInput(project_id, newInput.type, newInput.title);
                        }
                        if (is_deleted !== undefined) {
                            newInput.is_deleted = is_deleted
                            newInput.order = undefined
                            CoreAnalytics.trackProjectDeleteInput(project_id, newInput.title);

                            return { "new_input": newInput, "old_input_order": order, "new_input_order": undefined }
                        }
                        if (input_extra_params !== undefined) {
                            newInput.input_extra_params = input_extra_params
                        }
                    }

                    incrementingOrder += 1
                    newInput.order = incrementingOrder
                }

                return { "new_input": newInput, "old_input_order": input.order, "new_input_order": newInput.order }
            })

            newInputsWithOrders.forEach((newInputsWithOrders) => {
                let new_input_order = newInputsWithOrders.new_input_order
                let old_input_order = newInputsWithOrders.old_input_order

                if (new_input_order !== old_input_order) {
                    this.setState(previousState2 => {
                        let inputs_extras = previousState2.inputs_extras.map((input_extras) => {
                            var newInputExtras = input_extras
                            if (input_extras.input_order == old_input_order) {
                                newInputExtras.input_order = new_input_order
                            }
                            return newInputExtras
                        })

                        return { "inputs_extras": inputs_extras }
                    })
                }
            })

            let inputsParamsSection = newInputsWithOrders.map((newInputsWithOrder) => newInputsWithOrder.new_input).filter((input) => !(!input.is_saved || (input.is_saved && (input.is_added && input.is_deleted))) && input.section_order === this.state.section_selected_order).sort((input1, input2) => {
                if (input1.order === undefined) {
                    return 1
                }
                if (input2.order === undefined) {
                    return -1
                }

                return input1.order! > input2.order! ? 1 : -1
            }).map((input) => { // all saved or not added and deleted
                if (!input.is_added && input.is_deleted) {
                    return { "action": "delete", "input_id": input.id }
                }

                if (input.is_added && !input.is_deleted) {
                    if (input.section_id) {
                        return { "action": "create", "type": input.type, "title": input.title, "is_required": input.is_required, "thumbnail": input.thumbnail, "order": input.order, "input_extra_params": input.input_extra_params }
                    } else {
                        return { "type": input.type, "title": input.title, "is_required": input.is_required, "thumbnail": input.thumbnail, "order": input.order, "input_extra_params": input.input_extra_params }
                    }
                }

                return { "action": "update", "input_id": input.id, "order": input.order } // should never happen, update not valid action
            })
            this.updateSection(this.state.section_selected_order, undefined, undefined, undefined, inputsParamsSection)

            return { "inputs": newInputsWithOrders.map((newInputsWithOrder) => newInputsWithOrder.new_input) }
        })
    }

    handleAddInput = (event: React.MouseEvent<HTMLAnchorElement>) => { //todo: check regex
        event.preventDefault()

        this.setState(previousState => {
            return { "inputs": previousState.inputs.concat({ "section_id": this.state.sections.filter((section) => section.order === this.state.section_selected_order)[0].id, "section_order": this.state.section_selected_order, "freelancer_project_id": this.state.freelancer_project_id, "order": previousState.inputs.filter((input) => !input.is_deleted).length + 1, "type": "question", "is_required": false, "title": "", "thumbnail": {}, "input_extra_id": undefined, "title_check": true, "type_check": true, "is_saved": false, "is_deleted": false, "is_added": true, "input_extra_params": undefined }) }
        })
    }

    updateExtraInput = (input_order: number, quote?: string, file_s3_link?: string, url_link?: string, specific_part?: string, relevance_reason?: string, options?: Record<string, any>[]) => {
        this.setState(previousState => {
            return { "inputs_extras": previousState.inputs_extras.concat({ "input_order": input_order, "section_order": this.state.section_selected_order, "quote": quote, "file_s3_link": file_s3_link, "url_link": url_link, "specific_part": specific_part, "relevance_reason": relevance_reason, "options": options?.map((option) => ({ "name": option.name, "order": option.order })), "quote_check": true, "file_s3_link_check": true, "url_link_check": true, "specific_part_check": true, "relevance_reason_check": true, "options_check": true }) }
        })
        this.updateInput(input_order, undefined, undefined, undefined, undefined, undefined, { "quote": quote, "file_s3_link": file_s3_link, "url_link": url_link, "specific_part": specific_part, "relevance_reason": relevance_reason, "options": options?.map((option) => ({ "name": option.name, "order": option.order })) })
    }

    handleSave = async (event: React.MouseEvent<HTMLButtonElement>) => {
        await CoreAnalytics.trackSaveProject(this.state.project_id);
        event.preventDefault()

        await this.setStateLikeSync({ sectionsCheck: [true] })

        let allValidationsPass = true

        if (allValidationsPass) {
            var incrementingOrder = 0
            let preferenceGroupParams = {
                "sections_params": this.state.sections.filter((section) => !(!section.is_saved || (section.is_saved && (section.is_added && section.is_deleted)))).sort((section1, section2) => {
                    if (section1.order === undefined) {
                        return 1
                    }
                    if (section2.order === undefined) {
                        return -1
                    }

                    return section1.order! > section2.order! ? 1 : -1
                }).map((section) => { // all saved or not added and deleted
                    if (!section.is_added && section.is_deleted) {
                        return { "action": "delete", "section_id": section.id! }
                    }

                    incrementingOrder += 1
                    if (section.is_added && !section.is_deleted) {
                        return { "action": "create", "name": section.name, "order": incrementingOrder, "inputs_params": section.inputs_params }
                    }

                    return { "action": "update", "section_id": section.id!, "order": incrementingOrder, "inputs_params": section.inputs_params }
                })
            }

            let persistenceToken = localStorage.getItem("persistence_token")
            try {
                const response = await fetch(`${config.API_URL}/save_preference_group`, {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
                    body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
                        persistence_token: persistenceToken,
                        action: "update",
                        preference_group_id: this.state.preference_group_id,
                        preference_group_params: preferenceGroupParams
                    })
                })
                if (!response.ok) {
                    // this.showSnackMessage(`An error has occured: ${response.status}`)
                }
                const responseJSON = await response.json();
                this.responseSavePreferenceGroupResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.error)
            } catch (error) {
                this.showSnackMessage(`save-preference-group-fetch-error: ${error}`)
            }
        } else {
            this.showSnackMessage("check validations")
        }
    }

    handlePublish = async (event: React.MouseEvent<HTMLButtonElement>) => {
        await CoreAnalytics.trackPublishProject(this.state.project_id);
        event.preventDefault()

        await this.setStateLikeSync({ sectionsCheck: [true] } as any)

        let allValidationsPass = true

        if (allValidationsPass) {
            var incrementingOrder = 0
            let preferenceGroupParams = {
                "current_state": "published", "sections_params": this.state.sections.filter((section) => (section.is_deleted && !section.is_added) || (section.is_added && section.is_saved && !section.is_deleted) || !(section.is_added && section.is_deleted)).sort((section1, section2) => {
                    if (section1.order === undefined) {
                        return 1
                    }
                    if (section2.order === undefined) {
                        return -1
                    }

                    return section1.order! > section2.order! ? 1 : -1
                }).map((section) => {
                    if (section.is_deleted && !section.is_added) {
                        return { "action": "delete", "section_id": section.id! }
                    }

                    incrementingOrder += 1
                    if (section.is_added && section.is_saved && !section.is_deleted) {
                        return { "action": "create", "name": section.name, "order": incrementingOrder, "inputs_params": section.inputs_params }
                    }

                    return { "action": "update", "section_id": section.id!, "order": incrementingOrder, "inputs_params": section.inputs_params }
                })
            }

            let persistenceToken = localStorage.getItem("persistence_token")
            try {
                const response = await fetch(`${config.API_URL}/save_preference_group`, {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
                    body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
                        persistence_token: persistenceToken,
                        action: "update",
                        preference_group_id: this.state.preference_group_id,
                        preference_group_params: preferenceGroupParams
                    })
                })
                if (!response.ok) {
                    // this.showSnackMessage(`An error has occured: ${response.status}`)
                }
                const responseJSON = await response.json();
                this.responseSavePreferenceGroupResolvePublish(responseJSON.response, responseJSON.persistence_token, responseJSON.error)
            } catch (error) {
                this.showSnackMessage(`save-preference-group-resolve-fetch-error: ${error}`)
            }
        } else {
            this.showSnackMessage("check validations")
        }
    }

    render() {
        let InputsMenuCards = this.state.inputs.filter((input) => input.section_order === this.state.section_selected_order && !input.is_deleted).sort((input1, input2) => {
            if (input1.order === undefined) {
                return 1
            }
            if (input2.order === undefined) {
                return -1
            }

            return input1.order! > input2.order! ? 1 : -1
        }).map((input) => {
            let input_extra = this.state.inputs_extras.filter((input_extra) => (input_extra.section_order === input.section_order && input_extra.input_order === input.order))

            return (
                <InputCard
                    order={input.order!}
                    type={input.type}
                    is_required={input.is_required}
                    title={input.title}
                    thumbnail={Object.keys(input.thumbnail).length !== 0 ? input.thumbnail : undefined}
                    input_extras={input_extra.length == 1 ? input_extra[0] : undefined}

                    type_check={input.type_check}
                    title_check={input.title_check}
                    is_saved={input.is_saved}
                    updateInput={this.updateInput}
                    updateExtraInput={this.updateExtraInput}

                    showSnackMessage={this.showSnackMessage}
                />
            )
        })

        let SectionsMenuCards = this.state.sections.filter((section) => !section.is_deleted).sort((section1, section2) => {
            if (section1.order === undefined) {
                return 1
            }
            if (section2.order === undefined) {
                return -1
            }

            return section1.order! > section2.order! ? 1 : -1
        }).map((section) => {
            return (
                <SectionMenuCard
                    order={section.order!}
                    name={section.name}

                    name_check={section.name_check}
                    is_saved={section.is_saved}
                    is_selected={section.order === this.state.section_selected_order}
                    updateSection={this.updateSection}
                />
            )
        })

        return (
            <div className="mainStyle">
                <Snackbar open={this.state.errorForm} autoHideDuration={this.state.errorDuration}>
                    <Alert severity="error">
                        {this.state.errorMessage}
                    </Alert>
                </Snackbar>

                <div className="drawerRoot">
                    <Drawer
                        className="drawerDrawer"
                        variant="permanent"
                        classes={{
                            paper: "drawerDrawerPaper"
                        }}
                        anchor="left">
                        <div className="topButtonMenu">
                            <a href="/dashboard">
                                <ArrowBackRounded /> <span>back</span>
                            </a>
                        </div>

                        <div className="logo">
                            <a href="/dashboard">
                                <img src={logo} width="115" height="32" />
                            </a>
                        </div>
                        <div className="drawerToolbar" />

                        <div className="menuItems">
                            <h3>{this.state.project_name}</h3>

                            <List component="nav" className="sectionMenu">
                                <div className="scroll">
                                    {SectionsMenuCards}
                                </div>
                                <ListItem button onClick={this.handleAddSection}>
                                    <ListItemIcon>
                                        <AddRounded />
                                    </ListItemIcon>
                                    <ListItemText primary="add section" />
                                </ListItem>
                            </List>
                            <div className="clear"></div>
                        </div>
                    </Drawer>

                    <main className="drawerContent">
                        <Container
                            maxWidth="lg"
                            className="topCenterContent topContent">
                            <Grid container>
                                <Grid item xs={12}>
                                    <div className="titleBar">
                                        <h1>{this.state.section_selected_name}</h1>
                                        <div className="keyboardTipsBar">
                                            <Button

                                                size="small"
                                                className="smallButton left"
                                                onClick={this.handleSave}
                                            >
                                                <SaveAltRounded />
                                                save draft
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                className="primaryButton largeButton right"
                                                onClick={this.handlePublish}
                                            >
                                                create questionnaire
                                            </Button>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                </Grid>

                                <Grid item xs={12} className="questionsListTable">
                                    <form autoComplete="off">
                                        <Grid
                                            container
                                            justify="flex-start"
                                            xs={12}
                                            direction="column"
                                            className="block"
                                            alignItems="stretch"
                                            spacing={1}>
                                            <Grid item xs={12}>
                                                <GridList
                                                    cellHeight="auto"
                                                    className="gridList"
                                                    cols={1}
                                                    spacing={10}>
                                                    {InputsMenuCards}
                                                </GridList>

                                                <div className="outlinedButton">
                                                    <a href="#" onClick={this.handleAddInput}>
                                                        <Add />{" "}
                                                        <span>
                                                            add question
                                                        </span>
                                                    </a>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                            </Grid>
                        </Container>
                    </main>
                </div>
            </div>
        )
    }
}

export default EditProjectBriefing