import React from "react"

import {
	TextField,
	IconButton,
	GridListTile,
	Grid,
	InputAdornment,
	Select,
	MenuItem,
	CircularProgress
} from "@material-ui/core"

import {
	CheckRounded,
	InfoOutlined,
	ClearRounded
} from "@material-ui/icons"

interface Props {
	invitation_email: string
	type: string
	invitation_email_check: boolean
	created_at?: Date

	is_invitation: boolean
	is_self: boolean
	is_saved: boolean
	is_loading_save?: boolean

	updateInvitation: (created_at: Date, invitation_email?: string, type?: string, is_saved?: boolean, is_deleted?: boolean) => void
}

interface State { }

class MemberCard extends React.Component<Props, State> {
	handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.props.updateInvitation(this.props.created_at!, event.target.value, undefined, undefined, undefined)
	}

	// handleChangeFreelancerType = (event: React.MouseEvent<HTMLButtonElement>) => {
	//     this.props.updateInvitation(this.props.created_at, undefined, "freelancer", undefined)
	// }

	// handleChangeClientType = (event: React.MouseEvent<HTMLButtonElement>) => {
	//     this.props.updateInvitation(this.props.created_at, undefined, "client", undefined)
	// }

	handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
		this.props.updateInvitation(this.props.created_at!, undefined, undefined, undefined, true)
	}

	handleSave = (event: React.MouseEvent<HTMLButtonElement>) => {
		this.props.updateInvitation(this.props.created_at!, undefined, undefined, true, undefined)
	}

	render() {
		return (
			<GridListTile cols={1} className={!this.props.is_invitation ? `activeMember ${this.props.is_self ? "selfMember" : ""}` : ""}>
				<Grid container spacing={1}>
					<Grid item xs={7}>
						{!this.props.is_invitation && <TextField
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<CheckRounded />
									</InputAdornment>
								)
							}}
							value={`${this.props.invitation_email} ${this.props.is_self ? "(you)" : ""}`}
							className="noOutline readOnly"
							name="section_name"
							placeholder="Member e-mail"
							fullWidth
						/>}
						{this.props.is_invitation && <TextField
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<InfoOutlined />
									</InputAdornment>
								)
							}}
							value={this.props.invitation_email}
							className={`noOutline ${this.props.is_saved ? "readOnly" : ""}`}
							required
							name="section_name"
							placeholder="Member e-mail"
							fullWidth
							onChange={this.handleChange}
							type="text"
							error={!this.props.invitation_email_check}
							helperText={!this.props.invitation_email_check ? 'Invalid Invitation Email' : ''}
						/>}
					</Grid>
					<Grid item xs={this.props.is_saved ? 2 : 2}>
						<Select defaultValue={30} required disabled={this.props.is_saved}>
							<MenuItem value={30}>{this.props.type}</MenuItem>
						</Select>
					</Grid>
					<Grid item xs={3}>
						{!this.props.is_saved && this.props.is_loading_save != true &&
							<div>
								<IconButton onClick={this.handleCancel}>
									<ClearRounded />
								</IconButton>
							</div>
						}
						{this.props.is_loading_save && <div><CircularProgress size={20} /></div>}
						{this.props.is_saved && this.props.is_invitation && <label>invited</label>}
					</Grid>
				</Grid>
			</GridListTile>
		)
	}
}

export default MemberCard
