import React from "react"
import { RouteComponentProps } from "react-router"

import {
	Button,
	Container,
	Grid,
	GridList,
	CircularProgress,
	GridListTile,
	TextField,
	RadioGroup,
	FormControlLabel,
	Radio,
	Snackbar
} from "@material-ui/core"

import { Alert } from "@material-ui/lab"

import {
	ArrowForward,
	CheckRounded
} from "@material-ui/icons"

import {
	loadStripe,
	SetupIntentResult,
	Stripe,
	StripeElements
} from '@stripe/stripe-js';

import {
	Elements,
	CardElement,
	ElementsConsumer
} from '@stripe/react-stripe-js';

import "./../../styles/partners_payments_pages.scss"

import { config } from "./../../config"

import { } from "./../interfaces/backend"
import CoreAnalytics from "./../../utils/core_analytics";

const paymentsComponentsClientMap: Record<string, Record<string, any>> = {
	"installments": { "title": "Parcelado no Cartão", "fee": 0.00, "description": <div className="textPaymentDescription"><p></p></div> }
	// "boleto": { "title": "Com Boleto", "fee": 0.02, "description": <div className="textPaymentDescription"><p></p></div> },
}

const regexExpressions: Record<string, string> = {
	"cardNameCheck": "^((.|\n){0,512})$",
	"cardEmailheck": "^((.|\n){0,512})$",
	"cardAddressCheck": "^((.|\n){0,512})$",
	"cardCityCheck": "^((.|\n){0,512})$",
	"cardStateCheck": "^((.|\n){0,512})$",
	"cardCountryCheck": "^((.|\n){0,512})$",
	"cardZipCheck": "^((.|\n){0,512})$",
	"numberOfInstallmentsCheck": "^[1-3]$",
	"taxIdCheck": "^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$"
}

const stripePromise = loadStripe(config.STRIPE_PUBLISHABLE_KEY);

interface Props extends RouteComponentProps<{}> {
	stripe: Stripe,
	elements: StripeElements
}

interface State {
	isLoadingForm: boolean
	successForm: boolean

	paymentMethodIndex: string

	name: string
	nameCheck: boolean
	email: string
	emailCheck: boolean
	address: string
	addressCheck: boolean
	city: string
	cityCheck: boolean
	state: string
	stateCheck: boolean
	country: string
	countryCheck: boolean
	zip: string
	zipCheck: boolean

	numberOfInstallments: number
	numberOfInstallmentsCheck: boolean
	taxId: string
	taxIdCheck: boolean

	partner_payment_id?: number
	confirmedCardSetup?: SetupIntentResult
	stripe_subscription_id?: number

	errorForm: boolean
	errorMessage: string
	errorDuration: number
}

class AhazouLogo extends React.Component<Props, State> {
	setStateLikeSync(state: any) {
		return new Promise<void>((resolve) => {
			this.setState(state, resolve)
		});
	}

	showSnackMessage(message?: string, duration?: number) {
		if (message == undefined) {
			return
		}

		let actualDuration = duration ? duration : 3000

		this.setState({
			"errorForm": true,
			"errorMessage": message,
			"errorDuration": actualDuration
		})

		setTimeout(() => {
			this.setState({
				"errorForm": false,
				"errorMessage": "",
				"errorDuration": 0
			})
		}, actualDuration)
	}

	constructor(props: Props) {
		super(props)

		this.state = {
			isLoadingForm: false,
			successForm: false,

			paymentMethodIndex: "installments",

			name: "",
			nameCheck: true,
			email: "",
			emailCheck: true,
			address: "",
			addressCheck: true,
			city: "",
			cityCheck: true,
			state: "",
			stateCheck: true,
			country: "",
			countryCheck: true,
			zip: "",
			zipCheck: true,

			numberOfInstallments: 0,
			numberOfInstallmentsCheck: false,
			taxId: "",
			taxIdCheck: true,

			partner_payment_id: undefined,
			confirmedCardSetup: undefined,
			stripe_subscription_id: undefined,

			errorForm: false,
			errorMessage: "",
			errorDuration: 0
		}
	}

	responseSavePartnerPaymentResolve = async (response: boolean, partner_payment_id?: number, stripe_setup_intent_client_secret?: string, error?: string): Promise<boolean> => {
		if (response === true) {
			if (this.state.paymentMethodIndex == "installments") {
				let billingDetails = {
					name: this.state.name,
					email: this.state.email,
					address: {
						city: this.state.city,
						line1: this.state.address,
						state: this.state.state,
						postal_code: this.state.zip
					}
				}
				let cardElement = this.props.elements.getElement(CardElement)

				let confirmedCardSetup = await this.props.stripe.confirmCardSetup(stripe_setup_intent_client_secret!, {
					payment_method: {
						card: cardElement!,
						billing_details: billingDetails
					}
				})
				if (confirmedCardSetup.error) {
					this.showSnackMessage(confirmedCardSetup.error.message)
					this.setStateLikeSync({ isLoadingForm: false });
					this.setStateLikeSync({ successForm: false });
					return true
				}

				this.setStateLikeSync({ confirmedCardSetup: confirmedCardSetup });
			}
			// else if (this.state.paymentMethodIndex == "boleto") {
			// 	let billingDetails = {
			// 		name: this.state.name,
			// 		email: this.state.email,
			// 		address: {
			// 			city: this.state.city,
			// 			line1: this.state.address,
			// 			state: this.state.state,
			// 			country: this.state.country == "Brasil" ? "BR" : "",
			// 			postal_code: this.state.zip
			// 		}
			// 	}
			// 	let boleto = {
			// 		tax_id: this.state.taxId
			// 	}

			// 	let confirmBoletoPayment = await this.props.stripe.confirmBoletoPayment(stripe_setup_intent_client_secret!, {
			// 		payment_method: {
			// 			billing_details: billingDetails,
			// 			boleto: boleto
			// 		}
			// 	})
			// 	if (confirmedCardSetup.error) {
			// 		this.showSnackMessage(confirmedCardSetup.error.message)
			// 		this.setStateLikeSync({ isLoadingForm: false });
			// 		this.setStateLikeSync({ successForm: false });
			// 		return true
			// 	}
			// }
			
			this.setStateLikeSync({
				"partner_payment_id": partner_payment_id!,
				"successForm": true
			})
		} else {
			this.setState({
				"successForm": false,
				"isLoadingForm": false
			});
			this.showSnackMessage(error) // some regex problem?
			return true
		}

		return false
	}

	responseExecuteInstallmentsPaymentResolve = async (response: boolean, stripe_subscription_id?: number, error?: string): Promise<boolean> => {
		if (response === true) {
			this.setStateLikeSync({
				"stripe_subscription_id": stripe_subscription_id!,
				"successForm": true
			})
		} else {
			this.setState({
				"successForm": false,
				"isLoadingForm": false
			});
			this.showSnackMessage(error) // some regex problem?
			return true
		}

		return false
	}

	handleChangePaymentMethod = (event: React.ChangeEvent<any>, newValue: string) => {
		this.setState({ paymentMethodIndex: newValue });
	}

	handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const key = event.target.name
		const keyCheck = event.target.name + "Check"
		const value = event.target.value
		const valueCheck = (RegExp(regexExpressions[keyCheck])).test(value)

		this.setState({ [key]: value, [keyCheck]: valueCheck } as any)
	}

	handleMakePayment = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()

		let price = 19900
		let fee = 0.00 * 19900

		if (this.state.paymentMethodIndex == "installments") {
			let paymentOption = `{
				"name": "installments",
				"number_of_installments": ${this.state.numberOfInstallments}
			}`

			try {
				await this.setStateLikeSync({ isLoadingForm: true });

				const response = await fetch(`${config.API_URL}/save_partner_payment`, {
					method: 'post',
					headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
					body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
						action: "create",
						partner_payment_params: { "partner": "ahazou", "product": "logo", "payment_option": paymentOption, "amount": price, "fee": fee }
					})
				})
				if (!response.ok) {
					// this.showSnackMessage(`An error has occured: ${response.status}`)
				}
				const responseJSON = await response.json();

				let isDone = await this.responseSavePartnerPaymentResolve(responseJSON.response, responseJSON.partner_payment_id, responseJSON.stripe_setup_intent_client_secret, responseJSON.error)
				if (isDone) {
					return
				}
			} catch (error) {
				await this.setStateLikeSync({ isLoadingForm: false });
				this.showSnackMessage(`save-payment-fetch-error: ${error}`)
				return
			}

			let confirmedCardSetup = this.state.confirmedCardSetup

			if (confirmedCardSetup) {
				try {
					await this.setStateLikeSync({ isLoadingForm: true });

					const response = await fetch(`${config.API_URL}/execute_installments_payment`, {
						method: 'post',
						headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
						body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
							partner: "ahazou",
							email: this.state.email,
							payment_method: confirmedCardSetup.setupIntent?.payment_method,
							number_of_installments: this.state.numberOfInstallments
						})
					})
					if (!response.ok) {
						// this.showSnackMessage(`An error has occured: ${response.status}`)
					}
					const responseJSON = await response.json();

					let isDone = await this.responseExecuteInstallmentsPaymentResolve(responseJSON.response, responseJSON.stripe_subscription_id, responseJSON.error)
					if (isDone) {
						return
					}
				} catch (error) {
					await this.setStateLikeSync({ isLoadingForm: false });
					this.showSnackMessage(`save-payment-fetch-error: ${error}`)
					return
				}
			}

			this.setStateLikeSync({ isLoadingForm: false });
		}
		// else if (this.state.paymentMethodIndex == "boleto") {
		// 	let paymentOption = `{
		// 		"name": "boleto"
		// 	}`
		// } 
		else { // should never happen application wise
			return
		}
	}

	render() {
		let price = 19900

		return (
			<div className="mainStyle partnersPaymentsStyle">
				<Snackbar open={this.state.errorForm} autoHideDuration={this.state.errorDuration}>
					<Alert severity="error">
						{this.state.errorMessage}
					</Alert>
				</Snackbar>

				<div className="drawerRoot">
					<main className="drawerContentPartner">
						<Container
							maxWidth="md"
							className="topCenterContent topCenterContentVerticalScroll">

							<Grid container spacing={3}>
								<Grid item xs={12}>

									<div className="clientHeader">
										{!this.state.partner_payment_id && <h2>Pague agora e começaremos a trabalhar no seu logo imediatamente</h2>}
									</div>

									<div className="titleBar">
										<div className="clear"></div>
									</div>

									<form autoComplete="off" onSubmit={e => e.preventDefault()}>
										<Grid
											container
											justify="flex-start"
											xs={12}
											direction="column"
											className="block"
											alignItems="stretch"
											spacing={1}>
											<Grid item xs={12}>

												<GridList
													cellHeight="auto"
													className="gridList"
													cols={1}
													spacing={10}>

													{this.state.partner_payment_id && ((this.state.paymentMethodIndex == "installments" && this.state.stripe_subscription_id != undefined)) && <GridListTile // || (this.state.paymentMethodIndex == "boleto" && true)
														cols={1} className="noBorder">
														<div className="questionBox">

															<div className="investmentDescription left">
																<div className="clear"></div>
																{/* (this.state.paymentMethodIndex == "boleto" ? (this.state.stripe_subscription_id ? " - Pago" : " - Pendente") */}
																<h3>Método De Pagamento {this.state.paymentMethodIndex == "installments" ? " - Pago" : ""}</h3>

																<p><strong>{paymentsComponentsClientMap[this.state.paymentMethodIndex].title}{this.state.paymentMethodIndex == "installments" ? ` em ${this.state.numberOfInstallments}x` : ""}</strong></p>
																{paymentsComponentsClientMap[this.state.paymentMethodIndex].description}
															</div>
															<div className="investmentBox right">
																<h3>Investmento Total</h3>
																<p><span className="price">R$ <strong>{price / 100}</strong>{price % 100 == 0 ? ".00" : ""}</span></p>
															</div>
															<div className="clear"></div>
														</div>
													</GridListTile>}
													{/* || (this.state.paymentMethodIndex == "boleto" && true) */}
													{!(this.state.partner_payment_id && ((this.state.paymentMethodIndex == "installments" && this.state.stripe_subscription_id != undefined))) && <GridListTile
														cols={1} className="noBorder">
														<div className="questionBox">

															<div className="clear"></div>

															<div className="investmentDescription left">
																<div className="clear"></div>

																<h3>Logo Ahazou</h3>
																<p>Pagando com a Smelt, você tem qualidade garantida ou seu dinheiro de volta</p>
															</div>
															<div className="investmentBox right">
																<h3>Investmento</h3>
																<p><span className="price">R$ <strong>{price / 100}</strong>{price % 100 == 0 ? ".00" : ""}</span></p>
															</div>

															<div className="clear"></div>

															<h3>Endereço</h3>

															<TextField
																className=""
																name="name"
																label="Nome"
																required
																type="text"
																onChange={this.handleChange}
																helperText={!this.state.nameCheck ? 'Nome inválido' : ''}
																value={this.state.name}
															/>

															<TextField
																className=""
																name="email"
																label="Email"
																required
																type="text"
																onChange={this.handleChange}
																helperText={!this.state.emailCheck ? 'Email inválido' : ''}
																value={this.state.email}
															/>

															<TextField
																className=""
																name="address"
																label="Endereço"
																required
																type="text"
																onChange={this.handleChange}
																helperText={!this.state.addressCheck ? 'Endereço inválido' : ''}
																value={this.state.address}
															/>

															<TextField
																className=""
																name="city"
																label="Cidade"
																required
																type="text"
																onChange={this.handleChange}
																helperText={!this.state.cityCheck ? 'Cidade Inválida' : ''}
																value={this.state.city}
															/>

															<TextField
																className=""
																name="state"
																label="Estado"
																required
																type="text"
																onChange={this.handleChange}
																helperText={!this.state.stateCheck ? 'Estado Inválido' : ''}
																value={this.state.state}
															/>

															<TextField
																className=""
																name="country"
																label="País"
																required
																type="text"
																onChange={this.handleChange}
																helperText={!this.state.countryCheck ? 'País inválido' : ''}
																value={this.state.country}
															/>

															<TextField
																className=""
																name="zip"
																label="CEP"
																required
																type="text"
																onChange={this.handleChange}
																helperText={!this.state.zipCheck ? 'CEP inválido' : ''}
																value={this.state.zip}
															/>

															<RadioGroup name="paymentOptions" className="paymentOptions" value={this.state.paymentMethodIndex} onChange={this.handleChangePaymentMethod}>
																<h3>Métodos de pagamento</h3>

																{<FormControlLabel disabled={this.state.partner_payment_id != undefined} value="installments" control={<Radio />} label={paymentsComponentsClientMap["installments"].title} />}
																{this.state.paymentMethodIndex == 'installments' && <div>
																	{paymentsComponentsClientMap["installments"].description}
																	<div className="totalInvestment">
																		<p>
																			<span>Taxa</span>
																			<span className="price"><strong>{paymentsComponentsClientMap["installments"].fee * 100}</strong>%</span>
																		</p>

																		<p>
																			<span>Investimento Total</span>
																			<span className="price">R$ <strong>{price * (1 + paymentsComponentsClientMap["installments"].fee) / 100}</strong>{(price * (1 + paymentsComponentsClientMap["installments"].fee)) % 100 == 0 ? ".00" : ""}</span>
																		</p>

																		{paymentsComponentsClientMap["installments"].extra_label}
																	</div>

																	<div className="clear"></div>

																	<TextField
																		className=""
																		name="numberOfInstallments"
																		label="Número de parcelas"
																		required
																		type="number"
																		onChange={this.handleChange}
																		helperText={!this.state.numberOfInstallmentsCheck ? 'Escolha o número de parcelas (até 3x)' : ''}
																		value={this.state.numberOfInstallments}
																	/>

																	<div className="cardInfo">
																		<h4>Cartão de crédito</h4>
																		<CardElement options={{
																			hidePostalCode: true,
																			style: {
																				base: {
																					fontSize: '16px',
																					color: '#424770',
																					'::placeholder': {
																						color: '#aab7c4',
																					},
																				},
																				invalid: {
																					color: '#9e2146',
																				},
																			},
																		}} />
																	</div>
																</div>}

																{/* {<FormControlLabel disabled={this.state.partner_payment_id != undefined} value="boleto" control={<Radio />} label={paymentsComponentsClientMap["boleto"].title} />}
																{this.state.paymentMethodIndex == 'boleto' && <div>
																	{paymentsComponentsClientMap["boleto"].description}
																	<div className="totalInvestment">
																		<p>
																			<span>Taxa</span>
																			<span className="price"><strong>{paymentsComponentsClientMap["boleto"].fee * 100}</strong>%</span>
																		</p>

																		<p>
																			<span>Investimento Total</span>
																			<span className="price">R$ <strong>{price * (1 + paymentsComponentsClientMap["boleto"].fee) / 100}</strong>{(price * (1 + paymentsComponentsClientMap["boleto"].fee)) % 100 == 0 ? ".00" : ""}</span>
																		</p>

																		{paymentsComponentsClientMap["boleto"].extra_label}
																	</div>

																	<div className="clear"></div>

																	<TextField
																		className=""
																		name="taxId"
																		label="CPF ou CNPJ"
																		required
																		type="text"
																		onChange={this.handleChange}
																		helperText={!this.state.taxIdCheck ? 'CPF ou CNPJ inválido' : ''}
																		value={this.state.taxId}
																	/>
																</div>} */}
															</RadioGroup>
															<div className="clear"></div>
														</div>
													</GridListTile>}
												</GridList>
											</Grid>

											{!this.state.partner_payment_id && <div className="actionBarPartner">
												<Button
													disabled={this.state.isLoadingForm}
													variant="contained"
													color="primary"
													size="large"
													fullWidth
													className="primaryButton"
													startIcon={(this.state.isLoadingForm ? <CircularProgress
														size={14}
													/> : (this.state.successForm ? <CheckRounded /> : <ArrowForward />))}
													onClick={this.handleMakePayment}
												>
													pagar
												</Button>
											</div>}
										</Grid>
									</form>
								</Grid>
							</Grid>
						</Container>
					</main>
				</div>
			</div>
		)
	}
}

class StripeInjectedAhazouLogo extends React.Component<RouteComponentProps<{}>, State> {
	render() {
		return (
			<Elements stripe={stripePromise} options={{ locale: "en" }}>
				<ElementsConsumer>
					{({ stripe, elements }) => (
						<AhazouLogo
							history={this.props.history}
							location={this.props.location}
							match={this.props.match}
							stripe={stripe!}
							elements={elements!}
						/>
					)}
				</ElementsConsumer>
			</Elements>
		)
	}
}

export default StripeInjectedAhazouLogo