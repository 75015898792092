const prod = {
    API_URL: 'https://api.smelt.ai',
    APP_URL: 'https://app.smelt.ai',
    STRIPE_PUBLISHABLE_KEY: 'pk_live_51JDGHgA7ZSt5X1pwzuVgNuGyivhBNjSKqHX7DDSSLusk672OAFTAU1P2QPnNinXMqZVXEswp0BAtxCJ6hawlKv28001u1Dlry2'
}

const dev = {
    API_URL: 'http://localhost:5000',
    APP_URL: 'http://localhost:3000',
    STRIPE_PUBLISHABLE_KEY: 'pk_test_51JDGHgA7ZSt5X1pwD1Afn5HKHKGUddY3q2y59oVj8YMHFQdsrXQkfkjLfom4pAT2dxkY5NYRHs9nON1dOfHwKxZh00rwwolFoU'
}

export const config = process.env.NODE_ENV === 'development' ? dev : prod