import React from "react"

import {
    Chip,
    Avatar,
    GridListTile,
    CardMedia,
    Modal,
    Fade,
    Backdrop,
    TextField
} from "@material-ui/core"

import exampleImage from "../../images/smiling-woman-hero.png";

import {
    FormatQuoteRounded,
    InsertLinkRounded,
    ThumbUpRounded,
    ThumbDownRounded
} from "@material-ui/icons"

import {
    Autocomplete
} from "@material-ui/lab"

import OptionCardView from "./option_card_view";

import { config } from "./../../config"

import { BackendClientUserProject, BackendInputExtra, BackendAnswer, BackendAnswerExtra } from "./../interfaces/backend"

interface Props {
    order: number
    type: string
    is_required: boolean
    title: string
    answers: BackendAnswer[]
    answers_extras: BackendAnswerExtra[]
    answers_clients_users_projects: BackendClientUserProject[]
    thumbnail?: Record<string, any>
    input_extras?: BackendInputExtra

    showSnackMessage: (message?: string, duration?: number) => void
}

interface State {
    openPictureModal: boolean

    // only for examples inputs
    exampleType?: string

    // only for url link preview
    url_preview_title?: string,
    url_preview_description?: string,
    url_preview_image?: string
}

const monthNames: Array<string> = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

class InputCardView extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            openPictureModal: false,
            exampleType: undefined,
            url_preview_title: undefined,
            url_preview_description: undefined,
            url_preview_image: undefined
        }
    }

    async updateComponentType() {
        if (this.state.exampleType == null) {
            if (this.props.input_extras?.file_s3_link) {
                this.setState({ "exampleType": "image" })
            }
            else if (this.props.input_extras?.url_link) {
                this.setState({ "exampleType": "link" })

                let url_link = this.props.input_extras.url_link
                let persistenceToken = localStorage.getItem("persistence_token")

                try {
                    const response = await fetch(`${config.API_URL}/get_url_preview?url_link=${url_link}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
                        method: 'get',
                        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
                    })
                    if (!response.ok) {
                        // this.props.showSnackMessage(`An error has occured: ${response.status}`)
                    }
                    const responseJSON = await response.json();
                    this.responseGetUrlPreviewResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.url_preview_title, responseJSON.url_preview_description, responseJSON.url_preview_image, responseJSON.error)
                } catch (error) {
                    this.props.showSnackMessage(`get-url-preview-error: ${error}`)
                }
            }
            else {
                if (this.props.input_extras?.quote) {
                    this.setState({ "exampleType": "text" })
                }
            }
        }
    }

    async componentDidUpdate() {
        this.updateComponentType();
    }

    async componentDidMount() {
        this.updateComponentType();
    }

    responseGetUrlPreviewResolve = (response: boolean, persistence_token?: string, url_preview_title?: string, url_preview_description?: string, url_preview_image?: string, error?: string): void => {
        if (response === true) {
            localStorage.setItem("persistence_token", persistence_token as string)
            this.setState({
                "url_preview_title": url_preview_title!,
                "url_preview_description": url_preview_description!,
                "url_preview_image": url_preview_image!
            })
        } else {
            this.props.showSnackMessage(error) // likely a library problem loading url
        }
    }

    getCardinalString = (number: number): string => {
        if (number == 1) {
            return "st"
        } else if (number == 2) {
            return "nd"
        } else if (number == 3) {
            return "rd"
        } else {
            return "nd"
        }
    }

    getDateString = (timestamp: Date): string => {
        let dateObject = new Date(timestamp);
        let monthName = monthNames[dateObject.getMonth()]
        let day = dateObject.getDate()
        let cardinalString = this.getCardinalString(day)
        let year = dateObject.getFullYear()

        //dateObject.getHours() + ":" + dateObject.getMinutes() 

        return monthName + " " + day + cardinalString + ", " + year
    }

    handleOpenPictureModal = () => {
        this.setState({ openPictureModal: true });
    };

    handleClosePictureModal = () => {
        this.setState({ openPictureModal: false });
    };

    render() {
        var self = this;

        let AnswersCards = this.props.answers.sort((answer1, answer2) => answer1.created_at > answer2.created_at ? 1 : -1).map((answer) => {
            let user = self.props.answers_clients_users_projects != null ? self.props.answers_clients_users_projects.filter((user) => user.client_project_id == answer.client_project_id)[0] : null;
            let answerExtra = self.props.answers_extras != null ? self.props.answers_extras.filter((answer_extra) => answer_extra.id == answer.answer_extra_id)[0] : null;

            return (
                <div className="response">
                    <div className="responseProfile">
                        <Avatar className="left">
                            {String(user?.first_name != null ? user.first_name : '').charAt(0)}
                        </Avatar>
                        <div className="responseName left">
                            <label>
                                <strong>
                                    {user?.first_name != null ? user.first_name : 'Unknown'} {user?.last_name != null ? user.last_name : ''}
                                </strong>
                            </label>
                            <label>
                                {this.getDateString(answer.created_at)}
                            </label>
                        </div>
                    </div>
                    <div className="clear"></div>
                    <>
                        {
                            {
                                question: <p>
                                    {answer.free_text}
                                </p>,
                                checkbox: <div>
                                    {answerExtra?.options ? answerExtra.options.map((option) => {
                                        return (
                                            <OptionCardView
                                                order={0}
                                                name={option.name}
                                                type="checkbox"
                                                is_view={true}
                                                is_saved={true}
                                            />
                                        )
                                    }) : ""}
                                </div>,
                                radio: <div>
                                    {answerExtra?.options ? answerExtra.options.map((option) => {
                                        return (
                                            <OptionCardView
                                                order={0}
                                                name={option.name}
                                                type="radio"
                                                is_view={true}
                                                is_saved={true}
                                            />
                                        )
                                    }) : ""}
                                </div>,
                                ask_feedback: <div>
                                    <div className="thumbsIcon left">
                                        {answerExtra?.like_dislike == "like" && <ThumbUpRounded />}
                                        {answerExtra?.like_dislike == "dislike" && <ThumbDownRounded />}
                                    </div>
                                    <p className="left">{answer.free_text}</p>
                                    <div className="clear"></div>
                                    <div>
                                        {(answerExtra?.options ? answerExtra?.options : []).map(
                                            (option) => (
                                                <Chip variant="outlined" size="small" label={option.name} />
                                            )
                                        )}
                                    </div>
                                </div>,
                                ask_example: <div className="exampleView">
                                    <div className="exampleContainer">
                                        <div className="left quoteArea">
                                            <div className="opinion"><p>{answerExtra?.relevance_reason}</p></div>

                                            {answerExtra?.quote && <FormatQuoteRounded className="left" />}
                                            {answerExtra?.quote && <div className="quote">{answerExtra?.quote}</div>}
                                            <div className="clear"></div>

                                            {answerExtra?.url_link && <div className="linkDescription">
                                                <InsertLinkRounded className="left" />
                                                <a href={answerExtra?.url_link} className="left" target="_blank">{answerExtra?.url_link}</a>
                                                <a href={answerExtra?.url_link} className="right regularLink" target="_blank">visit link</a>
                                                <div className="clear"></div>
                                            </div>}
                                            <div>
                                                {(answerExtra?.options ? answerExtra?.options : []).map(
                                                    (option) => (
                                                        <Chip variant="outlined" size="small" label={option.name} />
                                                    )
                                                )}
                                            </div>
                                        </div>
                                        {false && <div className="illustration right">
                                            <CardMedia
                                                image={exampleImage}
                                                onClick={this.handleOpenPictureModal}
                                            />
                                            <Modal
                                                className="modalPicture"
                                                aria-labelledby="transition-modal-title"
                                                aria-describedby="transition-modal-description"
                                                open={this.state.openPictureModal}
                                                onClose={this.handleClosePictureModal}
                                                closeAfterTransition
                                                BackdropComponent={Backdrop}
                                                BackdropProps={{
                                                    timeout: 500,
                                                }}
                                            >
                                                <Fade in={this.state.openPictureModal}>
                                                    <div className="paperModal">
                                                        <img src={exampleImage}></img>
                                                    </div>
                                                </Fade>
                                            </Modal>
                                        </div>}
                                        <div className="clear"></div>
                                    </div>
                                </div>,
                                multiline: <div>
                                    {answerExtra?.options ? <span className="multilineOptions">{answerExtra?.options.map((option) => (option.name)).join(", ")}</span> : ""}
                                </div>,
                            }[this.props.type]
                        }
                    </>
                    <div className="clear"></div>
                </div>
            )
        })

        return (
            <GridListTile classes={{ root: "inputView" }} cols={1}>
                <div className="questionView">
                    <div className="questionViewTitle">
                        <h3>
                            {this.props.title}
                        </h3>
                        <small>{this.props.is_required ? 'Required' : 'Optional'}</small>
                    </div>

                    <div className="right">
                        <Chip
                            label={(() => {
                                switch (this.props.type) {
                                    case 'radio':
                                    case 'checkbox':
                                    case 'multiline':
                                        return this.props.type;
                                    case 'question':
                                        return 'open-ended question';
                                    case 'ask_feedback':
                                        return 'ask feedback';
                                    case 'ask_example':
                                        return 'request an example';
                                    default:
                                        return '';
                                }
                            })()}
                            color="primary"
                            variant="outlined"
                        />
                    </div>

                    {this.props.thumbnail && <div className="inputGroupOptionImage">

                        <div className="illustration">

                            <CardMedia
                                image={this.props.thumbnail.thumbnail_img}
                                onClick={this.handleOpenPictureModal} />
                            <Modal
                                className="modalPicture"
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                open={this.state.openPictureModal}
                                onClose={this.handleClosePictureModal}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{ timeout: 500 }}
                            >
                                <Fade in={this.state.openPictureModal}>
                                    <div className="paperModal">
                                        <img src={this.props.thumbnail.thumbnail_img}></img>
                                    </div>
                                </Fade>
                            </Modal>
                        </div>

                        <label>{this.props.thumbnail.name}</label>

                        {this.props.thumbnail.thumbnail_url && <div className="anchor">
                            <InsertLinkRounded className="left" />
                            <a href={this.props.thumbnail.thumbnail_url} className="left" target="_blank">{this.props.thumbnail.thumbnail_url}</a>
                        </div>}

                        <div className="clear"></div>

                    </div>}

                    <div className="clear"></div>
                    <>
                        {
                            {
                                question:
                                    <div className="inlineQuestionEdit noMargin">
                                        <div className="clear"></div>
                                    </div>,
                                checkbox:
                                    <div className="inlineQuestionEdit noMargin">
                                        <div className="clear"></div>
                                        <label className="helperLabel optionsLabel">
                                            {this.props.input_extras?.options ? this.props.input_extras.options.map((option) => {
                                                return (
                                                    <OptionCardView
                                                        order={0}
                                                        name={option.name}
                                                        type="checkbox"
                                                        thumbnail_img={option.thumbnail_img}
                                                        thumbnail_url={option.thumbnail_url}
                                                        is_view={true}
                                                        is_saved={true}
                                                    />
                                                )
                                            }) : ""}
                                        </label>
                                        <div className="clear"></div>
                                    </div>,
                                radio: <div className="inlineQuestionEdit noMargin">
                                    <div className="clear"></div>
                                    <label className="helperLabel optionsLabel">
                                        {this.props.input_extras?.options ? this.props.input_extras.options.map((option) => {
                                            return (
                                                <OptionCardView
                                                    order={0}
                                                    name={option.name}
                                                    type="radio"
                                                    thumbnail_img={option.thumbnail_img}
                                                    thumbnail_url={option.thumbnail_url}
                                                    is_view={true}
                                                    is_saved={true}
                                                />
                                            )
                                        }) : ""}
                                    </label>
                                    <div className="clear"></div>
                                </div>,
                                ask_feedback: <div className="inlineQuestionEdit noMargin">
                                    <div className="clear"></div>
                                    <div className="exampleView">
                                        <div className="exampleContainer">
                                            <div className="left quoteArea">
                                                <div className="specificPart"><p>{this.props.input_extras?.specific_part}</p></div>
                                                <div className="opinion"><p>{this.props.input_extras?.relevance_reason}</p></div>

                                                {this.props.input_extras?.quote && <FormatQuoteRounded className="left" />}
                                                {this.props.input_extras?.quote && <div className="quote">{this.props.input_extras?.quote}</div>}


                                                <div className="clear"></div>

                                                {this.state.exampleType === 'link' && <div className="linkDescription">
                                                    <InsertLinkRounded className="left" />
                                                    <a href={this.props.input_extras?.url_link} className="left" target="_blank">{this.props.input_extras?.url_link}</a>
                                                    <a href={this.props.input_extras?.url_link} className="right regularLink" target="_blank">visit link</a>
                                                    <div className="clear"></div>
                                                </div>}
                                            </div>
                                            {(this.state.exampleType === 'link' || this.state.exampleType === 'image') && <div className="illustration right">
                                                <CardMedia
                                                    image={this.state.url_preview_image}
                                                    onClick={this.handleOpenPictureModal}
                                                />
                                                <Modal
                                                    className="modalPicture"
                                                    aria-labelledby="transition-modal-title"
                                                    aria-describedby="transition-modal-description"
                                                    open={this.state.openPictureModal}
                                                    onClose={this.handleClosePictureModal}
                                                    closeAfterTransition
                                                    BackdropComponent={Backdrop}
                                                    BackdropProps={{ timeout: 500 }}
                                                >
                                                    <Fade in={this.state.openPictureModal}>
                                                        <div className="paperModal">
                                                            <img src={this.state.url_preview_image}></img>
                                                        </div>
                                                    </Fade>
                                                </Modal>
                                            </div>}
                                            <div className="clear"></div>
                                        </div>
                                    </div>
                                    <div className="clear"></div>
                                </div>,
                                ask_example: <div className="inlineQuestionEdit noMargin">
                                    <div className="clear"></div>
                                </div>,
                                multiline: <div className="inlineQuestionEdit noMargin">
                                    <div className="clear"></div>
                                    <label className="helperLabel">
                                        <Autocomplete
                                            disabled
                                            multiple
                                            defaultValue={["Select multiple options or create new ones."]}
                                            options={[]}
                                            freeSolo
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Please, type your answer"
                                                />
                                            )
                                            }
                                        />
                                        {this.props.input_extras?.options ? <span className="multilineOptions"><strong>Options: </strong>{this.props.input_extras.options.map((option) => (option.name)).join(", ")}</span> : ""}
                                    </label>
                                    <div className="clear"></div>
                                </div>,
                            }[this.props.type]
                        }
                    </>

                    <div className="responses">
                        {AnswersCards}
                        {AnswersCards.length == 0 && <div>
                            <label className="left">Waiting for responses</label>
                        </div>}
                    </div>

                    <div className="clear"></div>
                </div>
            </GridListTile>
        )
    }
}

export default InputCardView