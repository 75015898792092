import React from "react";

import "../styles/mobile_prelaunch.scss"

class MobilePreLaunch extends React.Component {
    componentDidMount() {
        document.title = "Mobile version soon"

    }

    render() {
        return (
            <div className="mobile-prelaunch">
                <div className="mainContainer">
                    <h2>Work in progress</h2>
                    <p>Hello,</p>
                    <p>Please access Smelt on a desktop computer, as we're working to launch the mobile version in a few weeks.</p>
                    <p>Feel free to reach out to us at <a href="mailto:founders@smelt.ai">founders@smelt.ai</a>.</p>
                </div>
            </div>
        )
    }
}

export default MobilePreLaunch