import React from "react"
import { RouteComponentProps } from "react-router"
import { Editor } from 'react-draft-wysiwyg'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import { stateToHTML } from 'draft-js-export-html';

import {
    GridListTile,
    Tabs,
    Tab,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    TextField,
    FormControlLabel,
    FormGroup,
    Checkbox,
    InputAdornment,
    Input,
    Container,
    Grid,
    Drawer,
    Avatar,
    Popover,
    ButtonBase,
    Button,
    GridList,
    Snackbar
} from "@material-ui/core"

import {
    AvatarGroup,
    Alert
} from "@material-ui/lab"

import {
    AddRounded,
    ArrowBackRounded,
    OpenInNew
} from "@material-ui/icons"

import "../styles/checkout_pages.scss"

import logo from "../images/logo-smelt.png"
import MemberCard from './components/member_card'
import CheckoutSteps from './components/checkout_steps'

import CoreAnalytics from "../utils/core_analytics"
import { config } from "./../config"

import { BackendProjectFull, BackendFreelancerUserProject, BackendClientUserProject, BackendUserFull, BackendCheckoutFull, BackendCheckout, BackendFreelancerProfile, BackendPreferenceGroupFull, BackendSection, BackendInput, BackendInputExtra, BackendPreferenceGroupAnswers, BackendAnswer, BackendAnswerExtra, BackendFreelancerInvitation, BackendClientInvitation, BackendProposal } from "./interfaces/backend"
import { FrontendFreelancerInvitation, FrontendClientInvitation } from "./interfaces/frontend"

interface Params {
    id?: number
    invitationToken?: string
    noRedirect?: boolean
}

interface Props extends RouteComponentProps<{}> { }

interface State {
    freelancer_name: string
    client_name: string
    client_email: string

    freelancer_user_project_id?: number
    client_user_project_id?: number

    project_id: number
    project_name: string
    project_current_state: string
    checkout_id: number
    proposal_id?: number

    freelancers_invitations: FrontendFreelancerInvitation[]
    clients_invitations: FrontendClientInvitation[]
    freelancers_users_projects: BackendFreelancerUserProject[]
    clients_users_projects: BackendClientUserProject[]

    checkout?: BackendCheckout
    freelancerProfile?: BackendFreelancerProfile
    proposal?: BackendProposal

    sections: BackendSection[]
    inputs: BackendInput[]
    inputs_extras: BackendInputExtra[]
    answersBackend: BackendAnswer[]
    answersExtrasBackend: BackendAnswerExtra[]

    openMembersPopover: boolean
    memberAnchorRef: React.RefObject<HTMLButtonElement>

    is_freelancer: boolean
    is_client: boolean

    activeTabIndex: number
    activeAccordionIndex: number

    price: number
    paymentOptions: Record<string, any>[]
    deadline: number
    coverLetterEditor: EditorState
    deliverableEditor: EditorState
    summaryBriefingChangesEditor: EditorState
    whyMeEditor: EditorState

    priceCheck: boolean
    paymentOptionsCheck: boolean
    deadlineCheck: boolean
    coverLetterEditorCheck: boolean
    deliverableEditorCheck: boolean
    summaryBriefingChangesEditorCheck: boolean
    whyMeEditorCheck: boolean

    errorForm: boolean
    errorMessage: string
    errorDuration: number
}

const regexExpressions: Record<string, RegExp> = {
    invitationEmailCheck: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
}

const paymentsComponentsMap: Record<string, Record<string, any>> = {
    "direct": { "title": "Direct with service provider", "client_fee": 0, "freelancer_fee": 0, "description": <div className="textPaymentDescription"><p>This payment method allows you to get paid for the project directly by the custommer. If you enable this option and your customer hires through this payment method, you shoud talk to your customer to understand what would be the best payment method to do the transaction directly.</p><p>This payment method does not include payment nor quality protection by Smelt Technologies Inc.</p></div>, "extra_label": <p className="insuranceLabel">No insurance or<br /> quality guarantee</p> },
    "credit_card": { "title": "Credit Card", "client_fee": 0, "freelancer_fee": 0.02, "description": <div className="textPaymentDescription"><p>This payment method allows your customer to pay using a credit card with the money then being deposited to aprovided bank account of your choice.</p></div>, "extra_label": <p className="insuranceLabel">No insurance or<br /> quality guarantee</p> },
    "basic_escrow": { "title": "Basic Escrow", "client_fee": 0.03, "freelancer_fee": 0.02, "description": <div className="textPaymentDescription"><p>Basic escrow guarantees payment to you, based on the proposed description. It is great for your customer if he has the capability to evaluate the delivered project himself.</p><p>Smelt Technologies Inc may resolve any conflicts between the parties, but will evaluate based on facts and arguments, and not based on the actual project delivered.</p></div>, "extra_label": <p className="insuranceLabel">Dispute-based Escrow</p> },
    "assurance_escrow": { "title": "Technical-assurance Escrow", "client_fee": 0.05, "freelancer_fee": 0.02, "description": <div className="textPaymentDescription"><p>Technical-assurance escrow guarantees technical quality to your customer and payment to you, following the project described on the proposal.</p> <p>Before paying you, our technical team will evaluate the project after it has been delivered to check if it matches your proposal.</p></div>, "extra_label": <p className="insuranceLabel">Technical-quality guaranteed</p> },
    "flexible": { "title": "Flexible (buy-now pay-later)", "client_fee": -1, "freelancer_fee": -1, "description": <div className="textPaymentDescription"><p>Flexible options are evaluated on a case-by-case basis and it allows you to get paid, before your customer pays for the project. Smelt Technologies Inc. pays you and takes care of charging the customer in flexible methods.</p></div>, "extra_label": <p className="insuranceLabel">Technical-quality guaranteed<br />Pay after project delivered</p> }
}

class MakeProposal extends React.Component<Props, State> {
    setStateLikeSync(state: any) {
        return new Promise<void>((resolve) => {
            this.setState(state, resolve)
        });
    }

    showSnackMessage(message?: string, duration?: number) {
        if (message == undefined) {
            return
        }

        let actualDuration = duration ? duration : 3000

        this.setState({
            "errorForm": true,
            "errorMessage": message,
            "errorDuration": actualDuration
        })

        setTimeout(() => {
            this.setState({
                "errorForm": false,
                "errorMessage": "",
                "errorDuration": 0
            })
        }, actualDuration)
    }

    constructor(props: Props) {
        super(props)

        let defaultProposal = require('./../library_proposals/default.json')
        let coverLetterInitialEditor = defaultProposal.cover_letter
        let deliverableInitialEditor = defaultProposal.project_deliverables
        let summaryBriefingChangesEditor = defaultProposal.summary_changes
        let whyMeInitialEditor = defaultProposal.why_me

        this.state = {
            freelancer_name: "",
            client_name: "",
            client_email: "",

            freelancer_user_project_id: undefined,
            client_user_project_id: undefined,

            project_id: -1,
            project_name: "",
            project_current_state: "published",
            checkout_id: -1,
            proposal_id: undefined,

            freelancers_invitations: [],
            clients_invitations: [],
            freelancers_users_projects: [],
            clients_users_projects: [],

            checkout: undefined,
            freelancerProfile: undefined,
            proposal: undefined,

            sections: [],
            inputs: [],
            inputs_extras: [],
            answersBackend: [],
            answersExtrasBackend: [],

            openMembersPopover: false,
            memberAnchorRef: React.createRef(),

            is_freelancer: false,
            is_client: false,

            activeTabIndex: 1,
            activeAccordionIndex: -1,

            price: 100000,
            paymentOptions: [{ 'name': 'direct' }, { 'name': 'credit_card' }, { 'name': 'basic_escrow' }, { 'name': 'assurance_escrow' }, { 'name': 'flexible' }],
            deadline: Date.now(),

            coverLetterEditor: EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(coverLetterInitialEditor).contentBlocks)),
            deliverableEditor: EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(deliverableInitialEditor).contentBlocks)),
            summaryBriefingChangesEditor: EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(summaryBriefingChangesEditor).contentBlocks)),
            whyMeEditor: EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(whyMeInitialEditor).contentBlocks)),

            priceCheck: true,
            paymentOptionsCheck: true,
            deadlineCheck: true,
            coverLetterEditorCheck: true,
            deliverableEditorCheck: true,
            summaryBriefingChangesEditorCheck: true,
            whyMeEditorCheck: true,

            errorForm: false,
            errorMessage: "",
            errorDuration: 0
        }
    }

    async componentDidMount() {
        let persistenceToken = localStorage.getItem("persistence_token")
        if (persistenceToken === null) {
            this.props.history.replace("/login")
            return
        }

        let params = this.parseUrlParams(this.props.location.search)

        if (params.invitationToken) {
            try {
                const response = await fetch(`${config.API_URL}/execute_invitation`, {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
                    body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
                        persistence_token: persistenceToken,
                        invitation_token: params.invitationToken!
                    })
                })
                if (!response.ok) {
                    // this.showSnackMessage(`An error has occured: ${response.status}`)
                }
                const responseJSON = await response.json();
                this.responseExecuteInvitation(responseJSON.response, responseJSON.persistence_token, responseJSON.redirect_to_project_id, responseJSON.error)
            } catch (error) {
                this.showSnackMessage(`execute-invitation-fetch-error: ${error}`)
            }
        } else if (params.id) {
            try {
                const response = await fetch(`${config.API_URL}/read_project?project_id=${encodeURIComponent(params.id!)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
                    method: 'get',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
                })
                if (!response.ok) {
                    // this.showSnackMessage(`An error has occured: ${response.status}`)
                }
                const responseJSON = await response.json();
                let isDone = this.responseReadProjectResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.project_full, responseJSON.error)
                if (isDone) {
                    return
                }
            } catch (error) {
                this.showSnackMessage(`read-project-fetch-error: ${error}`)
            }

            try {
                let persistenceToken = localStorage.getItem("persistence_token")

                const response = await fetch(`${config.API_URL}/read_user?persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
                    method: 'get',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
                })
                if (!response.ok) {
                    // this.showSnackMessage(`An error has occured: ${response.status}`)
                }
                const responseJSON = await response.json();
                this.responseReadUserResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.user_full, responseJSON.error)
            } catch (error) {
                this.showSnackMessage(`read-user-fetch-error: ${error}`)
            }

            try {
                let persistenceToken = localStorage.getItem("persistence_token")
                let checkout_id = this.state.checkout_id

                const response = await fetch(`${config.API_URL}/read_checkout?checkout_id=${encodeURIComponent(checkout_id)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
                    method: 'get',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
                })
                if (!response.ok) {
                    // this.showSnackMessage(`An error has occured: ${response.status}`)
                }
                const responseJSON = await response.json();
                let isDone = this.responseReadCheckoutResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.checkout_full, responseJSON.error)
                if (isDone) {
                    return
                }
            } catch (error) {
                this.showSnackMessage(`read-checkout-fetch-error: ${error}`)
            }

            let isDone = this.redirect(4, params.noRedirect)
            if (isDone) {
                return
            }

            try {
                let persistenceToken = localStorage.getItem("persistence_token")
                let project_id = this.state.project_id

                const response = await fetch(`${config.API_URL}/list_project_invitations?project_id=${encodeURIComponent(project_id)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
                    method: 'get',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
                })
                if (!response.ok) {
                    // this.showSnackMessage(`An error has occured: ${response.status}`)
                }
                const responseJSON = await response.json();
                let isDone = this.responseListProjectInvitationsResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.freelancers_invitations, responseJSON.clients_invitations, responseJSON.error)
                if (isDone) {
                    return
                }
            } catch (error) {
                this.showSnackMessage(`list-project-invitations-fetch-error: ${error}`)
            }

            try {
                let persistenceToken = localStorage.getItem("persistence_token")
                let preference_group_id = this.state.checkout?.preference_group_id ? this.state.checkout?.preference_group_id! : ""

                const response = await fetch(`${config.API_URL}/read_preference_group?preference_group_id=${encodeURIComponent(preference_group_id)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
                    method: 'get',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
                })
                if (!response.ok) {
                    // this.showSnackMessage(`An error has occured: ${response.status}`)
                }
                const responseJSON = await response.json();
                let isDone = this.responseReadPreferenceGroupResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.preference_group_full, responseJSON.error)
                if (isDone) {
                    return
                }
            } catch (error) {
                this.showSnackMessage(`read-preference-group-fetch-error: ${error}`)
            }

            try {
                let persistenceToken = localStorage.getItem("persistence_token")
                let preference_group_id = this.state.checkout?.preference_group_id ? this.state.checkout?.preference_group_id! : ""

                const response = await fetch(`${config.API_URL}/read_preference_group_answers?preference_group_id=${encodeURIComponent(preference_group_id)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
                    method: 'get',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
                })
                if (!response.ok) {
                    // this.showSnackMessage(`An error has occured: ${response.status}`)
                }
                const responseJSON = await response.json();
                this.responseReadPreferenceGroupAnswersResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.preference_group_answers, responseJSON.error)
            } catch (error) {
                this.showSnackMessage(`read-preference-group-answers-fetch-error: ${error}`)
            }

            let proposal_id = this.state.checkout?.proposal_id
            if (proposal_id) {
                try {
                    let persistenceToken = localStorage.getItem("persistence_token")

                    const response = await fetch(`${config.API_URL}/read_proposal?proposal_id=${encodeURIComponent(proposal_id)}&persistence_token=${encodeURIComponent(persistenceToken!)}`, { // todo: create parser function, and interface for params?
                        method: 'get',
                        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
                    })
                    if (!response.ok) {
                        // this.showSnackMessage(`An error has occured: ${response.status}`)
                    }
                    const responseJSON = await response.json();
                    let isDone = this.responseReadProposalResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.proposal, responseJSON.error)
                    if (isDone) {
                        return
                    }
                } catch (error) {
                    this.showSnackMessage(`read-checkout-fetch-error: ${error}`)
                }
            }
        } else {
            this.showSnackMessage("Missing invitation_id or project_id")
            this.props.history.replace("/dashboard")
        }
    }

    parseUrlParams = (query: string): Params => {
        const urlSearchParams = new URLSearchParams(query)

        let params: Params = {
            id: urlSearchParams.get("id") === null ? undefined : Number(urlSearchParams.get("id")!),
            invitationToken: urlSearchParams.get("invitation_token") === null ? undefined : urlSearchParams.get("invitation_token")!,
            noRedirect: urlSearchParams.get("no_redirect") === null ? undefined : (urlSearchParams.get("no_redirect")! == "true")
        }
        return params
    }

    redirect = (currentStep: number, no_redirect?: boolean) => {
        let activeStep = this.getActiveStep()

        if (no_redirect) {
            return false
        }

        if (currentStep == activeStep) {
            return false
        }

        var pushString = undefined

        if (activeStep == 3) {
            pushString = `/view_project_briefing?id=${this.state.project_id}`
        } else if (activeStep == 4) {
            pushString = `/make_proposal?id=${this.state.project_id}`
        } else if (activeStep == 5) {
            pushString = `/check_payment?id=${this.state.project_id}`
        } else if (activeStep == 6) {
            pushString = `/make_delivery?id=${this.state.project_id}`
        } else if (activeStep == 7) {
            pushString = `/make_delivery?id=${this.state.project_id}`
        }

        if (pushString) {
            this.props.history.push(pushString)
        }

        return true
    }

    getActiveStep() {
        if (this.state.checkout?.briefing_step_status != undefined && this.state.checkout?.proposal_step_status == undefined) {
            return 3
        } else if (this.state.checkout?.proposal_step_status != undefined && this.state.checkout?.payment_step_status == undefined) {
            return 4
        } else if (this.state.checkout?.payment_step_status != undefined && this.state.checkout?.delivery_step_status == undefined) {
            return 5
        } else if (this.state.checkout?.delivery_step_status != undefined && this.state.checkout?.release_step_status == undefined) {
            return 6
        } else if (this.state.checkout?.release_step_status != undefined) {
            return 7
        }

        return 0
    }

    responseExecuteInvitation = (response: boolean, persistence_token?: string, redirect_to_project_id?: number, error?: string): void => {
        if (response === true) {
            this.showSnackMessage("invitation successful")
            this.props.history.replace(`/view_project_briefing?id=${redirect_to_project_id!}`)
            window.location.reload()
        } else {
            this.showSnackMessage(error)
        }
    }

    responseReadProjectResolve = (response: boolean, persistence_token?: string, project_full?: BackendProjectFull, error?: string): boolean => {
        if (response === true) {
            if ((project_full!.project.current_state !== "published" && project_full!.project.current_state !== "archived") || project_full!.preferences_groups.length != 1) {
                this.showSnackMessage("Project is not published or archived, or has 0 or more than 1 (not possible) preference group")
                this.props.history.replace("/dashboard")
                return true
            }

            this.setState({
                "freelancer_name": project_full!.freelancers_users_projects[0].first_name,
                "client_name": (project_full!.clients_users_projects.length == 1) ? project_full!.clients_users_projects[0].first_name : project_full!.freelancers_users_projects[0].first_name,
                "client_email": (project_full!.clients_users_projects.length == 1) ? project_full!.clients_users_projects[0].email : project_full!.freelancers_users_projects[0].email,
                "freelancer_user_project_id": project_full!.freelancer_user_project_id,
                "client_user_project_id": project_full!.client_user_project_id,
                "project_id": project_full!.project.id,
                "project_name": project_full!.project.name,
                "project_current_state": project_full!.project.current_state,
                "checkout_id": project_full!.checkouts[0].id,
                "clients_users_projects": project_full!.clients_users_projects,
                "freelancers_users_projects": project_full!.freelancers_users_projects,
            })
            localStorage.setItem("persistence_token", persistence_token as string)
            document.title = project_full!.project.name + " - Smelt"
        } else {
            this.showSnackMessage(error)
            this.props.history.replace("/login")
            return true
        }
        return false
    }

    responseListProjectInvitationsResolve = (response: boolean, persistence_token?: string, freelancers_invitations?: BackendFreelancerInvitation[], clients_invitations?: BackendClientInvitation[], error?: string): boolean => {
        if (response === true) {
            this.setState({
                "freelancers_invitations": freelancers_invitations!.map((freelancer_invitation) => ({ "invitation_email": freelancer_invitation.invitation_email, "invitation_email_check": true, "created_at": freelancer_invitation.created_at, "is_saved": true })), // check so there is always at least one section
                "clients_invitations": clients_invitations!.map((client_invitation) => ({ "invitation_email": client_invitation.invitation_email, "invitation_email_check": true, "created_at": client_invitation.created_at, "is_saved": true }))
            })
            localStorage.setItem("persistence_token", persistence_token as string)
        } else {
            this.showSnackMessage(error)
            this.props.history.replace("/login")
            return true
        }
        return false
    }

    responseReadUserResolve = (response: boolean, persistence_token?: string, user_full?: BackendUserFull, error?: string): void => {
        if (response === true) {
            this.setState({
                "is_freelancer": user_full!.is_freelancer,
                "is_client": user_full!.is_client
            })
            localStorage.setItem("persistence_token", persistence_token as string)
        } else {
            this.showSnackMessage(error) // user with token not loaded
            localStorage.removeItem("persistence_token")
            this.props.history.replace("/login")
        }
    }

    responseReadCheckoutResolve = (response: boolean, persistence_token?: string, checkout_full?: BackendCheckoutFull, error?: string): boolean => {
        if (response === true) {
            this.setState({
                "checkout": checkout_full!.checkout,
                "freelancerProfile": checkout_full!.freelancer_profile,
                "proposal_id": checkout_full!.checkout.proposal_id
            })
            localStorage.setItem("persistence_token", persistence_token as string)
        } else {
            this.showSnackMessage(error)
            this.props.history.replace("/login")
            return true
        }
        return false
    }

    responseReadProposalResolve = (response: boolean, persistence_token?: string, proposal?: BackendProposal, error?: string): boolean => {
        if (response === true) {
            this.setState({
                "proposal": proposal!,
                "price": proposal!.price,
                "paymentOptions": (proposal!.payment_options ? proposal!.payment_options : []).map(payment_option => JSON.parse(payment_option.replace(/'/g, '"'))),
                "deadline": new Date(proposal!.deadline).getTime(),
                "coverLetterEditor": EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(proposal!.cover_letter).contentBlocks)),
                "deliverableEditor": EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(proposal!.deliverable).contentBlocks)),
                "summaryBriefingChangesEditor": EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(proposal!.summary_briefing_changes).contentBlocks)),
                "whyMeEditor": EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(proposal!.why_me).contentBlocks))
            })
            localStorage.setItem("persistence_token", persistence_token as string)
        } else {
            this.showSnackMessage(error)
            this.props.history.replace("/dashboard")
            return true
        }
        return false
    }

    responseReadPreferenceGroupResolve = (response: boolean, persistence_token?: string, preference_group_full?: BackendPreferenceGroupFull, error?: string): boolean => {
        if (response === true) {
            if (preference_group_full!.preference_group.current_state !== "published" && preference_group_full!.preference_group.current_state !== "archived") {
                this.showSnackMessage("Preference group not is not published or archived - should never happen and always match the project published or archived")
                this.props.history.replace("/dashboard")
                return true
            }

            this.setState({
                "sections": preference_group_full!.sections.map((section) => ({ "id": section.id, "preference_group_id": section.preference_group_id, "name": section.name, "order": section.order })),
                "inputs": preference_group_full!.inputs.map((input) => ({ "id": input.id, "section_id": input.section_id, "freelancer_project_id": input.freelancer_project_id, "order": input.order, "type": input.type, "is_required": input.is_required, "title": input.title, "thumbnail": JSON.parse(((input.thumbnail ? input.thumbnail : "{}")).replace(/'/g, '"')), "input_extra_id": input.input_extra_id })),
                "inputs_extras": preference_group_full!.inputs_extras.map((input_extra) => ({ "id": input_extra.id, "quote": input_extra.quote, "file_s3_link": input_extra.file_s3_link, "url_link": input_extra.url_link, "specific_part": input_extra.specific_part, "relevance_reason": input_extra.relevance_reason, "options": (input_extra.options ? input_extra.options : []).map(option => JSON.parse(option.replace(/'/g, '"'))) }))
            })
            localStorage.setItem("persistence_token", persistence_token as string)
        } else {
            this.showSnackMessage(error)
            this.props.history.replace("/login")
            return true
        }
        return false
    }

    responseReadPreferenceGroupAnswersResolve = (response: boolean, persistence_token?: string, preference_group_answers?: BackendPreferenceGroupAnswers, error?: string): void => {
        if (response === true) {
            this.setState({
                "answersBackend": preference_group_answers!.answers.map((answer) => ({ "id": answer.id, "input_id": answer.input_id, "client_project_id": answer.client_project_id, "has_skipped": answer.has_skipped, "free_text": answer.free_text, "answer_extra_id": answer.answer_extra_id, "created_at": answer.created_at })), // check so there is always at least one section
                "answersExtrasBackend": preference_group_answers!.answers_extras.map((answer_extra) => ({ "id": answer_extra.id, "like_dislike": answer_extra.like_dislike, "quote": answer_extra.quote, "file_s3_link": answer_extra.file_s3_link, "url_link": answer_extra.url_link, "specific_part": answer_extra.specific_part, "relevance_reason": answer_extra.relevance_reason, "options": (answer_extra.options ? answer_extra.options : []).map(option => JSON.parse(option.replace(/'/g, '"'))) }))
            })
            localStorage.setItem("persistence_token", persistence_token as string)

        } else {
            this.showSnackMessage(error)
            this.props.history.replace("/login")
        }
    }

    responseCreateProjectInvitationResolve = (response: boolean, persistence_token?: string, error?: string, extras?: Record<string, any>): void => {
        if (response === true) {
            let created_at = extras!.created_at

            this.setState(previousState => {
                let newClientsInvitations = previousState.clients_invitations.map((client_invitation) => {
                    var newClientInvitation = client_invitation

                    if (newClientInvitation.created_at === created_at) {
                        newClientInvitation.is_loading_save = false
                        newClientInvitation.is_saved = true
                    }

                    return newClientInvitation
                })

                return { "clients_invitations": newClientsInvitations }
            })
        } else {
            let created_at = extras!.created_at
            this.showSnackMessage(error)
            this.setState(previousState => {
                let newClientsInvitations = previousState.clients_invitations.map((client_invitation) => {
                    var newClientInvitation = client_invitation

                    if (newClientInvitation.created_at === created_at) {
                        newClientInvitation.is_loading_save = false
                    }

                    return newClientInvitation
                })

                return { "clients_invitations": newClientsInvitations }
            })
        }
    }

    responseSaveCheckoutResolve = (response: boolean, persistence_token?: string, checkout_id?: number, error?: string): void => {
        if (response === true) {
            localStorage.setItem("persistence_token", persistence_token as string)
            window.location.reload()
        } else {
            this.showSnackMessage(error) // some regex problem?
        }
    }

    responseSaveProposalResolve = (response: boolean, persistence_token?: string, proposal_id?: number, error?: string): void => {
        if (response === true) {
            this.setStateLikeSync({ "proposal_id": proposal_id! })
            localStorage.setItem("persistence_token", persistence_token as string)
        } else {
            this.showSnackMessage(error) // some regex problem?
        }
    }

    updateInvitation = async (created_at: Date, invitation_email?: string, type?: string, is_saved?: boolean, is_deleted?: boolean) => {
        if (type == "freelancer") { // type will always have to be client on the MVP, so we do not really handle type changes here nor freelancer functions
            return
        }

        if (is_saved) {
            let persistenceToken = localStorage.getItem("persistence_token")
            let project_id = this.state.project_id

            let client_invitation = this.state.clients_invitations.filter((client_invitation) => created_at === client_invitation.created_at)[0]
            let invitationType = "client"
            let email = client_invitation.invitation_email
            let project_permissions = ["is_preferences_responder", "is_checkout_editor"]

            let newClientsInvitations = this.state.clients_invitations.filter((newClientInvitation) => (newClientInvitation.created_at !== client_invitation.created_at || (newClientInvitation.created_at === client_invitation.created_at && !is_deleted))).map((newClientInvitation) => {
                if (newClientInvitation.created_at === client_invitation.created_at) {
                    if (client_invitation.invitation_email) {
                        newClientInvitation.is_loading_save = true
                    }
                }

                return newClientInvitation
            })
            await this.setStateLikeSync({ "clients_invitations": newClientsInvitations })

            await CoreAnalytics.trackProjectInviteMember(this.state.project_id, email);

            try {
                const response = await fetch(`${config.API_URL}/create_project_invitation`, {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
                    body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
                        persistence_token: persistenceToken,
                        project_id: project_id,
                        type: invitationType,
                        email: email,
                        project_permissions: project_permissions
                    })
                })
                if (!response.ok) {
                    // this.showSnackMessage(`An error has occured: ${response.status}`)
                }
                const responseJSON = await response.json();

                this.responseCreateProjectInvitationResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.error, { "created_at": created_at })
            } catch (error) {
                this.showSnackMessage(`create-project-invitations-fetch-error: ${error}`)
            }
        } else {
            let newClientsInvitations = this.state.clients_invitations.filter((client_invitation) => (client_invitation.created_at !== created_at || (client_invitation.created_at === created_at && !is_deleted))).map((client_invitation) => {
                var newClientInvitation = client_invitation

                if (newClientInvitation.created_at === created_at) {
                    if (invitation_email) {
                        newClientInvitation.invitation_email = invitation_email
                        newClientInvitation.invitation_email_check = (RegExp(regexExpressions["invitationEmailCheck"])).test(invitation_email)
                    }
                }

                return newClientInvitation
            })
            await this.setStateLikeSync({ "clients_invitations": newClientsInvitations })
        }
    }

    handleChangeTab = (event: React.ChangeEvent<any>, newValue: number) => {
        this.setState({ activeTabIndex: newValue + 1 });
    }

    handleChangeAccordion = (newValue: number) => {
        this.setState((previousState) => ({ activeAccordionIndex: previousState.activeAccordionIndex == newValue ? -1 : newValue }));
    }

    handleCoverLetterEditorChange = (editorState: EditorState) => {
        this.setState({
            coverLetterEditor: editorState,
            coverLetterEditorCheck: stateToHTML(editorState.getCurrentContent()).length < 8192
        });
    };

    handleDeliverableEditorChange = (editorState: EditorState) => {
        this.setState({
            deliverableEditor: editorState,
            deliverableEditorCheck: stateToHTML(editorState.getCurrentContent()).length < 8192
        });
    };

    handleSummaryBriefingChangesEditorChange = (editorState: EditorState) => {
        this.setState({
            summaryBriefingChangesEditor: editorState,
            deliverableEditorCheck: stateToHTML(editorState.getCurrentContent()).length < 8192
        });
    };

    handleWhyMeEditorChange = (editorState: EditorState) => {
        this.setState({
            whyMeEditor: editorState,
            deliverableEditorCheck: stateToHTML(editorState.getCurrentContent()).length < 8192
        });
    };

    handleAddInvitation = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        event.preventDefault();

        this.setState(previousState => { // must be of type client
            return { "clients_invitations": previousState.clients_invitations.concat({ "invitation_email": "", "invitation_email_check": false, "created_at": (new Date(Date.now())), is_saved: false }) }
        })
    }

    handleToggle = async () => {
        let newOpenMembersPopover = !this.state.openMembersPopover
        await this.setStateLikeSync({ "openMembersPopover": newOpenMembersPopover })

        if (this.state.memberAnchorRef.current !== null) {
            if (this.state.openMembersPopover === false) {
                this.state.memberAnchorRef.current.focus()
            }
        }
    }

    handleClose = (event: React.MouseEvent<Document | HTMLLIElement | HTMLButtonElement>) => {
        if (null !== this.state.memberAnchorRef.current) {
            if (this.state.memberAnchorRef.current && this.state.memberAnchorRef.current.contains(event.target as Node)) {
                return
            }
        }

        this.setState(({ openMembersPopover: false }))
    }

    handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
        const key = event.target.name
        var value = undefined

        try {
            value = parseInt(event.target.value) * 100
        } catch (error) {
            this.setState({ "priceCheck": false })
        }

        this.setState({ "price": value, "priceCheck": true } as any)
    }

    handleChangeDeadline = (event: React.ChangeEvent<HTMLInputElement>) => {
        var value = undefined

        try {
            value = new Date(Date.parse(event.target.value)).getTime() + 86400000 // given delta of a day when casting
        } catch (error) {
            this.setState({ "deadlineCheck": false })
        }

        this.setState({ "deadline": value, "deadlineCheck": true } as any)
    }

    handleCheck = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        let payment_option_check_name = event.target.name
        let already_has_option = this.state.paymentOptions?.map((paymentOption) => paymentOption.name).includes(payment_option_check_name)
        var newPaymentOptions = (this.state.paymentOptions ? this.state.paymentOptions : [])

        if (checked) {
            if (!already_has_option) {
                newPaymentOptions = newPaymentOptions.concat({ "name": payment_option_check_name })
            }
        } else {
            if (already_has_option) {
                newPaymentOptions = newPaymentOptions.filter((paymentOption) => paymentOption.name != payment_option_check_name)
            }
        }

        this.setState({ "paymentOptions": newPaymentOptions, paymentOptionsCheck: true })
    }

    handleSeeClientView = (event: React.MouseEvent<Document | HTMLLIElement | HTMLButtonElement>) => {
        window.open(`/view_proposal?id=${this.state.project_id}`, "_blank")
    }

    handleSaveInvitations = (event: React.MouseEvent<Document | HTMLLIElement | HTMLButtonElement>) => {
        if (null !== this.state.memberAnchorRef.current) {
            if (this.state.memberAnchorRef.current && this.state.memberAnchorRef.current.contains(event.target as Node)) {
                return
            }
        }

        var self = this;
        this.state.clients_invitations.map((client_invitation_obj) => {
            if (client_invitation_obj.is_saved == false) {
                self.updateInvitation(client_invitation_obj.created_at, undefined, undefined, true, undefined)
            }
        });

        // to-do: change for a callback to close it instead of waiting 2s. 2s are important to make sure the customer sees it loading
        setTimeout(() => {
            self.setState(({ openMembersPopover: false }))
        }, 2000);
    }

    handleSendProposal = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()

        await CoreAnalytics.trackProjectCreateProposal(this.state.project_id, this.state.project_name, this.state.clients_users_projects);

        let allValidationsPass = this.state.deliverableEditorCheck && this.state.priceCheck && this.state.paymentOptionsCheck && this.state.deadlineCheck && this.state.summaryBriefingChangesEditorCheck && this.state.coverLetterEditorCheck && this.state.whyMeEditorCheck

        if (allValidationsPass) {
            var persistenceToken = localStorage.getItem("persistence_token")
            let proposalParams = {
                "checkout_id": this.state.checkout_id,
                "deliverable": stateToHTML(this.state.deliverableEditor.getCurrentContent()),
                "price": this.state.price,
                "payment_options": this.state.paymentOptions.map((paymentOption) => (JSON.stringify(paymentOption))),
                "deadline": this.state.deadline,
                "summary_briefing_changes": stateToHTML(this.state.summaryBriefingChangesEditor.getCurrentContent()),
                "cover_letter": stateToHTML(this.state.coverLetterEditor.getCurrentContent()),
                "why_me": stateToHTML(this.state.whyMeEditor.getCurrentContent())
            }

            try {
                const response = await fetch(`${config.API_URL}/save_proposal`, {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
                    body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
                        persistence_token: persistenceToken,
                        action: "create",
                        proposal_params: proposalParams
                    })
                })
                if (!response.ok) {
                    // this.showSnackMessage(`An error has occured: ${response.status}`)
                }
                const responseJSON = await response.json();

                this.responseSaveProposalResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.proposal_id, responseJSON.error)
            } catch (error) {
                this.showSnackMessage(`save-proposal-fetch-error: ${error}`)
            }

            persistenceToken = localStorage.getItem("persistence_token")

            try {
                const response = await fetch(`${config.API_URL}/save_checkout`, {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
                    body: JSON.stringify({ // todo: create JSON.stringify parser function, and interface for params?
                        persistence_token: persistenceToken,
                        action: "update",
                        checkout_id: this.state.checkout_id,
                        checkout_params: { "proposal_step_status": "pending_client", "proposal_id": this.state.proposal_id }
                    })
                })
                if (!response.ok) {
                    // this.showSnackMessage(`An error has occured: ${response.status}`)
                }
                const responseJSON = await response.json();
                this.responseSaveCheckoutResolve(responseJSON.response, responseJSON.persistence_token, responseJSON.checkout_id, responseJSON.error)
            } catch (error) {
                this.showSnackMessage(`save-checkout-fetch-error: ${error}`)
            }
        }
    }

    push = (page: string) => {
        this.props.history.push(page)
    }

    formatDate = (date: number) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    render() {
        let SectionsSummariesCards = this.state.sections.sort((section1, section2) => section1.order > section2.order ? 1 : -1).map((section) => {
            let InputsAnswersCards = this.state.inputs.filter((input) => input.section_id === section.id).sort((input1, input2) => input1.order > input2.order ? 1 : -1).map((input) => {
                // let input_extra = this.state.inputs_extras.filter((input_extra) => input_extra.id == input.input_extra_id)
                let answers = this.state.answersBackend.filter((answer) => answer.input_id == input.id)
                let answers_extras_ids = answers.map((answer) => answer.answer_extra_id)

                let answers_extras = this.state.answersExtrasBackend.filter((answer_extra) => answers_extras_ids.includes(answer_extra.id))

                return (
                    <ul>{input.title}
                        <br />
                        <>
                            {
                                {
                                    question: (
                                        answers.map((answer: BackendAnswer) => {
                                            return (<p>{answer.free_text}</p>)
                                        })
                                    ),
                                    checkbox: (
                                        answers.map((answer) => (
                                            answers_extras.filter((answer_extra) => (answer_extra.id == answer.answer_extra_id)).map((answer_extra) => (
                                                answer_extra.options?.map((option) => (
                                                    <p>{option.name}</p>
                                                ))
                                            ))
                                        ))
                                    ),
                                    radio: (
                                        answers.map((answer) => (
                                            answers_extras.filter((answer_extra) => (answer_extra.id == answer.answer_extra_id)).map((answer_extra) => (
                                                answer_extra.options?.map((option) => (
                                                    <p>{option.name}</p>
                                                ))
                                            ))
                                        ))
                                    ),
                                    ask_feedback: (
                                        answers.map((answer) => (
                                            answers_extras.filter((answer_extra) => (answer_extra.id == answer.answer_extra_id)).map((answer_extra) => (
                                                <>
                                                    <p>{answer_extra.like_dislike}</p>
                                                    <p>{answer.free_text}</p>
                                                </>
                                            ))
                                        ))
                                    ),
                                    ask_example: (
                                        answers.map((answer) => (
                                            answers_extras.filter((answer_extra) => (answer_extra.id == answer.answer_extra_id)).map((answer_extra) => (
                                                <>
                                                    <p>{answer_extra.quote}{answer_extra.url_link}{answer_extra.file_s3_link}</p>
                                                    <p>{answer_extra.specific_part}</p>
                                                    <p>{answer_extra.relevance_reason}</p>
                                                </>
                                            ))
                                        ))
                                    ),
                                    multiline: (
                                        answers.map((answer) => (
                                            answers_extras.filter((answer_extra) => (answer_extra.id == answer.answer_extra_id)).map((answer_extra) => (
                                                answer_extra.options?.map((option) => (
                                                    <p>{option.name}</p>
                                                ))
                                            ))
                                        ))
                                    )
                                }[input.type]
                            }
                        </>
                        <br />
                    </ul>
                )
            })

            return (
                <Accordion expanded={this.state.activeAccordionIndex == section.order} onChange={() => { this.handleChangeAccordion(section.order) }}>
                    <AccordionSummary>
                        <Typography>{section.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {InputsAnswersCards}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            )
        })

        let MembersCardFreelancers = this.state.freelancers_users_projects.map((freelancer_user_project) => {
            return (
                <MemberCard
                    invitation_email={freelancer_user_project.email}
                    type={"freelancer"}
                    invitation_email_check={true}
                    is_loading_save={false}
                    created_at={undefined}
                    is_saved={true}
                    is_invitation={false}
                    is_self={freelancer_user_project.freelancer_project_id === this.state.freelancer_user_project_id}
                    updateInvitation={this.updateInvitation}
                />
            )
        })

        let MembersCardClients = this.state.clients_users_projects.map((client_user_project) => {
            return (
                <MemberCard
                    invitation_email={client_user_project.email}
                    type={"client"}
                    invitation_email_check={true}
                    is_loading_save={false}
                    created_at={undefined}
                    is_saved={true}
                    is_invitation={false}
                    is_self={client_user_project.client_project_id === this.state.client_user_project_id}
                    updateInvitation={this.updateInvitation}
                />
            )
        })

        let MembersCardFreelancersInvitations = this.state.freelancers_invitations.map((freelancer_invitation) => {
            return (
                <MemberCard
                    invitation_email={freelancer_invitation.invitation_email}
                    type={"freelancer"}
                    invitation_email_check={freelancer_invitation.invitation_email_check}
                    created_at={freelancer_invitation.created_at}
                    is_loading_save={freelancer_invitation.is_loading_save}
                    is_saved={freelancer_invitation.is_saved}
                    is_invitation={true}
                    is_self={false}
                    updateInvitation={this.updateInvitation}
                />
            )
        })

        let MembersCardClientsInvitations = this.state.clients_invitations.map((client_invitation) => {
            return (
                <MemberCard
                    invitation_email={client_invitation.invitation_email}
                    type={"client"}
                    invitation_email_check={client_invitation.invitation_email_check}
                    created_at={client_invitation.created_at}
                    is_loading_save={client_invitation.is_loading_save}
                    is_saved={client_invitation.is_saved}
                    is_invitation={true}
                    is_self={false}
                    updateInvitation={this.updateInvitation}
                />
            )
        })

        let initialsAllMembers = ([] as string[]).concat(this.state.freelancers_users_projects.map((user) => { return String(user!.first_name).charAt(0) }), this.state.clients_users_projects.map((user) => { return String(user!.first_name).charAt(0) }));
        let initialsMembersAvatars = initialsAllMembers.map((initial_member) => {
            return (
                <Avatar alt={initial_member}>
                    {initial_member}
                </Avatar>
            )
        })

        return (
            <div className="mainStyle checkoutStyle checkoutStyleLeft">
                <Snackbar open={this.state.errorForm} autoHideDuration={this.state.errorDuration}>
                    <Alert severity="error">
                        {this.state.errorMessage}
                    </Alert>
                </Snackbar>

                <div className="drawerRoot">
                    <Drawer
                        className="drawerDrawer"
                        variant="permanent"
                        classes={{
                            paper: "drawerDrawerPaper"
                        }}
                        anchor="left">
                        <div className="topButtonMenu">
                            <a href="/dashboard">
                                <ArrowBackRounded /> <span>back</span>
                            </a>
                        </div>

                        <div className="logo">
                            <a href="/dashboard">
                                <img src={logo} width="115" height="32" />
                            </a>
                        </div>

                        <CheckoutSteps
                            id={this.state.checkout?.id}
                            project_id={this.state.checkout?.project_id}
                            briefing_step_status={this.state.checkout?.briefing_step_status}
                            proposal_step_status={this.state.checkout?.proposal_step_status}
                            payment_step_status={this.state.checkout?.payment_step_status}
                            delivery_step_status={this.state.checkout?.delivery_step_status}
                            release_step_status={this.state.checkout?.release_step_status}
                            preference_group_id={this.state.checkout?.preference_group_id}
                            proposal_id={this.state.checkout?.proposal_id}
                            payment_id={this.state.checkout?.payment_id}
                            delivery_id={this.state.checkout?.delivery_id}
                            release_id={this.state.checkout?.release_id}

                            is_freelancer={true}
                            is_client={false}
                            number_of_clients={this.state.clients_users_projects.length}

                            profile_id={this.state.freelancerProfile?.profile_id}
                            profile_image_link={this.state.freelancerProfile?.profile_image_link}
                            freelancer_name={this.state.freelancer_name}
                            job_title={this.state.freelancerProfile?.job_title}
                            city={this.state.freelancerProfile?.city}
                            country={this.state.freelancerProfile?.country}
                            job_description={this.state.freelancerProfile?.job_description}
                            project_name={this.state.project_name}

                            client_name={this.state.client_name}
                            client_email={this.state.client_email}

                            push={this.push}
                        />
                    </Drawer>

                    <main className="drawerContent">
                        <Container
                            maxWidth="lg"
                            className="topCenterContent topContent">
                            <Grid container>
                                <Grid item xs={12}>
                                    <div className="titleBar">
                                        <h1>Make Proposal</h1>

                                        <div className="keyboardTipsBar">
                                            <ButtonBase
                                                ref={this.state.memberAnchorRef}
                                                focusRipple
                                                className="customButton"
                                                onClick={this.handleToggle}>
                                                <label className="avatarGroupLabel left">
                                                    invite customers
                                                </label>
                                                <AvatarGroup max={4} className="left">
                                                    {initialsMembersAvatars}
                                                </AvatarGroup>
                                            </ButtonBase>
                                            <Popover
                                                open={this.state.openMembersPopover}
                                                anchorEl={this.state.memberAnchorRef.current}
                                                className="inviteMembersPopover"
                                                onClose={this.handleClose}
                                                anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "center"
                                                }}
                                                transformOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center"
                                                }}>
                                                <div className="leftTitleBox">
                                                    <h3>Invite new customers</h3>
                                                    <p>
                                                        Send invitation links to team
                                                        members
                                                    </p>
                                                </div>
                                                <div className="clear"></div>
                                                <p></p>
                                                <div className="membersList">
                                                    <GridList
                                                        cellHeight={45}
                                                        className="gridList"
                                                        cols={1}
                                                        spacing={10}>
                                                        {MembersCardFreelancers}
                                                        {MembersCardClients}
                                                        {MembersCardFreelancersInvitations}
                                                        {MembersCardClientsInvitations}
                                                    </GridList>
                                                    <div className="buttons">
                                                        {this.state.freelancer_user_project_id && <Button
                                                            startIcon={<AddRounded />}
                                                            variant="outlined"
                                                            className="outlined left"
                                                            size="medium"
                                                            onClick={this.handleAddInvitation}
                                                        >
                                                            add member
                                                        </Button>}
                                                        <Button onClick={this.handleSaveInvitations}
                                                            variant="contained"
                                                            color="primary"
                                                            size="medium"
                                                            className="primaryButton right">
                                                            save
                                                        </Button>
                                                        <div className="clear"></div>
                                                    </div>
                                                </div>
                                            </Popover>

                                            {(this.state.checkout?.proposal_step_status == "pending_freelancer" || this.state.checkout?.proposal_step_status == "pending_client") && <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                className="primaryButton largeButton right"
                                                onClick={this.handleSendProposal}
                                            >
                                                {this.state.checkout?.proposal_step_status == "pending_freelancer" ? "create proposal" : (this.state.checkout?.proposal_step_status == "pending_client" ? "update proposal" : "")}
                                            </Button>}

                                            <Button
                                                size="small"
                                                className="smallButton right"
                                                onClick={this.handleSeeClientView}
                                            >
                                                <OpenInNew />
                                                see client view
                                            </Button>
                                        </div>
                                        <div className="clear"></div>

                                        {this.state.checkout?.proposal_step_status == "pending_freelancer" && <Alert severity="info" className="headerMessage">
                                            Tip: Create a custom proposal to send to your customer.
                                        </Alert>}

                                        {this.state.checkout?.proposal_step_status == "pending_client" && (this.state.clients_invitations.length == 0 && this.state.clients_users_projects.length == 0) && <Alert severity="info" className="headerMessage">
                                            Tip: Invite a customer to get the proposal.
                                        </Alert>}

                                        {this.state.checkout?.proposal_step_status == "pending_client" && (this.state.clients_invitations.length > 0 || this.state.clients_users_projects.length > 0) && <Alert severity="info" className="headerMessage">
                                            Tip: Your customer has received the proposal and you should wait for his response.
                                        </Alert>}

                                    </div>
                                </Grid>
                            </Grid>
                            <Container
                                maxWidth="md"
                                className="topCenterContent topContent">
                                <Grid container>
                                    <Grid item xs={12} className="questionsListTable">
                                        <form autoComplete="off">
                                            <Grid
                                                container
                                                justify="flex-start"
                                                xs={12}
                                                direction="column"
                                                className="block"
                                                alignItems="stretch"
                                                spacing={1}>
                                                <Grid item xs={12}>
                                                    <h2 className="left">Write your proposal</h2>

                                                    <div className="clear"></div>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Tabs
                                                        value={this.state.activeTabIndex - 1}
                                                        indicatorColor="primary"
                                                        textColor="primary"
                                                        onChange={this.handleChangeTab}
                                                    >
                                                        <Tab label="Cover Letter"></Tab>
                                                        <Tab label="Project Deliverables"> </Tab>
                                                        <Tab label="Why me?"> </Tab>
                                                        <Tab label="Project Investment"> </Tab>
                                                    </Tabs>
                                                    <GridList
                                                        cellHeight="auto"
                                                        className="gridList"
                                                        cols={1}
                                                        spacing={10}>
                                                        <GridListTile cols={1} className="noBorder">
                                                            {this.state.activeTabIndex == 1 && <div className="questionBox">
                                                                <h3>Cover Letter</h3>
                                                                <Editor editorState={this.state.coverLetterEditor} onEditorStateChange={e => this.handleCoverLetterEditorChange(e)} toolbar={{ options: ['inline', 'blockType', 'fontSize', 'list', 'colorPicker', 'link', 'emoji', 'image'], inline: { options: ['bold', 'italic', 'underline', 'strikethrough'] } }} wrapperClassName="editorWrapper"
                                                                    editorClassName="editorContent"
                                                                    toolbarClassName="editorToolbar" />
                                                            </div>}
                                                            {this.state.activeTabIndex == 2 && <div className="questionBox">
                                                                <h3>Final Due Date</h3>
                                                                <TextField
                                                                    type="date"
                                                                    required
                                                                    className="outlinedStyle"
                                                                    name="deadline"
                                                                    helperText={'You cannot edit the due date after the proposal has been accepted by your customer.'}
                                                                    onChange={this.handleChangeDeadline}
                                                                    value={this.formatDate(this.state.deadline)}
                                                                />

                                                                <h3>Project Deliverables</h3>
                                                                <Editor editorState={this.state.deliverableEditor} onEditorStateChange={e => this.handleDeliverableEditorChange(e)} toolbar={{ options: ['inline', 'blockType', 'fontSize', 'list', 'colorPicker', 'link', 'emoji', 'image'], inline: { options: ['bold', 'italic', 'underline', 'strikethrough'] } }} wrapperClassName="editorWrapper"
                                                                    editorClassName="editorContent"
                                                                    toolbarClassName="editorToolbar" />

                                                                <h3>Initial Project Requirements Summary</h3>
                                                                <p>Below is a summary of your customer's initial thoughts for this project. You can't edit the inital thoughts and it will be visible on the proposal, but you can suggest changes to these initial thoughts by typing on the field below.</p>

                                                                {SectionsSummariesCards}

                                                                <h3>Summary of Changes on Project Requirements</h3>
                                                                <Editor editorState={this.state.summaryBriefingChangesEditor} onEditorStateChange={e => this.handleSummaryBriefingChangesEditorChange(e)} toolbar={{ options: ['inline', 'blockType', 'fontSize', 'list', 'colorPicker', 'link', 'emoji', 'image'], inline: { options: ['bold', 'italic', 'underline', 'strikethrough'] } }} wrapperClassName="editorWrapper"
                                                                    editorClassName="editorContent"
                                                                    toolbarClassName="editorToolbar" />
                                                            </div>}
                                                            {this.state.activeTabIndex == 3 && <div className="questionBox">
                                                                <h3>Why Me?</h3>
                                                                <Editor editorState={this.state.whyMeEditor} onEditorStateChange={e => this.handleWhyMeEditorChange(e)} toolbar={{ options: ['inline', 'blockType', 'fontSize', 'list', 'colorPicker', 'link', 'emoji', 'image'], inline: { options: ['bold', 'italic', 'underline', 'strikethrough'] } }} wrapperClassName="editorWrapper"
                                                                    editorClassName="editorContent"
                                                                    toolbarClassName="editorToolbar" />

                                                            </div>}
                                                            {this.state.activeTabIndex == 4 && <div className="questionBox">
                                                                <h3>Project Investment</h3>
                                                                <Input
                                                                    startAdornment={<InputAdornment position="start">USD </InputAdornment>}
                                                                    type="number"
                                                                    required
                                                                    className="outlinedStyle"
                                                                    name="price"
                                                                    onChange={this.handleChangePrice}
                                                                    value={this.state.price / 100}
                                                                />
                                                                <p>You will be paid according to the payment method chosen by you and your customer. The total project investment cannot be changed after your customer hires the project.</p>

                                                                <h3>Payment Methods Available</h3>

                                                                <p>Please choose which payment methods you would like to make available for your customer to hire your services.</p>

                                                                <FormGroup className="paymentOptions">
                                                                    <FormControlLabel className="fullRow" value="direct" control={<Checkbox checked={this.state.paymentOptions?.map((paymentOption) => paymentOption.name).includes("direct")} name="direct" onChange={this.handleCheck} />} label={paymentsComponentsMap["direct"].title} />
                                                                    <div className="fullRowContent">
                                                                        {paymentsComponentsMap["direct"].description}
                                                                        <div className="totalInvestment">
                                                                            <p>
                                                                                <span>Fee</span>
                                                                                <span className="price"><strong>{paymentsComponentsMap["direct"].freelancer_fee * 100}</strong>%</span>
                                                                            </p>
                                                                            {paymentsComponentsMap["direct"].extra_label}
                                                                        </div>
                                                                        <div className="clear"></div>
                                                                    </div>
                                                                    <FormControlLabel className="fullRow" value="credit_card" control={<Checkbox checked={this.state.paymentOptions?.map((paymentOption) => paymentOption.name).includes("credit_card")} name="credit_card" onChange={this.handleCheck} />} label={paymentsComponentsMap["credit_card"].title} />
                                                                    <div className="fullRowContent">
                                                                        {paymentsComponentsMap["credit_card"].description}
                                                                        <div className="totalInvestment">
                                                                            <p>
                                                                                <span>Fee</span>
                                                                                <span className="price"><strong>{paymentsComponentsMap["credit_card"].freelancer_fee * 100}</strong>%</span>
                                                                            </p>

                                                                            {paymentsComponentsMap["credit_card"].extra_label}

                                                                            <p><small>Your customer may also have to pay a fee to use this payment system. It won't affect your earnings.</small></p>
                                                                        </div>

                                                                        <div className="clear"></div>
                                                                    </div>
                                                                    <div className="clear"></div>
                                                                    <FormControlLabel className="fullRow" value="basic_escrow" control={<Checkbox checked={this.state.paymentOptions?.map((paymentOption) => paymentOption.name).includes("basic_escrow")} name="basic_escrow" onChange={this.handleCheck} />} label={paymentsComponentsMap["basic_escrow"].title} />
                                                                    <div className="fullRowContent">
                                                                        {paymentsComponentsMap["basic_escrow"].description}
                                                                        <div className="totalInvestment">
                                                                            <p>
                                                                                <span>Fee</span>
                                                                                <span className="price"><strong>{paymentsComponentsMap["basic_escrow"].freelancer_fee * 100}</strong>%</span>
                                                                            </p>
                                                                            {paymentsComponentsMap["basic_escrow"].extra_label}

                                                                            <p><small>Your customer may also have to pay a fee to use this payment system. It won't affect your earnings.</small></p>
                                                                        </div>

                                                                        <div className="clear"></div>
                                                                    </div>
                                                                    <FormControlLabel className="fullRow" value="assurance_escrow" control={<Checkbox checked={this.state.paymentOptions?.map((paymentOption) => paymentOption.name).includes("assurance_escrow")} name="assurance_escrow" onChange={this.handleCheck} />} label={paymentsComponentsMap["assurance_escrow"].title} />
                                                                    <div className="fullRowContent">
                                                                        {paymentsComponentsMap["assurance_escrow"].description}
                                                                        <div className="totalInvestment">
                                                                            <p>
                                                                                <span>Fee</span>
                                                                                <span className="price"><strong>{paymentsComponentsMap["assurance_escrow"].freelancer_fee * 100}</strong>%</span>
                                                                            </p>
                                                                            {paymentsComponentsMap["assurance_escrow"].extra_label}
                                                                            <p><small>Your customer may also have to pay a fee to use this payment system. It won't affect your earnings.</small></p>
                                                                        </div>

                                                                        <div className="clear"></div>
                                                                    </div>
                                                                    <FormControlLabel className="fullRow" value="flexible" control={<Checkbox checked={this.state.paymentOptions?.map((paymentOption) => paymentOption.name).includes("flexible")} name="flexible" onChange={this.handleCheck} />} label={paymentsComponentsMap["flexible"].title} />
                                                                    <div className="fullRowContent">
                                                                        {paymentsComponentsMap["flexible"].description}
                                                                        <div className="totalInvestment">
                                                                            <p>
                                                                                <span>Fee</span>
                                                                                <span className="price"><strong>varies</strong></span>
                                                                            </p>

                                                                            {paymentsComponentsMap["flexible"].extra_label}

                                                                            <p><small>Your customer may also have to pay a fee to use this payment system. It won't affect your earnings.</small></p>
                                                                        </div>

                                                                        <div className="clear"></div>
                                                                    </div>
                                                                    <div className="clear"></div>
                                                                </FormGroup>
                                                            </div>}
                                                        </GridListTile>
                                                    </GridList>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Container>
                    </main>
                </div>
            </div>
        )
    }
}

export default MakeProposal